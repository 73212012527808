import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../utils/TypeScript'
import { onIdTokenChanged } from 'firebase/auth'
import { auth } from '../Firebase'
import { addUser } from "../redux/slice/authSlice";





import CallToAction from '../components/callToAction/CallToAction'
import AsideBar from '../components/global/AsideBar/AsideBar'
import ProfileBar from '../components/global/ProfileBar/ProfileBar'
import ProfileComp from "../components/profileComps/ProfileComp";
import Toast from "../components/Alert/Toast";
import { toast } from "react-toastify";

const ProfilePage = () => {

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector(state => state.auth)


  // useEffect(() => {

  //   const unsubscribe = onIdTokenChanged(auth, async (user) => {
  //     if(user){
  //       // const providerId = user.providerData.some(
  //       //   p => p.providerId === 'password'
  //       // );

  //       // if(providerId && !user.emailVerified){
  //       //   await sendEmailVerification(user)
  //       //   await signOut(auth)
  //       //   return navigate("/email-verified");
         
  //       // }

  //       dispatch(addUser(user))
  //     }else{
  //       dispatch(addUser(undefined))
  //       toast.error(() => <Toast title="Error"
  //           body="Please Login!" />)
  //       return navigate("/login");
  //     }
  //   })

  //   return unsubscribe;

  // }, [dispatch, navigate])

  useEffect(() => {
    if(!currentUser){
      toast.error(() => <Toast title="Error"
        body="Please Login!" />)
       return navigate("/login");
    }
  }, [ navigate, currentUser])



  return (
    <div>
        <ProfileBar />

        <div id="content" className="py-4">
            <div className="container">
                <div className="row">

                  {
                    currentUser

                    &&

                    <ProfileComp />

                  }


                  {
                    currentUser

                    &&

                    <AsideBar />

                  }


                    

                </div>
            </div>
        </div>

        <CallToAction />
    </div>
  )
}

export default ProfilePage