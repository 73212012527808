import React from 'react'
// import {  useAppSelector } from '../utils/TypeScript'

import CallToAction from '../components/callToAction/CallToAction'
import FaqHeader from '../components/faq/FaqHeader'
import PaymentAssurance from '../components/PaymentAssurance/PaymentAssurance'
import WeAreHere from '../components/faq/WeAreHere'
import Questions from '../components/faq/Questions'

const SupportPage = () => {


  return (
    <div className='faq-page'>
        <FaqHeader />

        <WeAreHere />

        <Questions />


        <PaymentAssurance />

        <CallToAction />
    </div>
  )
}

export default SupportPage