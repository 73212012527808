import React from 'react'
import TermsContent from '../components/termsAndConditions/TermsContent'
import TermsAndConditionsHeader from '../components/termsAndConditions/TermsHeader'

const TermsAndContionsPage = () => {
  return (
    <div>
        <TermsAndConditionsHeader />

        <TermsContent />
    </div>
  )
}

export default TermsAndContionsPage