import React from 'react'
import { Link } from "react-router-dom"
import Form from './Form'

import  One from "../assets/images/feature/1.png"
import  Two from "../assets/images/feature/2.png"
import  Seven from "../assets/images/feature/7.png"
import  Nine from "../assets/images/feature/9.png"
import  Ten from "../assets/images/feature/10.png"

const RechargeForm = () => {
  return (
    
    
    <section className="main-form py-0">
            <div className="container">
                <div className="row not-for-mobile" style={{
                    animation: "slideInFromTop 1s ease-in"
                }}>
                    <div className="col-md-12">
                        <ul className="bill-items nav nav-tabs font-weight-bold" id="teleporter">
                            <li className="nav-item active"><Link to="/select-recharge" className="nav-link"><i className="fas fa-mobile"></i>Airtime</Link></li>
                            
                            <li className="nav-item"><Link to="/data-select" className="nav-link"><i className="fas fa-network-wired"></i>Data</Link></li>
                            {/* <li className="nav-item"><Link to="/data-select" className="nav-link"><i className="fas fa-network-wired"></i>Data</Link></li> */}
                            
                            <li className="nav-item"><Link to="/select-cabletv" className="nav-link"><i className="fas fa-tv"></i>Cable TV</Link></li>

                            {/* <li className="nav-item"><Link to="/select-cabletv" className="nav-link"><i className="fas fa-credit-card"></i>Cable TV</Link></li> */}
                            
                            <li className="nav-item"><Link to="/select-electricity" className="nav-link"><i className="fas fa-lightbulb"></i>Electricity</Link></li>

                            <li className="nav-item"><Link to="/select-betting" className="nav-link"><i className="fas fa-futbol"></i>Betting</Link></li>

                            <li className="nav-item"><Link to="/select-education" className="nav-link"><i className="fas fa-book"></i>Education</Link></li>
                            
                            
                            <li className="nav-item"><Link to="/select-govt-levy" className="nav-link"><i className="fas fa-receipt"></i>Govt Levy</Link></li>
                            
                            <li className="nav-item"><Link to="/select-bodies" className="nav-link"><i className="fas fa-dna"></i>Bodies</Link></li>
                            
                            <li className="nav-item"><Link to="#" className="nav-link"><i className="fas fa-coins"></i>Savings</Link></li>
                            
                            
                            <li className="nav-item"><Link to="#" className="nav-link"><i className="fas fa-lightbulb"></i>Inverters</Link></li>
                            
                            <li className="nav-item"><Link to="#" className="nav-link"><i className="fa fa-award"></i>Pension</Link></li>
                            
                           
                        </ul>
                            <ul className="morebtn">
                                <li>
                                    <Link to="#" className="nav-link mbtn"><i className="fas fa-ellipsis-v"></i>More</Link>
                                    <ul className="dropdown-menu" id="receiver">
        
                                    </ul>
                                </li>
                            </ul>
        
                        </div>
                </div>



                <div className="row for-mobile">
                    <div className="col-md-12">
                        <ul className="bill-items nav nav-tabs for-mobile-ul" id="teleporter ">
                            <li className="nav-item active"><Link to="/select-recharge" className="nav-link"><i className="fas fa-mobile"></i>Airtime</Link></li>
                            
                            <li className="nav-item"><Link to="/data-select" className="nav-link"><i className="fas fa-network-wired"></i>Data</Link></li>

                            
                        </ul>
                            <ul className="morebtn">
                                

                                <li>
                                    <div className="dropdown nav-link ">
                                        <i className="fas fa-ellipsis-v"></i>
                                        <button className="btn dropdown-toggle " type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            More
                                        </button>
                                        <div className="dropdown-menu mt-0" aria-labelledby="dropdownMenu2">


                                            <span className="dropdown-item"><Link to="/select-electricity" className="nav-link"><i className="fas fa-lightbulb"></i>Electricity</Link></span>

                                            <span className="dropdown-item"><Link to="/select-cabletv" className="nav-link"><i className="fas fa-tv"></i>Cable TV</Link></span>

                                            <span className="dropdown-item"><Link to="/select-insurance" className="nav-link"><i className="fas fa-dna"></i>Insurance</Link></span>

                            
                                            <span className="dropdown-item"><Link to="#" className="nav-link"><i className="fas fa-futbol"></i>Betting</Link></span>
                                            
                                            <span className="dropdown-item"><Link to="/select-govt-levy" className="nav-link"><i className="fas fa-receipt"></i>Govt Levy</Link></span>
                                            
                                            <span className="dropdown-item"><Link to="#" className="nav-link"><i className="fas fa-coins"></i>Savings</Link></span>
                                            
                                            <span className="dropdown-item"><Link to="#" className="nav-link"><i className="fas fa-book"></i>Education</Link></span>
                                            
                                            <span className="dropdown-item"><Link to="#" className="nav-link"><i className="fas fa-lightbulb"></i>Inverters</Link></span>
                                            
                                            <span className="dropdown-item"><Link to="#" className="nav-link"><i className="fa fa-award"></i>Pension</Link></span>

                                        </div>
                                    </div>
                                </li>
                            </ul>
        
                        </div>
                </div>


    
                <div className="row d-flex">
                    <div className="col-md-3 form-area pt-5">
                        <h2 className="text-4 mb-3 font-weight-bold text-center" 
                        style={{color: "#6C0BA9"}}>Buy Airtime</h2>
                        <Link to="/select-recharge">
                            <Form />
                        </Link>


                    </div>
                    <div className="col-md-9 mx-auto pt-0">
                        <section className="featured-offer pb-0">
                            <div className="">
                                <div className="row">
                                    <div className=" col-md-12 m-auto text-left">
                                        <div className="sec-heading mb-3">
                                            <span className="tagline font-weight-bold text-center" style={{color: "#6C0BA9"}}
                                            >Featured Offer</span>
                                            {/* <h2 className="text-1 mb-3 font-weight-bold text-center">Featured Offer</h2> */}
                                        </div>
                                    </div>
                                </div>
                                
                                
                                <div className="row featured-image-container">
                                    <div className="col-md-12 m-auto">
                                        <div className="featuredOfferBox">
                                            <div className="featuredOffer-caro fc-2 owl-carousel" data-slide="3" data-return="true">
                                                
                                                

                                                <div className="featuredItems">

                                                    <img src={Two} alt="" className="featuredimage" />
                                                </div>
                                               
                                                <div className="featuredItems">

                                                    <img src={Seven} alt="" className="featuredimg" />
                                                </div>

                                                {/* <div className="featuredItems">

                                                    <img src={One} alt="" className="featuredimage" />
                                                </div> */}
                                                
                                                <div className="featuredItems">

                                                    <img src={Nine} alt="" className="featuredimg" />
                                                </div>

                                                <div className="featuredItems">

                                                    <img src={Ten} alt="" className="featuredimg" />
                                                </div>

                                                
                                               
                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
    </section>
    
  )
}

export default RechargeForm