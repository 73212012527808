import React, { FormEvent, useState } from 'react'
import { Link, useNavigate  } from "react-router-dom"
import { IcurrentRechargeOrder, useAppDispatch } from "../../utils/TypeScript"

import { toast } from 'react-toastify'
import { validateHomeRechargeForm } from '../../utils/Validation'
import Toast from '../Alert/Toast'
// import { addAirtimeOrder } from '../../redux/slice/airtimeSlice'
import { getBiller } from '../../utils/billers'
import Spinner from '../Alert/Spinner/Spinner'

import  One from "../assets/images/feature/1.png"


const Form = () => {

    const billers = [
        {name: "Select Your Operator", value: "empty" },
        {name: "9mobile", value: "9MOBILE AIRTIME"},
        {name: "Airtel", value: "Airtel"},
        {name: "Glo", value: "Glo"},
        {name: "MTN", value: "MTN"},
        {name: "NTEL", value: "ntel Airtime"}
    ]


    const [loading, setLoading] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const [biller, setBiller] = useState("")
    const [amount, setAmount] = useState("")

    const navigate = useNavigate()
    const dispatch = useAppDispatch()



    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)

        const rechargeOrderDetails = { phoneNumber, biller, amount, email }

        const result = validateHomeRechargeForm(rechargeOrderDetails)
        

        if(result.errLength){

            toast.error(() => <Toast title="Error"
            body={result.errMsg} />)

            setLoading(false)
            return 
        }

        const selectedBiller = await getBiller(biller)


        

        if(!selectedBiller.productId){
            setLoading(false)
            return 

        }else {

            const rechargeOrder: IcurrentRechargeOrder = {
                billerCode: selectedBiller.billerCode,
                productId: selectedBiller.productId,
                transactionDetails: [
                    {
                    fieldName: "Amount",
                    fieldValue: amount,
                    fieldControlType : "TEXTBOX"
                    },
                    {
                    fieldName: "Email",
                    fieldValue: email,
                    fieldControlType : "TEXTBOX"
                    },
                    {
                    fieldName: "Phone Number",
                    fieldValue: phoneNumber,
                    fieldControlType : "TEXTBOX"
                    },
                    {
                    fieldName: "Product",
                    fieldValue: selectedBiller.productName,
                    fieldControlType : "LOOKUP"
                    }
                ]
            }
    
           
            // dispatch(addAirtimeOrder(rechargeOrder))
            navigate("/select-recharge") 
            setLoading(false)
        }       
        
        
    }


  return (
    <form id="form-area" method="post" onSubmit={handleSubmit}>

<div className="featuredItems px-4">

<img src={One} alt="" className="featuredimage" />
</div>
       

        {/* <div className="form-row">

                <div className="col-md-12 form-group">
                    <select className="custom-select" id="operator" 
                    value={biller} onChange={e => setBiller(e.target.value)}
                >

                    {
                        billers.map((biller, index) =>
                            <option key={index} value={biller.value}>{biller.name}</option>  
                        )
                    }
                </select>
            </div>

            <div className="col-md-12 form-group">
                <input type="number" className="form-control" data-bv-field="number" id="mobileNumber" placeholder="Enter Mobile Number" 
                name="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}
                />
            </div>
           
            <div className="col-md-12">
                <div className="form-group input-group">
                    <div className="input-group-prepend"> <span className="input-group-text">₦</span> </div>
                    <input type="number" className="form-control" id="amount" placeholder="Enter Amount" 
                    name="amount" value={amount} onChange={e => setAmount(e.target.value)}
                    />
                </div>
            </div>

            <div className="col-md-12 form-group">
                <input type="email" className="form-control" data-bv-field="email" id="email" placeholder="Enter Email" 
                name="email" value={email} onChange={e => setEmail(e.target.value)}
                />
            </div>

            <div className="col-md-12 form-group">
                <button type='submit' className="btn btn-filled btn-block">Continue</button>
            </div>
        </div>
        { loading && <Spinner /> } */}
    </form>
  )
}

export default Form