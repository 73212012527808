import React, {  useEffect } from 'react'
import { Link } from "react-router-dom"
import { addEducationPhoto } from '../../redux/slice/educationSlice/educationPhoto'

import {  useAppDispatch, useAppSelector } from "../../utils/TypeScript"

import Education1 from "../assets/images/education/1.webp"
import Education2 from "../assets/images/education/2.gif"
import JambFormComp from './JambForm'
import WAECFormComp from './WaecForm'


const EducationOrderComp = () => {


    const { currentEducationPhoto }  = useAppSelector((state) => state.educationPhoto)

    const dispatch = useAppDispatch()


    useEffect(()=>{
        const educationPhoto =  JSON.parse(localStorage.getItem('educationPhoto') || "{}");
        dispatch(addEducationPhoto(educationPhoto))

    }, [])
    

  return (
    <div className="col-lg-9">
                    <div className="row mx-1">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3 ">
                                    <div className="mr-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>{
                                            currentEducationPhoto?.billerCode === "WAEC" ? "WAEC" : currentEducationPhoto?.billerCode === "JAMB" ? "JAMB" : ""
                                        }</h4>
                                    </div>
                                    <div className="ml-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>Amount</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills mx-3">
                           
                            <li className="nav-item">
                                <Link className="nav-link active" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                           
                            <li className="nav-item">
                                <Link className="nav-link" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            
                            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                  
                                    <div className="operators mx-3">
                                       
                                       <div className="row operator-row" id='operator-row'>
                                          
                                       <div className="col-md-2 col-sm-4">
                                               <div className="single-operator">
                                                   <div className="op-logo" data-operator="op1">
                                                       <img src={currentEducationPhoto?.billerCode === "WAEC" ? Education1 : currentEducationPhoto?.billerCode === "JAMB" ? Education2 : ""} alt="Cable TV Logo" />
                                                   </div>
                                               </div>
                                           </div>
                                          
                                           
                                       </div>
                                   </div>


                                {currentEducationPhoto?.billerCode === "WAEC" ? <WAECFormComp /> : currentEducationPhoto?.billerCode === "JAMB" ? <JambFormComp /> : ""}
                            </div>

                        </div>
                    </div>

                </div>
  )
}

export default EducationOrderComp