import React, { useEffect, useState } from 'react'
import XPay from "npm_xpresspay";
import {  useNavigate  } from "react-router-dom"

import { Link } from "react-router-dom"
import { useAppSelector } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner';
import axios from "axios";
import { generateTransactionId, LiveBaseURL } from "../../utils/config";

const MultipleRechargeSummaryComp = () => {

  const tranId = localStorage.getItem("tranId") || "";


    const [state, setState] = useState({
        amount: "",
        loading: false,
        transactions: [],
    });

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    

    const { currentAirtimePhoto } = useAppSelector(state => state.airtimePhoto)
    const { currentMultipleAirtimeOrder } = useAppSelector(state => state.multipleAirtime)
    
    const totalAmount = currentMultipleAirtimeOrder? Number(currentMultipleAirtimeOrder.totalAmount)  : ""

    const userEmail =  currentMultipleAirtimeOrder?.userEmail === ""?  "info@preponebills.com" :   currentMultipleAirtimeOrder?.userEmail

    const publicKey =  process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC;



    const PaymentGateway = async () => {
      let transId = generateTransactionId();
        
        setState({ ...state, loading: true });
        setLoading(true)
        try {
          // XPay.InitialisePayment({
          //   amount: `${totalAmount}`,
          //   transactionId: transId,
          //   email: `${userEmail}`,
          //   // publicKey: `${process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_TEST}`,
          //   publicKey: `${process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC}`,
          //   currency: "NGN",
          //   mode: "Production",
          //   // "mode": "Debug",
          //   callbackUrl: window.location.href,
          //   metadata: [
          //     {
          //       name: "sample",
          //       value: "test",
          //     },
          //   ],
          // }).then((response: any) => {
          //   if (response.success) {
          //     setState({ ...state, amount: `${totalAmount}`, loading: false });
          //     sessionStorage.setItem("tranId", transId); // it can be saved to Database.
          //     sessionStorage.setItem("reference", response.data?.reference); // it can be saved to Database
          //     localStorage.setItem('tranId', transId);
             
          //     window.location.href = response.data?.authorizeUrl;
          //     setLoading(false)
          //   } else {
          //     setState({ ...state, amount: `${totalAmount}`, loading: false });
             
          //     window.location.href = response.data?.authorizeUrl;
          //     setLoading(false)
          //   }
          // });
        
        
          const response = await axios.post(
            `${LiveBaseURL}/api/Payments/Initialize`,
            {
              amount: `${totalAmount}`,
              transactionId: transId,
              email: `${userEmail}`,
              publicKey: `${process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC}`,
              currency: "NGN",
              mode: "Live",
              callbackUrl: window.location.href,
              metadata: [
                {
                  name: "sample",
                  value: "test",
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${publicKey}`,
                "Content-Type": "application/json",
              },
            }
          );
    
          if (response?.data?.data?.paymentUrl) {
            setState({ ...state, amount: `${totalAmount}`, loading: false });
            sessionStorage.setItem("tranId", transId);
            sessionStorage.setItem("reference", response?.data?.reference);
            localStorage.setItem("tranId", transId);
    
            window.location.href = response?.data?.data?.paymentUrl;
            setLoading(false);
          } else {
            setState({ ...state, amount: `${totalAmount}`, loading: false });
            window.location.href = response?.data?.data?.paymentUrl;
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
    }




    const VerifyPayment = async () => {    
      setLoading(true);
      const tranId = localStorage.getItem("tranId") || "";
      const publicKey =
        process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC;
  
      if (!publicKey) {
        setLoading(false);
        return;
      }
      
      if (!tranId) {
        setLoading(false);
        return;
      }
  
      try {
  
        const response = await axios.post(
          `${LiveBaseURL}/api/Payments/VerifyPayment`,
          {
            publicKey,
            transactionId: tranId,
            mode: "Live",
          },
          {
            headers: {
              Authorization: `Bearer ${publicKey}`,
              "Content-Type": "application/json",
            },
          }
        );
      
        const { data } = response;
  
        if (data?.data?.isSuccessful) {
          const amount = data?.amount;
  
          setState({ ...state, amount, transactions: data?.history });
          console.log("Transaction successful");
          localStorage.removeItem("tranId");
          localStorage.setItem("payment", "i");
          navigate("/multiple-airtime-done")
        } else {
          console.log("Transaction not successful:", data?.responseMessage);
          setState({ ...state, amount: "" });
          localStorage.setItem("payment", "o");
        }
      } catch (error) {
        console.log("Error verifying payment:", error);
        setState({ ...state, amount: "" });
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      VerifyPayment();
    }, [tranId]);









  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i> {
                                          currentAirtimePhoto?.billerCode === '9MOBILE_69358' ? "9Mobile Airtime"
                                          : 
                                          currentAirtimePhoto?.billerCode === 'AIRTEL_22689' ? "Airtel Airtime"
                                          : 
                                          currentAirtimePhoto?.billerCode === 'GLO_30387' ? "Glo Airtime"
                                          : 
                                          currentAirtimePhoto?.billerCode === 'MTN_24207' ? 'MTN Airtime'
                                          :
                                          currentAirtimePhoto?.billerCode === 'NTEL' ? "Ntel Airtime"
                                          :
                                          ""
                                        }</h4>
                                    </div>
                                    <div className="ml-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>₦
                                        { totalAmount }
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">

                            <li className="nav-item">
                                <Link className="nav-link" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to="">Summary</Link>
                            </li>
                            
                            <li className="nav-item">
                                <Link className="nav-link" to="">Done</Link>
                            </li>
                        </ul>


                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade  show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <div className="order-summery">
                                    <h3 className="title">Order Summary</h3>
                                    <div className="order-details-area table">

                                        


                                        <div className=" table-content">
                                            <div className="row">
                                                <div className="col">Email</div>
                                                <div className="col text-right">
                                                  {
                                                    currentMultipleAirtimeOrder?.userEmail
                                                  }
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            currentMultipleAirtimeOrder?.details.map((item, index) =>(

                                            <div className="bg-offwhite table-content" key={index}>
                                                <div className="row">
                                                    <div className="col">Phone Number {index+1}</div>

                                                    <div className="col text-right">
                                                    {
                                                        item.phoneNumber
                                                    }
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            ))
                                        }





                                        <div className="row">
                                            <div className="col-md-5 ml-auto">
                                                <div className="table-content">

                                                    {
                                                        currentMultipleAirtimeOrder?.details.map((item, index) =>(
                
                                                            <div className="row" key={index}>
                                                                <div className="col">Amount {index+1}</div>
                                                                <div className="col text-right">₦
                                                                {
                                                                    item.amount
                                                                }
                                                                </div>
                                                            </div>
                                                            ))
                                                    }



                                                    <hr />
                                                    <div className="row">
                                                        <div className="col">Service Charge</div>
                                                        <div className="col text-right">₦0.00</div>
                                                    </div>
                                                    <hr />
                                                  
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col font-weight-bold">Total</div>
                                                        <div className="col text-right font-weight-bold">₦
                                                        {
                                                          totalAmount
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                               

                                <ul className="pager my-5">
                                    <li>
                                        <Link to="/recharge-order" className="btn btn-default "><i className="fas fa-chevron-left"></i> Back</Link>
                                    </li>

                                    <li>
                                        <Link to="" className="btn btn-default "
                                        onClick={() => PaymentGateway()}
                                        > Next <i className="fas fa-chevron-right"></i></Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    { loading && <Spinner /> }
                </div>
  )
}

export default MultipleRechargeSummaryComp