import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../../Alert/Toast'
import { IProfile, useAppDispatch, useAppSelector } from "../../../utils/TypeScript"
import Spinner from '../../Alert/Spinner/Spinner'

import {  IElectricityOrder } from '../../../utils/electricityTypes/electricityTypes'
import { validatePHEDCForm } from '../../../utils/ValidElectricity/ValidateElectricity'
import { lookupElectricity } from '../../../utils/lookup/lookupTrans'
import { profileUpdate } from '../../../redux/slice/profileSlice/profileSlice'
import { addElectricityOrder } from '../../../redux/slice/electricitySlice/electSlice'





const EnuguForm = () => {

  const [loading, setLoading] = useState(false)

  const [meterType, setMeterType] = useState("")
  const [meterOrAccountNumber, setMeterOrAccountNumber] = useState("")
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  
  const { profile } = useAppSelector(state => state.profile)
  const { currentUser } = useAppSelector(state => state.auth)


  const dispatch = useAppDispatch()
  const navigate = useNavigate()


  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    const phedcOrderDetails = { amount, email, meterType, meterOrAccountNumber, phoneNumber }

    
    const result = validatePHEDCForm(phedcOrderDetails)

    if(result.errLength){

      toast.error(() => <Toast title="Error"
      body={result.errMsg} />)

      setLoading(false)
      return
    }


    let transactionId = "PreponeBills" + Math.floor(Math.random() * 1000000);


    const phedLookupObj = {
      requestId: transactionId,
      uniqueCode: meterType === "PREPAID" ? "ENUELE_59185" : meterType === "POSTPAID" ? "ENUELE_36694" : "",
      details: {
        accountNumber: meterOrAccountNumber,
        accountType: meterType

      }
    }
    const lookupResult = await lookupElectricity(phedLookupObj)
    const { data, responseMessage } = lookupResult
    // console.log(lookupResult)


    if(responseMessage.toLowerCase() === "successful"){
      // Save Transaction details for later
        if(currentUser){
        
            const dataa: IProfile = {
                meterNo: meterOrAccountNumber, 
                phoneNo: phoneNumber,
                userEmail: email,
                smartCardNumber: profile?.smartCardNumber ? profile?.smartCardNumber : ""
            }

         await dispatch(profileUpdate({user: currentUser, dataa}))
        }

        const electricityOrder: IElectricityOrder = {
          requestId: transactionId,
          uniqueCode: meterType === "PREPAID" ? "ENUELE_59185" : meterType === "POSTPAID" ? "ENUELE_36694" : "",
          details: {
            accountNumber: meterOrAccountNumber,
            accountType: meterType,
            amount: Number(amount),
            name: data?.name,
            address: data?.address,
            phoneNumber,
            email
          }
        }

        dispatch(addElectricityOrder(electricityOrder))
        localStorage.setItem("electricityOrder", JSON.stringify(electricityOrder));

        // console.log(electricityOrder);

        navigate("/electricity-summary") 
        setLoading(false)

     
   } else {

      setLoading(false)
      return toast.error("Could not find the user with this Meter or Account Number!") 

    }
    
}

useEffect( ()=>{
  if(!profile) return;
  setPhoneNumber(profile.phoneNo)
  setEmail(profile.userEmail)
  setMeterOrAccountNumber(profile.meterNo)
}, [profile])






  return (
    <form onSubmit={handleSubmit} className="mx-3 mt-4">

      <span className='text-danger' style={{fontSize: "0.9rem"}}>
        {
          currentUser ? "" : "Login to remember details next time."
        }
        
        </span>
      <div className="form-row">
     
        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlInput04">Meter Type <span className='text-danger'>*</span></label>

          <select className="custom-select form-control" id="operator" name="product" value={meterType} onChange={e => setMeterType(e.target.value)} >
              <option value="">Select Meter Type</option>
              <option value="POSTPAID">POSTPAID</option>
              <option value="PREPAID">PREPAID</option>
          </select>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Meter or Account Number <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter meter or account number" name="customerNumber" value={meterOrAccountNumber} onChange={e => setMeterOrAccountNumber(e.target.value)} />
        </div>

      </div>


      <div className="form-row">

        

        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Enter Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)}/>
        </div>

        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'>*</span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>

      </div>


      <div className="form-row">

        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'></span></label>
            <input type="number" className="form-control" data-bv-field="customerNumber" id="amount"  placeholder="Enter phone number" name="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
        </div>

      </div>

      


      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default EnuguForm