import { IElectricityOrderDetails } from './electricityTypes/electricityTypes';
import { IUserRegister, IContactForm, IUserLogin, IHomepageRechargeForm, IBillingDetails, IDataForm, IDSTVOrder, IGOTVOrder, IStartimesOrder, ValidFIRSOrder, ValidLCCOrder, ValidNSEOrder, ValidRIVsOrder } from './TypeScript'


export const validateRegisterDetails = (user: IUserRegister) => {
    const { name, email, password, cf_password, agreeToTerms } = user;

    const errors: string[] = [];

    if(!name.trim()){
      errors.push("Please add your name.")
    }else if(name.length > 20){
      errors.push("Your name is up to 20 chars long.")
    }
  
    if(!email.trim()){
      errors.push("Please enter your email.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }
    
    const msg = checkPassword(password, cf_password)
    if(msg) errors.push(msg)

    
    if(agreeToTerms !== true ){
      errors.push("You need to see our terms & condition.")
    }
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
}

export const validateLoginDetails = (userLogin: IUserLogin) => {
    const { email, password } = userLogin;
    const errors: string[] = [];
  
  
    if(!email){
      errors.push("Please enter your email.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

    if(!password){
      errors.push("Please enter your password.")
    }
  
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
}

export const checkPassword = (password: string, cf_password: string) => {
    if(password.length < 6){
      return ("Password must be at least 6 chars.")
    }else if(password !== cf_password){
      return ("Confirm password did not match.")
    }
}


export function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export const validateContactForm = (contactForm: IContactForm) => {
    const { firstName, lastName, email, message, } = contactForm
  
    const errors = [];
  
    if(!firstName.trim() && !lastName.trim()){
      errors.push("Please add a first or last name.")
    }else if(firstName.length > 20  ||  lastName.length > 20){
      errors.push("Your name should 20 chars long.")
    }
  
    if(!email){
      errors.push("Please add your email.")
    }else if(!validateEmail(email)){
      errors.push("Email format is incorrect.")
    }
  
    if(!message){
      errors.push("Please add your Message.")
    }
    
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
}


export const validateHomeRechargeForm = (HomepageRechargeDetails: IHomepageRechargeForm) => {
  const {  phoneNumber, biller, amount, email } = HomepageRechargeDetails

  const errors = [];

  // if(!prepaid){
  //   errors.push("Please pick either prepaid.")
  // }else if(prepaid === true && postpaid === true){
  //   errors.push("please refresh the page.")
  // }

  if(!phoneNumber){
    errors.push("Please add your phone number.")
  }

  if(!biller){
    errors.push("Please select a mobile operator.")
  }

  if(!amount){
    errors.push("Please enter amount.")
  }else if( Number(amount) < 100 ){
    errors.push("Minimum amount should be ₦100.")
  }

  if(!email){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }


}


// export const validateDataForm = (dataOrderDetails: IDataForm) => {
//   const {  phoneNumber, biller, listItem, email } = dataOrderDetails

//   const errors = [];

//   // if(!prepaid){
//   //   errors.push("Please pick either prepaid.")
//   // }else if(prepaid === true && postpaid === true){
//   //   errors.push("please refresh the page.")
//   // }

//   if(!phoneNumber){
//     errors.push("Please add your phone number.")
//   }

//   if(!biller){
//     errors.push("Please select a mobile operator.")
//   }

//   if(!listItem){
//     errors.push("Please select a data plan.")
//   }

//   if(!email){
//     errors.push("Please enter your email.")
//   }else if( !validateEmail(email)){
//     errors.push("Email format is incorrect.")
//   }
  

//   return {
//     errMsg: errors,
//     errLength: errors.length
//   }
// }


export const validateElectricityOrder = (electricityOrderDetails: IElectricityOrderDetails) => {
  const {  meterType, meterNumber, amount, email } = electricityOrderDetails

  const errors = [];

  if(!meterType){
    errors.push("Please select a meter type.")
  }

  if(!meterNumber){
    errors.push("Please add your meter number.")
  }

  if(!amount){
    errors.push("Please enter amount.")
  }else if( Number(amount) < 100 ){
    errors.push("Minimum amount should be ₦100.")
  }

  if(!email){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}

export const validateBillingDetails = (billingDetails: IBillingDetails) => {
  const { cardNumber, expiryDate, cvv, nameOnCard} = billingDetails

  const errors = [];

  if(!cardNumber){
    errors.push("Please enter card number.")
  }

  if(!expiryDate){
    errors.push("Please add expiry date.")
  }

  if(!cvv){
    errors.push("Please add cvv number.")
  }

  if(!nameOnCard){
    errors.push("Please add full name on card.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}


export const validateForgotPasswordDetail = (email: string) => {
  // const { cardNumber, expiryDate, cvv, nameOnCard} = billingDetails

  const errors = [];

  if(!email.trim()){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}



export const validateDSTVOrder = (dSTVOrder: IDSTVOrder) => {
  const {  smartCardNumber, email, numberOfMonths, phoneNumber, uniqueCode } = dSTVOrder

  const errors = [];

  if(!smartCardNumber){
    errors.push("Please enter Smart Card Number.")
  } else if(smartCardNumber.length < 2){
    errors.push("Enter a valid Smart Card Number.")
  }

  if(!uniqueCode){
    errors.push("Please select a package.")
  } 

  if(!email){
    errors.push("Please enter email")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }

  if(!numberOfMonths){
    errors.push("Please select number of month(s).")
  }

  if(!phoneNumber){
    errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}



export const validateGOTVOrder = (gOTVOrder: IGOTVOrder) => {
  const {  smartCardNumber, email,  phoneNumber, uniqueCode } = gOTVOrder

  const errors = [];

  if(!smartCardNumber){
    errors.push("Please enter Smart Card Number.")
  } else if(smartCardNumber.length < 2){
    errors.push("Enter a valid Smart Card Number.")
  }

  if(!uniqueCode){
    errors.push("Please select a package.")
  } 

  if(!email){
    errors.push("Please enter email")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }

  if(!phoneNumber){
    errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}


export const validateStartimesOrder = (startimesOrder: IStartimesOrder) => {
  const {  smartCardNumber, email, uniqueCode,  phoneNumber } = startimesOrder

  const errors = [];

  if(!smartCardNumber){
    errors.push("Please enter Smart Card Number.")
  } else if(smartCardNumber.length < 2){
    errors.push("Enter a valid Smart Card Number.")
  }

  if(!uniqueCode){
    errors.push("Please select a package.")
  }

  if(!email){
    errors.push("Please enter email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }


  if(!phoneNumber){
    errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  }else if(phoneNumber.length > 11){
    errors.push("[Please enter a valid phone number]")
  }

  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}

export const validateValidFIRSOrder = (dSTVOrder: ValidFIRSOrder) => {
  const {  tin, email, amount, phoneNumber, itemCode } = dSTVOrder

  const errors = [];

  if(!tin){
    errors.push("Please enter Tax Identification Number.")
  } else if(tin.length < 2){
    errors.push("Enter a valid Tax Identification Number.")
  }

  if(!itemCode){
    errors.push("Please select a payment package.")
  }


  if(!email){
    errors.push("Please enter email")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }

  if(!amount){
    errors.push("Please enter amount.")
  } else if(Number(amount) < 100){
    errors.push("Please enter a valid amount.")
  } else if(Number(amount) > 1000000){
    errors.push("Please enter a valid amount.")
  }

  if(!phoneNumber){
    errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}

export const validateValidLCCOrder = (dSTVOrder: ValidLCCOrder) => {
  const {  vrn, email, amount, phoneNumber, itemCode } = dSTVOrder

  const errors = [];

  if(!vrn){
    errors.push("Please enter Vehicle Registration Number.")
  } else if(vrn.length < 2){
    errors.push("Enter a valid Vehicle Registration Number.")
  }

  if(!itemCode){
    errors.push("Please select a payment package.")
  }


  if(!email){
    errors.push("Please enter email")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }

  if(!amount){
    errors.push("Please enter amount.")
  } else if(Number(amount) < 100){
    errors.push("Please enter a valid amount.")
  } else if(Number(amount) > 1000000){
    errors.push("Please enter a valid amount.")
  }

  if(!phoneNumber){
    errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}



export const validateValidateRIVSOrder = (dSTVOrder: ValidRIVsOrder) => {
  const {  sin, email, amount, phoneNumber } = dSTVOrder

  const errors = [];

  if(!sin){
    errors.push("Please enter State-issued ID Number.")
  } else if(sin.length < 2){
    errors.push("Enter a valid State-issued ID Number.")
  }


  if(!email){
    errors.push("Please enter email")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }

  if(!amount){
    errors.push("Please enter amount.")
  } else if(Number(amount) < 100){
    errors.push("Please enter a valid amount.")
  } else if(Number(amount) > 1000000){
    errors.push("Please enter a valid amount.")
  }

  if(!phoneNumber){
    errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}

export const validateValidateNSEOrder = (dSTVOrder: ValidNSEOrder) => {
  const {  nseNumber, email, amount, phoneNumber } = dSTVOrder

  const errors = [];

  if(!nseNumber){
    errors.push("Please enter NSE membership number.")
  } else if(nseNumber.length < 2){
    errors.push("Enter a valid NSE membership number.")
  }


  if(!email){
    errors.push("Please enter email")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }

  if(!amount){
    errors.push("Please enter amount.")
  } else if(Number(amount) < 100){
    errors.push("Please enter a valid amount.")
  } else if(Number(amount) > 1000000){
    errors.push("Please enter a valid amount.")
  }

  if(!phoneNumber){
    errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}



