import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentEKEDCPrepaidOrder } from '../../../utils/electricityTypes/electricityTypes'


export interface KadunaPostpaidState {
    currentKadunaPostpaidOrder?: ICurrentEKEDCPrepaidOrder,
    loading: boolean
}

const initialState: KadunaPostpaidState = {
    currentKadunaPostpaidOrder: undefined,
    loading: false,
}


const kadunapostpaidSlice = createSlice({
    name: 'kadunapostpaid',
    initialState,
    reducers: {
      addKadunaPostpaidOrder: (state, action) => {
        state.currentKadunaPostpaidOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('kadunapostpaid') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('kadunapostpaid') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addKadunaPostpaidOrder } = kadunapostpaidSlice.actions

export default kadunapostpaidSlice.reducer
