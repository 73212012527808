import React, { FormEvent, useState } from 'react'
import { useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../../Alert/Toast'
import {useAppDispatch } from "../../../utils/TypeScript"
import Spinner from '../../Alert/Spinner/Spinner'

import { validateBulkAirtimeDetails } from '../../../utils/ValidAirtime/validAirtime'
import { ICurrentBulkAirtimeOrder } from '../../../utils/AirtimeDateTypeScript/airtimeDataTypes'
// import { addAirtimeOrder } from '../../../redux/slice/airtimeSlice/airtimeSlice'
import { addBulkAirtimeOrder } from '../../../redux/slice/airtimeSlice/bulkAirtimeSlice'




const BulkMTNForm = () => {

  const [loading, setLoading] = useState(false)

  // const [product, setProduct] = useState("")
  const [phoneNumbers, setPhoneNumbers] = useState("")
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")


  
  // const { currentAirtimePhoto }  = useAppSelector((state) => state.airtimePhoto)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()



  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    const mtnOrderDetails = { amount, phoneNumbers }

    const result = validateBulkAirtimeDetails(mtnOrderDetails)

    if(result.errLength){

       toast.error(() => <Toast title="Error"
        body={result.errMsg} />)

        setLoading(false)
        return 
    }



    let bulkPhoneNumbers = phoneNumbers.split(",").map(item => item.trim());
    bulkPhoneNumbers.forEach(item => {
      if (item.length !== 11) {
        return toast.error("please enter valid phone numbers.")

      }
    });


    let transactionId = "PreponeBills-" + Math.floor(Math.random() * 1000000);

    const bulkAirtimeOrder: ICurrentBulkAirtimeOrder = {
      requestId: transactionId,
      uniqueCode: "MTN_24207",
      details: {
          phoneNumber: bulkPhoneNumbers,
          amount: Number(amount) // * Number(bulkPhoneNumbers.length)
      },
      userEmail: email
  }

       
    dispatch( addBulkAirtimeOrder(bulkAirtimeOrder))
    localStorage.setItem("bulkAirtimeOrder", JSON.stringify(bulkAirtimeOrder));
    navigate("/bulk-airtime-summary") 
    setLoading(false)
    
}

// useEffect(()=>{

 
// })

// if(currentAirtimePhoto?.billerCode !== "mtn"){
//   navigate("/select-recharge")
// }




// const fetchBiller = async () => {

//   const currentBillerCode = currentAirtimePhoto ? currentAirtimePhoto?.billerCode : 'MTN'
//   const selectetCableTVBiller = await fetchAirtimeBiller( currentBillerCode)
//   console.log(selectetCableTVBiller);

//   return selectetCableTVBiller;

// }



// useEffect( ()=>{
//   fetchBiller()
// }, [])









  return (
    <form onSubmit={handleSubmit} className="mx-3">
      <div className="form-row">
        
        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Enter Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)}/>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'></span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>

      </div>


      <div className="form-row">

        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'>*</span></label>
          <textarea className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter phone number" name="customerNumber" value={phoneNumbers} onChange={e => setPhoneNumbers(e.target.value)}></textarea>

          <span className='text-danger' style={{fontSize: "0.9rem", marginLeft: "15px"}}>  separate phone numbers with comma.  </span>

        </div>        

      </div>


      {/* <div className="form-row">
       


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Enter Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)}/>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'>*</span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>

      </div> */}


      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default BulkMTNForm