import React, { FormEvent, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import { validateBillingDetails } from '../../utils/Validation'
import Toast from '../Alert/Toast'
import { IcurrentRechargeOrder, useAppDispatch, useAppSelector } from "../../utils/TypeScript"



import PaymentOne from "../assets/images/payment-1.png"
import PaymentTwo from "../assets/images/payment-2.png"
// import PaymentThree from "../assets/images/payment-3.png"
import PaymentFour from "../assets/images/payment-3.png"
// import { addAirtimeOrder, airtimeProcessTransaction } from '../../redux/slice/airtimeSlice'
// import VisaCard from "../assets/images/visa.png"
// import MasterCard from "../assets/images/mastercard.png"


const RechargePaymentComp = () => {

    const [cardNumber, setCardNumber] = useState("")
    const [expiryDate, setExpiryDate] = useState("")
    const [cvv, setCvv] = useState("")
    const [nameOnCard, setNameOnCard] = useState("")
    // const [streetAddress, setStreetAddress] = useState("")
    // const [city, setCity] = useState("")
    // const [country, setCountry] = useState("")
    const [saveDetails, setSaveDetails] = useState(false)

    // const { currentOrder } = useAppSelector(state => state.airtime)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()



    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        const billingDetails = { cardNumber, expiryDate, cvv, nameOnCard, saveDetails }

        const result = validateBillingDetails(billingDetails)
        

        if(result.errLength){

            return toast.error(() => <Toast title="Error"
            body={result.errMsg} />)
        }

        // dispatch(addAirtimeOrder(billingDetails))



        // const currentRechargeOrder: IcurrentRechargeOrder = {
        //     billerCode: currentOrder?.mobileOperator,
        //     productId: 55,
        //     transactionDetails: [
        //         {
        //             fieldName: "Email",
        //             fieldValue: "example@gmail.com",
        //             fieldControlType: "TEXTBOX"
        //         },
        //         {
        //             fieldName: "PhoneNumber",
        //             fieldValue: currentOrder?.phoneNumber,
        //             fieldControlType: "TEXTBOX"
        //         },
        //         {
        //             fieldName: "Amount",
        //             fieldValue: currentOrder?.amount,
        //             fieldControlType: "TEXTBOX"
        //         },
        //         {
        //             fieldName: "Product",
        //             fieldValue: currentOrder?.mobileOperator,
        //             fieldControlType: "TEXTBOX"
        //         }
        //     ]
        // }


        // dispatch(airtimeProcessTransaction(currentRechargeOrder))
        navigate ("/recharge-done")
              
        
    }

  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg- light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                    {/* <h4 className="text-9 font-weight-400"><i className="fas fa-wallet mr-2"></i>{currentOrder?.transactionDetails[3].fieldValue}</h4> */}
                                    </div>
                                    <div className="ml-auto">
                                    {/* <h4 className="text-9 font-weight-400">₦{currentOrder?.transactionDetails[0].fieldValue}</h4> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">                    
                            <li className="nav-item">
                                <Link className="nav-link" to="/recharge-order">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/recharge-summary">Summary</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to="/recharge-payment">Payment</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/recharge-done" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">                            
                            
                            <div className="tab-pane fade show active" id="four" role="tabpanel" aria-labelledby="four-tab">
                               


                                <div className="credit-card my-5 ">
                                    <form onSubmit={handleSubmit}>
                                        <h3 className="title">Credit Card Information</h3>
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail09">Credit Card Number</label>
                                                    <input type="number" className="form-control" id="exampleInputEmail09" placeholder="1234 1234 1234 1234" 
                                                    name="cardNumber" value={cardNumber} onChange={e => setCardNumber(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail10">Expiration Date</label>
                                                    <input type="number" className="form-control" id="exampleInputEmail10" placeholder="MM / YY" 
                                                    name="expiryDate" value={expiryDate} onChange={e => setExpiryDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail11">Cvv</label>
                                                    <input type="number" className="form-control" id="exampleInputEmail11" placeholder="cvv"
                                                    name="cvv" value={cvv} onChange={e => setCvv(e.target.value)}
                                                     />
                                                </div>
                                            </div>

                                            <div className="col-xl-12 col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail12">Full Name on Card</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail12" placeholder="Full Name on Card" 
                                                    name="nameOnCard" value={nameOnCard} onChange={e => setNameOnCard(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="savecard" 
                                            checked={saveDetails}
                                            onChange={() => setSaveDetails(!saveDetails)}
                                            />
                                            <label className="form-check-label" htmlFor="savecard">Save my card details.</label>
                                        </div>

                                        <ul className="pager my-5">
                                            <li><Link to="/recharge-summary" className="btn btn-default "><i className="mr-2 fas fa-chevron-left"></i> Back</Link></li>
                                            {/* <li><Link to="/recharge-done" className="btn btn-default "> Next <i className="fas fa-chevron-right"></i></Link></li> */}
                                            <button type='submit' className="btn btn-default " > Pay <i className="ml-2 fas fa-chevron-right"></i></button>
                                        </ul>
                                    </form>
                                    
                                </div>


                                <div className="payment mt-5">
                                    {/* <h3 className="title">Payment Methods</h3> */}
                                    <div className="row">
                                        <div className="col-md-3 col-sm-6">
                                            <div className="single-payment">
                                                <img src={PaymentOne} alt="payment one" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="single-payment">
                                                <img src={PaymentTwo} alt="payment two" />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3 col-sm-6">
                                            <div className="single-payment">
                                                <img src={PaymentThree} alt="payment three" />
                                            </div>
                                        </div> */}
                                        <div className="col-md-3 col-sm-6">
                                            <div className="single-payment">
                                                <img src={PaymentFour} alt="payment four" />
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                {/* <div className="credit-card">
                                    <form onSubmit={handleSubmit}>
                                        <h3 className="title">Billing Address</h3>
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail05">Street Address</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail05" placeholder="street address" 
                                                    name="streetAddress" value={streetAddress} onChange={e => setStreetAddress(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail06">City</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail06" placeholder="city" 
                                                    name="city" value={city} onChange={e => setCity(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail07">Country</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail07" placeholder="Country" 
                                                    name="country" value={country} onChange={e => setCountry(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div> */}
                                {/* <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="savecard" 
                                    checked={saveDetails}
                                    onChange={() => setSaveDetails(!saveDetails)}
                                    />
                                    <label className="form-check-label" htmlFor="savecard">Save my card Details.</label>
                                </div>

                                <ul className="pager my-5">
                                    <li><Link to="/recharge-summary" className="btn btn-default "><i className="fas fa-chevron-left"></i> Back</Link></li>
                                    <li><Link to="/recharge-done" className="btn btn-default "> Next <i className="fas fa-chevron-right"></i></Link></li>
                                    <button type='submit' className="btn btn-default " ><i className="fas fa-chevron-right"></i> Next </button>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
  )
}

export default RechargePaymentComp