import React from 'react'
import { motion } from "framer-motion"
import CallToAction from '../components/callToAction/CallToAction'
import PaymentAssurance from '../components/PaymentAssurance/PaymentAssurance'
import Testimonial from '../components/testimonials/Testimonial'
import RechargeForm from '../components/RechargeForm/RechargeFormMain'
import WhatWeDo from '../components/whatwedo/WhatWeDo'
import Banner from '../components/Banner/Banner'




const Home = () => {
  return (
    <motion.div exit={{ opacity: 0 }}>
     

      <Banner />

      <RechargeForm />

      {/* <WorldWideSection /> */}



      <WhatWeDo />
      <Testimonial />
      <PaymentAssurance />
      <CallToAction />
    </motion.div> 
  )
}

export default Home