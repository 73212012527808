import { IJambOrderDetails, IWAECPINOrderDetails, IWAECREGOrderDetails } from "../TypeScript";



export function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}



export const validateJambOrder = (jambOrder: IJambOrderDetails) => {
  const {  profileCode, uniqueCode, phoneNumber } = jambOrder

  const errors = [];

  if(!profileCode){
    errors.push("Please enter profile code!.")
  } 
  // else if(smartCardNumber.length < 2){
  //   errors.push("Enter a valid Smart Card Number.")
  // }

  if(!uniqueCode){
    errors.push("Please select a package!")
  }

  if(!phoneNumber){
    errors.push("Please enter phone number!")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}



export const validateWAECPINDetails = (pinOrder: IWAECPINOrderDetails) => {
  const { uniqueCode, email, phoneNumber } = pinOrder

  const errors = [];

  if(!uniqueCode){
    errors.push("Please select a WAEC service!")
  }

  if(!email){
    errors.push("Please add your email.")
  }else if(!validateEmail(email)){
    errors.push("Email format is incorrect.")
  }

  if(!phoneNumber){
    errors.push("Please enter phone number!")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}


export const validateWAECREGDetails = (pinOrder: IWAECREGOrderDetails) => {
  const { uniqueCode, noOfCandidates, email, phoneNumber } = pinOrder

  const errors = [];

  if(!uniqueCode){
    errors.push("Please select a WAEC service!")
  }

  if(!noOfCandidates){
    errors.push("Please enter number of candidates!")
  }

  if(!email){
    errors.push("Please add your email!")
  }else if(!validateEmail(email)){
    errors.push("Email format is incorrect.")
  }

  if(!phoneNumber){
    errors.push("Please enter phone number!")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
}