import { createSlice } from '@reduxjs/toolkit'
import { ICurrentOrderGeneral } from '../../../utils/TypeScript'



export interface AirtimePhotoState {
    currentAirtimePhoto?: ICurrentOrderGeneral,
   
}

const initialState: AirtimePhotoState= {
    currentAirtimePhoto: undefined,
  
}


const airtimePhotoSlice = createSlice({
    name: 'airtimephoto',
    initialState,
    reducers: {
      addAirtimePhoto: (state, action) => {
        // state.currentOrder = action.payload
        state.currentAirtimePhoto = action.payload
      }
    },

    // extraReducers: (builder) => {
    //   builder
    //     .addMatcher(
    //       ({type}) => type.startsWith('airtimephoto') && type.endsWith('/pending'),
    //       (state) => { state.loading = true }
    //     )
    //     .addMatcher(
    //       ({type}) => type.startsWith('airtimephoto') && type.endsWith('/fulfilled'),
    //       (state) => { state.loading = false}
    //     )
    // }
})


export const { addAirtimePhoto } = airtimePhotoSlice.actions

export default airtimePhotoSlice.reducer
