import React, { useState, FormEvent } from 'react'
import { Link  } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../utils/TypeScript"
import { authLogin } from "../../redux/slice/authSlice"

import Vector from "../assets/images/vector.png"
import { validateLoginDetails } from '../../utils/Validation'
import Toast from '../Alert/Toast'
import { toast } from 'react-toastify'
import Spinner from '../Alert/Spinner/Spinner'




const LoginMainComp = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState(false)


  const { loading }  = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()


    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()

        const user = { email, password, remember }
        const result = validateLoginDetails(user)

        if(result.errLength){

            return toast.error(() => <Toast title="Error"
            body={result.errMsg} />)
        }

        dispatch(authLogin(user))
       
    }


    



  return (
    <section className="banner login-registration">
        <div className="vector-img">
            <img src={Vector} alt="vector" />
        </div>
        <div className="container" 
        // style={{ animation: "slideInFromLeft 2s ease-in" }}
        >
            <div className="row">
                <div className="col-md-6">
                    <div className="content-box">
                        <h2 className='font-weight-bold'>Login Account</h2>
                        <p>Login with your email and password to continue to PreponeBills.</p>
                    </div>
                    <form action="#" className="sl-form text-dark" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Enter Email</label>
                            <input type="email" placeholder="example@gmail.com" 
                            name="email" value={email}
                            onChange={e => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" placeholder="Password"
                            name="password" value={password}
                            onChange={e => setPassword(e.target.value)}
                            />
                        </div>

                        <div className="row">

                            <div className="form-check mx-3">
                                <input type="checkbox" className="form-check-input" 
                                checked={remember}
                                onChange={() => setRemember(!remember)}
                                />
                                <label className="form-check-label">Remember Password</label>
                            </div>
                            <div className="form-check">
                                {/* <input type="checkbox" className="form-check-input" 
                                checked={remember}
                                onChange={() => setRemember(!remember)}
                                /> */}
                                {/* <label className="form-check-label">Remember Password</label> */}

                                <Link to="/forgot-password" className='text-danger'>Forgot Password?</Link>
                            </div>
                        </div>


                        <button className="btn btn-filled btn-round" type="submit"
                            // disabled={(email && password) ? false : true}
                        >

                            <span className="bh"></span> <span>Login</span>
                        </button>

                        <p className="notice">Don’t have an account? <Link to="/signup">Signup Now</Link></p>
                    </form>
                </div>
            </div>
        </div>

        { loading && <Spinner /> }
    </section>
  )
}

export default LoginMainComp