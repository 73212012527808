import React from 'react'

const ChangePasswordComp = () => {
  return (
    <div className="content-edit-area mt-5 shadow bg-white p-4">
        <div className="edit-header">
            <h5 className="title" style={{color:"#3f0d5e"}}>Change Password</h5>
        </div>
        <div className="edit-content">
            <form id="change-password" >
                <div className="form-group">
                    <label htmlFor="Current-pass">Confirm Current Password</label>
                    <input type="text" className="form-control" data-pr-form="Current-pass" id="Current-pass" required placeholder="Enter Current Password" />
                </div>
                <div className="form-group">
                    <label htmlFor="new-password">New Password</label>
                    <input type="text" className="form-control" data-pr-form="new-password" id="new-Password" required placeholder="Enter New Password" />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm New Password</label>
                    <input type="text" className="form-control" data-pr-form="confirmg-password" id="confirm-Password" required placeholder="Enter Confirm New Password" />
                </div>

                <div className="row">
                    <div className="col-12 col-sm-8">
                        <div className="form-group">
                            <label htmlFor="input-zone">Security question</label>
                            <select className="custom-select" id="input-zone" name="security">
                                <option value=""> --- Please Select ---</option>
                                <option value="3622">What county were you born?</option>
                                <option value="3623">What is your oldest cousin’s first name? </option>
                                <option 
                                // selected="selected" 
                                value="3624">What is the title and artist of your favorite song? </option>
                                <option value="3625">What is your work address?</option>
                                <option value="3626">What is your oldest sibling’s middle name? </option>
                                <option value="3627">What is your car’s license plate number? </option>
                                <option value="3628">What was your favorite elementary school teacher’s name? </option>
                                <option value="3629">What was your favorite Color?</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="form-group">
                            <label htmlFor="secqu">Security Name or Number</label>
                            <input id="secqu" value="" type="text" className="form-control" required placeholder="Enter Your Security" />
                        </div>
                    </div>
                </div>
                <button className="btn btn-default" style={{width: "100%"}} type="submit"><i className="far fa-save"></i> Save Changes</button>
            </form>
        </div>
    </div>
  )
}

export default ChangePasswordComp