import React from 'react'
import ClientOne from "../../components/assets/images/client/client1.png"
import ClientTwo from "../../components/assets/images/client/client2.png"
import ClientThree from "../../components/assets/images/client/client3.png"

const Testimonial = () => {
  return (
    <section className="testimonial">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-9 m-auto text-center">
                    <div className="sec-heading">
                        <span className="tagline text-white">Testimonials</span>
                        <h3 className="sec-title text-white">What Our Users <br />  Say About Our Services.</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="testimonialmainBox col-lg-10 col-md-12 m-auto">
                    <div className="testimonialBox">
                        <div className="test-caro 
                        owl-carousel"
                        >
                            <div className="caro-stage-outer">
                                <div className="caro-stage" 
                               
                                >
                                    <div className="caro-item active" style={{width: "860px"}}>
                                        <div className="single-testimonial mb-5">
                                            <p className='text-center'>At first I wasn't sure it will serve me better than other bills platforms on the internet, but I have used it to pay bills and it was fast, Prepone Bills!</p>
                                            <div className="testimonial-user">
                                                <img src={ClientOne} className="avatar-medium circle" alt="" />
                                                <strong>Chinwendu</strong>
                                            </div>
                                            <i className="fa fa-quote-left"></i>
                                        </div>
                                    </div>

                                    <div className="caro-item active" style={{width: "860px"}}>
                                        <div className="single-testimonial mb-5">
                                            <p className='text-center'>I've used Prepone Bills to pay my bills a few times and it was super reliable, fast and efficient. I will totally recommend it to anyone.</p>
                                            <div className="testimonial-user">
                                                <img src={ClientTwo} className="avatar-medium circle" alt="" />
                                                <strong>David S.</strong>
                                            </div>
                                            <i className="fa fa-quote-left"></i>
                                        </div>
                                    </div>




                                    <div className="caro-item active" style={{width: "860px"}}>
                                        <div className="single-testimonial mb-5">
                                            <p className='text-center'>Got to know about Prepone Bills recently as an online bills payment platform and I must say, I'm really impressed. It's easy to use and really fast.</p>
                                            <div className="testimonial-user">
                                                <img src={ClientThree} className="avatar-medium circle" alt="" />
                                                <strong>Mitchelle B.</strong>
                                            </div>
                                            <i className="fa fa-quote-left"></i>
                                        </div>
                                    </div>

                                    


                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Testimonial