import React from 'react'
import { Link } from "react-router-dom"

const CallToAction = () => {
  return (
    <section className="callto-action">
      <div className="container">
          <div className="row align-items-center">
              <div className="col-md-8">
                  <h3>Create An Account With Us</h3>
                  <p>Success And Spirit In Our Company</p>
              </div>
              <div className="col-md-4 text-md-right">
                  <Link to="/signup" className="btn btn-default">Register Now</Link>
              </div>
          </div>
      </div>
    </section>
  )
}

export default CallToAction