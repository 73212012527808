import React, { FormEvent, useState } from 'react'
import { useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../../Alert/Toast'
import {useAppDispatch } from "../../../utils/TypeScript"
import Spinner from '../../Alert/Spinner/Spinner'

import {  validateMultipleAirtimeDetails } from '../../../utils/ValidAirtime/validAirtime'
import {  ICurrentMultipleAirtimeOrder } from '../../../utils/AirtimeDateTypeScript/airtimeDataTypes'

import { addMultipleAirtimeOrder } from '../../../redux/slice/airtimeSlice/multipleAirtimeSlice'



export interface Input {
    [key: string]: string;
}


const MultipleAirtelForm = () => {

  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState("")
  const [inputs, setInputs] = useState<Input[]>([]);


  
  // const { currentAirtimePhoto }  = useAppSelector((state) => state.airtimePhoto)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

    // Function to add two new input tags
    const addInputs = () => {
        setInputs([...inputs, { phoneNumber: '', amount: '' }]);
    }

    // Function to handle changes to an input's value
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newInputs = [...inputs];
        newInputs[index][event.target.name] = event.target.value;
        setInputs(newInputs);
    }

    // Create an array of input tags based on the current state
    const inputTags = inputs.map((input, index) => (
        
        <div className="form-row" key={index}>

            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Phone Number {index+1}<span className='text-danger'>*</span></label>

                <input type="number" className="form-control" name="phoneNumber" value={input.phoneNumber} onChange={(event) => handleInputChange(event, index)} placeholder="Enter phone number" />
            </div>

            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Amount {index+1}<span className='text-danger'>*</span></label>

                <input type="number" className="form-control" name="amount" value={input.amount} onChange={(event) => handleInputChange(event, index)} placeholder="Enter Amount" />
            </div>

            {/* <input type="text" name="text" value={input.text} onChange={(event) => handleInputChange(event, index)} />
            <input type="number" name="number" value={input.number} onChange={(event) => handleInputChange(event, index)} /> */}
        </div>
    ));

    


    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setLoading(true)

        // console.log(inputs);

        const result = validateMultipleAirtimeDetails(inputs)

        if(result.errLength){

        toast.error(() => <Toast title="Error"
            body={result.errMsg} />)

            setLoading(false)
            return 
        }

        const totalAmount = inputs.reduce((sum, item) => sum + Number(item.amount), 0);


        let transactionId = "PreponeBills-" + Math.floor(Math.random() * 1000000);

        const multipleAirtimeOrder: ICurrentMultipleAirtimeOrder = {
            requestId: transactionId,
            uniqueCode: "AIRTEL_22689",
            details: inputs,
            userEmail: email,
            totalAmount
        }
    

        
        dispatch( addMultipleAirtimeOrder(multipleAirtimeOrder))
        localStorage.setItem("multipleAirtimeOrder", JSON.stringify(multipleAirtimeOrder));
        navigate("/multiple-airtime-summary") 
        setLoading(false)
        
    }



  return (
    <form onSubmit={handleSubmit} className="mx-3">
      

        {inputTags}

        <div className="form-row">

            <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'></span></label>
            <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>

        </div>

      <ul className="pager my-5" style={{width: "100%"}}
        onClick={addInputs}
      >
          <button type='button' className="btn btn-default text-align-end " style={{width: "100%"}}>add phone Number
          </button>
      </ul>

      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Done 
          </button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default MultipleAirtelForm