import axios from "axios";
import { toast } from "react-toastify"
import { ICurrentAirtelOrder,  ICurrentGloOrder, ICurrentMTNOrder, ICurrentNineMobileOrder, ICurrentNTELOrder } from "../../AirtimeDateTypeScript/airtimeDataTypes";




export const postBulkAirtimeTransaction = async ( payload: Object  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transactions`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
          'publicKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PRIVATE_KEY}`,
          endpoint: "airtime",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }

}

export const postAirtimeTransaction = async ( payload: Object  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transactions`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
          'publicKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PRIVATE_KEY}`,
          endpoint: "airtime",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }

}


export const postAirtelAirtimeTransaction = async ( payload: ICurrentAirtelOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }

}


export const postGloAirtimeTransaction = async ( payload: ICurrentGloOrder | undefined ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
       
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}


export const postMTNAirtimeTransaction = async ( payload: ICurrentMTNOrder | undefined ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_TEST}`,
          'Authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          "PaymentHash": "GENERATED_HMAC",
        //   'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
        //   'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          "Content-Type" : "application/json",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

       
        return res.data;

     
    } catch (err: any) {
        return toast.error(err.message)
    }

}


export const postNineMobileAirtimeTransaction = async ( payload: ICurrentNineMobileOrder | undefined ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        

        return  res.data;
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}


export const postNtelAirtimeTransaction = async ( payload: ICurrentNTELOrder | undefined) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
      
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}