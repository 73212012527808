import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFaceBook } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faPinterestP} from '@fortawesome/free-brands-svg-icons';

import Logo from "../../assets/png/prepone-logo.png"

import { Link } from "react-router-dom"

const FooterSection = () => {
  return (
    <footer className="footer">
        <div className="foo-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="widget widget-navigation pl-1">
                            <h4 className="widget-title" style={{color: "#6C0BA9"}}>Follow Us</h4>
                            <div className="widget-content">
                                {/* <p>
                                    PreponeBills is powered by Prepone Technologies Limited, a telecommunication and information technology services firm that aims at providing seamless and value-added services to its clientele.
                                </p> */}

                                
                                <ul className="social-media">
                                    


                                    <li><Link to="#"><i className="ti-faceboo">
                                    <FontAwesomeIcon icon={faFacebookF} className="mb-1"/></i></Link></li>

                                    <li><Link to="#"><i className="ti-twitte">
                                    <FontAwesomeIcon icon={faTwitter} className="mb-1"/></i></Link></li>

                                    <li><Link to="#"><i className="ti-instagrm"><FontAwesomeIcon icon={faInstagram} className="mb-1"/></i></Link></li>

                                    <li><Link to="#"><i className="ti-linkedi"><FontAwesomeIcon icon={faLinkedinIn} className="mb-1"/></i></Link></li>

                                    <li><Link to="#"><i className="ti-pinterest"><FontAwesomeIcon icon={faPinterestP} className="mb-1" /></i></Link></li>
                                </ul>

                                <Link to="/">
                                    <div className="prepone-logo">
                                        <img src={Logo} alt="logo" />
                                    </div>
                                </Link>
                                
                            </div>
                        </div>
                    </div>



                    <div className="col-md-4 col-sm-6">
                        <div className="widget widget-navigation">
                            <h4 className="widget-title pl-1" style={{color: "#6C0BA9"}}>About Prepone</h4>
                            <p className='pl-1'>
                                PreponeBills is powered by Prepone Technologies Limited, a telecommunication and information technology services firm that aims at providing seamless and value-added services to its clientele.
                            </p>
                        </div>
                    </div>


                    <div className="col-md-2 col-sm-6">
                        {/* <div className="widget widget-navigation">
                            <h4 className="widget-title" style={{color: "#6C0BA9"}}>About Prepone</h4>
                            <p>
                                PreponeBills is powered by Prepone Technologies Limited, a telecommunication and information technology services firm that aims at providing seamless and value-added services to its clientele.
                            </p>
                        </div> */}
                    </div>



                    <div className="col-md-2 col-sm-6 pl-2">
                        <div className="widget widget-navigation">
                            <h4 className="widget-title pl-3" style={{color: "#6C0BA9"}}>Quick Links</h4>
                            <ul className='pl-3'>
                                <li><Link to="/faq">Faq</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                {/* <li><Link to="/terms-conditions">Terms and conditions</Link></li> */}
                            </ul>
                        </div>
                    </div>


                    


                    {/* <div className="col-md-3 col-sm-6">
                        <div className="widget widget-navigation">
                            <h4 className="widget-title" style={{color: "#6C0BA9"}}>Security Brand</h4>
                            <ul>
                                <li><Link to="#">Privacy Statement</Link></li>
                                <li><Link to="#">Report Copyright Infringement</Link></li>
                                <li><Link to="#">Report Security Issue</Link></li>
                                <li><Link to="#">Trademark Notice</Link></li>
                                <li><Link to="#">Access Control</Link></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>


        <div className="foo-btm">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <p className="copyright text-left el-sm-center">Copyright © 
                                {(new Date().getFullYear()) }
                             <i className="ti-heart"></i> <Link to="/" style={{color: "#6C0BA9"}}>Prepone</Link>. All rights reserved.</p>
                    </div>
                    <div className="col-md-6">
                        <ul className="payments-types text-right  el-sm-center">
                            <li><Link to="/"> <img data-toggle="tooltip" src="images/pay/visa.png" alt="visa" title="" data-original-title="Visa" /></Link></li>

                            <li><Link to="/"> <img data-toggle="tooltip" src="images/pay/discover.png" alt="discover" title="" data-original-title="Discover" /></Link></li>

                            <li><Link to="/"> <img data-toggle="tooltip" src="images/pay/paypal.png" alt="paypal" title="" data-original-title="PayPal" /></Link></li>

                            <li><Link to ="/"> <img data-toggle="tooltip" src="images/pay/american.png" alt="american express" title="" data-original-title="American Express" /></Link></li>

                            <li><Link to ="/"> <img data-toggle="tooltip" src="images/pay/mastercard.png" alt="Mastercard" title="" data-original-title="Mastercard" /></Link></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </footer>
  )
}

export default FooterSection