import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { addDataHistory } from '../../redux/slice/transHistorySlice/dataHistory'
import { fetchAllDataHistory } from '../../utils/transHistory/fetchTransHistory'
import { IHistoryResponse } from '../../utils/transHistory/historyTypes'
import { useAppDispatch, useAppSelector } from '../../utils/TypeScript'
import Spinner from '../Alert/Spinner/Spinner'
import DataHistoryCardComp from './DataHistoryCard'

const DataHistoryPageComp = () => {

    const [loading, setLoading] = useState(false)

    const { currentUser } = useAppSelector(state => state.auth)
    const { currentDataHistory } = useAppSelector(state => state.dataHistory)
    const dispatch = useAppDispatch()


    const userSignUpDate = moment(currentUser?.metadata?.creationTime).format('DD/MM/YYYY').toString() 

    
    const getDataTransactionsHistory = async () =>{

        if(!currentDataHistory){ 
            setLoading(true)
    
            const dataHistory = await fetchAllDataHistory(currentUser?.email, userSignUpDate) 
            
            dispatch(addDataHistory(dataHistory))
        
            setLoading(false)

        } else {
            return
        }
        
    }

    useEffect(() => {
        getDataTransactionsHistory()
    }, [currentUser])


  return (
    
            <div className="col-lg-9">
                <h2 className="admin-heading bg-offwhite">All Data Transactions</h2>
                <div className="profile-content">
                   
                    <div className="transaction-title bg-offwhite">
                            <div className="items">
                                <div className="row">
                                    <div className="col"><span className="">Date</span></div>
                                    <div className="col">Description</div>
                                    <div className="col text-center">Contact</div>
                                    <div className="col text-center">Status</div>
                                    <div className="col">Amount</div>
                                </div>
                            </div>
                        </div>

                        <div className="transaction-area">

                            {

                                currentDataHistory && currentDataHistory?.length < 1 ? 

                                    <div className="items">


                                        <div className="roww">
                                            


                                            <div className="text-center">
                                                <h5 className="text-center">No history to show.</h5>
                                            
                                            </div>
                                        </div> 


                                    </div>

                                :

                                    currentDataHistory?.map(( obj: IHistoryResponse, i: any )=>{
                                        return <DataHistoryCardComp hist={obj} key={i}/>
                                    })
                            }

                        </div>

                </div>
                { loading && <Spinner /> }

            </div>
  )
}

export default DataHistoryPageComp