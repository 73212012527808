import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { addCableTVPhoto } from '../../redux/slice/cableTVSlice/cableTVPhotoSlice'
import { ICurrentCableTVOrder, useAppDispatch } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'


import NSE from "../assets/new/nigerian_society_of_engineers_logo_s2.png"
import LCC from "../assets/new/lcc.jpg"
import RIVS from "../assets/new/logoriv.jpg"



const BodiesSelectComp = () => {

    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()



    const getCableTVBiller = async (billerCode: string ) => {
        setLoading(true)


             const govtLevyPhoto: ICurrentCableTVOrder = {
                
                externalref: "",
                billerCode: billerCode,
                productId: "",
                
            }

            console.log({govtLevyPhoto})

        dispatch(addCableTVPhoto(govtLevyPhoto))
        localStorage.setItem("govtLevyPhoto", JSON.stringify(govtLevyPhoto));
        navigate("/bodies-order") 
        setLoading(false)


    }


    useEffect(() => {
        localStorage.clear();
    }, [])


  return (
    <section className="we-work bg-offwhite">
        <div className="container">
            <div className="row">
                <div className=" col-md-12 m-auto text-center">
                    <div className="sec-heading mb-4">
                        <span className="tagline">Service providers</span>
                        {/* <h3 className="sec-title">Select your service provider</h3> */}
                    </div>
                </div>
            </div>
            <div className="portfolioITems">
                <div className="ui-group filters mb-disable">
                    <div className="button-group js-radio-button-group" data-filter-group="color">
                    <button className="button is-checked" data-filter="">All</button>
                        <button className="button" data-filter="">Nigerian Asso. of Engineers</button>
                        {/* <button className="button " data-filter=".data">Lekki Concession Company</button>
                        <button className="button " data-filter=".data">Rivers State Government</button>
                       */}
                    </div>
                </div>


                <div className="portGrid row">
                    <div className="protItem col-3 col-md-2" onClick={() => getCableTVBiller("NSE")}>
                        <img src={NSE} alt="" className="we-work-im"  aria-label="NSE" title="NSE"/>
                        {/* <h6 style={{fontSize: "15px"}}>FIRS PAYMENT</h6> */}
                    </div>

                    {/* <div className="protItem col-3 col-md-2 data" onClick={() => getCableTVBiller("LCC")}>
                        <img src={LCC} alt="" className="we-work-img" aria-label="LCC" title="LCC"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getCableTVBiller("RIVS")}>
                        <img src={RIVS} alt="" className="we-work-img" aria-label="LCC" title="RIVS"/>
                    </div> */}
                </div>
            </div>
        </div>

        { loading && <Spinner /> }
    </section>
  )
}

export default BodiesSelectComp