
  export function generateReference(prefix: string) {
    if (!prefix) {
      return;
    }
  
    const date = new Date();
    const dateString = date.toISOString().slice(0, 10).replace(/-/g, ''); // YYYYMMDD
    const timeString = date.toTimeString().slice(0, 8).replace(/:/g, ''); // HHMMSS
    const randomString = Math.random().toString(36).substring(2, 8).toUpperCase(); // Random string
  
    return `${prefix.toUpperCase()}-${dateString}${timeString}-${randomString}`;
  }