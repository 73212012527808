import React from 'react'
import { Link } from "react-router-dom"
import { IHistoryResponse } from '../../utils/transHistory/historyTypes'

const ElectricityDetailsComp = (props: {electricityHistory: IHistoryResponse | undefined}) => { 


    const transDetailsArray = props?.electricityHistory?.transDetails?.split('|')
    const details = transDetailsArray 

    const totalTransactionAmount = Number(props?.electricityHistory?.transactionAmount) + 100



  return (
    <div className="col-lg-9">
        <div className="profile-content">
            <h3 className="admin-heading bg-offwhite">
                <p>CableTV Transaction Details</p>
            </h3>
            <div className="order-summery transaction-summery">
                <div className="order-details-area table">
                    <div className="table-head">
                        <div className="modal_header">
                            <span className={props?.electricityHistory?.statusDescription === "SUCCESSFUL" ? "status bg-success" : "status bg-danger" }>
                                
                                {
                                    props?.electricityHistory?.statusDescription === "SUCCESSFUL" ? "Paid" : "Failed"
                                }
                            </span>
                            <div className="row">
                                <div className="col">
                                    <div className="content-area">
                                        <b>Payment sent to</b>
                                        <i className="fas fa-building ml-3 mr-1"></i>
                                         PreponeBills Ltd
                                        <p>{props?.electricityHistory?.transDate}</p>
                                        <p> Trans Ref:  
                                             {
                                                ` ${ props?.electricityHistory?.transRef}` 
                                            }

                                        </p>
                                        <p>Payment Status: 
                                            {
                                                ` ${ props?.electricityHistory?.paymentStatus}` 
                                            }
                                        </p>
                                        <p>Payment Type: XpressPay Gateway</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="content-area text-right">
                                        <div className="price">
                                            <b>Amount</b>
                                            <p>₦
                                            {
                                                totalTransactionAmount
                                            }
                                            </p>
                                        </div>                                                        
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="table-content bg-offwhite">
                            <b>
                                <div className="row">
                                    <div className="col">Order Details</div>
                                    <div className="col">Meter Number</div>
                                    {/* <div className="col">Package</div> */}
                                </div>
                            </b>
                        </div>
                        <div className="table-content">

                            <div className="row">
                                <div className="col">
                                    {
                                        ` ${props?.electricityHistory?.product} `
                                    }
                                </div>
                                <div className="col text-left mr-4">
                                    {
                                        props?.electricityHistory?.billerCode === 'ABJ_POSTPAID' ?  `${ details? details[1] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === 'ABJ_PREPAID' ?  `${ details? details[1] : ""}`
                                        : 
                                        props?.electricityHistory?.billerCode === 'EKEDC_ORDER_PAYMENT' ?  ""
                                        :
                                        props?.electricityHistory?.billerCode === 'EKEDC_POSTPAID' ?  `${ details? details[1] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === 'EKEDC_PREPAID'?  `${ details? details[1] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === "PHEDDIR2"?  `${ details? details[1] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === 'IBEDC_F'?   `${ details? details[0] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === 'iedc postpaid'?  `${ details? details[0] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === 'iedc'?  `${ details? details[0] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === 'JOS_POSTPAID'?  `${ details? details[1] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === 'JOS_PREPAID'?  `${ details? details[1] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === 'KADUNA_POSTPAID'?  `${ details? details[1] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === 'KADUNA_PREPAID'?  `${ details? details[1] : ""}`
                                        :
                                        props?.electricityHistory?.billerCode === 'KEDCOPostpaid'?  ""
                                        :
                                        props?.electricityHistory?.billerCode === 'KEDCOPrepaid'?  ""
                                        :
                                        ""
                                    }
                                </div>

                                
                                {/* <div className="col text-right">
                                    {
                                        props?.electricityHistory?.billerCode === 'DSTV1' ? `${ details? details[3] : ""}`
                                        : 
                                        props?.electricityHistory?.billerCode === 'GOTV1' ? `${ details? details[2] : ""}`
                                        : 
                                        props?.electricityHistory?.billerCode === 'startimes' ? `${ details? details[3] : ""}`
                                        : 
                                        ""
                                    }
                                </div> */}
                               
                            </div>
                        </div>
                       

                        <div className="row">
                            <div className="col-md-5 ml-auto">
                                <div className="sub-content">
                                    <br/>
                                    <div className="row">
                                        <div className="col">Purchase Total</div>
                                        <div className="col text-right">₦
                                            {
                                               `${props?.electricityHistory?.transactionAmount}`
                                            }
                                        </div>
                                    </div>
                                   
                                    
                                    <div className="row">
                                        <div className="col">Service Charge</div>
                                        <div className="col text-right">₦100.00</div>
                                    </div>
                                    
                                   
                                    
                                    <hr/>
                                    <div className="row">
                                        <div className="col"><b>Net Amount</b></div>
                                        <div className="col text-right">₦
                                            {
                                                 
                                                 totalTransactionAmount
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-footer mt-3 bg-offwhite p-3">
                            <div className="row">
                                <div className="col">
                                    <p><b>Payment Ref: </b>
                                        {
                                            ` ${props?.electricityHistory?.paymentRef} `
                                        }
                                    </p>
                                    <p className="mb-0"><b>Thank You!</b></p>
                                    <p>
                                        Thank you for using Prepone bills payment platform,
                                        we promise to serve you better.
                                    </p>
                                </div>
                                <div className="col">
                                    <p className="mb-0"><b>Contact info</b></p>
                                    <p>
                                        PreponeBills Ltd
                                        The recipient of this payment is verified and is located in Port Harcourt, Nigeria.
                                        http://preponebills.com/,
                                        help@preponebills.com
                                    </p>
                                </div>
                            </div>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ElectricityDetailsComp