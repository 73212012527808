import React, { useEffect, useState } from "react";
import XPay from "npm_xpresspay";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../utils/TypeScript";
import Spinner from "../Alert/Spinner/Spinner";
import axios from "axios";
import { generateTransactionId, LiveBaseURL } from "../../utils/config";



const EOneSummaryComp = () => {

  const tranId = localStorage.getItem("tranId") || "";


  useEffect(() => {
    VerifyPayment();
  }, [tranId]);

  const [state, setState] = useState({
    amount: "",
    loading: false,
    transactions: [],
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { currentElectricityPhoto } = useAppSelector(
    (state) => state.electricityPhoto
  );
  const { currentElectricityOrder } = useAppSelector(
    (state) => state.electricity
  );

  const totalAmount = Number(
    currentElectricityOrder ? currentElectricityOrder?.details.amount : ""
  );

  const userEmail = currentElectricityOrder
    ? currentElectricityOrder?.details.email
    : "";

    const publicKey =  process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC;


  const PaymentGateway = async () => {
    let transId = generateTransactionId();

    setState({ ...state, loading: true });
    setLoading(true);
    try {
      // XPay.InitialisePayment({
      //   amount: `${totalAmount}`,
      //   transactionId: transId,
      //   email: `${userEmail}`,
      //   // publicKey: `${process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_TEST}`,
      //   publicKey: `${process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC}`,

      //   currency: "NGN",
      //   mode: "Production",
      //   // mode: "Debug",

      //   callbackUrl: window.location.href,
      //   metadata: [
      //     {
      //       name: "sample",
      //       value: "test",
      //     },
      //   ],
      // }).then((response: any) => {
      //   if (response.success) {
      //     setState({ ...state, loading: false });
      //     sessionStorage.setItem("tranId", transId); // it can be saved to Database.
      //     sessionStorage.setItem("reference", response.data?.reference); // it can be saved to Database
      //     localStorage.setItem("tranId", transId);

      //     window.location.href = response.data?.authorizeUrl;
      //     setLoading(false);
      //   } else {
      //     setState({ ...state, loading: false });
      //     window.location.href = response.data?.authorizeUrl;
      //     setLoading(false);
      //   }
      // });
    
      const response = await axios.post(
        `${LiveBaseURL}/api/Payments/Initialize`,
        {
          amount: `${totalAmount}`,
          transactionId: transId,
          email: `${userEmail}`,
          publicKey: `${process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC}`,
          currency: "NGN",
          mode: "Live",
          callbackUrl: window.location.href,
          metadata: [
            {
              name: "sample",
              value: "test",
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${publicKey}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.data?.paymentUrl) {
        setState({ ...state, amount: `${totalAmount}`, loading: false });
        sessionStorage.setItem("tranId", transId);
        sessionStorage.setItem("reference", response?.data?.reference);
        localStorage.setItem("tranId", transId);

        window.location.href = response?.data?.data?.paymentUrl;
        setLoading(false);
      } else {
        setState({ ...state, amount: `${totalAmount}`, loading: false });
        window.location.href = response?.data?.data?.paymentUrl;
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };



  const VerifyPayment = async () => {    
    setLoading(true);
    const tranId = localStorage.getItem("tranId") || "";
    const publicKey =
      process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC;

    if (!publicKey) {
      setLoading(false);
      return;
    }
    
    if (!tranId) {
      setLoading(false);
      return;
    }

    try {

      const response = await axios.post(
        `${LiveBaseURL}/api/Payments/VerifyPayment`,
        {
          publicKey,
          transactionId: tranId,
          mode: "Live",
        },
        {
          headers: {
            Authorization: `Bearer ${publicKey}`,
            "Content-Type": "application/json",
          },
        }
      );
    
      const { data } = response;

      if (data?.data?.isSuccessful) {
        const amount = data?.amount;

        setState({ ...state, amount, transactions: data?.history });
        console.log("Transaction successful");
        localStorage.removeItem("tranId");
        localStorage.setItem("payment", "i");
        navigate("/electricity-done");
      } else {
        console.log("Transaction not successful:", data?.responseMessage);
        setState({ ...state, amount: "" });
        localStorage.setItem("payment", "o");
      }
    } catch (error) {
      console.log("Error verifying payment:", error);
      setState({ ...state, amount: "" });
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="col-lg-9">
      <div className="row">
        <div className="col-lg-12">
          <div className="bg-light shadow-sm text-center mb-3">
            <div className="d-flex admin-heading pr-3">
              <div className="mr-auto">
                <h4
                  className="text-9 font-weight-400"
                  style={{ color: "#3f0d5e" }}
                >
                  <i className="fas fa-wallet mr-2"></i>
                  {currentElectricityPhoto?.billerCode === "ABJ_POSTPAID"
                    ? "Abuja Postpaid"
                    : currentElectricityPhoto?.billerCode === "ABJ_PREPAID"
                    ? "ABUJA PREPAID"
                    : currentElectricityPhoto?.billerCode ===
                      "EKEDC_ORDER_PAYMENT"
                    ? "EKEDC ORDERPAYMENT"
                    : currentElectricityPhoto?.billerCode === "EKEDC_POSTPAID"
                    ? "EKEDC Postpaid"
                    : currentElectricityPhoto?.billerCode === "EKEDC_PREPAID"
                    ? "EKEDC Prepaid"
                    : currentElectricityPhoto?.billerCode === "EEDC"
                    ? "EEDC"
                    : currentElectricityPhoto?.billerCode === "PHEDDIR2"
                    ? "PHED"
                    : currentElectricityPhoto?.billerCode === "IBEDC_F"
                    ? "IBEDC"
                    : currentElectricityPhoto?.billerCode === "iedc postpaid"
                    ? "iedc Postpaid"
                    : currentElectricityPhoto?.billerCode === "iedc"
                    ? "iedc Prepaid"
                    : currentElectricityPhoto?.billerCode === "JOS_POSTPAID"
                    ? "Jos Electric Postpaid"
                    : currentElectricityPhoto?.billerCode === "JOS_PREPAID"
                    ? "Jos Prepaid Electric"
                    : currentElectricityPhoto?.billerCode === "KADUNA_POSTPAID"
                    ? "Kaduna Electric Postpaid"
                    : currentElectricityPhoto?.billerCode === "KADUNA_PREPAID"
                    ? "Kaduna Electric Prepaid"
                    : currentElectricityPhoto?.billerCode === "KEDCOPostpaid"
                    ? "Kano Electric Postpaid"
                    : currentElectricityPhoto?.billerCode === "KEDCOPrepaid"
                    ? "Kano Electric Prepaid"
                    : ""}
                </h4>
              </div>
              <div className="ml-auto">
                <h4
                  className="text-9 font-weight-400"
                  style={{ color: "#3f0d5e" }}
                >
                  ₦{currentElectricityOrder?.details.amount}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 profile-content ">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link className="nav-link" to="">
              Order
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link active" to="">
              Summary
            </Link>
          </li>
          {/* <li className="nav-item">
                                <Link className="nav-link" to="/recharge-payment">Payment</Link>
                            </li> */}
          <li className="nav-item">
            <Link className="nav-link" to="">
              Done
            </Link>
          </li>
        </ul>

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade  show active"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <div className="order-summery">
              <h3 className="title">Order Summary</h3>
              <div className="order-details-area table">
                <div className="bg-offwhite table-content">
                  <div className="row">
                    <div className="col">Meter Type</div>

                    <div className="col text-right">
                      {currentElectricityOrder?.details.accountType}
                    </div>
                  </div>
                </div>

                <div className="table-content">
                  <div className="row">
                    <div className="col">Email</div>
                    <div className="col text-right">
                      {currentElectricityOrder?.details.email}
                    </div>
                  </div>
                </div>

                <div className="bg-offwhite table-content">
                  <div className="row">
                    <div className="col">Meter (Account) Number</div>

                    <div className="col text-right">
                      {currentElectricityOrder?.details.accountNumber}
                    </div>
                  </div>
                </div>

                <div className="table-content">
                  <div className="row">
                    <div className="col">Customer Name</div>
                    <div className="col text-right">
                      {currentElectricityOrder?.details.name}
                    </div>

                    {/* <div className="col text-right">
                                                    {
                                                    currentElectricityPhoto?.billerCode === 'ABJ_POSTPAID' ? currentAbujaElectOrder?.transDetails[2].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'ABJ_PREPAID' ? currentAbujaElectOrder?.transDetails[2].fieldValue
                                                    : 
                                                    currentElectricityPhoto?.billerCode === 'EKEDC_ORDER_PAYMENT' ? currenteOrderPaymentElectOrder?.transDetails[5].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'EKEDC_POSTPAID' ? currentEKEDCPostpaidOrder?.transDetails[2].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'EKEDC_PREPAID'? currentEKEDCPrepaidOrder?.transDetails[1].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === "PHEDDIR2"? currentPHEDCOrder?.transDetails[5].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'IBEDC_F'?  currentIBEDCOrder?.transDetails[2].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'iedc postpaid'? currentIEPostpaidOrder?.transDetails[3].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'iedc'? currentIEPrepaidOrder?.transDetails[2].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'JOS_POSTPAID'? "Jos Electric Postpaid"
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'JOS_PREPAID'? "Jos Prepaid Electric"
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'KADUNA_POSTPAID'? "Kaduna Electricity Postpaid"
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'KADUNA_PREPAID'? "Kaduna Prepaid Electricity"
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'KEDCOPostpaid'? ""
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'KEDCOPrepaid'? ""
                                                    :
                                                    ""
                                                    }
                                                </div> */}
                  </div>
                </div>

                <div className="bg-offwhite table-content">
                  <div className="row">
                    <div className="col">Phone Number</div>
                    <div className="col text-right">
                      {currentElectricityOrder?.details.phoneNumber}
                    </div>
                  </div>
                </div>

                <div className="table-content">
                  <div className="row">
                    <div className="col">Address</div>
                    <div className="col text-right">
                      {currentElectricityOrder?.details.address}
                    </div>
                  </div>
                </div>

                {/* <div className="table-content">
                                            <div className="row">
                                                <div className="col">
                                                {
                                                        currentElectricityPhoto?.billerCode === 'ABJ_POSTPAID' ? ""
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'ABJ_PREPAID' ? ""
                                                        : 
                                                        currentElectricityPhoto?.billerCode === 'EKEDC_ORDER_PAYMENT' ? "Customer Details"
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'EKEDC_POSTPAID' ? " "
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'EKEDC_PREPAID'? ""
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'JOS_PREPAID'? "Invoice Number"
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'KADUNA_POSTPAID'? "Customer Details"
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'KADUNA_PREPAID'? "Customer Details"
                                                        :
                                                        // currentElectricityPhoto?.billerCode === "PHEDDIR2"? "EFive "
                                                        // :
                                                        ""
                                                    }
                                                </div>
                                                <div className="col text-right">
                                                    {
                                                    currentElectricityPhoto?.billerCode === 'ABJ_POSTPAID' ? ""
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'ABJ_PREPAID' ? ""
                                                    : 
                                                    currentElectricityPhoto?.billerCode === 'EKEDC_ORDER_PAYMENT' ? currenteOrderPaymentElectOrder?.transDetails[1].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'EKEDC_POSTPAID' ? " "
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'EKEDC_PREPAID'? ""
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'JOS_PREPAID'? currentJosPrepaidOrder?.transDetails[3].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'KADUNA_POSTPAID'? currentKadunaPostpaidOrder?.transDetails[3].fieldValue
                                                    :
                                                    currentElectricityPhoto?.billerCode === 'KADUNA_PREPAID'? currentKadunaPrepaidOrder?.transDetails[3].fieldValue
                                                    :
                                                    // currentElectricityPhoto?.billerCode === "PHEDDIR2"? ""
                                                    // : 
                                                    ""
                                                    }
                                                </div>
                                            </div>
                                        </div> */}

                {/* <hr />
                                        <div className="row">
                                            <div className="col">Service Charge</div>
                                            <div className="col text-right">₦100</div>
                                        </div>
                                        <hr /> */}

                <div className="row">
                  <div className="col-md-5 ml-auto">
                    <div className="table-content">
                      <div className="row">
                        <div className="col">Service Charge</div>
                        <div className="col text-right">₦0.00</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 ml-auto">
                    <div className="table-content">
                      <div className="row">
                        <div className="col">Amount</div>
                        <div className="col text-right">
                          ₦{Number(currentElectricityOrder?.details.amount)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul className="pager my-5">
              <li>
                <Link to="/electricity-order" className="btn btn-default ">
                  <i className="fas fa-chevron-left"></i> Back
                </Link>
              </li>

              <li>
                <Link
                  to=""
                  className="btn btn-default "
                  onClick={() => PaymentGateway()}
                >
                  {" "}
                  Next <i className="fas fa-chevron-right"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default EOneSummaryComp;
