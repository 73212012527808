import { createSlice, } from '@reduxjs/toolkit'
import { ITransactionState } from '../../../utils/TypeScript'


export interface TransactionState {
    currentTransactionState?: ITransactionState,
    loading: boolean
}

const initialState: TransactionState = {
    currentTransactionState: undefined,
    loading: false,
}


const transactionSateSlice = createSlice({
    name: 'transactionSate',
    initialState,
    reducers: {
      addTransactionState: (state, action) => {
        state.currentTransactionState = {...state.currentTransactionState, ...action.payload}
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('transactionstate') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('transactionstate') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addTransactionState } = transactionSateSlice.actions

export default transactionSateSlice.reducer
