import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { ICurrentBillerPhoto, useAppDispatch } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'

import Betting1 from "../assets/images/betting/1.png"
import Betting2 from "../assets/images/betting/2.webp"
import Betting3 from "../assets/images/betting/3.webp"
import Betting4 from "../assets/images/betting/4.webp"
import Betting5 from "../assets/images/betting/5.webp"
import Betting6 from "../assets/images/betting/6.webp"
import Betting7 from "../assets/images/betting/7.webp"
import Betting8 from "../assets/images/betting/8.webp"
import Betting9 from "../assets/images/betting/9.webp"
import Betting10 from "../assets/images/betting/10.webp"
import Betting11 from "../assets/images/betting/11.webp"

import { addBettingPhoto } from '../../redux/slice/bettingSlice/bettingPhoto'


const BettingSelectComp = () => {

    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()



    const getBettingBiller = async (billerCode: string ) => {
        setLoading(true)


             const bettingPhoto: ICurrentBillerPhoto = {
                
                externalref: "",
                billerCode: billerCode,
                productId: "",
                
            }

        dispatch(addBettingPhoto(bettingPhoto))
        localStorage.setItem("bettingPhoto", JSON.stringify(bettingPhoto));
        navigate("/betting-order") 
        setLoading(false)


    }

    useEffect(() => {
        localStorage.clear();
    }, [])
    


  return (
    <section className="we-work bg-offwhite">
        <div className="container">
            <div className="row">
                <div className=" col-md-12 m-auto text-center">
                    <div className="sec-heading mb-4">
                        <span className="tagline">Service providers</span>
                        {/* <h3 className="sec-title">Select your service provider</h3> */}
                    </div>
                </div>
            </div>
            <div className="portfolioITems">
                <div className="ui-group filters mb-disable">
                    <div className="button-group js-radio-button-group" data-filter-group="color">
                        <button className="button is-checked" data-filter="">All</button>
                        <button className="button" data-filter="">BET9JA</button>
                        <button className="button" data-filter=".data">MYLOTTOHUB</button>
                        <button className="button" data-filter=".dth">MELBET</button>
                        <button className="button" data-filter=".dth">PARIPESA</button>
                        <button className="button" data-filter=".dth">BANGBET</button>
                        <button className="button" data-filter=".dth">BETKING</button>
                        <button className="button" data-filter=".dth">NAIRAMILLION</button>
                        <button className="button" data-filter=".dth">CLOUDBET</button>
                        <button className="button" data-filter=".dth">ONE_XBET</button>
                        <button className="button" data-filter=".dth">NAIJABET</button>
                        <button className="button" data-filter=".dth">BETLION</button>
                      
                    </div>
                </div>


                <div className="portGrid row">
                    <div className="protItem col-3 col-md-2" onClick={() => getBettingBiller("BET9JA")}>
                        <img src={Betting1} alt="" className="we-work-im"  aria-label="BET9JA" title="BET9JA"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getBettingBiller("MYLOTTOHUB")}>
                        <img src={Betting2} alt="" className="we-work-img" aria-label="MYLOTTOHUB" title="MYLOTTOHUB"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getBettingBiller("MELBET")}>
                        <img src={Betting3} alt="" className="we-work-img" aria-label="MELBET" title="MELBET"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getBettingBiller("PARIPESA")}>
                        <img src={Betting4} alt="" className="we-work-img" aria-label="PARIPESA" title="PARIPESA"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getBettingBiller("BANGBET")}>
                        <img src={Betting5} alt="" className="we-work-img" aria-label="BANGBET" title="BANGBET"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getBettingBiller("BETKING")}>
                        <img src={Betting6} alt="" className="we-work-img" aria-label="BETKING" title="BETKING"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getBettingBiller("NAIRAMILLION")}>
                        <img src={Betting7} alt="" className="we-work-img" aria-label="NAIRAMILLION" title="NAIRAMILLION"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getBettingBiller("CLOUDBET")}>
                        <img src={Betting8} alt="" className="we-work-img" aria-label="CLOUDBET" title="CLOUDBET"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getBettingBiller("ONE_XBET")}>
                        <img src={Betting9} alt="" className="we-work-img" aria-label="ONE_XBET" title="ONE_XBET"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getBettingBiller("NAIJABET")}>
                        <img src={Betting10} alt="" className="we-work-img" aria-label="NAIJABET" title="NAIJABET"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getBettingBiller("BETLION")}>
                        <img src={Betting11} alt="" className="we-work-img" aria-label="BETLION" title="BETLION"/>
                    </div>

                   
                   

                   
                   
                </div>
            </div>
        </div>

        { loading && <Spinner /> }
    </section>
  )
}

export default BettingSelectComp