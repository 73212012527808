import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentMTNDataOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface MTNDataState {
    currentMTNDataOrder?: ICurrentMTNDataOrder,
    loading: boolean
}

const initialState: MTNDataState = {
    currentMTNDataOrder: undefined,
    loading: false,
}


const mtnDataSlice = createSlice({
    name: 'mtndata',
    initialState,
    reducers: {
      addMTNDataOrder: (state, action) => {
        state.currentMTNDataOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('mtndata') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('mtndata') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addMTNDataOrder } = mtnDataSlice.actions

export default mtnDataSlice.reducer
