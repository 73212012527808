import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentNTELOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface NTELState {
    currentNTELOrder?: ICurrentNTELOrder,
    loading: boolean
}

const initialState: NTELState = {
    currentNTELOrder: undefined,
    loading: false,
}


const ntelSlice = createSlice({
    name: 'ntel',
    initialState,
    reducers: {
      addNTELOrder: (state, action) => {
        state.currentNTELOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('ntel') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('ntel') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addNTELOrder } = ntelSlice.actions

export default ntelSlice.reducer
