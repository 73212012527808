import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addCableTVOrder } from '../../redux/slice/cableTVSlice/cableTVSlice'
import { profileUpdate } from '../../redux/slice/profileSlice/profileSlice'
import {  getProductsByCategoriesIdAndBillerId } from '../../utils/billers'
import { lookupCableTV } from '../../utils/lookup/lookupTrans'
import {  IDSTVMonths,  useAppSelector, useAppDispatch, IProfile, ICableTVPackages, ICurrentCableTVOrder, ICurrentCableTVOrderDetails,} from "../../utils/TypeScript"
import { validateDSTVOrder } from '../../utils/Validation'
import Spinner from '../Alert/Spinner/Spinner'
import Toast from '../Alert/Toast'


const DSTVFormComp = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate() 


    const [loading, setLoading] = useState(false)
    const [smartCardNumber, setSmartCardNumber] = useState("")
    const [email, setEmail] = useState("")
    const [numberOfMonths, setNumberOfMonths] = useState("")
    const [packageName, setPackageName] = useState("")
    const [subscriptionAmount, setSubscriptionAmount] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    const [packages, setPackages] = useState([])
    const [uniqueCode, setUniqueCode] = useState("")


    const { profile } = useAppSelector(state => state.profile)
    const { currentUser } = useAppSelector(state => state.auth)

    const months = [
        { name: "1", value: "1" }, { name: "2", value: "2" }, { name: "3", value: "3" }, { name: "4", value: "4" }, { name: "5", value: "5" }, { name: "6", value: "6" }, { name: "7", value: "7" }, { name: "8", value: "8" }, { name: "9", value: "9" }, { name: "10", value: "10" }, { name: "11", value: "11" }, { name: "12", value: "12" },
    ]
  

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)

        const dSTVOrder = {smartCardNumber, email, numberOfMonths, subscriptionAmount, phoneNumber, uniqueCode }
        
        const result = validateDSTVOrder(dSTVOrder)

        if(result.errLength){

           toast.error(() => <Toast title="Error"
            body={result.errMsg} />)

            setLoading(false)
            return 
        }

    

        let transactionId = "PreponeBills-" + Math.floor(Math.random() * 1000000);


        const dstvLookup = {
            requestId: transactionId,
            uniqueCode,
            details: {
                accountNumber: smartCardNumber,
                accountType: packageName,
                noOfMonth: numberOfMonths
            }
        }

        const lookupResult = await lookupCableTV(dstvLookup)
        const { data, responseMessage } = lookupResult
        // console.log(lookupResult)
        


        if(responseMessage.toLowerCase() === "successful"){
            // Save Transaction details for later
            if(currentUser){

                const dataa: IProfile = {
                meterNo: profile?.meterNo? profile?.meterNo : "", 
                phoneNo: phoneNumber,
                userEmail: email,
                smartCardNumber: smartCardNumber
                }
                await dispatch(profileUpdate({user: currentUser, dataa}))
            } 


            const cableTVOrder: ICurrentCableTVOrderDetails = {
                requestId: transactionId,
                uniqueCode,
                details: {
                    accountNumber: smartCardNumber,
                    accountType: packageName,
                    noOfMonth: Number(numberOfMonths),
                    amount: Number(subscriptionAmount),
                    name: data?.name,
                    phoneNumber,
                    email,
                    packageName,
                }
            }

            dispatch(addCableTVOrder(cableTVOrder))
            localStorage.setItem("cableTVOrder", JSON.stringify(cableTVOrder));

            navigate("/cabletv-summary") 
            setLoading(false)

        } else {
            setLoading(false)
            return toast.error("Could not find the user with this smart card number!")
            
        }
    }



    const fetchBiller = async () => {

        if(packages.length < 1){

            setLoading(true)
            const selectetCableTV = await getProductsByCategoriesIdAndBillerId( 5, 22)

            // console.log(selectetCableTV.data.productDTOList);
            setPackages(selectetCableTV?.data?.productDTOList) 
            setLoading(false)
        } else {
            return
        }

    }

    const setPackageAmount = ()=>{
       if(!uniqueCode){
           return
       } else {
            const selectedPackage = packages.filter(( paackage : ICableTVPackages)=>{
                return paackage.uniqueCode === uniqueCode
            })

            setSubscriptionAmount(selectedPackage[0]['amount']);
            setPackageName(selectedPackage[0]['name']);
            // console.log(selectPackage)


       }
        
    }


    useEffect( ()=>{
         fetchBiller()
    }, [])

    useEffect( ()=>{
         setPackageAmount()
    }, [uniqueCode])


    useEffect( ()=>{
        if(!profile) return;
        setEmail(profile.userEmail)
        setSmartCardNumber(profile.smartCardNumber)
        setPhoneNumber(profile.phoneNo)
    }, [])



  return (
      <>
    <form onSubmit={handleSubmit} className="mx-3 mt-4">

        <span className='text-danger' style={{fontSize: "0.9rem"}}>
            {
            currentUser ? "" : "Login to remember details next time."
            }
        
        </span>
        <div className="form-row">
            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1" className='fs-10'>Smart Card Number <span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter Card Number" name="smartCardNumber" value={smartCardNumber} onChange={e => setSmartCardNumber(e.target.value)}/>
            </div>


            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlInput04">Select Package (Amount) <span className='text-danger'>*</span></label>

                <select className="custom-select form-control" id="operator" name="selectPackage" value={uniqueCode} onChange={e => setUniqueCode(e.target.value)}>
                    <option value="">Select Package (Amount)</option>
                    { packages?.length ?
                       ( packages.map((cablePackage: ICableTVPackages, index) =>
                            <option  key={index} value={cablePackage.uniqueCode}>{cablePackage.name}</option>  
                        )) : ""
                    }
                </select>
            </div>
            
        </div>


        <div className="form-row">

            { months &&
                <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlInput04">Number of Months <span className='text-danger'>*</span></label>

                <select className="custom-select form-control" id="operator" name="NumberOfMonths" value={numberOfMonths} onChange={e => setNumberOfMonths(e.target.value)} >
                    <option value="">Select Number of Months</option>
                    {
                        months.map((month : IDSTVMonths, index) =>
                            <option className='text-center' key={index} value={month.value}>{month.name}</option>  
                        )
                    }
                </select>
            </div>}

            
            <div className="col-md-6 col-lg-6">
                
                <label htmlFor="exampleFormControlSelect "> <span className='text-danger'></span></label>
                <div className="form-group input-group pt-2">
                    <div className="input-group-prepend "> <span className="input-group-text px-3 text-white" style={{backgroundColor: "#BC61F5"}}>(Amount) ₦</span> 
                    </div>
                    <input className="form-control text-center" id="amount" placeholder="Amount"  type="number" name='subscriptionAmount' value={subscriptionAmount} readOnly/>
                    
                </div>
            </div>

            
        </div>


        <div className="form-row">
                
            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Email Address <span className='text-danger'>*</span></label>
                <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>

            
            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="customerNumber" id="amount"  placeholder="Enter phone number" name="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
            </div>

        </div>



        <ul className="pager my-5" style={{width: "100%"}}>
            <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
        </ul>



    </form>
    { loading && <Spinner /> }

    </>
  )
}

export default DSTVFormComp