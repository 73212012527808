import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../Alert/Toast'
import {useAppDispatch, useAppSelector } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'
import { fetchAirtimeBiller } from '../../utils/billers'
import { validateNTELForm } from '../../utils/ValidAirtime/validAirtime'
import { ICurrentNTELOrder } from '../../utils/AirtimeDateTypeScript/airtimeDataTypes'
import { addNTELOrder } from '../../redux/slice/airtimeSlice/ntelSlice'




const NtelForm = () => {

  const [loading, setLoading] = useState(false)

  const [productType, setProductType] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")

  
  const { currentAirtimePhoto }  = useAppSelector((state) => state.airtimePhoto)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()



  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    const ntelOrderDetails = { amount, email, phoneNumber, productType }

    const result = validateNTELForm(ntelOrderDetails)

    if(result.errLength){

       toast.error(() => <Toast title="Error"
        body={result.errMsg} />)

        setLoading(false)
        return 
    }



    let transactionId = "PreponeBills" + Math.floor(Math.random() * 1000000);

    const ntelOrder: ICurrentNTELOrder = {
      billerCode: 'NTEL',
      productId: "1118",
      externalRef: transactionId,
      transDetails: [
        {
        fieldName: "Amount",
        fieldValue: amount,
        fieldControlType : "TEXTBOX"
        },
        {
        fieldName: "E-mail",
        fieldValue: email,
        fieldControlType : "TEXTBOX"
        },
        {
        fieldName: "Phone Number",
        fieldValue: phoneNumber,
        fieldControlType : "TEXTBOX"
        },
        {
        fieldName: "Product Type",
        fieldValue: "NTEL-VTU",
        fieldControlType : "LOOKUP"
        }
      ]
    }

       
    dispatch(addNTELOrder(ntelOrder))
    localStorage.setItem("ntelOrder", JSON.stringify(ntelOrder));
    navigate("/recharge-summary") 
    setLoading(false)
    
}



// const fetchBiller = async () => {

//   const currentBillerCode = currentAirtimePhoto ? currentAirtimePhoto?.billerCode : 'MTN'
//   const selectetCableTVBiller = await fetchAirtimeBiller( currentBillerCode)
//   console.log(selectetCableTVBiller);

//   return selectetCableTVBiller;

// }



// useEffect( ()=>{
//   fetchBiller()
// }, [])  


  





  return (
    <form onSubmit={handleSubmit} className="mx-3">
      <div className="form-row">
     
        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlInput04">Product Type <span className='text-danger'>*</span></label>

          <select className="custom-select form-control" id="operator" name="product" value={productType} onChange={e => setProductType(e.target.value)} >
              <option value="NTEL-VTU">
                {
                  currentAirtimePhoto?.billerCode === "NTEL" ? "NTEL-VTU" : "Select Product Type"
                }
                
              </option>
          </select>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter phone number" name="customerNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        </div>

      </div>


      <div className="form-row">
       


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Enter Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)}/>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'>*</span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>

      </div>


      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default NtelForm