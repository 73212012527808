import { IBettingOrder } from "../TypeScript";

export const validateBettingForm = (bettingOrderDetails: IBettingOrder) => {
    const {userAccountNumber, amount, phoneNumber } = bettingOrderDetails
  
    const errors = [];
  
    if(!userAccountNumber){
      errors.push("Please enter customer account number.")
    }


    if(!amount){
      errors.push("Please enter amount.")
    }else if( Number(amount) < 100 ){
      errors.push("Minimum amount should be ₦100.")
    }else if( Number(amount) > 500000 ){
      errors.push("Maximum amount per transaction should be ₦500,000.")
    }
  
    // if(!email){
    //   errors.push("Please enter your email.")
    // }else if( !validateEmail(email)){
    //   errors.push("Email format is incorrect.")
    // }
  
    if(!phoneNumber){
        errors.push("Please enter phone number.")
    } else if(phoneNumber.length < 11){
      errors.push("Please enter a valid phone number.")
    } else if(phoneNumber.length > 11){
      errors.push("Please enter a valid phone number.")
    }
    
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
  
  
  }