import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentMTNOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface MTNState {
    currentMTNOrder?: ICurrentMTNOrder,
    loading: boolean
}

const initialState: MTNState = {
    currentMTNOrder: undefined,
    loading: false,
}


const mtnSlice = createSlice({
    name: 'mtn',
    initialState,
    reducers: {
      addMTNOrder: (state, action) => {
        state.currentMTNOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('mtn') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('mtn') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addMTNOrder } = mtnSlice.actions

export default mtnSlice.reducer
