import { toast } from "react-toastify"
import { IUserLogin, IUserRegister } from "../utils/TypeScript"

import { 
    browserLocalPersistence,
    browserSessionPersistence,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    setPersistence,
    signInWithEmailAndPassword,
    // signInWithPopup,
    sendEmailVerification,
    onIdTokenChanged,
    signOut,
    updateProfile
} from "firebase/auth";

  
import { auth } from "../Firebase"




export const registerApi = async (user: IUserRegister) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, user.email, user.password)

      await updateProfile(res.user, {
        displayName: user.name
      })

      toast.success( "Successful, Please check your email.")

      
      const unsubscribe = onIdTokenChanged(auth, async (user) => {
        if(user){
          const providerId = user.providerData.some(
            p => p.providerId === 'password'
          );
  
          if(providerId && !user.emailVerified){
            await sendEmailVerification(user)
            // await signOut(auth)
            // return navigate("/email-verified");
           
          }
  
          
          return res.user;
        }else{
          
          console.log("Navigate to Login")
        }
      })
  
      return unsubscribe;

    } catch (err: any) {
      return toast.error(err.message)
    }
}


export const loginApi = async (user: IUserLogin) => {
    try {
        const { email, password, remember } = user;

        // console.log(email, password, remember)
    
        await setPersistence(auth, 
            remember
            ? browserLocalPersistence
            : browserSessionPersistence
        )
  
      const res = await signInWithEmailAndPassword(auth, email, password)
      
      toast.success( "Welcome back!")

      return res.user;


    } catch (err: any) {
      return toast.error(err.message)
    }
}


export const forgotPassApi = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email)
      return toast.success('Success! Check your email.');
    } catch (err: any) {
      return toast.error(err.message)
    }
}
  
export const signOutApi = async () => {
    try {
        await signOut(auth)
    } catch (err: any) {
        return toast.error(err.message)
    }
}
  