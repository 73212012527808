import React, { useEffect, useState} from 'react'
import { Link, useNavigate } from "react-router-dom"
import { addDataPhoto } from '../../redux/slice/dataSlice/dataPhotoSlice'
import { addDataOrder } from '../../redux/slice/dataSlice/dataSlice'
import { postDataTransaction } from '../../utils/processTransactions/processData/dataTransactions'
import { useAppDispatch, useAppSelector } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'


const DataDoneComp = () => {

    const [success, setSuccess] =useState(false)
    const [loading, setLoading] =useState(false)

    const { currentDataPhoto } = useAppSelector(state => state.dataPhoto)
    const { currentDataOrder } = useAppSelector(state => state.data)

    

    const dispatch = useAppDispatch()
    const navigate = useNavigate()


    const redirectToHome = () =>{
        setTimeout(()=>{
            localStorage.clear();
            navigate("/")
        }, 50000)
    }

    const postTransaction = async () =>{

        setLoading(true)

        if(currentDataOrder !== undefined) {
            const transactionResponse = await postDataTransaction(currentDataOrder)
            const {  responseMessage } = transactionResponse
            // console.log(transactionResponse);

            if(responseMessage.toLowerCase() === "successful" ){
                setSuccess(true) 
                setLoading(false)
                localStorage.clear();
                redirectToHome()
                
            }else{
                localStorage.clear();
                redirectToHome()
                return setLoading(false)
            }
        }        
    }


    const getFromLocalStorage = ()=>{
        const dataPhoto =  JSON.parse(localStorage.getItem('dataPhoto') || "{}");
        const dataOrder =  JSON.parse(localStorage.getItem('dataOrder') || "{}");

        

        dispatch(addDataPhoto(dataPhoto))
        dispatch(addDataOrder(dataOrder))
       
    }


    useEffect(()=>{
        if(localStorage.getItem('dataPhoto') === null ) return navigate("/")

        getFromLocalStorage()
        // getDataInfo()

    }, [ ])

    useEffect(()=>{
        // if(localStorage.getItem('dataPhoto') === null ) return navigate("/")
        if(localStorage.getItem('payment') === null ) return navigate("/")

        const payment =  localStorage.getItem('payment') || " ";
        if(payment !== "i") return setLoading(false)
        
        postTransaction()

    }, [currentDataOrder])



  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                    <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>
                                    {
                                        
                                        currentDataPhoto?.billerCode === '9mobiledata1' ? "9Mobile Data"
                                        : 
                                        currentDataPhoto?.billerCode === 'airtel-data' ? "Airtel Data"
                                        : 
                                        currentDataPhoto?.billerCode === 'glo-data' ? "Glo Data"
                                        : 
                                        currentDataPhoto?.billerCode === 'data' ? "MTN Data"
                                        :
                                        currentDataPhoto?.billerCode === 'NTELBundle' ? "ntel Data"
                                        :
                                        currentDataPhoto?.billerCode === 'SMILE' ? "SMILE DATA BUNDLE"
                                        :
                                        currentDataPhoto?.billerCode === 'SPECTRANET' ? "SPECTRANET"
                                        :
                                        ""
                                    }
                                    </h4>
                                    </div>
                                    <div className="ml-auto">
                                    <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>
                                        {
                                        currentDataOrder?.details?.productName
                                    }
                                    </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">
                
                            <li className="nav-item">
                                <Link className="nav-link" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/data-payment">Payment</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link active" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="five" role="tabpanel" aria-labelledby="five-tab">
                                
                                
                                {
                                    loading ? 

                                        ""
                                    :
                                
                                        <div className="done my-5">
                                            <h3 className="title">
                                                {
                                                    success ? 
                                                        "Transaction Successful"
                                                    :
                                                        "Transaction Failed"
                                                }
                                            </h3>

                                            <span className={`text-5 d-block  mt-4 mb-3 ${success? "text-success" : "text-danger"}`}>
                                                {
                                                    success ? 
                                                        <i className="fas fa-check-circle"></i>
                                                    :
                                                        <i className="fas fa-times-circle"></i>
                                                }
                                            </span>

                                            {/* <p>Your payment ₦{currentOrder?.amount} {success ? "    was successfully" : "failed"} <br/>
                                                to receive <b>+1 408 7094360</b> Mobile Operator.
                                            </p> */}

                                            <p> <span>Your </span>
                                                 
                                                { 
                                                    currentDataOrder?.details?.productName
                                                } 

                                                <span> data of ₦</span>
                                                
                                                {
                                                    currentDataOrder?.details?.amount
                                                } 
                                                
                                                <br/>
                                                to <b>
                                                    {
                                                        currentDataOrder?.details?.phoneNumber
                                                    } 
                                                
                                                </b>
                                                <span> {success ? "was successful" : "failed"}.</span>
                                                {success ? " Thank you for using PreponeBills" : " Please try again."}
                                            </p>




                                            {/* <div className="invoice-option text-center">
                                                <Link to="#" className="invoice-btn"><i className="far fa-file-pdf"></i>Save as PDF</Link>
                                                <Link to="#" className="invoice-btn"><i className="fas fa-print"></i>Print Receipt</Link>
                                                <Link to="#" className="invoice-btn"><i className="far fa-envelope"></i>Email Receipt</Link>
                                                <p className="mt-4 mb-0"><Link to="/data-order" className="btn btn-default">Make another Recharge</Link></p>
                                            </div> */}
                                        </div>


                                }
                            </div>
                        </div>
                    </div>
                    { loading && <Spinner /> }
                </div>
  )
}

export default DataDoneComp