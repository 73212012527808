import React from 'react'
import { motion } from "framer-motion"

import AboutPageFirstSection from '../components/AboutPage/AboutPageFirstSection'
import AboutPageHeader from '../components/AboutPage/AboutPageHeader'
import ContactInfoSection from '../components/AboutPage/ContactInfoSection'
// import StaffSection from '../components/AboutPage/StaffSection'
import PaymentAssurance from '../components/PaymentAssurance/PaymentAssurance'

const AboutsUs = () => {
  return (
    <motion.div
    initial={{ scaleY: 0 }}
    animate={{ scaleY: 1 }}
    exit={{ scaleY: 0 }}
    transition={{ duration: 0.5 }}
    >

      <AboutPageHeader />
  
      <AboutPageFirstSection />


      {/* <StaffSection /> */}
      <PaymentAssurance />
      <ContactInfoSection />
    </motion.div>
  )
}

export default AboutsUs