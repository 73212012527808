import React from 'react'
import { Link } from "react-router-dom"

const PrivacyHeader = () => {
  return (
    // <header className="header">
        <section className="page-feature py-5">
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className=" text-left">Privacy Policy</h2>
                    </div>
                    <div className="col-md-6">
                        <div className="breadcrumb text-right">
                            <Link to="/">Home</Link>
                            <span>/ Compare</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    // </header>
  )
}

export default PrivacyHeader