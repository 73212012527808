import { IDataOrder, IMTNDataOrder, INineMobileDataOrder, ISmileOrder, ISpectranetOrder } from "../AirtimeDateTypeScript/airtimeDataTypes";
import { validateEmail } from "../Validation";


export const validateDataForm = (mtnDataOrderDetails: IDataOrder) => {
  const { amount, phoneNumber, uniqueCode } = mtnDataOrderDetails

  const errors = [];

  if(!uniqueCode){
    errors.push("Please select a data plan.")
  }

  if(!amount){
    errors.push("Please select a data plan.")
  }

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  

  return {
    errMsg: errors,
    errLength: errors.length
  }
  
}




export const validateMTNDataForm = (mtnDataOrderDetails: IMTNDataOrder) => {
  const {productType, phoneNumber, email } = mtnDataOrderDetails

  const errors = [];

  if(!productType){
    errors.push("Please select a product type.")
  }

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  if(!email){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
  
}


export const validateAirtelDataForm = (airtelDataOrderDetails: IMTNDataOrder) => {
  const { productType, phoneNumber, email } = airtelDataOrderDetails

  const errors = [];

  if(!productType){
    errors.push("Please select a product type.")
  }

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  if(!email){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
  
}


export const validateGloDataForm = (gloDataOrderDetails: IMTNDataOrder) => {
  const { productType, phoneNumber, email } = gloDataOrderDetails

  const errors = [];

  if(!productType){
    errors.push("Please select a product type.")
  }

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  if(!email){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
  
}


export const validateNtelDataForm = (ntelDataOrderDetails: IMTNDataOrder) => {
  const { productType, phoneNumber, email } = ntelDataOrderDetails

  const errors = [];

  if(!productType){
    errors.push("Please select a product type.")
  }

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 2){
    errors.push("Please enter a valid ntel number.")
  } 

  if(!email){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
  
}




export const validateNineMobileDataForm = (nineMobileDataOrderDetails: INineMobileDataOrder) => {
  const { dataPlan, phoneNumber, email } = nineMobileDataOrderDetails

  const errors = [];

  if(!dataPlan){
    errors.push("Please select a data plan.")
  }

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  if(!email){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
  
}


export const validateSpectranetDataForm = (spectranetDataOrderDetails: ISpectranetOrder) => {
  const { pin, email } = spectranetDataOrderDetails

  const errors = [];

  if(!pin){
    errors.push("Please select PIN Amount.")
  }


  if(!email){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }
  
}



export const validateSmileDataForm = (smileDataOrderDetails: ISmileOrder) => {
  const {dataPackage,  smileAccountNumber,  email } = smileDataOrderDetails

  const errors = [];

  if(!smileAccountNumber){
    errors.push("Please enter smile account number.")
  } else if(smileAccountNumber.length < 2){
    errors.push("Please enter a valid smile account number.")
  } 

  if(!dataPackage){
    errors.push("Please select data package.")
  }

  if(!email){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }

  // if(!invoiceId){
  //   errors.push("Please enter invoice Id.")
  // }
  // if(!customerName){
  //   errors.push("Please enter customer name.")
  // }

  return {
    errMsg: errors,
    errLength: errors.length
  }
  
}