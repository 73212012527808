import React from 'react'
import ForgotPasswordForm from '../components/forgotPassword/ForgotPasswordForm'
import ForgotPasswordHeader from '../components/forgotPassword/ForgotPasswordHeader'

const ForgotPasswordPage = () => {
  return (
    <div  className='faq-page'>
        <ForgotPasswordHeader />

        <ForgotPasswordForm />
    </div>
  )
}

export default ForgotPasswordPage