import React from 'react'
import { Link } from "react-router-dom"

import NotFoundImg from "../../assets/images/404.png"

const NotFoundContentSection = () => {
  return (
    <section className="error-section">
      <div className="container">
        <div className="row">
          <div className="col-md-11 m-auto">
            <img src={NotFoundImg} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-9 m-auto text-center">
            <div className="sec-heading">
              <h2 className="sec-title">Oops! Page Not Found</h2>
              <p className="sec-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <Link to="/" className="btn btn-filled btn-round"><span className="bh"></span> <span>Back to Home</span></Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NotFoundContentSection