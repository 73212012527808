import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Toast from '../components/Alert/Toast'
import CallToAction from '../components/callToAction/CallToAction'
import AsideBar from '../components/global/AsideBar/AsideBar'
import ProfileBar from '../components/global/ProfileBar/ProfileBar'
import TransactionsDetailsComp from '../components/transactionsDetailsComp/TransactionsDetailsComp'
import { useAppSelector } from '../utils/TypeScript'

const TransactionsDetailsPage = () => {

  const { currentUser } = useAppSelector(state => state.auth)
  const navigate = useNavigate()


  useEffect(() => {
    if(!currentUser){
      toast.error(() => <Toast title="Error"
        body="Please Login!" />)
       return navigate("/login");
    }
  }, [ navigate, currentUser])



  return (
    <div>
        <ProfileBar />

        <div id="content" className="py-4">
            <div className="container">
                <div className="row">

                  {
                    currentUser

                    &&
                    
                    <h1>Transaction details ppage commented out</h1>
                    // <TransactionsDetailsComp />

                  }

                  {
                    currentUser

                    &&

                    <AsideBar />

                  }



                </div>
            </div>
        </div>

        <CallToAction />
    </div>
  )
}

export default TransactionsDetailsPage