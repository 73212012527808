import { createSlice } from '@reduxjs/toolkit'
import { ICurrentOrderGeneral } from '../../../utils/TypeScript'



export interface ElectricityPhotoState {
    currentElectricityPhoto?: ICurrentOrderGeneral,
   
}

const initialState: ElectricityPhotoState= {
    currentElectricityPhoto: undefined,
  
}


const electricityPhotoSlice = createSlice({
    name: 'electricityphoto',
    initialState,
    reducers: {
      addElectricityPhoto: (state, action) => {
        state.currentElectricityPhoto = action.payload
      }
    },

})


export const { addElectricityPhoto } = electricityPhotoSlice.actions

export default electricityPhotoSlice.reducer
