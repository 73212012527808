import { RootState } from '../redux/store';




// Save state to local storage
export const saveState = (state: Partial<RootState>) => { 
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('reduxState', serializedState);
    } catch (e) {
      console.warn("Could not save state", e);
    }
  };


  
  
  // Load state from local storage
  export const loadState = (): Partial<RootState> | undefined => { 
    try {
      const serializedState = localStorage.getItem('reduxState');
      if (serializedState === null) {
        return undefined; 
        
      }
      return JSON.parse(serializedState) as Partial<RootState>; 
      
    } catch (e) {
      console.warn("Could not load state", e);
      return undefined;
    }
  };
  