import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentNtelDataOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface NtelDataState {
    currentNtelDataOrder?: ICurrentNtelDataOrder,
    loading: boolean
}

const initialState: NtelDataState = {
    currentNtelDataOrder: undefined,
    loading: false,
}


const ntelDataSlice = createSlice({
    name: 'nteldata',
    initialState,
    reducers: {
      addNtelDataOrder: (state, action) => {
        state.currentNtelDataOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('nteldata') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('nteldata') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addNtelDataOrder } = ntelDataSlice.actions

export default ntelDataSlice.reducer
