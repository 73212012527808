import axios from "axios";
import { toast } from "react-toastify";
import { ICurrentDSTVOrder, ICurrentGOTVOrder, ICurrentStartimesOrder } from "../../TypeScript";




export const postCableTVTransaction = async ( payload: Object  ) => {
    try { 
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transactions`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
          'publicKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PRIVATE_KEY}`,
          endpoint: "cable",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }

}



export const postDSTVTransaction = async ( payload: ICurrentDSTVOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { responseMessage } = res.data
        console.log(responseMessage)

        return responseMessage;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }

}


export const postGOTVTransaction = async ( payload: ICurrentGOTVOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { responseMessage } = res.data
        console.log(responseMessage)

        return responseMessage;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }

}


export const postStartimesTransaction = async ( payload: ICurrentStartimesOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { responseMessage } = res.data
        console.log(responseMessage)

        return responseMessage;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }

}