import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../../Alert/Toast'
import {IProfile, useAppDispatch, useAppSelector } from "../../../utils/TypeScript"
import Spinner from '../../Alert/Spinner/Spinner'

import { validateEKEDCPostpaidForm } from '../../../utils/ValidElectricity/ValidateElectricity'
import {  IElectricityOrder } from '../../../utils/electricityTypes/electricityTypes'
import {  lookupElectricity } from '../../../utils/lookup/lookupTrans'
import { profileUpdate } from '../../../redux/slice/profileSlice/profileSlice'
import { addElectricityOrder } from '../../../redux/slice/electricitySlice/electSlice'





const EKEDCPostpaidForm = () => {

  const [loading, setLoading] = useState(false)

  const [accountNumber, setAccountNumber] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")


  
  // const { currentElectricityPhoto }  = useAppSelector((state) => state.electricityPhoto)
  const { profile } = useAppSelector(state => state.profile)
  const { currentUser } = useAppSelector(state => state.auth)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()



  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    const ekedcPostpaidOrderDetails = { amount, email, accountNumber, phoneNumber }

    const result = validateEKEDCPostpaidForm(ekedcPostpaidOrderDetails)

    if(result.errLength){

      toast.error(() => <Toast title="Error"
      body={result.errMsg} />)

      setLoading(false)
      return 
    }


    let transactionId = "PreponeBills" + Math.floor(Math.random() * 1000000);

    const electLookupObj = {
      requestId: transactionId,
      uniqueCode: "EKOELE_89928",
      details: {
        accountNumber: accountNumber,
        accountType: "POSTPAID",
      }
    }
    const lookupResult = await lookupElectricity(electLookupObj)
    const { data, responseMessage } = lookupResult

    if(responseMessage.toLowerCase() === "successful"){
      // Save Transaction details for later
      if(currentUser){

        const dataa: IProfile = {
          meterNo: accountNumber, 
          phoneNo: phoneNumber,
          userEmail: email,
          smartCardNumber: profile?.smartCardNumber ? profile?.smartCardNumber : ""
        }
        await dispatch(profileUpdate({user: currentUser, dataa}))
      } 


      const electricityOrder: IElectricityOrder = {
        requestId: transactionId,
        uniqueCode: "EKOELE_89928",
        details: {
          accountNumber: accountNumber,
          accountType: "POSTPAID",
          amount: Number(amount),
          name: data?.name,
          address: data?.address,
          phoneNumber,
          email
        }
      }

      dispatch(addElectricityOrder(electricityOrder))
      localStorage.setItem("electricityOrder", JSON.stringify(electricityOrder));

      navigate("/electricity-summary") 
      setLoading(false)

    } else {

      setLoading(false)
      return toast.error("Could not find the user with this Meter or Account Number!") 

    }
    
}

useEffect( ()=>{
  if(!profile) return;
  setPhoneNumber(profile.phoneNo)
  setEmail(profile.userEmail)
  setAccountNumber(profile.meterNo)
}, [profile])







  return (
    <form onSubmit={handleSubmit} className="mx-3 mt-4">
        <span className='text-danger' style={{fontSize: "0.9rem"}}>
          {
            currentUser ? "" : "Login to remember details next time."
          }
        
        </span>
      <div className="form-row">

        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Account Number <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter account number" name="customerNumber" value={accountNumber} onChange={e => setAccountNumber(e.target.value)} />
        </div>

        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Enter Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)}/>
        </div>

      </div>


      <div className="form-row">

        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'>*</span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>

        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'></span></label>
            <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter phone number" name="customerNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        </div>

      </div>


      {/* <div className="form-row">

        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Customer Details <span className='text-danger'></span></label>
            <input type="text" className="form-control" data-bv-field="customerDetails" id="amount"  placeholder="Enter customer details" name="customerDetails" value={customerDetails} onChange={e => setCustomerDetails(e.target.value)}/>
        </div>

        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Customer Name <span className='text-danger'></span></label>
          <input type="text" className="form-control" data-bv-field="customerName" id="mobileNumber"  placeholder="Enter full name" name="customerName" value={customerName} onChange={e => setCustomerName(e.target.value)}/>
        </div>

      </div> */}


      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default EKEDCPostpaidForm