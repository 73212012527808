import React from 'react'
import ComingSoonComp from '../components/ComingSoon/ComingSoon'

const BlogPage = () => {
  return (
    <div>
      <ComingSoonComp />
    </div>
  )
}

export default BlogPage