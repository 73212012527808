import React, { useEffect } from 'react'
import { useNavigate  } from "react-router-dom"
import { motion } from "framer-motion"

import { useAppSelector } from "./../utils/TypeScript"
import SignUpHeader from '../components/SignUp/SignUpHeader'
import SignupMainComp from '../components/SignUp/SignupMainComp'

const SignupPage = () => {
  const { currentUser }  = useAppSelector((state) => state.auth)

  const navigate = useNavigate()

  useEffect(() => {
    if(currentUser) return navigate('/')
  },[currentUser, navigate])


  return (
    <motion.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    >

        <SignUpHeader />

        <SignupMainComp />

    </motion.div>
  )
}

export default SignupPage