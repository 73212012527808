import React, { useEffect, useState } from 'react'
import XPay from "npm_xpresspay";

import { Link, useNavigate } from "react-router-dom"
import { useAppSelector } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner';
import axios from "axios";
import { generateTransactionId, LiveBaseURL } from "../../utils/config";


const CableTVSummaryComp = () => {

    const tranId = localStorage.getItem("tranId") || "";


    const [state, setState] = useState({
        amount: "",
        loading: false,
        transactions: [],
    });

    const navigate = useNavigate()
    
    const [loading, setLoading] = useState(false)    

    const { currentCableTVPhoto }  = useAppSelector((state) => state.cableTVPhoto)
    const { currentCableTVOrder }  = useAppSelector((state) => state.cableTV)

    
    
    const PaymentGateway = async () => {
        let transId = generateTransactionId();

        const totalAmount = Number(
            currentCableTVOrder?.details.amount
        ) + 100

        const publicKey =  process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC;

        setState({ ...state, loading: true });
        setLoading(true)
        try {
        //   XPay.InitialisePayment({
        //     amount: `${totalAmount}`,
        //     transactionId: transId,
            
        //     email:  currentCableTVOrder?.details.email,

        //     // publicKey: `${process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_TEST}`,
        //     publicKey: `${process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC}`,

        //     currency: "NGN",
        //     mode: "Production",
        //     // "mode": "Debug",
        //     callbackUrl: window.location.href,
        //     metadata: [
        //       {
        //         name: "sample",
        //         value: "test",
        //       },
        //     ],
        //   }).then((response: any) => {
        //     if (response.success) {
        //       setState({ ...state, loading: false });
        //       sessionStorage.setItem("tranId", transId); // it can be saved to Database.
        //       sessionStorage.setItem("reference", response.data?.reference); // it can be saved to Database
        //       localStorage.setItem('tranId', transId);

        //       window.location.href = response.data?.authorizeUrl;
        //       setLoading(false)
              
        //     } else {
        //       setState({ ...state, loading: false });
        //       window.location.href = response.data?.authorizeUrl;
        //       setLoading(false)
             
        //     }
        //   });
        
        const response = await axios.post(
            `${LiveBaseURL}/api/Payments/Initialize`,
            {
              amount: `${totalAmount}`,
              transactionId: transId,
              email: currentCableTVOrder?.details.email,
              publicKey: `${process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC}`,
              currency: "NGN",
              mode: "Live",
              callbackUrl: window.location.href,
              metadata: [
                {
                  name: "sample",
                  value: "test",
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${publicKey}`,
                "Content-Type": "application/json",
              },
            }
          );
    
          if (response?.data?.data?.paymentUrl) {
            setState({ ...state, amount: `${totalAmount}`, loading: false });
            sessionStorage.setItem("tranId", transId);
            sessionStorage.setItem("reference", response?.data?.reference);
            localStorage.setItem("tranId", transId);
    
            window.location.href = response?.data?.data?.paymentUrl;
            setLoading(false);
          } else {
            setState({ ...state, amount: `${totalAmount}`, loading: false });
            window.location.href = response?.data?.data?.paymentUrl;
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
    }

    const VerifyPayment = async () => {    
        setLoading(true);
        const tranId = localStorage.getItem("tranId") || "";
        const publicKey =
          process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC;
    
        if (!publicKey) {
          setLoading(false);
          return;
        }
        
        if (!tranId) {
          setLoading(false);
          return;
        }
    
        try {
    
          const response = await axios.post(
            `${LiveBaseURL}/api/Payments/VerifyPayment`,
            {
              publicKey,
              transactionId: tranId,
              mode: "Live",
            },
            {
              headers: {
                Authorization: `Bearer ${publicKey}`,
                "Content-Type": "application/json",
              },
            }
          );
        
          const { data } = response;
    
          if (data?.data?.isSuccessful) {
            const amount = data?.amount;
    
            setState({ ...state, amount, transactions: data?.history });
            console.log("Transaction successful");
            localStorage.removeItem("tranId");
            localStorage.setItem("payment", "i");
            navigate("/cabletv-done")
        } else {
            console.log("Transaction not successful:", data?.responseMessage);
            setState({ ...state, amount: "" });
            localStorage.setItem("payment", "o");
          }
        } catch (error) {
          console.log("Error verifying payment:", error);
          setState({ ...state, amount: "" });
        } finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        VerifyPayment();
      }, [tranId]);





  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>
                                            {
                                                    currentCableTVPhoto?.billerCode === "DSTV1" ? "DSTV" : 
                                                    currentCableTVPhoto?.billerCode === "GOTV1" ? "GOTV" : 
                                                    currentCableTVPhoto?.billerCode === "startimes" ? "Startimes" : 
                                                    ""
                                            }
                                        </h4>
                                    </div>
                                    <div className="ml-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>₦
                                        {
                                            currentCableTVOrder?.details.amount
                                        }
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">

                            <li className="nav-item">
                                <Link className="nav-link" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to="">Summary</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/recharge-payment">Payment</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="">Done</Link>
                            </li>
                        </ul>


                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade  show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <div className="order-summery">
                                    <h3 className="title">Order Summary</h3>
                                    <div className="order-details-area table">

                                        <div className="bg-offwhite table-content">
                                            <div className="row">

                                                <div className="col">Smart Card Number</div>

                                                <div className="col text-right">
                                                {
                                                    currentCableTVOrder?.details.accountNumber
                                                }
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className=" table-content">
                                            <div className="row">
                                                <div className="col">Number of Months</div>
                                                <div className="col text-right">
                                                {
                                                    currentCableTVOrder?.details.noOfMonth
                                                }
                                                </div>
                                            </div>
                                        </div>


                                        <div className="bg-offwhite table-content">
                                            <div className="row">
                                                <div className="col">Email</div>
                                                <div className="col text-right">
                                                {
                                                    currentCableTVOrder?.details.email
                                                }
                                                </div>
                                            </div>
                                        </div>


                                                      
                                        <div className="table-content">
                                            <div className="row">
                                                <div className="col">
                                                    Subscription Package
                                                </div>

                                                <div className="col text-right">
                                                {
                                                    currentCableTVOrder?.details.packageName
                                                }
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div className="bg-offwhite table-content">
                                            <div className="row">
                                                <div className="col">Phone Number</div>
                                                <div className="col text-right">
                                                {
                                                    currentCableTVOrder?.details.phoneNumber
                                                }
                                                </div>
                                            </div>
                                        </div>


                                        <div className="table-content">
                                            <div className="row">
                                                <div className="col">
                                                Customer Name
                                                </div>

                                                <div className="col text-right">
                                                {
                                                    currentCableTVOrder?.details.name
                                                }
                                                </div>
                                            </div>
                                        </div>
                                         
                                          



                                        


                                        <div className="row">
                                            <div className="col-md-5 ml-auto">
                                                <div className="table-content">
                                                    <div className="row">
                                                        <div className="col">Amount</div>
                                                        <div className="col text-right">₦
                                                        {
                                                            currentCableTVOrder?.details.amount
                                                        }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col">Service Charge</div>
                                                        <div className="col text-right">₦100</div>
                                                    </div>
                                                    <hr />
                                                    {/* <div className="row">
                                                        <div className="col">Subtotal</div>
                                                        <div className="col text-right">₦4, 500</div>
                                                    </div> */}
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col font-weight-bold">Total</div>
                                                        <div className="col text-right font-weight-bold">₦
                                                        {Number(
                                                                 currentCableTVOrder?.details.amount

                                                        ) + 100}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-6 text-left">
                                        <Link className="another-item-btn" to="/cabletv-order">+ Add another number Recharge</Link>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <Link className="coupon-btn" to="#">I have a promo code</Link>
                                    </div>
                                </div> */}
                                {/* <br /> */}
                                <div id="couponBox" className="coupon-box bg-offwhite p-3">
                                    <h3 className="title">Coupon Code</h3>
                                    <div className="input-group form-group mt-0">
                                        <input className="form-control" placeholder="Coupon Code" type="text" />
                                        <span className="input-group-append">
                                            <button className="btn btn-default" type="submit">APPLY</button>
                                        </span> 
                                    </div>
                                </div>

                                <ul className="pager my-5">
                                    <li>
                                        <Link to="/cabletv-order" className="btn btn-default "><i className="fas fa-chevron-left"></i> Back</Link>
                                    </li>

                                    <li>
                                        <button className="btn btn-default"
                                            onClick={() => PaymentGateway()}
                                        >
                                        Next <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    { loading && <Spinner /> }
                </div>
  )
}

export default CableTVSummaryComp