import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CallToAction from '../../components/callToAction/CallToAction';
import AsideBar from '../../components/global/AsideBar/AsideBar';
import ProfileBar from '../../components/global/ProfileBar/ProfileBar';
import ElectricityDetailsComp from '../../components/transactionsDetailsComp/ElectricityDetailsComp';
import { IHistoryResponse } from '../../utils/transHistory/historyTypes';
import { useAppSelector } from '../../utils/TypeScript';

const TransactionsDetails = () => {

    const { currentUser } = useAppSelector(state => state.auth)
    const { currentElectricityHistory } = useAppSelector(state => state.electricityHistory)

    const [singelAirtimeHistory, setSingleAirtimeHistory] = useState<IHistoryResponse | undefined>()

    useEffect(()=>{
        filterHistory()
    }, [])

    const params = useParams();

    const filterHistory = ()=>{

        const filteredAirtimeHistory = currentElectricityHistory?.filter((obj: IHistoryResponse)=>{
            return obj.transRef === params.slug
        })
    
        if(!filteredAirtimeHistory) {
            return
        } else {
            const exactAirtimeHistory =  filteredAirtimeHistory[0]
            setSingleAirtimeHistory(exactAirtimeHistory)
        }
    }

   

   

  return (
    <div>
        <ProfileBar />

        <div id="content" className="py-4">
            <div className="container">
                <div className="row">

                  {
                    currentUser

                    &&
                    
                    <ElectricityDetailsComp  electricityHistory={singelAirtimeHistory} />


                  }

                  {
                    currentUser

                    &&

                    <AsideBar />

                  }


                    {/* { currentDataHistory &&
                        <TransactionsDetailsComp  dataHistory={singelDataHistory} />}

                    <AsideBar /> */}



                </div>
            </div>
        </div>

        <CallToAction />
    </div>
  )
}

export default TransactionsDetails


