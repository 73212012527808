import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentFIRSOrder } from '../../../utils/TypeScript'


export interface FIRSOrderState {
    currentFIRSOrder?: ICurrentFIRSOrder,
}

const initialState: FIRSOrderState = {
    currentFIRSOrder: undefined,
}


const firsSlice = createSlice({
    name: 'firs', 
    initialState,
    reducers: {
      addFIRSOrder: (state, action) => {
        state.currentFIRSOrder = action.payload;
      }
    },
})


export const {  addFIRSOrder } = firsSlice.actions

export default firsSlice.reducer
