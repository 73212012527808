import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore/lite"
// import { getFirestore } from "firebase/firestore/lite"

import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_PREPONE_BILLS_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_PREPONE_BILLS_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_PREPONE_BILLS_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_PREPONE_BILLS_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_PREPONE_BILLS_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_PREPONE_BILLS_FIREBASE_APP_ID}`

};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth();

// export const provierGoogle = new GoogleAuthProvider()
// export const provierFacebook = new FacebookAuthProvider()

export const db = getFirestore(app)

export const storage = getStorage(app);





// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "",
//   authDomain: "",
//   projectId: "",
//   storageBucket: "",
//   messagingSenderId: "",
//   appId: "",
//   measurementId: "G-KK1VE2FFSM"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

