import React from 'react'
import { motion } from "framer-motion"

import ContactFormSection from '../components/ContactPage/ContactFormSection'
import ContactPageHeader from '../components/ContactPage/ContactPageHeader'

const ContactUs = () => {
  return (
    <motion.div
    initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ContactPageHeader />

      <ContactFormSection />
      </motion.div>  )
}

export default ContactUs