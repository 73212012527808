import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../utils/TypeScript'

import CallToAction from '../components/callToAction/CallToAction'
import AsideBar from '../components/global/AsideBar/AsideBar'
import ProfileBar from '../components/global/ProfileBar/ProfileBar'
import Toast from '../components/Alert/Toast'
import { toast } from 'react-toastify'
import CableTVHistoryPageComp from '../components/transactionsPageComp/CableTVHistoryComp'

const CableTVTransactionsPage = () => {

  const navigate = useNavigate()
  // const dispatch = useAppDispatch()

  const { currentUser } = useAppSelector(state => state.auth)



  useEffect(() => {
    if(!currentUser){
      toast.error(() => <Toast title="Error"
        body="Please Login!" />)
       return navigate("/login");
    }
  }, [ navigate, currentUser])
  


  return (
    <div>
        <ProfileBar />

        <div id="content" className="py-4">
            <div className="container">
                <div className="row">

                  {
                    currentUser

                    &&

                   <CableTVHistoryPageComp />

                  }

                  {
                    currentUser

                    &&

                    <AsideBar />

                  }

                </div>
            </div>
        </div>

        <CallToAction />
    </div>
  )
}

export default CableTVTransactionsPage