import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { addAirtimePhoto } from '../../redux/slice/airtimeSlice/airtimePhotoSlice'
import { ICurrentOrderGeneral, useAppDispatch } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'


import NineMobile from "../assets/images/airtime/1.png"
import Airtel from "../assets/images/airtime/2.png"
import AirtelSmartRecharge from "../assets/images/airtime/3.png"
import Glo from "../assets/images/airtime/4.png"
import MTN from "../assets/images/airtime/5.png"
import MTNAWUF from "../assets/images/airtime/6.png"
// import ntel from "../assets/images/airtime/7.png"


const MultipleSelectComp = () => {

    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()



    const getAirtimeBiller = async (billerCode: string ) => {
        setLoading(true)


             const airtimePhoto: ICurrentOrderGeneral = {
                
                billerCode: billerCode,
                productId: "",
                
            }

        dispatch(addAirtimePhoto(airtimePhoto))
        localStorage.setItem("airtimePhoto", JSON.stringify(airtimePhoto));
        navigate("/multiple-airtime-order") 
        setLoading(false)


    }

    useEffect(() => {
        localStorage.clear();
    }, [])


  return (
    <section className="we-work bg-offwhite">
        <div className="container">
            <div className="row">
                <div className=" col-md-12 m-auto text-center">
                    <div className="sec-heading mb-4">
                        <span className="tagline">Service providers</span>
                        {/* <h3 className="sec-title">Select your service provider</h3> */}
                    </div>
                </div>
            </div>
            <div className="portfolioITems">
                <div className="ui-group filters mb-disable">
                    <div className="button-group js-radio-button-group" data-filter-group="color">
                        <button className="button is-checked" data-filter="">All</button>
                        <button className="button" data-filter="">9mobile</button>
                        <button className="button" data-filter="">Airtel</button>
                        <button className="button" data-filter="">Glo</button>
                        <button className="button" data-filter="">MTN</button>
                        {/* <button className="button" data-filter="">ntel</button> */}
                      
                    </div>
                </div>


                <div className="portGrid row">
                    <div className="protItem col-3 col-md-1" onClick={() => getAirtimeBiller('9MOBILE_69358')}>
                        <img src={NineMobile} alt="" className="we-work-im"  aria-label='9MOBILE AIRTIME' title='9MOBILE AIRTIME'/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getAirtimeBiller('AIRTEL_22689')}>
                        <img src={Airtel} alt="" className="we-work-img" aria-label='Airtel' title='Airtel'/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getAirtimeBiller('AIRTEL_22689')}>
                        <img src={AirtelSmartRecharge} alt="" className="we-work-img" aria-label='Airtel' title='Airtel'/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getAirtimeBiller('GLO_30387')}>
                        <img src={Glo} alt="" className="we-work-img" aria-label="Glo" title="Glo"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getAirtimeBiller('MTN_24207')}>
                        <img src={MTN} alt="" className="we-work-img" aria-label="MTN" title="MTN"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getAirtimeBiller('MTN_24207')}>
                        <img src={MTNAWUF} alt="" className="we-work-img" aria-label="MTN" title="MTN"/>
                    </div>

                    {/* <div className="protItem col-3 col-md-1 data" onClick={() => getAirtimeBiller('NTEL')}>
                        <img src={ntel} alt="" className="we-work-img" aria-label='ntel Airtime' title='ntel Airtime'/>
                    </div> */}
                   
                </div>
            </div>
        </div>

        { loading && <Spinner /> }
    </section>
  )
}

export default MultipleSelectComp