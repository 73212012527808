import * as React from "react";
import  { useState, FormEvent } from 'react'
import {  InputChange } from "../../utils/TypeScript"

import Toast from '../Alert/Toast'
import { toast } from 'react-toastify'
// import Spinner from '../Alert/Spinner/Spinner'
import { validateContactForm } from '../../utils/Validation'
// import { db } from "../../Firebase"
// import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useNavigate } from 'react-router-dom'
import Spinner from '../Alert/Spinner/Spinner'
// import emailjs from "emailjs-com";
import { sendContactFormEmail } from "../../utils/FetchData";


const ContactFormSection = () => {

    // const [firstName, setFirstName] = useState('')
    // const [lastName, setLastName] = useState('')
    // const [email, setEmail] = useState('')
    // const [phoneNumber, setPhoneNumber] = useState('')
    // const [message, setMessage] = useState('')

    const [loading, setLoading] = useState(false)
    
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: "",
    })
   

    const navigate = useNavigate()
    const { firstName, lastName, email, phoneNumber, message } =
    formData;

    const handleChange = (e: InputChange) => {
        const {value, name} = e.target
        setFormData({...formData, [name]:value})
        // setFormData((prevState) => ({
        //   ...prevState,
        //   [e.target.id]: e.target.value,
        // }));
      };

    // const handleChangee = (e: InputChange) => {
    //     const {value, name} = e.target
    //     setEmailData({...emailData, [name]:value})
       
    //   };

    const handleSubmit = async (e: FormEvent) => {

        e.preventDefault()

        // if(!message && !email) return
        setLoading(true)

        const contactMessageDetails = { firstName, lastName, email, phoneNumber, message}

        const result = validateContactForm(contactMessageDetails)

        if(result.errLength){
            setLoading(false)

            return toast.error(() => <Toast title="Error"
            body={result.errMsg} />)
        }

        await sendContactFormEmail(contactMessageDetails)

       
        // let uId = "Contact_form" + Math.floor(Math.random() * 1000000);
        // console.log(uId);

        // const formDataCopy = {
        //     ...formData,
        //     timestamp: serverTimestamp(),
        // };

        // await addDoc(collection(db, "contactform"), formDataCopy);


        toast.success("Thank you for your message, we'll respond to you ASAP!")
        setLoading(false)
        navigate("/");
    }

    




  return (
    
    <section className="contact">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-9 m-auto text-center">
                    <div className="sec-heading" style={{ animation: "slideInFromTop 1s ease-in" }}>
                        <span className="tagline" style={{color: "#9417E2"}}>Contact us</span>
                        <h3 className="sec-title" style={{color: "#3f0d5e"}}>If You Have Any Question, <br />Drop a Message</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label htmlFor="f_name">First Name</label>
                                <input type="text" className="form-control" id="f_name" placeholder="First Name" name="firstName" value={firstName}
                            onChange={handleChange} />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="l_name">Last Name</label>
                                <input type="text" className="form-control" id="l_name" placeholder="Last name" name="lastName" value={lastName}
                            onChange={handleChange} />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="email">email</label>
                                <input type="email" className="form-control" id="email" placeholder="Email address" name="email" value={email}
                            onChange={handleChange}  />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="phone">Phone number</label>
                                <input type="number" className="form-control" id="phone" placeholder="Phone number" name="phoneNumber" value={phoneNumber}
                            onChange={handleChange} />
                            </div>

                        </div>

                        <div className="form-group">
                            <label htmlFor="message">Your Message</label>
                            <textarea className="form-control" id="message" placeholder="Your Message" name="message" value={message}
                            onChange={handleChange}></textarea>
                        </div>

                        <button type="submit" className="btn btn-default">send message</button>
                    </form>
                </div>
                <div className="col-md-4">
                    <aside className="sidebar">
                        <div className="widget contact-info">
                            <h3 className="widget-title" style={{color: "#3f0d5e"}}>Contact info</h3>
                            <p>Need to talk to us directly? <br/>Find PreponeBills office address below also there's option to fill this form or send us an email directly.</p>
                            <div>
                                <h6 style={{color: "#3f0d5e"}}>Office Addresses:</h6>
                                <span style={{color: "#3f0d5e"}}>Port Harcourt Office:</span> 
                                <span className="mb-2">#32 Omunakwe Street, Port Harcourt, Nigeria.</span> 
                                <span style={{color: "#3f0d5e"}}>Lagos Office:</span> 
                                <span>#14A Fola Jinadu Crescent, Gbagada Phase 1 Lagos, Nigeria.</span> 


                            </div>
                            {/* <div>
                                <h6>Hotline numbers</h6>
                                <a href="tel:+12345678912">+123 4567 8912</a>
                                <a href="tel:+32176542198">+321 7654 2198</a>
                            </div> */}
                            <div>
                                <h6 style={{color: "#3f0d5e"}}>Email Us</h6>
                                {/* <a href="mailto:youremail@domain.com">info@preponebills.com</a> */}
                                <a href="mailto:help@example.com">info@preponebills.com</a>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
        { loading && <Spinner /> }

        
    </section>
  )
}

export default ContactFormSection