import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentAirtelOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface AirtelState {
    currentAirtelOrder?: ICurrentAirtelOrder,
    loading: boolean
}

const initialState: AirtelState = {
    currentAirtelOrder: undefined,
    loading: false,
}


const airtelSlice = createSlice({
    name: 'airtel',
    initialState,
    reducers: {
      addAirtelOrder: (state, action) => {
        state.currentAirtelOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('airtel') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('airtel') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addAirtelOrder } = airtelSlice.actions

export default airtelSlice.reducer
