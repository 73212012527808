import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../../Alert/Toast'
import {useAppDispatch, useAppSelector } from "../../../utils/TypeScript"
import Spinner from '../../Alert/Spinner/Spinner'

import { fetchAirtimeBiller } from '../../../utils/billers'
import { validateEKEDCOrderPaymentForm } from '../../../utils/ValidElectricity/ValidateElectricity'
import {  ICurrentEKEDCOrderPaymentOrder } from '../../../utils/electricityTypes/electricityTypes'
import { addEKOrderPaymentElectOrder } from '../../../redux/slice/electricitySlice/EKEDCOrderPayment'
import { lookupAbjPostpaid } from '../../../utils/lookup/lookupTrans'





const EKEDCOrderPaymentForm = () => {

  const [loading, setLoading] = useState(false)

  const [orderId, setOrderId] = useState("")
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [customerDetails, setCustomerDetails] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")


  
  const { currentElectricityPhoto }  = useAppSelector((state) => state.electricityPhoto)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()



  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    const eOrderDetails = { amount, email, phoneNumber, orderId, customerName, customerDetails }

    const result = validateEKEDCOrderPaymentForm(eOrderDetails)

    if(result.errLength){

       toast.error(() => <Toast title="Error"
        body={result.errMsg} />)

        setLoading(false)
        return 
    }


    const abjLookupObj = {
      billerCode: "EKEDC_ORDER_PAYMENT",
      customerAccountNumber: orderId,
      customerAccountType: "",
    }
    const lookupResult = await lookupAbjPostpaid(abjLookupObj)
    const customername = lookupResult.customerName
    const otherInfo = lookupResult.otherCustomerInfo


    if(customername === null && otherInfo === null){
      setLoading(false)
       return toast.error("Could not find the user with this meter number!") 
     } else {


        let transactionId = "PreponeBills" + Math.floor(Math.random() * 1000000);

        const ekOrderPaymentOrder: ICurrentEKEDCOrderPaymentOrder = {
          billerCode: "EKEDC_ORDER_PAYMENT",
          productId: "1172",
          externalRef: transactionId,
          transDetails: [
            {
            fieldName: "Amount",
            fieldValue: amount,
            fieldControlType : "TEXTBOX"
            },
            {
            fieldName: "Customer Details",
            fieldValue: otherInfo,
            fieldControlType : "TEXTBOX"
            },
            {
            fieldName: "Customer Name",
            fieldValue: customername,
            fieldControlType : "TEXTBOX"
            },
            {
            fieldName: "Email Address",
            fieldValue: email,
            fieldControlType : "TEXTBOX"
            },
            {
            fieldName: "OrderId",
            fieldValue: orderId,
            fieldControlType : "TEXTBOX"
            },
            {
            fieldName: "Phone Number",
            fieldValue: phoneNumber,
            fieldControlType : "TEXTBOX"
            }
          ]
        }

          
        dispatch(addEKOrderPaymentElectOrder(ekOrderPaymentOrder))
        localStorage.setItem("ekOrderPaymentOrder", JSON.stringify(ekOrderPaymentOrder));
        navigate("/electricity-summary") 
        setLoading(false)
      }
    
}




// const fetchBiller = async () => {

//   const currentBillerCode = currentElectricityPhoto ? currentElectricityPhoto?.billerCode : 'ABJ_POSTPAID'
//   const selectetCableTVBiller = await fetchAirtimeBiller( currentBillerCode)
//   console.log(selectetCableTVBiller);

//   return selectetCableTVBiller;

// }



// useEffect( ()=>{
//   fetchBiller()
// }, [])




  return (
    <form onSubmit={handleSubmit} className="mx-3">
      
      <div className="form-row">

      <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Order Id  <span className='text-danger'>*</span></label>
            <input type="text" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter OrderId" name="orderId" value={orderId} onChange={e => setOrderId(e.target.value)} />
        </div>
     
        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'></span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>
      </div>


      <div className="form-row">

        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Enter Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)}/>
        </div>

        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'></span></label>
            <input type="number" className="form-control" data-bv-field="customerNumber" id="amount"  placeholder="Enter phone number" name="customerNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
        </div>

      </div>


      {/* <div className="form-row">

        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Customer Name <span className='text-danger'></span></label>
          <input type="text" className="form-control" data-bv-field="customerName" id="mobileNumber"  placeholder="Enter full name" name="customerName" value={customerName} onChange={e => setCustomerName(e.target.value)}/>
        </div>

        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Customer Details <span className='text-danger'></span></label>
            <input type="text" className="form-control" data-bv-field="customerNumber" id="amount"  placeholder="Enter customer details" name="customerDetails" value={customerDetails} onChange={e => setCustomerDetails(e.target.value)}/>
        </div>

      </div> */}


      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default EKEDCOrderPaymentForm