import { IAbujaPostpaid, IEKEDCOrderPaymentOrder, IEKEDCPostpaidOrder, IEKEDCPrepaidOrder, IEPOSTPAID, IEPREPAID, IIBEDC, IJosPostpaid, IKadunaPostpaid, IKadunaPrepaid, IKanoPostpaid, IKanoPretpaid, IPHEDC } from "../electricityTypes/electricityTypes";
import { validateEmail } from "../Validation";




export const validateAbjPostpaidForm = (AbjPostpaidOrderDetails: IAbujaPostpaid) => {
    const {amount,  meterNumber,  email } = AbjPostpaidOrderDetails
  
    const errors = [];
  
    if(!meterNumber){
      errors.push("Please enter meter number.")
    } else if(meterNumber.length < 2){
      errors.push("Please enter a valid meter number.")
    } 
  
    if(!amount ){
      errors.push("Please enter amount.")
    }  else if(Number(amount) < 600){
      errors.push('Please enter an amount between NGN 100 and 5,000,000.')
    }
  
    if(!email){
      errors.push("Please enter your email.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
    
  }

  export const validateEKEDCOrderPaymentForm = (eOrderDetails: IEKEDCOrderPaymentOrder) => {
    const {amount,  orderId } = eOrderDetails
  
    const errors = [];
  
    if(!orderId){
      errors.push("Please enter order id.")
    } else if(orderId.length < 2){
      errors.push("Please enter a valid order id.")
    } 
  
    if(!amount ){
      errors.push("Please enter amount.")
    }  else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 100 and 5,000,000.')
    }
  
    // if(!email){
    //   errors.push("Please enter your email.")
    // }else if( !validateEmail(email)){
    //   errors.push("Email format is incorrect.")
    // }

    // if(!phoneNumber){
    // errors.push("Please enter phone number.")
    // } else if(phoneNumber.length < 11){
    // errors.push("Please enter a valid phone number.")
    // } else if(phoneNumber.length > 11){
    // errors.push("Please enter a valid phone number.")
    // }

    // if(!customerName){
    //   errors.push("Please enter customer name.")
    // }

    // if(!customerDetails){
    //   errors.push("Please enter customer details.")
    // }
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
    
  }

  export const validateIEPostpaidForm = (iePostpaidOrderDetails: IEPOSTPAID) => {
    const { amount, accountNumber, email } = iePostpaidOrderDetails
  
    const errors = [];
    if(!accountNumber){
      errors.push("Please enter account Number.")
    } else if(accountNumber.length < 2){
      errors.push("Please enter a valid account number.")
    } 
  
    if(!amount ){
      errors.push("Please enter amount.")
    }  else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 100 and 5,000,000.')
    }
  
    if(!email){
      errors.push("Please enter your email.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

    

    return {
      errMsg: errors,
      errLength: errors.length
    }
  }

  export const validateIEPrepaidForm = (iePrepaidOrderDetails: IEPREPAID) => {
    const { amount, meterNumber,  email } = iePrepaidOrderDetails
  
    const errors = [];
    if(!meterNumber){
      errors.push("Please enter meter Number.")
    } else if(meterNumber.length < 2){
      errors.push("Please enter a valid meter number.")
    } 
  
    if(!amount ){
      errors.push("Please enter amount.")
    }  else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 100 and 5,000,000.')
    }
  
    if(!email){
      errors.push("Please enter your email.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
  }

  export const validateEKEDCPostpaidForm = (ekedcPostpaidOrderDetails: IEKEDCPostpaidOrder) => {
    const {amount, accountNumber,  email } = ekedcPostpaidOrderDetails
  
    const errors = [];
  
    if(!accountNumber){
      errors.push("Please enter account Number.")
    } else if(accountNumber.length < 2){
      errors.push("Please enter a valid account number.")
    } 
  
    if(!amount ){
      errors.push("Please enter amount.")
    }  else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 100 and 5,000,000.')
    }
  
    if(!email){
      errors.push("Please enter your email.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

    // if(!phoneNumber){
    // errors.push("Please enter phone number.")
    // } else if(phoneNumber.length < 11){
    // errors.push("Please enter a valid phone number.")
    // } else if(phoneNumber.length > 11){
    // errors.push("Please enter a valid phone number.")
    // }
    
    // if(!customerDetails){
    //   errors.push("Please enter customer details.")
    // }
  
    // if(!customerName){
    //   errors.push("Please enter customer name.")
    // }
    return {
      errMsg: errors,
      errLength: errors.length
    }
  }

  
  export const validateIBEDCForm = (ibedcOrderDetails: IIBEDC) => {
    const {amount, email, meterNumber, meterType } = ibedcOrderDetails
  
    const errors = [];
  
    if(!meterType){
      errors.push("Please select a meter type.")
    }  

    if(!meterNumber){
      errors.push("Please enter meter Number.")
    } else if(meterNumber.length < 2){
      errors.push("Please enter a valid meter number.")
    } 
  
    if(!amount ){
      errors.push("Please enter amount.")
    }  else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 100 and 5,000,000.')
    }
  
    if(!email){
      errors.push("Please enter your email.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

    // if(!phoneNumber){
    // errors.push("Please enter phone number.")
    // } else if(phoneNumber.length < 11){
    // errors.push("Please enter a valid phone number.")
    // } else if(phoneNumber.length > 11){
    // errors.push("Please enter a valid phone number.")
    // }

    // if(!accountName){
    //   errors.push("Please enter account name.")
    // }
    
    // if(!customerDetails){
    //   errors.push("Please enter customer details.")
    // }

    return {
      errMsg: errors,
      errLength: errors.length
    }
    
  }

  export const validateEKEDCPrepaidForm = (ekedcPrepaidOrderDetails: IEKEDCPrepaidOrder) => {
    const {amount, meterNumber, email } = ekedcPrepaidOrderDetails
  
    const errors = [];
  
    if(!meterNumber){
      errors.push("Please enter meter Number.")
    } else if(meterNumber.length < 2){
      errors.push("Please enter a valid meter number.")
    } 
    
    if(!meterNumber){
      errors.push("Please enter meter Number.")
    } else if(meterNumber.length < 2){
      errors.push("Please enter a valid meter number.")
    } 
  
    if(!amount ){
      errors.push("Please enter amount.")
    }  else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 100 and 5,000,000.')
    }
  
    if(!email){
      errors.push("Please enter your email.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

  //  if(phoneNumber.length < 11){
  //   errors.push("Please enter a valid phone number.")
  //   } else if(phoneNumber.length > 11){
  //   errors.push("Please enter a valid phone number.")
  //   }
  
    // if(!customerName){
    //   errors.push("Please enter customer name.")
    // }
    return {
      errMsg: errors,
      errLength: errors.length
    }
    
  }


  export const validatePHEDCForm = (phedcOrderDetails: IPHEDC) => {

    const {amount,  email, meterOrAccountNumber, meterType } = phedcOrderDetails
  
    const errors = [];

    if(!meterType ){
      errors.push("Please select a meter type.")
    }
  
    if(!meterOrAccountNumber){
      errors.push("Please enter meter or account number.")
    } else if(meterOrAccountNumber.length < 2){
      errors.push("Please enter a valid meter or account number.")
    } 

    
  
  
    if(!email){
      errors.push("Please enter your email.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }


    if(!amount ){
      errors.push("Please enter amount.")
    }  else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 100 and 5,000,000.')
    }
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
    
  }


  export const validateJosPostpaidForm = (josPostpaidDetails: IJosPostpaid) => {
    const {amount, email, meterNumber } = josPostpaidDetails
  
    const errors = [];
  
    if(!meterNumber){
      errors.push("Please enter meter number.")
    } else if(meterNumber.length < 2){
      errors.push("Please enter a valid meter number.")
    } 

    if(!amount ){
      errors.push("Please enter amount.")
    } else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 1,000 and 5,000,000.')
    }

  
    if(!email){
      errors.push("Please enter email address.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

    return {
      errMsg: errors,
      errLength: errors.length
    }
  }


  export const validateJosPrepaidForm = (josPostpaidDetails: IJosPostpaid) => {
    const {amount, email, meterNumber } = josPostpaidDetails
  
    const errors = [];
  
    if(!meterNumber){
      errors.push("Please enter meter number.")
    } else if(meterNumber.length < 2){
      errors.push("Please enter a valid meter number.")
    } 

    if(!amount ){
      errors.push("Please enter amount.")
    } else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 600 and 1,000,000.')
    }

   
  
    if(!email){
      errors.push("Please enter email address.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

    return {
      errMsg: errors,
      errLength: errors.length
    }
  }


  export const validateKadunaPostpaidForm = (kadunaPostpaidDetails: IKadunaPostpaid) => {
    const {amount,  meterNumber, email  } = kadunaPostpaidDetails
  
    const errors = [];
  
    if(!meterNumber){
      errors.push("Please enter meter number.")
    } else if(meterNumber.length < 2){
      errors.push("Please enter a valid meter number.")
    } 

    if(!amount ){
      errors.push("Please enter amount.")
    }  else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 1,000 and 5,000,000.')
    }

  
    if(!email){
      errors.push("Please enter email address.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

    

    return {
      errMsg: errors,
      errLength: errors.length
    }
  }


  export const validateKadunaPrepaidForm = (kadunaPrepaidDetails: IKadunaPrepaid) => {
    const {amount, email, meterNumber, } = kadunaPrepaidDetails
  
    const errors = [];
  
    if(!meterNumber){
      errors.push("Please enter meter number.")
    } else if(meterNumber.length < 2){
      errors.push("Please enter a valid meter number 10 digits.")
    } 

    if(!amount ){
      errors.push("Please enter amount.")
    }  else if(Number(amount) < 100){
      errors.push('Please enter an amount between NGN 600 and 5,000,000.')
    }

  
    if(!email){
      errors.push("Please enter email address.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

    // if(!customerName){
    //   errors.push("Please enter customer name.")
    // }

    return {
      errMsg: errors,
      errLength: errors.length
    }
  }



  export const validateKanoPostpaidorm = (kadunaPostpaidDetails: IKanoPostpaid) => {
    const {amount, email, accountNumber } = kadunaPostpaidDetails
  
    const errors = [];
  
    if(!accountNumber){
      errors.push("Please enter account number.")
    } else if(accountNumber.length < 2){
      errors.push("Please enter a valid account number.")
    } 

    if(!email){
      errors.push("Please enter email address.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

    if(!amount ){
      errors.push("Please enter amount.")
    } else if(Number(amount) < 500){
      errors.push('Please enter an amount between NGN 1,000 and 5,000,000.')
    }
    
    return {
      errMsg: errors,
      errLength: errors.length
    }
  }


  export const validateKanoPrepaidorm = (kadunaPrepaidDetails: IKanoPretpaid) => {
    const {amount, email, meterNumber } = kadunaPrepaidDetails
  
    const errors = [];
  
    if(!meterNumber){
      errors.push("Please enter meter number.")
    } else if(meterNumber.length < 2){
      errors.push("Please enter a valid meter number.")
    } 

    if(!email){
      errors.push("Please enter email address.")
    }else if( !validateEmail(email)){
      errors.push("Email format is incorrect.")
    }

    if(!amount ){
      errors.push("Please enter amount.")
    } else if(Number(amount) < 500){
      errors.push('Please enter an amount between NGN 1,000 and 5,000,000.')
    }
    
    return {
      errMsg: errors,
      errLength: errors.length
    }
  }