import React from 'react'
import { Link } from "react-router-dom"
import {  useAppSelector } from '../../utils/TypeScript'


const ProfileMenuOne = () => {

    const { currentUser } = useAppSelector(state => state.auth)



  return (
    <div id="menu1" className="tab-pane fade in active">
        <div className="row">
            <p className="col-sm-3"><b>Name</b></p>
            <p className="col-sm-9">{currentUser ? currentUser.displayName : "User"}</p>
        </div>
        <hr/>
        <div className="row">
            <p className="col-sm-3"><b>Date of Birth</b></p>
            <p className="col-sm-9">  </p>
        </div>
        <hr />
        <div className="row">
            <p className="col-sm-3"><b>Account Status  </b><span className="text-muted font-weight-500">{currentUser?.emailVerified ? "(Verified)" : "(Not Verified)"}</span></p>
            <p className="col-sm-9"><span className={currentUser?.emailVerified ? "text-success" : "text-danger"} data-toggle="tooltip" data-original-title="Active"><i className="fas fa-check-circle"></i>{currentUser?.emailVerified ? "" : "Please check your email to verify"}</span></p>
        </div>
        <hr/>
        <div className="row">
            <p className="col-sm-3"><b>Email</b> <span className="text-muted font-weight-500">(Primary)</span></p>
            <div className="col-sm-9">
                <p className="mb-0"> {currentUser ? currentUser.email : "example@gmail.com"} </p>
                <p> Send and receive <Link to="#" style={{color:"#9417E2"}}>mobile payments</Link></p>
            </div>
            <p className="col-sm-3"><b>Email</b> <span className="text-muted font-weight-500">(Personal)</span></p>
            <p className="col-sm-9">{currentUser ? currentUser.email : "example@gmail.com"} </p>
        </div>
        <hr/>
        <div className="row">
            <p className="col-sm-3"><b>Mobile</b> <span className="text-muted font-weight-500">(Primary)</span></p>
            <p className="col-sm-9">  </p>
            <p className="col-sm-3"><b>Landline</b> <span className="text-muted font-weight-500">(Home)</span></p>
            <p className="col-sm-9">  </p>
        </div>
        <hr/>
        <div className="row">
            <p className="col-sm-3"><b>Address</b></p>
            <p className="col-sm-9"></p>
        </div>
        <hr/>
        <div className="row">
            <p className="col-sm-3"><b>Customer Service ID</b></p>
            <p className="col-sm-9">When you call Customer Service, we’ll ask you to confirm your identity using this account information. <Link to="#" data-toggle="modal" data-target="#supportpin" style={{color:"#9417E2"}}>Update</Link></p>
        </div>

        {/* <!-- Modal --> */}
        <div className="modal fade step-secourity" id="supportpin" 
        //  tabindex="-1" 
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <p>Add or change your Customer Service PIN</p></h5>
                                <p>With One Touch™, you’ll speed through checkout without having to log in every time.</p>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>                                                    
                        </div>

                        <div className="modal-body">
                            <form>
                                <label htmlFor="inputPassword5"><b>New Pin</b></label>
                                <input type="password" id="inputPassword5" className="form-control" aria-describedby="passwordHelpBlock" />
                                <small id="passwordHelpBlock" className="form-text text-muted">
                                    Enter you're 6 digit pin.
                                </small>
                                <label htmlFor="inputPassword5"><b>Confirm Pin</b></label>
                                <input type="password" id="inputPassword5" className="form-control" aria-describedby="passwordHelpBlock" />
                                <small id="passwordHelpBlock" className="form-text text-muted">
                                    Enter you're 6 digit Confirm pin.
                                </small>
                            </form>
                            
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn " style={{backgroundColor:"#9417E2"}}>Add OR Change Pin</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default ProfileMenuOne