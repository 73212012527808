import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addJambOrder } from '../../redux/slice/educationSlice/jambSlice'
import { profileUpdate } from '../../redux/slice/profileSlice/profileSlice'
import {  getProductsByCategoriesIdAndBillerId } from '../../utils/billers'
import { lookupJAMB } from '../../utils/lookup/lookupTrans'
import { useAppSelector, useAppDispatch, ICableTVPackages, IJambPackages, ICurrentJambOrder,} from "../../utils/TypeScript"
import { validateJambOrder } from '../../utils/validateEducation/validateEducation'
import Spinner from '../Alert/Spinner/Spinner'
import Toast from '../Alert/Toast'


const JambFormComp = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()


    const [loading, setLoading] = useState(false)
    const [profileCode, setProfileCode] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [packageName, setPackageName] = useState("")
    const [amount, setAmount] = useState("")

    const [packages, setPackages] = useState([])
    const [uniqueCode, setUniqueCode] = useState("")


    const { profile } = useAppSelector(state => state.profile)
    const { currentUser } = useAppSelector(state => state.auth)
  


    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)

        const jambOrderDetails = {profileCode, uniqueCode, phoneNumber }
        
        const result = validateJambOrder(jambOrderDetails)

        if(result.errLength){

           toast.error(() => <Toast title="Error"
            body={result.errMsg} />)

            setLoading(false)
            return 
        }

        let transactionId = "PreponeBills-" + Math.floor(Math.random() * 1000000);

        const profileType = packageName === "JAMB UTME" ? "UTME" : packageName === "JAMB DE" ? "DE" : ""


        const jambLookup = {
            requestId:transactionId,
            uniqueCode,
            details: {
                profileCode,
                profileType,
            }
        }

        console.log({jambLookup: jambLookup});

        const lookupResult = await lookupJAMB(jambLookup)
        const { data, responseMessage } = lookupResult
        console.log(lookupResult);

        if(!responseMessage || responseMessage.toLowerCase() !== "successful"){
            const jambOrder: ICurrentJambOrder = {
                requestId: transactionId,
                uniqueCode,
                details: {
                    profileCode,
                    profileType,
                    phoneNumber,
                    amount,
                    packageName,
                    userEmail: email
                }
            }
    
            dispatch( addJambOrder(jambOrder))
            localStorage.setItem("jambOrder", JSON.stringify(jambOrder));
            navigate("/education-summary") 
            setLoading(false)
        } else {

            setLoading(false)
            return toast.error("Could not find the user with this profile code!") 
      
        }

            

    }



    const fetchBiller = async () => {

        if(packages.length < 1){

            setLoading(true)
            const JambServices = await getProductsByCategoriesIdAndBillerId( 6, 24)

            // console.log(JambServices.data.productDTOList)
            setPackages(JambServices.data.productDTOList) 
            setLoading(false)
        } else {
            return
        }

    }

    const setPackageAmount = ()=>{
       if(!uniqueCode){
           return
       } else {
            const selectedJambPackage = packages.filter(( paackage : IJambPackages)=>{
                return paackage.uniqueCode === uniqueCode
            })

            setAmount(selectedJambPackage[0]['amount']);
            setPackageName(selectedJambPackage[0]['name']);
            // console.log(selectPackage)


       }
        
    }


    useEffect( ()=>{
         fetchBiller()
    }, [])

    useEffect( ()=>{
         setPackageAmount()
    }, [uniqueCode])


    // useEffect( ()=>{
    //     if(!profile) return;
    //     setEmail(profile.userEmail)
    //     setSmartCardNumber(profile.smartCardNumber)
    // }, [profile])



  return (
      <>
    <form onSubmit={handleSubmit} className="mx-3 mt-4">

        <span className='text-danger' style={{fontSize: "0.9rem"}}>
            {
            currentUser ? "" : "Login to remember details next time."
            }
        
        </span>
        <div className="form-row">
            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1" className='fs-10'>Profile Code<span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter Profile Code" name="profileCode" value={profileCode} onChange={e => setProfileCode(e.target.value)}/>
            </div>


            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlInput04">Select JAMB Service<span className='text-danger'>*</span></label>

                <select className="custom-select form-control" id="operator" name="selectPackage" value={uniqueCode} onChange={e => setUniqueCode(e.target.value)}>
                    <option value="">Select JAMB Service</option>
                    { packages.length ?
                       ( packages.map((cablePackage: IJambPackages, index) =>
                            <option  key={index} value={cablePackage.uniqueCode}>{cablePackage.name}</option>  
                        )) : ""
                    }
                </select>
            </div>


        </div>


        <div className="form-row">

            <div className="col-md-6 col-lg-6">
                
                <label htmlFor="exampleFormControlSelect "> <span className='text-danger'></span></label>
                
                <div className="form-group input-group pt-2">
                    <div className="input-group-prepend "> <span className="input-group-text px-3 text-white" style={{backgroundColor: "#BC61F5"}}>(Amount) ₦</span> 
                    </div>
                    <input className="form-control text-center" id="amount" placeholder="Amount"  type="number" name='amount' value={amount} readOnly/>
                    
                </div>
            </div>

            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Phone Number<span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter Phone Number" name="email" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
            </div>
        </div>



        <div className="form-group">

            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Email Address <span className='text-danger'></span></label>
                <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>

            {/* <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Customer Full Name <span className='text-danger'></span></label>
                <input type="text" className="form-control shadow form-control-lg smartCardNumber" id="smartCardNumber"  placeholder="Enter full name" name="customerDetails" value={customerDetails} onChange={e => setCustomerDetails(e.target.value)} />
            </div> */}

           
        </div>



        <ul className="pager my-5" style={{width: "100%"}}>
            <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
        </ul>



    </form>
    { loading && <Spinner /> }

    </>
  )
}

export default JambFormComp