import { createSlice } from '@reduxjs/toolkit'
import {  ICurrentCableTVOrderDetails } from "../../../utils/TypeScript"



export interface CableTVState {
    currentCableTVOrder?: ICurrentCableTVOrderDetails,
    loading: boolean
}

const initialState: CableTVState = {
  currentCableTVOrder: undefined,
    loading: false,
}


const cableTVSlice = createSlice({
    name: 'cabletv',
    initialState,
    reducers: {
      addCableTVOrder: (state, action) => {
        state.currentCableTVOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('cabletv') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('cabletv') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addCableTVOrder } = cableTVSlice.actions

export default cableTVSlice.reducer
