import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../Alert/Toast'
import {useAppDispatch, useAppSelector } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'

import { ICurrentSpectranetDataOrder, IMTNDataProducts } from '../../utils/AirtimeDateTypeScript/airtimeDataTypes'
import {  fetchDataBiller } from '../../utils/billers'
import { validateSpectranetDataForm } from '../../utils/ValidData/validData'
import { addSpectranetDataOrder } from '../../redux/slice/dataSlice/spectranetSlice'





const SpectranetDataForm = () => {

  const [loading, setLoading] = useState(false)
  const [chooseSpectranetProduct, setChooseSpectranetProduct] = useState("")


  const [product, setProduct] = useState("")
  const [pin, setPIN] = useState("")
  const [products, setProducts] = useState([])
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")


  
  const { currentDataPhoto }  = useAppSelector((state) => state.dataPhoto)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()



  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    const spectranetDataOrderDetails = {product, pin, amount, email }

    const result = validateSpectranetDataForm(spectranetDataOrderDetails)

    if(result.errLength){

       toast.error(() => <Toast title="Error"
        body={result.errMsg} />)

        setLoading(false)
        return 
    }



    let transactionId = "PreponeBills" + Math.floor(Math.random() * 1000000);

    const spectranetDataOrder: ICurrentSpectranetDataOrder = {
      billerCode: 'SPECTRANET',
      productId: "96",
      externalRef: transactionId,
      transDetails: [
        {
        fieldName: "Amount",
        fieldValue: amount,
        fieldControlType : "TEXTBOX"
        },
        {
        fieldName: "Email",
        fieldValue: email,
        fieldControlType : "TEXTBOX"
        },
        {
        fieldName: "PRODUCT",
        fieldValue: 'SPECTRANET',
        fieldControlType : "LOOKUP"
        },
        {
        fieldName: "Select PIN Amount",
        fieldValue: pin,
        fieldControlType : "LOOKUP"
        }
      ]
    }

       
    dispatch(addSpectranetDataOrder(spectranetDataOrder))
    localStorage.setItem("spectranetDataOrder", JSON.stringify(spectranetDataOrder));
    localStorage.setItem("spectranetData", JSON.stringify(chooseSpectranetProduct));
    navigate("/data-summary") 
    setLoading(false)
    
}






const fetchBiller = async () => {

    setLoading(true)

  const currentBillerCode = currentDataPhoto ? currentDataPhoto?.billerCode : 'data'
  const selectetCableTVBiller = await fetchDataBiller( currentBillerCode)
  console.log(selectetCableTVBiller);

  const packages = selectetCableTVBiller.productFields[3].ListItems
  setProducts(packages)

  setLoading(false)

  return selectetCableTVBiller;

}


    const setPackageAmount = ()=>{
        if(!chooseSpectranetProduct){
            return
        } else {
            const selectedProduct = products.filter(( paackage : IMTNDataProducts)=>{
                return paackage.ItemName === chooseSpectranetProduct
        })

            
            setAmount(selectedProduct[0]['Amount']);
            setPIN(selectedProduct[0]['ItemType']);
        }
    }
    
     


 useEffect( ()=>{
      fetchBiller()
 }, [])

 useEffect( ()=>{
      setPackageAmount()
 }, [chooseSpectranetProduct])










  return (
    <form onSubmit={handleSubmit} className="mx-3">
      <div className="form-row">
     
        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlInput04">Product<span className='text-danger'>*</span></label>

          <select className="custom-select form-control" id="operator" name="productType" value={product} onChange={e => setProduct(e.target.value)} >
            <option value="">SPECTRANET PIN</option>
          </select>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlInput04">Select PIN Amount<span className='text-danger'>*</span></label>

          <select className="custom-select form-control" id="operator" name="productType" value={chooseSpectranetProduct} onChange={e => setChooseSpectranetProduct(e.target.value)} >
            <option value="">Select PIN Amount</option>
                {
                    products.map((month : IMTNDataProducts, index) =>
                        <option className='' key={index} value={month.ItemName}>{month.ItemName}</option>  
                    )
                }
          </select>
        </div>

      </div>


      <div className="form-row">

        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Amount" name="amount" value={amount} readOnly/>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'>*</span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>

      </div>



      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default SpectranetDataForm