import React, { useState } from "react";
import {useAppDispatch, useAppSelector } from '../../../utils/TypeScript'


import { Link, useLocation } from "react-router-dom";
import Menu from '../../../components/assets/png/menu.png'
import Close from '../../../components/assets/png/close.png'
import Logo from '../../../components/assets/png/prepone-logo.png'
import { authLogout } from "../../../redux/slice/authSlice";





const Navbar  = ( ) => {

    const { currentUser } = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()


    const navbarClass = ["nav-menu"];

    const [click, setClick]  = useState(false);
     const handleClick = () => setClick(!click);

    const { pathname } = useLocation()

    const bfLoginLinks : any = [
       
        // { label: 'Mobile Recharge', path: '/mobile-recharge' },
        // { label: 'Blog', path: '/blog' },
        { label: 'About Us', path: '/about-us' },
        { label: 'Contact Us', path: '/contact-us' },
        { label: 'FAQ', path: '/faq' }
    ]

    const isActive = (pn: string) => {
        if(pn === pathname) return 'active';
    }

    if (click) {
        navbarClass.push("show");
    }

    const handleMobileLogout = () =>{
        setClick(false)
        dispatch(authLogout())
    }


  return (
      

    <div className="nav__container" >

        <div className="nav-icon" onClick={handleClick}>
            <img src={click? Close : Menu } alt=" menu" />
        </div>

        <nav className="main__navbar">
            <Link to="/" className="nav-logo">

                <div className="logo" onClick={() => setClick(false)}>
                    <img src={Logo} alt="logo" />
                
                </div>                      
            </Link>
            


            <ul className={navbarClass.join(" ")}>
                <Link to="/">

                    <div className="mobile-logo-container" onClick={() => setClick(false)}>
                        <div className="mobile-logo">
                            <img src={Logo} alt="logo" />
                        </div>
                    </div>
                </Link>

                <li className={`nav-item ${isActive("/")}`} 
                    onClick={() => setClick(false)} >
                        <Link className="nav-link" to="/">
                            Home
                        </Link>
                </li>


                <div className="nav-item">
                    <div className="dropdown nav-link ">
                        <button className="btn btn-transparent dropdown-toggle " type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Bills & Recharge
                        </button>
                        <div className="dropdown-menu mt-0" aria-labelledby="dropdownMenu2">
                            <li className="dropdown-item" onClick={() => setClick(false)} >
                                <Link to="/select-recharge">Buy Airtime</Link> 
                            </li>

                            <li className="dropdown-item" onClick={() => setClick(false)} >
                                <Link to="/select-bulk-airtime">Bulk Airtime</Link> 
                            </li>

                            <li className="dropdown-item" onClick={() => setClick(false)} >
                                <Link to="/multiple-airtime-select">Multiple Airtime</Link> 
                            </li>

                            <li className="dropdown-item" onClick={() => setClick(false)}>
                                <Link to="/data-select">Buy Data</Link>
                            </li>

                            {/* <li className="dropdown-item" onClick={() => setClick(false)}
                                >Cable Bills</li> */}

                            <li className="dropdown-item" onClick={() => setClick(false)}>
                                <Link to="/select-cabletv">Cable TV</Link>
                            </li>

                            <li className="dropdown-item" onClick={() => setClick(false)} >
                                    <Link to="/select-electricity">Electricity</Link>
                            </li>

                            <li className="dropdown-item" onClick={() => setClick(false)} >
                                    <Link to="/select-betting">Sports Betting</Link>
                            </li>

                            <li className="dropdown-item" onClick={() => setClick(false)} >
                                    <Link to="/select-education">Education</Link>
                            </li>

                            <li className="dropdown-item" onClick={() => setClick(false)} >
                                    <Link to="/select-govt-levy">Govt Levy</Link>
                            </li>

                            <li className="dropdown-item" onClick={() => setClick(false)}>
                                <Link to="/select-bodies">Professional Bodies</Link>
                            </li>

                            {/* <li className="dropdown-item" onClick={() => setClick(false)}
                                ><Link to="/faq">FAQ</Link> </li> */}
                        </div>
                    </div>
                </div>




                { 
                

                    bfLoginLinks.map(( link: any , index: any ) => 
                    (
                        <li  key={index} className={`nav-item ${isActive(link.path)}`}
                            onClick={() => setClick(false)}
                        >
                            <Link className="nav-link" to={link.path}>
                                {link.label}
                            </Link>
                        </li>
                    ) )

                   
                    
                }

                {
                    currentUser
                    ?
                        <li className="nav-item only-for-mobile" onClick={() => setClick(false)}>
                            <Link className="nav-link" to="/profile">Dashboard</Link>
                        </li>
                    :
                        <li className="nav-item only-for-mobile" onClick={() => setClick(false)}>
                            <Link className="nav-link" to="/login">Login</Link>
                        </li>
                }

                {
                    currentUser
                    ?
                        <li className="nav-item only-for-mobile" onClick={handleMobileLogout}>
                            <Link className="nav-link" to="/">Logout</Link>
                        </li>
                    :
                        <li className="nav-item only-for-mobile" onClick={() => setClick(false)}>
                            <Link className="nav-link" to="/signup">Register</Link>
                        </li>
                }

                

            </ul>


        
        </nav>
    </div>

 
  )
}

export default Navbar