import React, {  useEffect } from 'react'
import { Link } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../utils/TypeScript"

import EOne from "../assets/images/electricity/e1.png"
import ETwo from "../assets/images/electricity/e2.png"
import EThree from "../assets/images/electricity/e3.png"
import EFour from "../assets/images/electricity/e4.png"
import EFive from "../assets/images/electricity/e5.png"
import ESix from "../assets/images/electricity/e6.png"
import ESeven from "../assets/images/electricity/e7.png"
import EEight from "../assets/images/electricity/e8.png"
import ENine from "../assets/images/electricity/e9.png"
import ETen from "../assets/images/electricity/e10.png"
import EEleven from "../assets/images/electricity/e11.png"
import ETwelve from "../assets/images/electricity/e12.png"
import EThirteen from "../assets/images/electricity/e13.png"
import EFourtheen from "../assets/images/electricity/e14.png"
import EFifteen from "../assets/images/electricity/e15.png"
import EEDC from "../assets/images/electricity/eedc.png"



import ABJPostpaidForm from './forms/ABJPostpaidForm'
import ABJPrepaidForm from './forms/ABJPrepaidForm'
import EKEDCOrderPaymentForm from './forms/EKEOrderPaymentForm'
import EKEDCPostpaidForm from './forms/EKEDCPostpaidForm'
import PHEDCForm from './forms/PHEDC'
import EKEDCPrepaidForm from './forms/EKEDCPrepaidForm'
import IBEDCForm from './forms/IBEDCForm'
import IEPostpaidForm from './forms/IEPostpaid'
import IEPrepaidForm from './forms/IEPrepaid'
import JosPostpaidForm from './forms/JosPostpaid'
import JosPrepaidForm from './forms/JosPrepaid'
import KadunaPostpaidForm from './forms/KadunaPostpaid'
import KadunaPrepaidForm from './forms/KadunaPrepaidForm'
import KanoPostpaidForm from './forms/KanoPostpaidForm'
import KanoPrepaidForm from './forms/KanoPrepaidForm'
import { addElectricityPhoto } from '../../redux/slice/electricitySlice/electricityPhotoSlice'
import EnuguForm from './forms/EnuguForm'


const ElectricityOrderComp = () => {

   
 
    const { currentElectricityPhoto }  = useAppSelector((state) => state.electricityPhoto)

    const dispatch = useAppDispatch()

    useEffect(()=>{
        const electricityPhoto =  JSON.parse(localStorage.getItem('electricityPhoto') || "{}");
        dispatch(addElectricityPhoto(electricityPhoto))

    }, [])


  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>
                                            {
                                                currentElectricityPhoto?.billerCode === 'ABJ_POSTPAID' ? "Abuja POSTPAID" :
                                                currentElectricityPhoto?.billerCode === 'ABJ_PREPAID' ? 'Abuja Prepaid' : 
                                                currentElectricityPhoto?.billerCode === 'EKEDC_ORDER_PAYMENT' ? 'EKEDC ORDERPAYMENT' :
                                                currentElectricityPhoto?.billerCode === 'EKEDC_POSTPAID' ? 'EKEDC Postpaid' :
                                                currentElectricityPhoto?.billerCode === 'EKEDC_PREPAID'? 'EKEDC Prepaid' :
                                                currentElectricityPhoto?.billerCode === 'EEDC'? 'EEDC' :
                                                currentElectricityPhoto?.billerCode === 'IBEDC_F'? 'IBEDC' :
                                                currentElectricityPhoto?.billerCode === 'iedc postpaid'? 'iedc Postpaid' :
                                                currentElectricityPhoto?.billerCode === 'iedc'? 'iedc Prepaid' :
                                                currentElectricityPhoto?.billerCode === 'JOS_POSTPAID'? 'Jos Electric Postpaid' :
                                                currentElectricityPhoto?.billerCode === 'JOS_PREPAID'? 'Jos Electric Prepaid' :
                                                currentElectricityPhoto?.billerCode === 'KADUNA_POSTPAID'? 'Kaduna Electric Postpaid' :
                                                currentElectricityPhoto?.billerCode === 'KADUNA_PREPAID'? 'Kaduna Electric Prepaid' :
                                                currentElectricityPhoto?.billerCode === 'KEDCOPostpaid'? 'KEDCO POSTPAID' :
                                                currentElectricityPhoto?.billerCode === 'KEDCOPrepaid'? 'KEDCO PREPAID' :
                                                currentElectricityPhoto?.billerCode === 'PHEDDIR2'? 'PHED' :""
                                            }
                                        </h4>
                                    </div>
                                    <div className="ml-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>Amount</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills ">
                           
                            <li className="nav-item">
                                <Link className="nav-link active" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                          
                            <li className="nav-item">
                                <Link className="nav-link" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            
                            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                                    <div className="operators mx-3">
                                        <div className="row operator-row" id='operator-row'>
                                            <div className="col-md-2 col-sm-4">
                                                <div className="single-operator">
                                                    <div className="op-logo" data-operator="op1">
                                                        <img src={currentElectricityPhoto?.billerCode === 'ABJ_POSTPAID' ? EOne :
                                                        currentElectricityPhoto?.billerCode === 'ABJ_PREPAID' ? ETwo : 
                                                        currentElectricityPhoto?.billerCode === 'EKEDC_ORDER_PAYMENT' ? EThree :
                                                        currentElectricityPhoto?.billerCode === 'EKEDC_POSTPAID' ? EFour :
                                                        currentElectricityPhoto?.billerCode === 'EEDC'? EEDC :
                                                        currentElectricityPhoto?.billerCode === 'EKEDC_PREPAID'? EFive :
                                                        currentElectricityPhoto?.billerCode === 'IBEDC_F'? ESix :
                                                        currentElectricityPhoto?.billerCode === 'iedc postpaid'? ESeven :
                                                        currentElectricityPhoto?.billerCode === 'iedc'? EEight :
                                                        currentElectricityPhoto?.billerCode === 'JOS_POSTPAID'? ENine :
                                                        currentElectricityPhoto?.billerCode === 'JOS_PREPAID'? ETen :
                                                        currentElectricityPhoto?.billerCode === 'KADUNA_POSTPAID'? EEleven :
                                                        currentElectricityPhoto?.billerCode === 'KADUNA_PREPAID'? ETwelve :
                                                        currentElectricityPhoto?.billerCode === 'KEDCOPostpaid'? EThirteen :
                                                        currentElectricityPhoto?.billerCode === 'KEDCOPrepaid'? EFourtheen :
                                                        currentElectricityPhoto?.billerCode === 'PHEDDIR2'?
                                                        EFifteen : ""
                                                    } 
                                                        alt="bill one" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    {
                                        currentElectricityPhoto?.billerCode === 'ABJ_POSTPAID' ? 
                                            <ABJPostpaidForm /> 
                                        :
                                        currentElectricityPhoto?.billerCode === 'ABJ_PREPAID' ? 
                                            <ABJPrepaidForm /> 
                                        : 
                                        currentElectricityPhoto?.billerCode === 'EKEDC_ORDER_PAYMENT' ? 
                                            <EKEDCOrderPaymentForm /> 
                                        :
                                        currentElectricityPhoto?.billerCode === 'EKEDC_POSTPAID' ? 
                                            <EKEDCPostpaidForm /> 
                                        :
                                        currentElectricityPhoto?.billerCode === 'EKEDC_PREPAID'? <EKEDCPrepaidForm />
                                        :
                                        currentElectricityPhoto?.billerCode === 'EEDC'? <EnuguForm />
                                        :
                                        currentElectricityPhoto?.billerCode === 'IBEDC_F'? <IBEDCForm /> 
                                        :
                                        currentElectricityPhoto?.billerCode === 'iedc postpaid'? <IEPostpaidForm />
                                        :
                                        currentElectricityPhoto?.billerCode === 'iedc'? <IEPrepaidForm /> 
                                        :
                                        currentElectricityPhoto?.billerCode === 'JOS_POSTPAID'? <JosPostpaidForm /> 
                                        :
                                        currentElectricityPhoto?.billerCode === 'JOS_PREPAID'?<JosPrepaidForm />
                                        :
                                        currentElectricityPhoto?.billerCode === 'KADUNA_POSTPAID'? <KadunaPostpaidForm /> 
                                        :
                                        currentElectricityPhoto?.billerCode === 'KADUNA_PREPAID'? <KadunaPrepaidForm /> 
                                        :
                                        currentElectricityPhoto?.billerCode === 'KEDCOPostpaid'? <KanoPostpaidForm /> 
                                        :
                                        currentElectricityPhoto?.billerCode === 'KEDCOPrepaid'? <KanoPrepaidForm />
                                        :
                                        currentElectricityPhoto?.billerCode === 'PHEDDIR2'?
                                        <PHEDCForm />
                                        : ""
                                    }


                                
                            </div>

                        </div>
                    </div>
                </div>
  )
}

export default ElectricityOrderComp