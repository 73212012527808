import React, { FormEvent, useState } from 'react'
import {  useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../Alert/Toast'
import {useAppDispatch } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'
// import { fetchAirtimeBiller } from '../../utils/billers'
import { validateAirtimeDetails,  } from '../../utils/ValidAirtime/validAirtime'
import {  ICurrentAirtimeOrder,  } from '../../utils/AirtimeDateTypeScript/airtimeDataTypes'
import { addAirtimeOrder } from '../../redux/slice/airtimeSlice/airtimeSlice'





const GloForm = () => {

  const [loading, setLoading] = useState(false)

  // const [product, setProduct] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")


  
  // const { currentAirtimePhoto }  = useAppSelector((state) => state.airtimePhoto)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()



  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    const gloOrderDetails = { phoneNumber, amount }

    const result = validateAirtimeDetails(gloOrderDetails)

    if(result.errLength){

      toast.error(() => <Toast title="Error"
      body={result.errMsg} />)

      setLoading(false)
      return 
    }



    let transactionId = "PreponeBills-" + Math.floor(Math.random() * 1000000);

    const airtimeOrder: ICurrentAirtimeOrder = {
      requestId: transactionId,
      uniqueCode: "GLO_30387",
      details: {
          phoneNumber: phoneNumber,
          amount: Number(amount)
      },
      userEmail: email
    }

        
    dispatch( addAirtimeOrder(airtimeOrder))
    localStorage.setItem("airtimeOrder", JSON.stringify(airtimeOrder));
    navigate("/recharge-summary") 
    setLoading(false)

  }


  // const fetchBiller = async () => {

  //   const currentBillerCode = currentAirtimePhoto ? currentAirtimePhoto?.billerCode : 'glo'
  //   const selectetCableTVBiller = await fetchAirtimeBiller( currentBillerCode)
  //   console.log(selectetCableTVBiller);

  //   return selectetCableTVBiller;

  // }



  // useEffect( ()=>{
  //   fetchBiller()
  // }, [])




  return (
    <form onSubmit={handleSubmit} className="mx-3">
      <div className="form-row">


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter phone number" name="customerNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        </div>


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Enter Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)}/>
        </div>

      </div>


      <div className="form-row">

        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'></span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>

      </div>


      {/* <div className="form-row">
       


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Enter Amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)}/>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'>*</span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>

      </div> */}


      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default GloForm