import { createSlice, } from '@reduxjs/toolkit'
import { IElectricityOrder } from '../../../utils/electricityTypes/electricityTypes'


export interface ElectricityState {
    currentElectricityOrder?: IElectricityOrder,
    loading: boolean
}

const initialState: ElectricityState = {
    currentElectricityOrder: undefined,
    loading: false,
}


const electricitySlice = createSlice({
    name: 'electricity',
    initialState,
    reducers: {
      addElectricityOrder: (state, action) => {
        state.currentElectricityOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('electricity') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('electricity') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addElectricityOrder } = electricitySlice.actions

export default electricitySlice.reducer
