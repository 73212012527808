import React from 'react'
import { Link, useLocation, 
    // useNavigate 
} from "react-router-dom"
// import { addTransHistoryBillerCode } from '../../../redux/slice/transHistorySlice/transHistoryBillerCode'
// import { ICurrentOrderGeneral, useAppDispatch } from '../../../utils/TypeScript'


const AsideBar = () => {

    // const [loading, setLoading] = useState(false)

    // const dispatch = useAppDispatch()
    // const navigate = useNavigate()

    const asideBarLinks : any = [
       
        { label: 'Dashboard', path: '/profile', icon: 'fas fa-tachometer-alt' },
        // { label: 'Recent Activities', path: '/dashboard', icon: 'fab fa-autoprefixer' },
        // { label: 'Cards Accounts', path: '/profile-cards' , icon: 'fas fa-university'},
        // { label: 'Transaction', path: '/transactions' , icon: 'fas fa-list-ul'},
        { label: 'Airtime Transactions', path: '/airtime-transactions' , icon: 'fas fa-list-ul'},
        { label: 'Data Transactions', path: '/data-transactions' , icon: 'fas fa-list-ul'},
        { label: 'CableTV Transactions', path: '/cabletv-transactions' , icon: 'fas fa-list-ul'},
        { label: 'Electricity Transactions', path: '/electricity-transactions' , icon: 'fas fa-list-ul'},
        // { label: 'Transaction Details', path: '/transactions-details' , icon: 'fas fa-list-ul'},
        { label: 'FAQ', path: '/faq' , icon: 'fas fa-cog'},
    ]

    const { pathname } = useLocation()

    const isActive = (pn: string) => {
        if(pn === pathname) return 'active';
    }


    // const dispatchHistoryBillerCode = async ( billerCode: string) => {
    //     setLoading(true)

    //          const historyBillerCode: ICurrentOrderGeneral = {
    //             billerCode: billerCode,
    //             productId: ""
    //         }

    //     dispatch(addTransHistoryBillerCode(historyBillerCode))
    //     // localStorage.setItem("electricityPhoto", JSON.stringify(electricityPhoto));
    //     navigate("/transactions") 
    //     setLoading(false)


    // }




  return (
    <aside className="col-lg-3 sidebar">
                    <div className="widget admin-widget p-0">
                        <div className="Profile-menu">
                            <ul className="nav secondary-nav">
                                {/* <li className="nav-item active"><Link className="nav-link" to="/profile"><i className="fas fa-tachometer-alt"></i> Dashboard</Link></li>

                                <li className="nav-item"><Link className="nav-link" to="/dashboard"><i className="fab fa-autoprefixer"></i> Recent Activities</Link></li>

                                <li className="nav-item"><Link className="nav-link" to="/profile-cards"><i className="fas fa-university"></i> Cards Accounts</Link></li>

                                <li className="nav-item"><Link className="nav-link" to="/transactions"><i className="fas fa-list-ul"></i>Transaction</Link></li>

                                <li className="nav-item"><Link className="nav-link" to="/transactions-details"><i className="fas fa-list-ul"></i>Transaction Details</Link></li>

                                <li className="nav-item"><Link className="nav-link" to="/profile-notifications"><i className="fas fa-cog"></i>Settings</Link></li> */}


                                { 
                                    

                                    asideBarLinks.map(( link: any , index: any ) => 
                                    (
                                        <li  key={index} className={`nav-item ${isActive(link.path)}`} >
                                            <Link className="nav-link" to={link.path}>
                                                <i className={link.icon}></i>
                                                {link.label}
                                            </Link>
                                        </li>
                                    ) )

                                
                                }

                            </ul>
                        </div>
                    </div>

                    <div className="widget admin-widget">
                        <i className="fas fa-coins admin-overlay-icon"></i>
                        <h2 style={{color: "#3f0d5e"}}>Refer Us</h2>
                        <p>Invite a friend to sign up and get a bonus from PreponeBills.</p>
                        <Link to="#" className="btn btn-default btn-center"><span>Coming Soon...</span></Link>
                    </div>

                    <div className="widget admin-widget">
                        <i className="fas fa-comments admin-overlay-icon"></i>
                        <h2 style={{color: "#3f0d5e"}}>Need Help?</h2>
                        <p>Have questions or concerns regrading your account? <br/>
                            Our experts are here to help!.</p>
                        <Link to="#" className="btn btn-default btn-center"><span>Start Chat</span></Link>
                    </div>

                </aside>
  )
}

export default AsideBar