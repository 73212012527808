import React from 'react'
import { useAppSelector } from '../utils/TypeScript'
import { motion } from "framer-motion"

import AsideBar from '../components/global/AsideBar/AsideBar'
import ProfileBar from '../components/global/ProfileBar/ProfileBar'
import EOneSummaryComp from '../components/ElectricityComps/EOneSummary'

const ElectricitySummaryPage = () => {

  const { currentUser } = useAppSelector(state => state.auth)
  const { currentElectricityPhoto }  = useAppSelector((state) => state.electricityPhoto)



  return (
    <motion.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      // exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    >
        <ProfileBar />

        <div id="content" className="py-4">
          <div className="container">
            <div className="row">

                 {
                    currentElectricityPhoto?.billerCode === 'ABJ_POSTPAID' ? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'ABJ_PREPAID' ? <EOneSummaryComp /> : 
                    currentElectricityPhoto?.billerCode === 'EKEDC_ORDER_PAYMENT' ? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'EKEDC_POSTPAID' ? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'EKEDC_PREPAID'? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'IBEDC_F'? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'iedc postpaid'? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'iedc'? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'JOS_POSTPAID'? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'JOS_PREPAID'? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'KADUNA_POSTPAID'? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'KADUNA_PREPAID'? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'KEDCOPostpaid'? <EOneSummaryComp /> :
                    currentElectricityPhoto?.billerCode === 'KEDCOPrepaid'? <EOneSummaryComp /> :
                    <EOneSummaryComp />
                  }



             
                  {
                    currentUser

                    &&
                    
                    <AsideBar />
                  }

            </div>
          </div>
        </div>
    </motion.div>
  )
}

export default ElectricitySummaryPage