import { createSlice, 
    // createAsyncThunk 
} from '@reduxjs/toolkit'




// export const airtimeProcessTransaction = createAsyncThunk(
//   'airtime/process-transaction',
//   async (currentRechargeOrder: IcurrentRechargeOrder) => {
//     return await buyAirtimeApi(currentRechargeOrder)
//   }
// )




export interface BillersState {
    billers?: [],
    loading: boolean
}

const initialState: BillersState = {
    billers: undefined,
    loading: false,
}


const billersSlice = createSlice({
    name: 'billers',
    initialState,
    reducers: {
      addBillers: (state, action) => {
        state.billers = action.payload
        // state.billers = {...state.billers, ...action.payload}
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('billers') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('billers') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addBillers} = billersSlice.actions

export default billersSlice.reducer
