import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addCableTVPhoto } from '../../redux/slice/cableTVSlice/cableTVPhotoSlice'
import { addCableTVOrder } from '../../redux/slice/cableTVSlice/cableTVSlice'
import { addGOTVOrder } from '../../redux/slice/cableTVSlice/gotvSlice'
import { profileUpdate } from '../../redux/slice/profileSlice/profileSlice'
import { fetchCableTVBiller, getProductsByCategoriesIdAndBillerId } from '../../utils/billers'
import { lookupCableTV, lookupGOTV } from '../../utils/lookup/lookupTrans'
import { ICurrentGOTVOrder, IGOTVPackages, useAppSelector, useAppDispatch, IProfile, ICableTVPackages, ICurrentCableTVOrderDetails,} from "../../utils/TypeScript"
import {  validateGOTVOrder } from '../../utils/Validation'
import Spinner from '../Alert/Spinner/Spinner'
import Toast from '../Alert/Toast'


const GOTVFormComp = () => {

    // const { currentCableTVPhoto }  = useAppSelector((state) => state.cableTVPhoto)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()



    const [loading, setLoading] = useState(false)
    const [smartCardNumber, setSmartCardNumber] = useState("")
    const [email, setEmail] = useState("")
    const [selectPackage, setSelectPackage] = useState("")
    const [amount, setAmount] = useState("")
    const [customerName, setCustomerName] = useState("")
    const [packageName, setPackageName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")


    const [uniqueCode, setUniqueCode] = useState("")
    const [packages, setPackages] = useState([])


    const { profile } = useAppSelector(state => state.profile)
    const { currentUser } = useAppSelector(state => state.auth)


    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)

        const gOTVOrder = {smartCardNumber, email, phoneNumber, uniqueCode }
        
        const result = validateGOTVOrder(gOTVOrder)

        if(result.errLength){

           toast.error(() => <Toast title="Error"
            body={result.errMsg} />)

            setLoading(false)
            return 
        }

        let transactionId = "PreponeBills" + Math.floor(Math.random() * 1000000);


        const gotvLookup = {
            requestId:transactionId,
            uniqueCode,
            details: {
                accountNumber: smartCardNumber,
                accountType: packageName,
                noOfMonth: 1
            }
        }

        const lookupResult = await lookupCableTV(gotvLookup)
        const { data, responseMessage } = lookupResult
        // console.log(lookupResult)


        if(responseMessage.toLowerCase() === "successful"){
            // Save Transaction details for later
            if(currentUser){

                const dataa: IProfile = {
                meterNo: profile?.meterNo? profile?.meterNo : "", 
                phoneNo: phoneNumber,
                userEmail: email,
                smartCardNumber: smartCardNumber
                }
                await dispatch(profileUpdate({user: currentUser, dataa}))
            } 


            const cableTVOrder: ICurrentCableTVOrderDetails = {
                requestId: transactionId,
                uniqueCode,
                details: {
                    accountNumber: smartCardNumber,
                    accountType: packageName,
                    noOfMonth: 1,
                    amount: Number(amount),
                    name: data?.name,
                    phoneNumber,
                    email,
                    packageName,
                }
            }

            dispatch(addCableTVOrder(cableTVOrder))
            localStorage.setItem("cableTVOrder", JSON.stringify(cableTVOrder));

            navigate("/cabletv-summary") 
            setLoading(false)

        } else {
            setLoading(false)
            return toast.error("Could not find the user with this smart card number!")
            
        }

    }



    const fetchBiller = async () => {

        if(packages.length < 1){

            setLoading(true)
            const selectetCableTV = await getProductsByCategoriesIdAndBillerId(5, 23)

            // console.log(selectetCableTV.data.productDTOList);
            setPackages(selectetCableTV?.data?.productDTOList) 
            setLoading(false)
        } else {
            return

        }

    }

    const setPackageAmount = ()=>{
       if(!uniqueCode){
           return
       } else {
            const selectedPackage = packages.filter(( paackage : ICableTVPackages)=>{
                return paackage.uniqueCode === uniqueCode
                
            })

            
            setAmount(selectedPackage[0]['amount']);
            setPackageName(selectedPackage[0]['name']);
            // console.log(selectedPackage);

       }
        
    }


    useEffect( ()=>{
         fetchBiller()
    }, [])

    useEffect( ()=>{
         setPackageAmount()
    }, [uniqueCode])


    useEffect( ()=>{
        if(!profile) return;
        setEmail(profile.userEmail)
        setSmartCardNumber(profile.smartCardNumber)
        setPhoneNumber(profile.phoneNo)
    }, [profile])


  return (
      <>
    <form onSubmit={handleSubmit} className="mx-3 mt-4">

        <span className='text-danger' style={{fontSize: "0.9rem"}}>
            {
                currentUser ? "" : "Login to remember details next time."
            }

        </span>

        <div className="form-row">
            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1" className='fs-10'>Smart Card Number <span className='text-danger'>*</span></label>
                <input type="text" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter Card Number" name="smartCardNumber" value={smartCardNumber} onChange={e => setSmartCardNumber(e.target.value)}/>
            </div>


            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlInput04">Package<span className='text-danger'>*</span></label>

                <select className="custom-select form-control" id="operator" name="selectPackage" value={uniqueCode} onChange={e => setUniqueCode(e.target.value)}>
                    <option value="">Select Package</option>
                    {
                        packages?.map((cablePackage : ICableTVPackages, index) =>
                            <option  key={index} value={cablePackage.uniqueCode}>{cablePackage.name}</option>  
                        )
                    }
                </select>
            </div>

        </div>


        <div className="form-row">

            <div className="col-md-6 col-lg-6">
                <label htmlFor="exampleFormControlSelect "> <span className='text-danger'></span></label>
                
                <div className="form-group input-group pt-2">
                    <div className="input-group-prepend "> <span className="input-group-text px-3 text-white" style={{backgroundColor: "#BC61F5"}}>(Amount) ₦</span> 
                    </div>
                    <input className="form-control text-center" id="amount" placeholder="Amount"  type="number" name='subscriptionAmount' value={amount}  readOnly/>
                    
                </div>
            </div>

            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Email Address <span className='text-danger'>*</span></label>
                <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>
        </div>


        <div className="form-row">
            
            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="customerNumber" id="amount"  placeholder="Enter phone number" name="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
            </div>

        </div>



        {/* <div className="form-group">

            <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Customer Name <span className='text-danger'></span></label>
                <input type="text" className="form-control shadow form-control-lg smartCardNumber" id="smartCardNumber"  placeholder="Enter full name" name="customerDetails" value={customerName} onChange={e => setCustomerName(e.target.value)} />
            </div>

            

        </div> */}



        <ul className="pager my-5" style={{width: "100%"}}>
            <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
        </ul>



    </form>
    { loading && <Spinner /> }

    </>
  )
}

export default GOTVFormComp