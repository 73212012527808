import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CallToAction from '../../components/callToAction/CallToAction';
import AsideBar from '../../components/global/AsideBar/AsideBar';
import ProfileBar from '../../components/global/ProfileBar/ProfileBar';
import TransactionsDetailsComp from '../../components/transactionsDetailsComp/TransactionsDetailsComp';
import { IHistoryResponse } from '../../utils/transHistory/historyTypes';
import { useAppSelector } from '../../utils/TypeScript';

const TransactionsDetails = () => {

    const { currentDataHistory } = useAppSelector(state => state.dataHistory)

    const [singelDataHistory, setSingleDataHistory] = useState<IHistoryResponse | undefined>()

    useEffect(()=>{
        filterHistory()
    }, [])

    const params = useParams();

    const filterHistory = ()=>{

        const filteredDataHistory = currentDataHistory?.filter((obj: IHistoryResponse)=>{
            return obj.transRef === params.slug
        })
    
        if(!filteredDataHistory) {
            return
        } else {
            const exactDataHistory =  filteredDataHistory[0]
            setSingleDataHistory(exactDataHistory)
        }
    }

   

   

  return (
    <div>
        <ProfileBar />

        <div id="content" className="py-4">
            <div className="container">
                <div className="row">

                  {/* {
                    currentUser

                    &&
                    
                    <h1>Transaction details ppage commented out</h1>
                    // <TransactionsDetailsComp />

                  }

                  {
                    currentUser

                    &&

                    <AsideBar />

                  } */}


                    { currentDataHistory &&
                        <TransactionsDetailsComp  dataHistory={singelDataHistory} />}

                    <AsideBar />



                </div>
            </div>
        </div>

        <CallToAction />
    </div>
  )
}

export default TransactionsDetails


