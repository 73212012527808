import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { profileUpdate } from '../../redux/slice/profileSlice/profileSlice'
import {  validateFLWPayload } from '../../utils/lookup/lookupTrans'
import {    useAppSelector, useAppDispatch,    ICurrentFIRSOrder,} from "../../utils/TypeScript"
import { validateValidateRIVSOrder, validateValidFIRSOrder, validateValidLCCOrder } from '../../utils/Validation'
import Spinner from '../Alert/Spinner/Spinner'
import Toast from '../Alert/Toast'
import { generateReference } from '../../utils/utils'
import { addFIRSOrder } from '../../redux/slice/govtPaySlice/firsSlice'


const RVSForm = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate() 


    const [loading, setLoading] = useState(false)
    const [sin, setSin] = useState("")
    const [email, setEmail] = useState("")

    const [amount, setAmount] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")




    const { profile } = useAppSelector(state => state.profile)
    const { currentUser } = useAppSelector(state => state.auth)
  

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)

        const validPayload = {sin, email, amount, phoneNumber }
        
        const result = validateValidateRIVSOrder(validPayload)

        if(result.errLength){

           toast.error(() => <Toast title="Error"
            body={result.errMsg} />)

            setLoading(false)
            return 
        }

    

        const reference = await generateReference("FIRS")


        const payloadLookup = {
          billerCode: "BIL119",
          itemCode: "CB141",
          customer: "0025401100"
        }

        const lookupResult = await validateFLWPayload(payloadLookup)
        // console.log({lookupResult})
        const { data, status } = lookupResult?.response
        


        if(status.toLowerCase() === "success"){
            // Save Transaction details for later
            // if(currentUser){

            //     const dataa: IProfile = {
            //     meterNo: profile?.meterNo? profile?.meterNo : "", 
            //     phoneNo: phoneNumber,
            //     userEmail: email,
            //     smartCardNumber: smartCardNumber
            //     }
            //     await dispatch(profileUpdate({user: currentUser, dataa}))
            // } 


            const payload: ICurrentFIRSOrder  = {
              country: "NG",                     
              customer_id: sin,          
              amount: amount,                      
            //   reference: reference,
              currency: "NGN",  
              serviceName: "Rivers State Tax",
              billerCode: "BIL119",
              itemCode: "CB140",                 
              type: "FIRS",                     
              biller_name: "FIRS",  
              callback_url: "https://www.preponebills.com/govt-levy-done" ,                            
              meta: {
                tax_type: "VAT",                
                tax_period: "Q1-2024"             
              },
              userEmail: email,
              phoneNumber
            }

            dispatch(addFIRSOrder(payload))
            localStorage.setItem("firsOrder", JSON.stringify(payload));

            navigate("/govt-levy-summary") 
            setLoading(false)

        } else {
            setLoading(false)
            return toast.error("Could not find the user with this TIN number!")
            
        }
    }




    useEffect( ()=>{
        if(!profile) return;
        setEmail(profile.userEmail)
        // setSmartCardNumber(profile.smartCardNumber)
        setPhoneNumber(profile.phoneNo)
    }, [])



  return (
      <>
    <form onSubmit={handleSubmit} className="mx-3 mt-4">

        {/* <span className='text-danger' style={{fontSize: "0.9rem"}}>
            {
            currentUser ? "" : "Login to remember details next time."
            }
        
        </span> */}
        <div className="form-row">
            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1" className='fs-10'>State-issued Identification Number<span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter state-issued id number" name="tin" value={sin} onChange={e => setSin(e.target.value)}/>
            </div>

            <div className="col-md-6 col-lg-6">
                
                <label htmlFor="exampleFormControlSelect "> <span className='text-danger'></span></label>
                <div className="form-group input-group pt-2">
                    <div className="input-group-prepend "> <span className="input-group-text px-3 text-white" style={{backgroundColor: "#BC61F5"}}>(Amount) ₦</span> 
                    </div>
                    <input className="form-control text-center" id="amount" placeholder="Amount"  type="number" name='amount' value={amount} onChange={e => setAmount(e.target.value)}/>
                    
                </div>
            </div>

            
        </div>


        <div className="form-row">
                
            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Email Address <span className='text-danger'>*</span></label>
                <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>

            
            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="customerNumber" id="amount"  placeholder="Enter phone number" name="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
            </div>

        </div>



        <ul className="pager my-5" style={{width: "100%"}}>
            <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
        </ul>



    </form>
    { loading && <Spinner /> }

    </>
  )
}

export default RVSForm