import React from 'react'



const AddCardComp = ( ) => {




  return (
        
        // <div id="add-new-card" className="accord bg-offwhite mt-3 p-3  shadow">
       
            <div className="edit-content">
                <form id="add-Card" method="post">       
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="your-card-provider">Your Card Provider</label>
                                <select id="your-card-provider" className="custom-select" >
                                    <option value="01">Your Card Provider</option>
                                    <option value="02">Visa</option>
                                    <option value="03">MasterCard</option>
                                    <option value="04">American Express</option>
                                    <option value="05">Discover</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="you-card-type">Card Type</label>
                                <select id="you-card-type" className="custom-select" required>
                                    <option value="">Card Type</option>
                                    <option value="debit">Debit</option>
                                    <option value="Credit">Credit</option>
                                </select>
                            </div>
                        </div>
                    </div>    
                    <div className="form-group">
                        <label htmlFor="cardNumber">Card Number</label>
                        <input type="text" className="form-control" data-bv-field="cardnumber" id="cardNumber" required value="" placeholder="Card Number" />
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="expiryDate">Expiry Date</label>
                                <input id="expiryDate" type="text" className="form-control" data-bv-field="expiryDate" required value="" placeholder="MM/YY" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="cvvNumber">CVV <span className="text-info ml-1" data-toggle="tooltip" data-original-title="For Visa/Mastercard, the three-digit CVV number is printed on the signature panel on the back of the card immediately after the card's account number. For American Express, the four-digit CVV number is printed on the front of the card above the card account number."><i className="fas fa-question-circle"></i></span></label>
                                <input id="cvvNumber" type="password" className="form-control" data-bv-field="cvvnumber" required value="" placeholder="CVV (3 digits)" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="edit-card-holder-name">Card Holder Name</label>
                                <input type="text" className="form-control" data-card-form="edit-card-holder-name" id="edit-card-holder-name" required placeholder="Card Holder Name" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Security question</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Security question" />
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-default btn-center btn-block">
                        <span className="bh"></span>
                        <span>Add Card</span>
                    </button>
                </form>
            </div>
       

    
  )
}

export default AddCardComp