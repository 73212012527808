import axios from 'axios'
import { toast } from 'react-toastify';
// import { API_URL } from './config'

// axios.defaults.withCredentials = true
// export const API_URL: = ${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}




export const sendContactFormEmail = async ( payload: object ) => {
  try {
      
      const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/contactformmail`, 
      {        
        'user': `${process.env.REACT_APP_PREPONE_BILLS_EMAIL}`,
        'pass': `${process.env.REACT_APP_PREPONE_BILLS_EMAIL_APP_PASSWORD}`,
        payload
       
      },
      {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
      )
      
      return res.data;

   
  } catch (err: any) {
      return toast.error(err.message) 
  }
}



export const postToAPI = async (url: string ) => {
  const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/${url}`, 
  {
    'url': `${process.env.REACT_APP_BASE_API_URL}`,
    'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
    'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
    'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
  },
  {
    headers: { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
     
    }
  },

  
  )

  return res;
}



export const processAPITransaction = async (url: string ) => {
  const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/${url}`, 
  {
    'url': `${process.env.REACT_APP_BASE_API_URL}`,
    'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
    'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
    'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
  },
  {
    headers: { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
     
    }
  }, 
  )

  return res;
}



// export const postAPI = async (url: string, post: object, token?: string | any ) => {
//   const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/${url}`, post, {
//     headers: { 
//       Authorization: token,
//       webkey: `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`
//     }
//   })

//   return res;
// }


// export const getAPI = async (url: string ) => {
//   const res = await axios.get(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/${url}`
//   );

//   return res;
// }























export const patchAPI = async (url: string, post: object, token?:string | any) => {
  const res = await axios.patch(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/${url}`, post, {
    headers: { 
      Authorization: token,
      webkey: `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`
    }  })

  return res;
}


export const putAPI = async (url: string, post: object, token?:string | any) => {
  const res = await axios.put(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/${url}`, post, {
    headers: { 
      Authorization: token,
      webkey: `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`
    }  })

  return res;
}


export const deleteAPI = async (url: string, token?:string | any) => {
  const res = await axios.delete(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/${url}`, {
    headers: { 
      Authorization: token,
      webkey: `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`
    }  })

  return res;
}