
import { createSlice } from '@reduxjs/toolkit'
import { IHistoryResponse } from '../../../utils/transHistory/historyTypes'


export interface AirtimeHistroyState {
    currentAirtimeHistory?: IHistoryResponse[],
    loading: boolean
}

const initialState: AirtimeHistroyState = {
    currentAirtimeHistory: undefined,
    loading: false,
}


const AirtimeHistorySlice = createSlice({
    name: 'airtimehistory',
    initialState,
    reducers: {
      addAirtimeHistory: (state, action) => {
        state.currentAirtimeHistory = [...action.payload]
        // state.currentHistory = [...state.currentHistory, ...action.payload]
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('airtimehistory') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('airtimehistory') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addAirtimeHistory } = AirtimeHistorySlice.actions

export default AirtimeHistorySlice.reducer
