import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { addTransHistory } from '../../redux/slice/transHistorySlice/transactionHistorySlice'
import { IHistoryDResponse, IHistoryResponse } from '../../utils/transHistory/historyTypes'
import { useAppDispatch, useAppSelector } from '../../utils/TypeScript'
import Spinner from '../Alert/Spinner/Spinner'
import HistoryCardComp from './HistoryCard'

const DashboardComp = () => {

    const [loading, setLoading] = useState(false)

    const { currentUser } = useAppSelector(state => state.auth)
    const { currentHistory } = useAppSelector(state => state.transHistory)
    const dispatch = useAppDispatch()



    

    // const mm = currentHistory?.map((a : IHistoryResponse)=>{
    //     return a.transDetails
    // }).forEach((a)=>{
    //     return a.split('|').join(',').split('|').join(',').split(',')
    // })

    // const transactionDetails = currentHistory?.forEach((a : IHistoryResponse)=>{
    //     console.log(a.transDetails.split('|').join(',').split('|').join(',').split(',')
    //     );
    // })

    // console.log(mm);





  return (
    <div className="col-lg-9">

                        <div className="profile-content">
                            <h3 className="admin-heading bg-offwhite">
                                <p>Recent Activity </p>
                                <span>{currentUser?.displayName}'s Activities</span>
                            </h3>

                            <div className="transaction-title bg-offwhite">
                                <div className="items">
                                    <div className="row">
                                        <div className="col"><span className="">Date</span></div>
                                        <div className="col">Description</div>
                                        <div className="col text-center">Status</div>
                                        <div className="col text-center"></div>
                                        <div className="col">Amount</div>
                                    </div>
                                </div>
                            </div>

                            <div className="transaction-area">

                                

                                {/* {
                                    currentHistory?.map((a: IHistoryResponse)=>{
                                        return <HistoryCardComp ak={a}/>
                                    })
                                } */}



                                {/* <div className="items">

                                    <Link to="/transactions-details">
                                        <div className="row">
                                            <div className="col pay-date">
                                                <span className="date">17</span>
                                                <span className="pay-month">Jan</span></div>
                                            <div className="col">
                                                <span className="name">Withdraw Via DBBL Bank</span></div>
                                            <div className="col text-center">
                                                <span className="payments-status text-warning" ><i className="fas fa-ellipsis-h" data-toggle="tooltip" data-original-title="In Progress"></i></span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦0.2</span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦342</span>
                                                <span className="currency">(₦)</span>
                                            </div>
                                        </div>
                                    </Link>

                                </div>


                                <div className="items">
                                    <Link to="/transactions-details">
                                        <div className="row">
                                            <div className="col pay-date">
                                                <span className="date">15</span>
                                                <span className="pay-month">APR</span></div>
                                            <div className="col">
                                                <span className="name">Payment Received From Envato </span></div>
                                            <div className="col text-center">
                                                <span className="payments-status text-success" ><i data-toggle="tooltip" data-original-title="Completed" className="fas fa-check-circle"></i></span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦0.2</span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">+ ₦912</span>
                                                <span className="currency">(₦)</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div className="items">
                                    <Link to="/transactions-details">
                                        <div className="row">
                                            <div className="col pay-date">
                                                <span className="date">25</span>
                                                <span className="pay-month">MAR</span></div>
                                            <div className="col">
                                                <span className="name">Payment Received From Upwork </span></div>
                                            <div className="col text-center">
                                                <span className="payments-status text-danger" ><i data-toggle="tooltip" data-original-title="Cancelled" className="fas fa-times-circle"></i></span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦0.2</span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">+ ₦1231</span>
                                                <span className="currency">(₦)</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div className="items">
                                    <Link to="/transactions-details">
                                        <div className="row">
                                            <div className="col pay-date">
                                                <span className="date">15</span>
                                                <span className="pay-month">APR</span></div>
                                            <div className="col">
                                                <span className="name">Payment Received From Upwork </span></div>
                                            <div className="col text-center">
                                                <span className="payments-status text-warning" ><i data-toggle="tooltip" data-original-title="Completed" className="fas fa-exclamation-circle"></i></span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦0.2</span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">+ ₦562</span>
                                                <span className="currency">(₦)</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div className="items">
                                    <Link to="/transactions-details">
                                        <div className="row">
                                            <div className="col pay-date">
                                                <span className="date">15</span>
                                                <span className="pay-month">APR</span></div>
                                            <div className="col">
                                                <span className="name">Fiverr Pty Ltd</span></div>
                                            <div className="col text-center">
                                                <span className="payments-status" ><i className="fas fa-ellipsis-h" data-toggle="tooltip" data-original-title="In Progress"></i></span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦0.2</span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">+ ₦234</span>
                                                <span className="currency">(₦)</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div className="items">
                                    <Link to="/transactions-details">
                                        <div className="row">
                                            <div className="col pay-date">
                                                <span className="date">15</span>
                                                <span className="pay-month">APR</span></div>
                                            <div className="col">
                                                <span className="name">Withdraw to DBBL Bank </span></div>
                                            <div className="col text-center">
                                                <span className="payments-status" ><i className="fas fa-ellipsis-h" data-toggle="tooltip" data-original-title="In Progress"></i></span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦0.2</span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦2562</span>
                                                <span className="currency">(₦)</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div className="items">
                                    <Link to="/transactions-details">
                                        <div className="row">
                                            <div className="col pay-date">
                                                <span className="date">15</span>
                                                <span className="pay-month">APR</span></div>
                                            <div className="col">
                                                <span className="name">Payment Received From Envato</span></div>
                                            <div className="col text-center">
                                                <span className="payments-status text-success" ><i data-toggle="tooltip" data-original-title="Completed" className="fas fa-check-circle"></i></span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦0.2</span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">+ ₦562</span>
                                                <span className="currency">(₦)</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div className="items">
                                    <Link to="/transactions-details">
                                        <div className="row">
                                            <div className="col pay-date">
                                                <span className="date">15</span>
                                                <span className="pay-month">APR</span></div>
                                            <div className="col">
                                                <span className="name">Gas Bill Pay</span></div>
                                            <div className="col text-center">
                                                <span className="payments-status text-success" ><i data-toggle="tooltip" data-original-title="Completed" className="fas fa-check-circle"></i></span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦0.2</span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦562</span>
                                                <span className="currency">(₦)</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div className="items">
                                    <Link to="/transactions-details">
                                        <div className="row">
                                            <div className="col pay-date">
                                                <span className="date">15</span>
                                                <span className="pay-month">APR</span></div>
                                            <div className="col">
                                                <span className="name">Water Bill Payment</span></div>
                                            <div className="col text-center">
                                                <span className="payments-status" ><i className="fas fa-ellipsis-h" data-toggle="tooltip" data-original-title="In Progress"></i></span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦0.2</span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦562</span>
                                                <span className="currency">(₦)</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div> */}
                                

                                {/* <div className="items">
                                    <Link to="/transactions-details">
                                        <div className="row">
                                            <div className="col pay-date">
                                                <span className="date">15</span>
                                                <span className="pay-month">APR</span></div>
                                            <div className="col">
                                                <span className="name">landline Bill Pay</span></div>
                                            <div className="col text-center">
                                                <span className="payments-status text-danger" ><i data-toggle="tooltip" data-original-title="Cancelled" className="fas fa-times-circle"></i></span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦0.2</span>
                                            </div>
                                            <div className="col">
                                                <span className="payment-amaount">- ₦562</span>
                                                <span className="currency">(₦)</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div> */}


                                




                                {/* <div className="trancstion-more">

                                        <div className="items">
                                            <Link to="/transactions-details">
                                                <div className="row">
                                                    <div className="col pay-date">
                                                        <span className="date">15</span>
                                                        <span className="pay-month">APR</span></div>
                                                    <div className="col">
                                                        <span className="name">Water Bill Payment</span></div>
                                                    <div className="col text-center">
                                                        <span className="payments-status" ><i className="fas fa-ellipsis-h" data-toggle="tooltip" data-original-title="In Progress"></i></span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="payment-amaount">- ₦0.2</span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="payment-amaount">- ₦5,062</span>
                                                        <span className="currency">(USD)</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                      
                                </div> */}


                            </div>






                            {/* <div id="transaction-detail" className="modal fade" role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered details-area" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="modal_header">
                                                <span className="status bg-success">Paid</span>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="content-area">
                                                            <i className="fas fa-building"></i>
                                                            Desco Govt Water Ltd.
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="content-area text-right">
                                                            <div className="price">
                                                                $559
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                            </div>
                                            <div className="modal-content-area">
                                                <h5 className="transcation-heading">Transaction Details</h5>
                                                <div className="transaction-area">
                                                    <div className="items">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="name">Gas Bill Paid By:</span>
                                                            </div>
                                                            <div className="col">
                                                                <span className="decs">Jhon Due</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="items">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="name">Transaction ID:</span>
                                                            </div>
                                                            <div className="col">
                                                                <span className="decs">8974HSDB32056KSDS</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="items">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="name">Transaction ID:</span>
                                                            </div>
                                                            <div className="col">
                                                                <span className="decs">8974HSDB32056KSDS</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="items">
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="name">Description:</span>
                                                            </div>
                                                            <div className="col">
                                                                <span className="decs">This bill pay via Credit Card</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="row mt-3 py-4">
                                <div className="col text-left" >
                                    <Link to="" className="btn btn-default">View All
                                        <i className="fas fa-chevron-right ml-2"></i>
                                    </Link>    
                                </div>
                                {/* <div className="col text-right">
                                    <button className="btn btn-default mr-0 load-more-btn">
                                        <span>Show more</span>
                                        <i className="fas fa-chevron-down"></i>
                                    </button>
                                </div> */}

                                <div className="col text-right" >
                                    <Link to="" className="btn btn-default ">More
                                        <i className="fas fa-chevron-down ml-2"></i>
                                    </Link>    
                                </div>
                                
                            </div>
                        </div>

                        { loading && <Spinner /> }

                    </div>
  )
}

export default DashboardComp