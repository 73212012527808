import React, { useEffect } from 'react'

import { useNavigate  } from "react-router-dom"
import { useAppSelector } from "./../utils/TypeScript"
import { motion } from "framer-motion"

import LoginHeader from '../components/Login/LoginHeader'
import LoginMainComp from '../components/Login/LoginMainComp'
// import PageTransition from "../PageTransition"

const LoginPage = ({ children }: any ): JSX.Element => {

  const { currentUser }  = useAppSelector((state) => state.auth)

  const navigate = useNavigate()


  useEffect(() => {
    if(currentUser) return navigate('/profile')
  },[currentUser, navigate])

  return (
    <motion.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    >
        <LoginHeader />

        <LoginMainComp />

    </motion.div>
  )
}

export default LoginPage