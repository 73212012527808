import { createSlice } from '@reduxjs/toolkit'
import { ICurrentBillerPhoto } from "../../../utils/TypeScript"



export interface EducationPhotoState {
    currentEducationPhoto?: ICurrentBillerPhoto,
    loading: boolean
}

const initialState: EducationPhotoState = {
  currentEducationPhoto: undefined,
    loading: false,
}


const educationPhotoSlice = createSlice({
    name: 'educationphoto',
    initialState,
    reducers: {
      addEducationPhoto: (state, action) => {
        state.currentEducationPhoto = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('educationphoto') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('educationphoto') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addEducationPhoto} = educationPhotoSlice.actions

export default educationPhotoSlice.reducer
