import React from "react";
import NotFoundContentSection from "./NotFoundContentSection";
import NotFoundHeader from "./NotFoundHeader";

const NotFound = () => {
  return (


    <div>

      <NotFoundHeader />

      <NotFoundContentSection />
    </div>







    // <div
    //   className="position-relative"
    //   style={{ minHeight: "calc(100vh - 70px)" }}
    // >
    //   <h2
    //     className="position-absolute text-secondary"
    //     style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
    //   >
    //     404 | NotFound
    //   </h2>
    // </div>
  );
};

export default NotFound;
