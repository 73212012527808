import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../utils/TypeScript'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPhone, faEnvelope, faPowerOff} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom"
import { authLogout } from '../../../../redux/slice/authSlice';


const TopNavbar = () => {

    const { currentUser } = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()



  return (
    <div className="header-top-bar">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="contact-info">
                        {/* <p> <FontAwesomeIcon icon={faPhone} /><a href="tel:305-449-3301">+234 456 789 0123</a></p> */}
                        <p> <FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:office@mail.com">info@preponebills.com</a></p>
                    </div>
                </div>
                <div className="col-md-6 text-md-right">

                    
                    {/* <div className="header-language">
                        <Link to="" className="langbtn">
                            <span>EN</span>
                            <FontAwesomeIcon icon={faAngleDown} />
                        </Link>
                        <ul className="list-unstyled dropdown-menu" style={{display: "none"}}>
                            <li className="active"><Link to="">EN</Link></li>
                            <li><Link to="">FR</Link></li>
                            <li><Link to="">PT</Link></li>
                            <li><Link to="">IT</Link></li>
                        </ul>
                    </div>  */}


                    {
                        currentUser

                        ?

                            <ul className="login-area">
                                <li><Link to="/profile" className="login"><FontAwesomeIcon icon={faPowerOff} /> Dashboard</Link></li>

                                <li onClick={() => dispatch(authLogout())}> <Link to="/" className="rsgiter"><FontAwesomeIcon icon={faPlus} /> Logout</Link></li>
                            </ul>

                        :
                        
                            <ul className="login-area">
                                <li><Link to="/login" className="login"><FontAwesomeIcon icon={faPowerOff} /> Login</Link></li>

                                <li><Link to="signup" className="rsgiter"><FontAwesomeIcon icon={faPlus} /> Register</Link></li>
                            </ul>

                    }


                </div>
            </div>
        </div>
</div>
  )
}

export default TopNavbar