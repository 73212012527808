import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../Alert/Toast'
import {useAppDispatch, useAppSelector } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'

import {  ICurrentNtelDataOrder, IMTNDataProducts } from '../../utils/AirtimeDateTypeScript/airtimeDataTypes'
import {  fetchDataBiller } from '../../utils/billers'
import {  validateNtelDataForm } from '../../utils/ValidData/validData'
import { addNtelDataOrder } from '../../redux/slice/dataSlice/ntelSlice'





const NtelDataForm = () => {

  const [loading, setLoading] = useState(false)
  const [chooseNtelProduct, setChooseNtelProduct] = useState("")

  const [productType, setProductType] = useState("")
  const [products, setProducts] = useState([])
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")


  
  const { currentDataPhoto }  = useAppSelector((state) => state.dataPhoto)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()



  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    const ntelDataOrderDetails = { amount, email, phoneNumber, productType }

    const result = validateNtelDataForm(ntelDataOrderDetails)

    if(result.errLength){

       toast.error(() => <Toast title="Error"
        body={result.errMsg} />)

        setLoading(false)
        return 
    }



    let transactionId = "PreponeBills" + Math.floor(Math.random() * 1000000);

    const ntelDataOrder: ICurrentNtelDataOrder = {
      billerCode: 'NTELBundle',
      productId: "1119",
      externalRef: transactionId,
      transDetails: [
        {
        fieldName: "Amount",
        fieldValue: amount,
        fieldControlType : "TEXTBOX"
        },
        {
        fieldName: "E-mail",
        fieldValue: email,
        fieldControlType : "TEXTBOX"
        },
        {
        fieldName: "Phone Number",
        fieldValue: phoneNumber,
        fieldControlType : "TEXTBOX"
        },
        {
        fieldName: "Product Type",
        fieldValue: productType,
        fieldControlType : "LOOKUP"
        }
      ]
    }

       
    dispatch(addNtelDataOrder(ntelDataOrder))
    localStorage.setItem("ntelDataOrder", JSON.stringify(ntelDataOrder));
    localStorage.setItem("ntelData", JSON.stringify(chooseNtelProduct));
    navigate("/data-summary") 
    setLoading(false)
    
}






const fetchBiller = async () => {

    setLoading(true)

  const currentBillerCode = currentDataPhoto ? currentDataPhoto?.billerCode : 'data'
  const selectetCableTVBiller = await fetchDataBiller( currentBillerCode)
  console.log(selectetCableTVBiller);

  const packages = selectetCableTVBiller.productFields[3].ListItems
  setProducts(packages)

  setLoading(false)

  return selectetCableTVBiller;

}


    const setPackageAmount = ()=>{
        if(!chooseNtelProduct){
            return
        } else {
            const selectedProduct = products.filter(( paackage : IMTNDataProducts)=>{
                return paackage.ItemName === chooseNtelProduct
        })

            
            setAmount(selectedProduct[0]['Amount']);
            setProductType(selectedProduct[0]['ItemType']);
        }
    }
    
     


 useEffect( ()=>{
      fetchBiller()
 }, [])

 useEffect( ()=>{
      setPackageAmount()
 }, [chooseNtelProduct])










  return (
    <form onSubmit={handleSubmit} className="mx-3">
      <div className="form-row">
     
        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlInput04">Product Type<span className='text-danger'>*</span></label>

          <select className="custom-select form-control" id="operator" name="productType" value={chooseNtelProduct} onChange={e => setChooseNtelProduct(e.target.value)} >
            <option value="">Select Product Type</option>

                {
                    products.map((month : IMTNDataProducts, index) =>
                        <option className='' key={index} value={month.ItemName}>{month.ItemName}</option>  
                    )
                }
          </select>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter phone number" name="customerNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        </div>

      </div>


      <div className="form-row">


        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'>*</span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Amount" name="amount" value={amount} readOnly/>
        </div>

      </div>



      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default NtelDataForm