import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import {  IHistoryResponse } from '../../utils/transHistory/historyTypes'


const AirtimeHistoryCardComp = (props: {hist: IHistoryResponse}) => {

    const transDetailsArray = props?.hist?.transDetails?.split('|')

    const transactionDate = moment(props?.hist?.transDate).format().split('T')[0];



  return (
    <div className="items">

        <Link to={`/airtime/${props.hist.transRef}`} >  
            <div className="row">
                <div className="col pay-date">
                    <span className="date">{transactionDate}</span>
                    {/* <span className="pay-month">Jan</span> */}
                </div>


                <div className="col">
                    
                    <span className="name">{props?.hist?.product}</span>
                </div>
                 


                <div className="col">
                    <span className="payment-amaount">
                        {
                            props?.hist?.billerCode === '9MOBILEAIRTIME' ? transDetailsArray[2]
                            : 
                            props?.hist?.billerCode === 'airtel' ? transDetailsArray[1]
                            : 
                            props?.hist?.billerCode === 'glo' ? transDetailsArray[1]
                            : 
                            props?.hist?.billerCode === 'mtn' ? transDetailsArray[1]
                            :
                            props?.hist?.billerCode === 'NTEL' ? transDetailsArray[1]
                            :
                            ""
                        }
                    </span>
                </div>


                <div className="col text-center">
                    <span className={props?.hist?.statusDescription === "SUCCESSFUL" ? "payments-status text-success" : "payments-status text-danger"} >
                        <i className={props?.hist?.statusDescription === "SUCCESSFUL" ? "fas fa-check-circle" : "fas fa-times-circle"} data-toggle="tooltip" data-original-title="In Progress"></i>
                    </span>
                </div>


                


                <div className="col">
                    <span className="payment-amaount">₦ 
                        {
                            props?.hist?.transactionAmount
                        }
                    </span>
                    {/* <span className="currency">(₦)</span> */}
                </div>
            </div>
        </Link>

    </div>
  )
}

export default AirtimeHistoryCardComp