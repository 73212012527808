import { createSlice } from '@reduxjs/toolkit'
import { ICurrentDSTVOrder } from '../../../utils/TypeScript'



// export const airtimeProcessTransaction = createAsyncThunk(
//   'caletv/process-transaction',
//   async (currentRechargeOrder: IcurrentRechargeOrder) => {
//   }
// )




export interface DSTVState {
    currentDSTVOrder?: ICurrentDSTVOrder,
    loading: boolean
}

const initialState: DSTVState = {
    currentDSTVOrder: undefined,
    loading: false,
}


const dSTVSlice = createSlice({
    name: 'dstv',
    initialState,
    reducers: {
      addDSTVOrder: (state, action) => {
        // state.currentOrder = action.payload
        state.currentDSTVOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('dstv') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('dstv') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addDSTVOrder } = dSTVSlice.actions

export default dSTVSlice.reducer
