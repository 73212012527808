import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../Alert/Toast'
import { useAppDispatch } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'

import { IAirtimeDataProduct, ICurrentDataOrder,  } from '../../utils/AirtimeDateTypeScript/airtimeDataTypes'
import {   getProductsByCategoriesIdAndBillerId } from '../../utils/billers'
import { validateDataForm} from '../../utils/ValidData/validData'
import { addDataOrder } from '../../redux/slice/dataSlice/dataSlice'





const MTNDataForm = () => {

  const [loading, setLoading] = useState(false)

  const [productName, setProductName] = useState("")
  const [products, setProducts] = useState([])
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")
  const [uniqueCode, setUniqueCode] = useState("")


  const dispatch = useAppDispatch()
  const navigate = useNavigate()



  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    const mtnDataOrderDetails = { amount, phoneNumber, uniqueCode }

    const result = validateDataForm(mtnDataOrderDetails)

    if(result.errLength){

       toast.error(() => <Toast title="Error"
        body={result.errMsg} />)

        setLoading(false)
        return 
    }



    let transactionId = "PreponeBills-" + Math.floor(Math.random() * 1000000);

    const dataOrder: ICurrentDataOrder = {
      requestId: transactionId,
      uniqueCode: uniqueCode,
      details: {
        phoneNumber: phoneNumber,
        amount: Number(amount),
        productName: productName
      },
      userEmail: email
    }

       
    dispatch(addDataOrder(dataOrder))
    localStorage.setItem("dataOrder", JSON.stringify(dataOrder));
    localStorage.setItem("selectedData", JSON.stringify(uniqueCode));
    navigate("/data-summary") 
    setLoading(false)

    // console.log(dataOrder);
    
}






  const fetchBiller = async () => {

    setLoading(true)
    const selectetDataProduct = await getProductsByCategoriesIdAndBillerId( 2, 20)
    // console.log(selectetDataProduct);

    setProducts(selectetDataProduct.data.productDTOList)

    setLoading(false)

  }


  const setProductAmount = ()=>{
    if(!uniqueCode){
        return
    } else {
      const selectedProduct = products.filter(( DPlan : IAirtimeDataProduct)=>{
      return DPlan.uniqueCode === uniqueCode

    })

    // console.log(selectedProduct);
    setAmount(selectedProduct[0]['amount']);
    setProductName(selectedProduct[0]['name']);
    }
  }
    
     


 useEffect( ()=>{
      fetchBiller()
 }, [])

 useEffect( ()=>{
      setProductAmount()
 }, [uniqueCode])





  return (
    <form onSubmit={handleSubmit} className="mx-3">
      <div className="form-row">
     
        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlInput04">Data Plan<span className='text-danger'>*</span></label>

          <select className="custom-select form-control" id="operator" name="productType" value={uniqueCode} onChange={e => setUniqueCode(e.target.value)} >
            <option value="">Select Data Plan</option>

            {
                products && products.map((DPlan : IAirtimeDataProduct, index) =>
                    <option className='mb-3' key={index} value={DPlan.uniqueCode}>{DPlan.name}</option>  
                )
            }
          </select>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter phone number" name="customerNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        </div>

      </div>


      <div className="form-row">


        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'></span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Amount" name="amount" value={amount} readOnly/>
        </div>

      </div>


       


      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default MTNDataForm