import { ChangeEvent, FormEvent } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../redux/store'
import * as FireAuth from 'firebase/auth'




export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector



export type InputChange = ChangeEvent<
  | HTMLInputElement 
  | HTMLTextAreaElement 
  | HTMLSelectElement
>

export type FormSubmit = FormEvent<HTMLFormElement>


export interface ITransactionState {
  transaction: string
}


export interface IParams {
  page: string
  slug: string
}

export interface NavbarProps {
  click: boolean
  handleClick: (values: any) => any;
}

export interface IUserRegister {
  name: string
  email: string
  password: string
  cf_password: string
  agreeToTerms: boolean
}


export interface IUserLogin {
  email: string
  password: string
  remember: boolean
}

export interface IContactForm {
  firstName: string
  lastName: string
  email: string
  phoneNumber: number | string
  message: string
}


export interface IHomepageRechargeForm {
 
  phoneNumber: number | string
  biller: string
  amount: string
  email: string
}

export interface IDataForm {
 
  phoneNumber: number | string
  biller: string
  listItem: string
  amount?: string
  email: string
}


export interface IBillingDetails {
  cardNumber : number | string
  expiryDate: number | string
  cvv : number | string
  nameOnCard :  string , 
  streetAddress? : string ,
  city?  : string, 
  country? : string , 
  saveDetails? : boolean
}


export interface IAuth extends FireAuth.User {}




// export interface ICollection {
//   id?: string
//   uid?: string
//   title?: string
//   photos?: string[]
//   createdAt?: number
// }



export interface IListItem {
  Amount: string
  ItemDesc: string
  ItemName: string
  ItemOthers:string
  ItemType: string
}


export interface ICurrentOrder {
  prepaid? : string
  postpaid? : string
  phoneNumber? : number | string
  mobileOperator? : string
  amount?: number | string
  email?: string
  cardNumber?: number | string
  expiryDate?: number | string
  cvv?: number | string
  nameOnCard?: string
  saveDetails?: boolean
}


export interface IcurrentRechargeOrder {
  billerCode: string | undefined
  productId: number | string | undefined
  transactionDetails: [
    {
      fieldName: string | undefined
      fieldValue: string | undefined
      fieldControlType : string | undefined
    },
    {
      fieldName: string | undefined
      fieldValue: number | string | undefined
      fieldControlType : string | undefined
    },
    {
      fieldName: string | undefined
      fieldValue: number | string | undefined
      fieldControlType : string | undefined
    },
    {
      fieldName: string | undefined
      fieldValue: string | undefined
      fieldControlType : string | undefined
    }
  ]
}

// export interface ICurrentDataOrder {
//   billerCode: string | undefined
//   productId: number | string | undefined
//   transactionDetails: [
//     {
//       fieldName: string | undefined
//       fieldValue: string | undefined
//       fieldControlType : string | undefined
//     },
//     {
//       fieldName: string | undefined
//       fieldValue: number | string | undefined
//       fieldControlType : string | undefined
//     },
//     {
//       fieldName: string | undefined
//       fieldValue: number | string | undefined
//       fieldControlType : string | undefined
//     },
//     {
//       fieldName: string | undefined
//       fieldValue: number | string | undefined
//       fieldControlType : string | undefined
//     },
//     {
//       fieldName: string | undefined
//       fieldValue: string | undefined
//       fieldControlType : string | undefined
//     }
//   ]
// }



export interface ICurrentElectricityOrder {
  billerCode?: string | undefined
  productId?: number | string | undefined
  transactionDetails?: [
    {
      fieldName?: string | undefined
      fieldValue?: string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?: number | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?: number | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?: number | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?: number | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?: number | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?: string | undefined
      fieldControlType? : string | undefined
    }
  ]
}


export interface ICurrentCableTVOrder {
  externalref?: string | undefined
  billerCode: string 
  productId?:  string | undefined
  
}

export interface ICurrentBillerPhoto {
  externalref?: string | undefined
  billerCode: string 
  productId?:  string | undefined
  
}

export interface ICurrentOrderGeneral {
  billerCode: string 
  productId?:  string 
  
}

export interface ICurrentDSTVOrder {
  externalref?: string | undefined
  billerCode: string 
  productId?:  string | undefined
  transDetails: [
    {
      fieldName?: string | undefined
      fieldValue?: string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string 
      fieldValue?:  string 
      fieldControlType? : string 
    },
    {
      fieldName?: string | undefined
      fieldValue?: string | undefined
      fieldControlType? : string | undefined
    }
  ]
}

export interface ICurrentGOTVOrder {
  billerCode: string 
  productId?:  string | undefined
  externalRef?: string | undefined
  transDetails: [
    {
      fieldName?: string | undefined
      fieldValue?: string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  string | undefined
      fieldControlType? : string | undefined
    }
  ]
}

export interface ICurrentStartimesOrder {
  externalref?: string | undefined
  billerCode: string 
  productId?:  string | undefined
  transDetails: [
    {
      fieldName?: string | undefined
      fieldValue?: string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  | string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  string | undefined
      fieldControlType? : string | undefined
    },
    {
      fieldName?: string | undefined
      fieldValue?:  string | undefined
      fieldControlType? : string | undefined
    }
  ]
}
export interface ICurrentCableTVOrderDetails {
  requestId: string,
  uniqueCode: string,
  details: {
    accountNumber: string,
    accountType: string,
    noOfMonth: number,
    amount: number,
    name: string,
    phoneNumber: string
    email: string
    packageName: string
  }
}


// export interface IElectricityOrder {
 
//   meterNumber: number | string
//   meterType: string
//   amount: number | string
//   email: string
// }


export interface IProduct {
  billerCode: string 
  billerName: string 
  
  productfields: [ {
      billerCode: string,
      categoryCode: string, 
      charge: number,
      chargeType: string,
      commission: number,
      listId: number,
      productFields: [ ],
      productId: number,
      productName: string,
      productType: string,
      serviceChargeId:number
   } ]
  productId: number,
  productName: string,
  productType: string,
  serviceChargeId: number
}


export interface IDSTVMonths {
  name: string,
  value: string,
}


export interface IDSTVPackages {
  amount: number,
  billerName: string,
  categoryDTO :  {
    id: number, 
    name: string
  },
  imageUrl : string | null,
  fixedAmount: boolean,
  lookUp: boolean,
  maximumAmount: number | null,
  minimumAmount: number | null,
  name: string,
  uniqueCode: string
}
export interface ICableTVPackages {
  amount: number,
  billerName: string,
  categoryDTO :  {
    id: number, 
    name: string
  },
  imageUrl : string | null,
  fixedAmount: boolean,
  lookUp: boolean,
  maximumAmount: number | null,
  minimumAmount: number | null,
  name: string,
  uniqueCode: string
}

export interface IGOTVPackages {
  Amount: string,
  ItemDesc: string,
  ItemName: string,
  ItemOthers: string,
  ItemType: string,
}

export interface IDSTVOrder {
  smartCardNumber: string, 
  email: string,
  numberOfMonths: string,
  subscriptionAmount: string,
  phoneNumber: string,
  uniqueCode: string,

}

export interface IGOTVOrder {
  smartCardNumber: string, 
  email: string,
  phoneNumber: string,
  uniqueCode: string,
}

export interface IStartimesOrder {
  smartCardNumber: string,  
  email: string,  
  amount: string,  
  phoneNumber: string, 
  uniqueCode: string, 
}


export interface IPostTransactionInfo {
  config: string,  
  data: string,  
  headers: string, 
  request: string,  
  status: string, 
  statusText: string, 
}


// export interface emailjsData {
//   name: string | HTMLFormElement,
//   email: string | HTMLFormElement,
//   token: string | HTMLFormElement,
//   amount: number | HTMLFormElement,
// }


export interface IElectricityResponseData {
 
  requestId: string,
  referenceId: string,
  responseCode: string,
  responseMessage: string,
  data: {
    amount: string,
    unit: string,
    address: string,
    arrears: string,
    tariffName: string,
    rate: string,
    vat: string,
    accountNumber: string,
    token: string
  }
}


export interface ISaveCustomerDetails {
  email: string
  meterNo: string
  phoneNumber: string
}

export interface IProfile {
  meterNo: string
  userEmail: string
  phoneNo: string
  smartCardNumber: string
}

export interface IBettingOrder {
  userAccountNumber: string
  amount: string
  phoneNumber: string
}

export interface ICurrentBettingOrder {
  requestId: string,
  uniqueCode: string,
  details: {
      accountNumber: string,
      amount: number,
      phoneNumber: string
      fullName:string
      userName:string
  },
  userEmail: string
}


export interface ICurrentFIRSOrder {
  country: string,                     
  customer_id: string,          
  amount: string,                      
  // reference: string | undefined,
  currency: string,  
  serviceName: string,
  billerCode: string,
  itemCode: string,                 
  type: string,                     
  biller_name: string,                
  callback_url: string,                
  meta: {
    tax_type: string,                
    tax_period: string            
  }
  userEmail: string
  phoneNumber: string
}

export interface ICurrentNSEOrder {
  country: string,                     
  customer_id: string,          
  amount: string,                      
  // reference: string | undefined,
  currency: string,  
  serviceName: string,
  billerCode: string,
  itemCode: string,                 
  type: string,                     
  biller_name: string,    
  callback_url: string           
            
  meta: {
    payment_for: string,                
    membership_year: string            
  }
  userEmail: string
  phoneNumber: string
}


export interface IJambPackages {
  amount: number,
  billerName: string,
  categoryDTO :  {
    id: number, 
    name: string
  },
  imageUrl : string | null,
  fixedAmount: boolean,
  lookUp: boolean,
  maximumAmount: number | null,
  minimumAmount: number | null,
  name: string,
  uniqueCode: string
}

export interface ICurrentJambOrder {
  requestId: string,
  uniqueCode: string,
  details: {
    profileCode: string,
    profileType: string,
    phoneNumber: string
    amount: string
    packageName: string
    userEmail: string
  }
}

export interface IJambOrderDetails {
  profileCode: string, 
  uniqueCode: string, 
  phoneNumber: string
}

export interface IWAECPINOrderDetails {
  email: string, 
  uniqueCode: string, 
  phoneNumber: string
}

export interface IWAECREGOrderDetails {
  email: string, 
  uniqueCode: string, 
  phoneNumber: string
  noOfCandidates: string
}

export interface ICurrentWAECPINOrder {
  requestId: string,
  uniqueCode: string,
  details: {
    email: string,
    phoneNumber: string
    packageName: string
    amount: string
    noOfCandidate: number

  }
}









// Dummy Data for test sendMail

// export const responseDataa = {
//   acctBalance : null,
// responseCode: "00",
// responseData: null,
// responseMessage: "Success: RECEIPT NUMBER: 0709202217887417 | TOKEN DESC: 48040504089664613123 | UNITS ACTUAL: 16.5 | ENERGY VALUE (NGN): 883.72 | VAT (NGN): 66.28",
// transRef: "8229592822405812",
// userAcct: null
// }

// export const userEEmail = "Davidchosen33@gmail.com"
// export const pproduct = "PHEDC"




// Response for success Airtime Transactions
// res.data: 
// {responseCode: '00', responseMessage: 'Success: SUCCESSFUL', transRef: '8223966374193166', responseData: null, userAcct: null, …}
// acctBalance: null
// responseCode: "00"
// responseData: null
// responseMessage: "Success: SUCCESSFUL"
// transRef :  "8223966374193166"
// userAcct : null
// [[Prototype]]: Object









// Response after successful electricity

// {responseCode: '00', responseMessage: 'Success: RECEIPT NUMBER: 0709202217887417 | TOKEN …5 | ENERGY VALUE (NGN): 883.72 | VAT (NGN): 66.28', transRef: '8229592822405812', responseData: null, userAcct: null, …}
// acctBalance
// : 
// null
// responseCode
// : 
// "00"
// responseData
// : 
// null
// responseMessage
// : 
// "Success: RECEIPT NUMBER: 0709202217887417 | TOKEN DESC: 48040504089664613123 | UNITS ACTUAL: 16.5 | ENERGY VALUE (NGN): 883.72 | VAT (NGN): 66.28"
// transRef
// : 
// "8229592822405812"
// userAcct
// : 
// null
// [[Prototype]]
// : 
// Object


export interface ValidFIRSOrder {
  tin: string, 
  email: string,
  amount: string,
  phoneNumber: string,
  itemCode: string,

}
export interface ValidLCCOrder {
  vrn: string, 
  email: string,
  amount: string,
  phoneNumber: string,
  itemCode: string,

}
export interface ValidRIVsOrder {
  sin: string, 
  email: string,
  amount: string,
  phoneNumber: string,

}

export interface ValidNSEOrder {
  nseNumber: string, 
  email: string,
  amount: string,
  phoneNumber: string,

}

export interface BillerItemData {
  id: number;
  biller_code: string;
  name: string;
  default_commission: number;
  date_added: string;
  country: string;
  is_airtime: boolean;
  biller_name: string;
  item_code: string;
  short_name: string;
  fee: number;
  commission_on_fee: boolean;
  reg_expression: string;
  label_name: string;
  amount: number;
  is_resolvable: boolean;
  group_name: string;
  category_name: string;
  is_data: boolean | null;
  default_commission_on_amount: number | null;
  commission_on_fee_or_amount: number;
  validity_period: string | null;
}
























