import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentAirtimeOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface AirtimeState {
    currentAirtimeOrder?: ICurrentAirtimeOrder,
    loading: boolean
}

const initialState: AirtimeState = {
    currentAirtimeOrder: undefined,
    loading: false,
}


const airtimeSlice = createSlice({
    name: 'airtime',
    initialState,
    reducers: {
      addAirtimeOrder: (state, action) => {
        state.currentAirtimeOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('airtime') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('airtime') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addAirtimeOrder } = airtimeSlice.actions

export default airtimeSlice.reducer
