import React, { useState} from 'react'
import { Link } from "react-router-dom"
import {  useAppSelector } from '../../utils/TypeScript'

import Modal from "react-bootstrap/Modal"
import AddCardComp from './AddCard'
import VisaCard from "../assets/images/visa.png"
import MasterCard from "../assets/images/mastercard.png"
import UpdateCard from './UpdateCard'

const CardComps = () => {

    const { currentUser } = useAppSelector(state => state.auth)


    const [addModalOpen, setAddModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);

    const showAddModal = () => {
      setAddModalOpen(true);
    };
    const showUpdateModal = () => {
      setUpdateModalOpen(true);
    };
  
    const hideAddModal = () => {
      setAddModalOpen(false);
    };

    const hideUpdateModal = () => {
      setUpdateModalOpen(false);
    };



  return (
    <div className="col-lg-9">


                    <Modal show={addModalOpen} onHide={hideAddModal}>
                    
                        <Modal.Body>
                        <div className=" bg-offwhite mt-3 p-3  shadow">
                            <div className="content-edit-area">
                                <div className="edit-header">
                                    <h5 className="title">Add a Card</h5>
                                    <button type="button" className="close" onClick={hideAddModal}><span aria-hidden="true">&times;</span></button>
                                </div>

                                    <AddCardComp />

                                </div>
                            </div>
                        </Modal.Body>
                    
                    </Modal>


                    <Modal show={updateModalOpen} onHide={hideUpdateModal}>
                    
                        <Modal.Body>
                            <div  className=" bg-offwhite mt-3 p-3  shadow">
                                <div className="content-edit-area">
                                    <div className="edit-header">
                                        <h5 className="title">Update Card</h5>
                                        <button type="button" className="close" onClick={hideUpdateModal}><span aria-hidden="true">&times;</span></button>
                                    </div>

                                    <UpdateCard />
                                </div>
                            </div>

                        </Modal.Body>
                    
                    </Modal>



                    <h2 className="admin-heading">{currentUser?.displayName} cards account(for payments) <Link to="#"  className="account-card-new d-flex align-items-center  btn-link pbtn" data-id="add-new-card" onClick={showAddModal} > New Card</Link></h2>

                    <div className="infoItems bg-offwhite  shadow">
                        <div className="row">
                            <div className="col-12">
                                    <h3>Current cards (3)</h3>
                            </div>


                            <div className="col-12 col-sm-6 col-lg-4 cardBox">                             
                                <div className="account-card account-card-primary">
                                        <p className="pirotry text-right">Primery</p> 
                                    <div className="row">
                                        <p className="card-number col-12">XXXX-XXXX-XXXX-8943</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="valid-card">Valid true</p>
                                            <p className="expare ">05/28</p>  
                                        </div>
                                        <div className="col-6">
                                             
                                        </div>                                        
                                    </div>      
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="card-holder-name">{currentUser?.displayName}</p> 
                                        </div>
                                        <div className="col-6">
                                            <img className="ml-auto" src={VisaCard} alt="visa" title="" />
                                        </div>
                                    </div>
                                    <div className="account-card-overlay">
                                        <Link to="#" data-target="#edit-card-details"className="text-light btn-link pbtn" data-id="edit-card-details" onClick={showUpdateModal}> <span className="mr-1"><i className="fas fa-edit"></i></span>Edit</Link> 
                                        <Link to="#" className="text-light btn-link pbtn"> <span className="mr-1"><i className="fas fa-minus-circle"></i></span>Delete</Link>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 col-sm-6 col-lg-4 cardBox">
                                <div className="account-card account-card-primary">
                                        <p className="pirotry text-right">Secondary </p>  
                                    <div className="row">
                                        <p className="card-number col-12">XXXX-XXXX-XXXX-2353</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="valid-card">Valid true</p>
                                            <p className="expare ">05/25</p>  
                                        </div>
                                        <div className="col-6">
                                            
                                        </div>                                        
                                    </div>      
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="card-holder-name">{currentUser?.displayName}</p> 
                                        </div>
                                        <div className="col-6">
                                            <img className="ml-auto" src={MasterCard} alt="visa" title="" />
                                        </div>
                                    </div>
                                    <div className="account-card-overlay">                                        
                                        <Link to="#" data-target="#edit-card-details"className="text-light btn-link pbtn" data-id="edit-card-details" onClick={showUpdateModal}> <span className="mr-1"><i className="fas fa-edit"></i></span>Edit</Link> 
                                        <Link to="#" className="text-light btn-link mx-2"> <span className="mr-1"><i className="fas fa-minus-circle"></i></span>Delete</Link>
                                    </div>
                                </div>
                            </div> 


                            <div className="col-12 col-sm-6 col-lg-4 cardBox">
                                <div className="account-card account-card-primary">
                                        <p className="pirotry text-right bg-warning">Upcoming</p>  
                                    <div className="row">
                                        <p className="card-number col-12">XXXX-XXXX-XXXX-2353</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="valid-card">Valid true</p>
                                            <p className="expare ">05/25</p>  
                                        </div>
                                        <div className="col-6">
                                            
                                        </div>                                        
                                    </div>      
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="card-holder-name">{currentUser?.displayName}</p> 
                                        </div>
                                        <div className="col-6">
                                            <img className="ml-auto" src={MasterCard} alt="visa" title="" />
                                        </div>
                                    </div>
                                    <div className="account-card-overlay">                                        
                                        <Link to="#">
                                            Active your Card
                                        </Link>
                                    </div>
                                </div>
                            </div>  


                            <div className="col-12 col-sm-6 col-lg-4 cardBox" onClick={showAddModal}>
                                <div className="account-card account-card-primary text-center p-4">
                                    <Link to="#" 
                                    // data-target="#add-new-card"  
                                    className="account-card-new d-flex align-items-center  btn-link pbtn" data-id="add-new-card" >
                                        <p className="text-center">
                                            <span className="text-3"><i className="fas fa-plus-circle"></i></span> 
                                            <span className="d-block">Add New Card</span>
                                        </p>
                                    </Link>
                                </div>                                
                            </div>


                            
                            <div className="col-12">
                                    <h3>Past cards (2)</h3>
                            </div>


                            <div className="col-12 col-sm-6 col-lg-4 cardBox">                             
                                <div className="account-card account-card-primary">
                                        <p className="pirotry text-right bg-danger">Expired</p> 
                                    <div className="row">
                                        <p className="card-number col-12">XXXX-XXXX-XXXX-8943</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="valid-card">Valid true</p>
                                            <p className="expare ">05/28</p>  
                                        </div>
                                        <div className="col-6">
                                             
                                        </div>                                        
                                    </div>      
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="card-holder-name">{currentUser?.displayName}</p> 
                                        </div>
                                        <div className="col-6">
                                            <img className="ml-auto" src={VisaCard} alt="visa" title="" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 col-sm-6 col-lg-4 cardBox">
                                <div className="account-card account-card-primary">
                                        <p className="pirotry text-right bg-danger">Expired</p>  
                                    <div className="row">
                                        <p className="card-number col-12">XXXX-XXXX-XXXX-2353</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="valid-card">Valid true</p>
                                            <p className="expare ">05/25</p>  
                                        </div>
                                        <div className="col-6">
                                            
                                        </div>                                        
                                    </div>      
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="card-holder-name">{currentUser?.displayName}</p> 
                                        </div>
                                        <div className="col-6">
                                            <img className="ml-auto" src={MasterCard} alt="visa" title="" />
                                        </div>
                                    </div>
                                </div>
                            </div>  


                        </div>
                    </div>




                    



                    {/* <!-- Add New Card Details Modal --> */}
                    {/* <div id="add-new-card-details  shadow" className="modal fade" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title font-weight-400">Add a Card</h5>
                                    <button type="button" className="close font-weight-400" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>
                                <div className="modal-body p-4">
                                    <form id="addCard" method="post">
                                        <div className="btn-group d-flex btn-group-toggle mb-3" data-toggle="buttons">
                                            <label className="btn btn-outline-secondary btn-sm shadow-none w-100 active">
                                                <input type="radio" name="options" id="option1" checked />
                                                Debit </label>
                                            <label className="btn btn-outline-secondary btn-sm shadow-none w-100">
                                                <input type="radio" name="options" id="option2"/>
                                                Credit </label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cardType">Card Type</label>
                                            <select id="cardType" className="custom-select" required>
                                                <option value="">Card Type</option>
                                                <option>Visa</option>
                                                <option>MasterCard</option>
                                                <option>American Express</option>
                                                <option>Discover</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cardNumber">Card Number</label>
                                            <input type="text" className="form-control" data-bv-field="cardnumber" id="cardNumber" value="" placeholder="Card Number" />
                                        </div>
                                        <div className="form-row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="expiryDate">Expiry Date</label>
                                                    <input id="expiryDate" type="text" className="form-control" data-bv-field="expiryDate" value="" placeholder="MM/YY" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="cvvNumber">CVV <span className="text-info ml-1" data-toggle="tooltip" data-original-title="For Visa/Mastercard, the three-digit CVV number is printed on the signature panel on the back of the card immediately after the card's account number. For American Express, the four-digit CVV number is printed on the front of the card above the card account number."><i className="fas fa-question-circle"></i></span></label>
                                                    <input id="cvvNumber" type="password" className="form-control" data-bv-field="cvvnumber" required value="" placeholder="CVV (3 digits)" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cardHolderName">Card Holder Name</label>
                                            <input type="text" className="form-control" data-bv-field="cardholdername" id="cardHolderName" required value="" placeholder="Card Holder Name" />
                                        </div>
                                        <button className="btn btn-default btn-center btn-block">
                                            <span className="bh"></span>
                                            <span>Add Card</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <!-- Credit or Debit Cards End --> */}



                    

                </div>
  )
}

export default CardComps