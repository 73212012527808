import React, { useEffect } from 'react'
import { Link} from "react-router-dom"
import { addDataPhoto } from '../../redux/slice/dataSlice/dataPhotoSlice'
import { 
    useAppDispatch,
    useAppSelector, } from "../../utils/TypeScript"

import NineMobile from "../assets/images/data/1.png"
import Airtel from "../assets/images/data/2.png"
import Glo from "../assets/images/data/3.png"
import MTN from "../assets/images/data/4.png"
import NTEL from "../assets/images/data/5.png"
import Smile from "../assets/images/data/6.png"
import Spectranet from "../assets/images/data/7.png"
import AirtelDataForm from './AirtelDataForm'
import GloDataForm from './GloDataForm'


import MTNDataForm from './MTNDataForm'
import NineMobileDataForm from './NineMobileDataForm'
import NtelDataForm from './NtelDataForm'
import SmileDataComp from './SmileDataForm'
import SpectranetDataForm from './SpectranetDataForm'


const DataOrderComp = () => {

    const { currentDataPhoto }  = useAppSelector((state) => state.dataPhoto)

    const dispatch = useAppDispatch()

  
  useEffect(()=>{
    const dataPhoto =  JSON.parse(localStorage.getItem('dataPhoto') || "{}");
    dispatch(addDataPhoto(dataPhoto))
  }, [])  


  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>Data</h4>
                                    </div>
                                    <div className="ml-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>Amount</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">
                           
                            <li className="nav-item">
                                <Link className="nav-link active" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/data-payment">Payment</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            
                            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                               
                               
                            <div className="operators mx-3">
                                       
                                       <div className="row operator-row" id='operator-row'>
                                          
                                       <div className="col-md-2 col-sm-4">
                                               <div className="single-operator">
                                                   <div className="op-logo" data-operator="op1">
                                                       <img src={
                                                           currentDataPhoto?.billerCode === '9mobiledata1' ? NineMobile : 
                                                           currentDataPhoto?.billerCode === 'airtel-data' ? Airtel : 
                                                           currentDataPhoto?.billerCode === 'glo-data' ? Glo : 
                                                           currentDataPhoto?.billerCode === 'data' ? MTN :
                                                            
                                                           ""} alt="data logo" />
                                                   </div>
                                               </div>
                                           </div>
                                          
                                           
                                       </div>
                                   </div>
                               
                               
                                                
                                
                                {
                                     currentDataPhoto?.billerCode === '9mobiledata1' ? <NineMobileDataForm /> : 
                                     currentDataPhoto?.billerCode === 'airtel-data' ? <AirtelDataForm /> : 
                                     currentDataPhoto?.billerCode === 'glo-data' ? <GloDataForm /> : 
                                     currentDataPhoto?.billerCode === 'data' ? <MTNDataForm /> : 
                                     currentDataPhoto?.billerCode === 'NTELBundle' ? <NtelDataForm /> : 
                                     currentDataPhoto?.billerCode === 'SMILE' ? <SmileDataComp /> : 
                                     currentDataPhoto?.billerCode === 'SPECTRANET' ? <SpectranetDataForm /> : 
                                     ""
                                }


                            </div>

                        </div>
                    </div>

                </div>
  )
}

export default DataOrderComp