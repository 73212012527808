import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentSmileDataOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface SmileDataState {
    currentSmileDataOrder?: ICurrentSmileDataOrder,
    loading: boolean
}

const initialState: SmileDataState = {
    currentSmileDataOrder: undefined,
    loading: false,
}


const smileDataSlice = createSlice({
    name: 'smiledata',
    initialState,
    reducers: {
      addSmileDataOrder: (state, action) => {
        state.currentSmileDataOrder =action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('smiledata') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('smiledata') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addSmileDataOrder } = smileDataSlice.actions

export default smileDataSlice.reducer
