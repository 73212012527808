
import { createSlice } from '@reduxjs/toolkit'
import { IHistoryResponse } from '../../../utils/transHistory/historyTypes'


export interface DataHistroyState {
    currentDataHistory?: IHistoryResponse[],
    loading: boolean
}

const initialState: DataHistroyState = {
    currentDataHistory: undefined,
    loading: false,
}


const DataHistorySlice = createSlice({
    name: 'datahistory',
    initialState,
    reducers: {
      addDataHistory: (state, action) => {
        state.currentDataHistory = [...action.payload]
        // state.currentHistory = [...state.currentHistory, ...action.payload]
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('datahistory') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('datahistory') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addDataHistory } = DataHistorySlice.actions

export default DataHistorySlice.reducer
