import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentKanoPostpaidCOrder } from '../../../utils/electricityTypes/electricityTypes'


export interface KanoPrepaidState {
    currentKanoPrepaidOrder?: ICurrentKanoPostpaidCOrder,
    loading: boolean
}

const initialState: KanoPrepaidState = {
    currentKanoPrepaidOrder: undefined,
    loading: false,
}


const kanoprepaidSlice = createSlice({
    name: 'kanoprepaid',
    initialState,
    reducers: {
      addKanoPrepaidOrder: (state, action) => {
        state.currentKanoPrepaidOrder =action.payload
      }
    },
 
    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('kanoprepaid') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('kanoprepaid') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addKanoPrepaidOrder } = kanoprepaidSlice.actions

export default kanoprepaidSlice.reducer
