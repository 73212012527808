import React, {  useEffect } from 'react'
import { Link } from "react-router-dom"
import {  useAppDispatch, useAppSelector } from "../../utils/TypeScript"

import NineMobile from "../assets/images/airtime/1.png"
import Airtel from "../assets/images/airtime/2.png"
import AirtelSmartRecharge from "../assets/images/airtime/3.png"
import Glo from "../assets/images/airtime/4.png"
import MTN from "../assets/images/airtime/5.png"
// import MTNAWUF from "../assets/images/airtime/6.png"
import ntel from "../assets/images/airtime/7.png"


// import NineMobileForm from './NineMobileForm'
// import AirtelForm from './AirtelForm'
// import GloForm from './GloForm'
// import MTNForm from './MTNForm'
// import NtelForm from './NtelForm'
import { addAirtimePhoto } from '../../redux/slice/airtimeSlice/airtimePhotoSlice'
// import BulkNineMobileForm from './forms/NineMobile'
// import BulkAirtelForm from './forms/Airtel'
// import BulkGloForm from './forms/Glo'
import MultipleMTNForm from './forms/MTN'
import MultipleAirtelForm from './forms/Airtel'
import MultipleGloForm from './forms/Glo'
import MultipleNineMobileForm from './forms/NineMobile'
// import BulkMTNNForm from './forms/MTNN'


const MultipleAirtimeOrder = () => {

    const { currentAirtimePhoto }  = useAppSelector((state) => state.airtimePhoto)

    const dispatch = useAppDispatch()

   
    useEffect(()=>{
        const airtimePhoto =  JSON.parse(localStorage.getItem('airtimePhoto') || "{}");
        dispatch(addAirtimePhoto(airtimePhoto))

    }, [])
   

  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>Airtime</h4>
                                    </div>
                                    <div className="ml-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>Amount</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">
                           
                            <li className="nav-item">
                                <Link className="nav-link active" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                         
                            <li className="nav-item">
                                <Link className="nav-link" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            
                            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                
                                
                            <div className="operators mx-3">
                                       
                                       <div className="row operator-row" id='operator-row'>
                                          
                                       <div className="col-md-2 col-sm-4">
                                               <div className="single-operator">
                                                   <div className="op-logo" data-operator="op1">
                                                       <img src={currentAirtimePhoto?.billerCode === '9MOBILE_69358' ? NineMobile : 
                                                       currentAirtimePhoto?.billerCode === 'AIRTEL_22689' ? Airtel : 
                                                       currentAirtimePhoto?.billerCode === 'AIRTEL_22689' ? AirtelSmartRecharge :
                                                       currentAirtimePhoto?.billerCode === 'GLO_30387' ? Glo :
                                                       currentAirtimePhoto?.billerCode === 'MTN_24207' ? MTN :
                                                       currentAirtimePhoto?.billerCode === 'mtn' ? MTN : 
                                                       currentAirtimePhoto?.billerCode === 'NTEL' ? ntel :
                                                       ""} alt="airtime logo" />
                                                   </div>
                                               </div>
                                           </div>
                                          
                                           
                                       </div>
                                   </div>
                                
                                
                                
                                
                                
                                {
                                        currentAirtimePhoto?.billerCode === '9MOBILE_69358' ? <MultipleNineMobileForm />
                                    : 
                                        currentAirtimePhoto?.billerCode === 'AIRTEL_22689' ? <MultipleAirtelForm />
                                    :
                                        currentAirtimePhoto?.billerCode === 'GLO_30387' ? <MultipleGloForm />
                                    :
                                        currentAirtimePhoto?.billerCode === 'MTN_24207' ? <MultipleMTNForm />
                                    
                                    :
                                        ""
                                }
                                
                                
                                
                            </div>

                        </div>
                    </div>

                </div>
  )
}

export default MultipleAirtimeOrder