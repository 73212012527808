import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentEKEDCPostpaidOrder} from '../../../utils/electricityTypes/electricityTypes'


export interface IBEDCState {
    currentIBEDCOrder?: ICurrentEKEDCPostpaidOrder,
    loading: boolean
}

const initialState: IBEDCState = {
    currentIBEDCOrder: undefined,
    loading: false,
}


const ibedcSlice = createSlice({
    name: 'ibedc',
    initialState,
    reducers: {
      addIBEDCOrder: (state, action) => {
        state.currentIBEDCOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('ibedc') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('pheibedcdc') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addIBEDCOrder } = ibedcSlice.actions

export default ibedcSlice.reducer
