import { createSlice } from '@reduxjs/toolkit'
import { ICurrentGOTVOrder } from '../../../utils/TypeScript'



// export const airtimeProcessTransaction = createAsyncThunk(
//   'caletv/process-transaction',
//   async (currentRechargeOrder: IcurrentRechargeOrder) => {
//   }
// )




export interface GOTVState {
    currentGOTVOrder?: ICurrentGOTVOrder,
    loading: boolean
}

const initialState: GOTVState = {
    currentGOTVOrder: undefined,
    loading: false,
}


const gOTVSlice = createSlice({
    name: 'gotv',
    initialState,
    reducers: {
      addGOTVOrder: (state, action) => {
        // state.currentOrder = action.payload
        state.currentGOTVOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('gotv') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('gotv') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addGOTVOrder } = gOTVSlice.actions

export default gOTVSlice.reducer
