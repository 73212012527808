import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { IMTNDataProducts } from '../../utils/AirtimeDateTypeScript/airtimeDataTypes'
import { fetchDataBiller } from '../../utils/billers'
import { IHistoryResponse } from '../../utils/transHistory/historyTypes'
import Spinner from '../Alert/Spinner/Spinner'

const DataDetailsComp = (props: {dataHistory: IHistoryResponse | undefined}) => { 


    const transDetailsArray = props?.dataHistory?.transDetails?.split('|')
    const details = transDetailsArray 

    const billerCode = props?.dataHistory?.billerCode
    const productCode = billerCode === '9mobiledata1' ? `${ details? details[2] : ""}`
                            : 
                            billerCode === 'airtel-data' ? `${ details? details[0] : ""}`
                            : 
                            billerCode === 'glo-data' ? `${ details? details[0] : ""}`
                            : 
                            billerCode === 'data' ? `${ details? details[0] : ""}`
                            :
                            billerCode === 'NTELBundle' ? `${ details? details[0] : ""}`
                            :
                            billerCode === 'SMILE' ? `${ details? details[3] : ""}`
                            :
                            billerCode === 'SPECTRANET' ? `${ details? details[1] : ""}`
                            :
                            ""

    const [loading, setLoading] = useState(false) 
    const [itemType, setItemType] = useState("")
    const [products, setProducts] = useState([])


    const fetchBiller = async () => {

        if(billerCode === '9mobiledata1'){

            setItemType(`${ details? details[1] : ""}`)
            setLoading(false)
            return

        } else if (billerCode === 'airtel-data'){
            setLoading(true)

            const selectetCableTVBiller = await fetchDataBiller('airtel-data')

            const packages = selectetCableTVBiller.productFields[3].ListItems

            setProducts(packages)
            setLoading(false)
            return

        } else if (billerCode === 'glo-data'){
            setLoading(true)

            const selectetCableTVBiller = await fetchDataBiller('glo-data')

            const packages = selectetCableTVBiller.productFields[3].ListItems
            
            setProducts(packages)
            setLoading(false)
            return

        } else if (billerCode === 'data'){
            setLoading(true)

            const selectetCableTVBiller = await fetchDataBiller('data')

            const packages = selectetCableTVBiller.productFields[3].ListItems
            const filteredPackages = packages.filter(( paackage : IMTNDataProducts)=>{
                return paackage.Amount === `${props?.dataHistory?.transactionAmount}`
            })
            setProducts(filteredPackages)

            setLoading(false)
            return

        } else if (billerCode === 'NTELBundle'){
            setLoading(true)

            const selectetCableTVBiller = await fetchDataBiller('NTELBundle')

            const packages = selectetCableTVBiller.productFields[3].ListItems
            setProducts(packages)

            setLoading(false)
            return

        } else if (billerCode === 'SMILE'){
            setLoading(true)

            const selectetCableTVBiller = await fetchDataBiller('SMILE')

            const packages = selectetCableTVBiller.productFields[4].ListItems

            setProducts(packages)
            setLoading(false)
            return

        } else {
            return
        }
    }


    const setPackage = ()=>{
        if(products.length < 1){
            return
        } else {
            const selectedProduct = products.filter(( paackage : IMTNDataProducts)=>{
                return paackage.ItemType === productCode
            })

        setItemType(selectedProduct[0]['ItemName']);
        // console.log(selectedProduct);

        }
    }
    
    useEffect( ()=>{
        fetchBiller()
    }, [billerCode]) 

    useEffect( ()=>{
    setPackage()
    }, [products])

    // console.log(itemType)




  return (
    <div className="col-lg-9">
        <div className="profile-content">
            <h3 className="admin-heading bg-offwhite">
                <p>Data Transaction Details</p>
            </h3>
            <div className="order-summery transaction-summery">
                <div className="order-details-area table">
                    <div className="table-head">
                        <div className="modal_header">
                            <span className={props?.dataHistory?.statusDescription === "SUCCESSFUL" ? "status bg-success" : "status bg-danger" }>
                                
                                {
                                    props?.dataHistory?.statusDescription === "SUCCESSFUL" ? "Paid" : "Failed"
                                }
                            </span>
                            <div className="row">
                                <div className="col">
                                    <div className="content-area">
                                        <b>Payment sent to</b>
                                        <i className="fas fa-building ml-3 mr-1"></i>
                                         PreponeBills Ltd
                                        <p>{props?.dataHistory?.transDate}</p>
                                        <p> Trans Ref:  
                                             {
                                                ` ${ props?.dataHistory?.transRef}` 
                                            }

                                        </p>
                                        <p>Payment Status: 
                                            {
                                                ` ${ props?.dataHistory?.paymentStatus}` 
                                            }
                                        </p>
                                        <p>Payment Type: XpressPay Gateway</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="content-area text-right">
                                        <div className="price">
                                            <b>Amount</b>
                                            <p>₦
                                            {
                                                `${props?.dataHistory?.transactionAmount}`
                                            }
                                            </p>
                                        </div>                                                        
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="table-content bg-offwhite">
                            <b>
                                <div className="row">
                                    <div className="col">Order Details</div>
                                    <div className="col">Phone Number</div>
                                </div>
                            </b>
                        </div>
                        <div className="table-content">

                            <div className="row">
                                <div className="col">
                                    {
                                        itemType
                                    }
                                </div>
                                <div className="col text-left mr-4">
                                    {
                                        billerCode === '9mobiledata1' ? `${ details? details[3] : ""}`
                                        : 
                                        billerCode === 'airtel-data' ? `${ details? details[1] : ""}`
                                        : 
                                        billerCode === 'glo-data' ? `${ details? details[1] : ""}`
                                        : 
                                        billerCode === 'data' ? `${ details? details[1] : ""}`
                                        :
                                        billerCode === 'NTELBundle' ? `${ details? details[1] : ""}`
                                        :
                                        billerCode === 'SMILE' ? `${ details? details[2] : ""}`
                                        :
                                        billerCode === 'SPECTRANET' ? `${ details? details[1] : ""}`
                                        :
                                        ""
                                    }
                                </div>
                               
                            </div>
                        </div>
                       

                        <div className="row">
                            <div className="col-md-5 ml-auto">
                                <div className="sub-content">
                                    <br/>
                                    <div className="row">
                                        <div className="col">Purchase Total</div>
                                        <div className="col text-right">₦
                                            {
                                               `${props?.dataHistory?.transactionAmount}`
                                            }
                                        </div>
                                    </div>
                                   
                                    
                                    <div className="row">
                                        <div className="col">Service Charge</div>
                                        <div className="col text-right">₦0.00</div>
                                    </div>
                                    
                                   
                                    
                                    <hr/>
                                    <div className="row">
                                        <div className="col"><b>Net Amount</b></div>
                                        <div className="col text-right">₦
                                            {
                                                 `${props?.dataHistory?.transactionAmount}`
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-footer mt-3 bg-offwhite p-3">
                            <div className="row">
                                <div className="col">
                                    <p><b>Payment Ref: </b>
                                        {
                                            ` ${props?.dataHistory?.paymentRef} `
                                        }
                                    </p>
                                    <p className="mb-0"><b>Thank You!</b></p>
                                    <p>
                                        Thank you for using Prepone bills payment platform,
                                        we promise to serve you better.
                                    </p>
                                </div>
                                <div className="col">
                                    <p className="mb-0"><b>Contact info</b></p>
                                    <p>
                                        PreponeBills Ltd
                                        The recipient of this payment is verified and is located in Port Harcourt, Nigeria.
                                        http://preponebills.com/,
                                        help@preponebills.com
                                    </p>
                                </div>
                            </div>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>

        { loading && <Spinner /> }

    </div>
  )
}

export default DataDetailsComp