import React from 'react'
import { IHistoryResponse } from '../../utils/transHistory/historyTypes'

const AirtimeDetailsComp = (props: {airtimeHistory: IHistoryResponse | undefined}) => { 


    const transDetailsArray = props?.airtimeHistory?.transDetails?.split('|')
    const details = transDetailsArray 





  return (
    <div className="col-lg-9">
        <div className="profile-content">
            <h3 className="admin-heading bg-offwhite">
                <p>Airtime Transaction Details</p>
            </h3>
            <div className="order-summery transaction-summery">
                <div className="order-details-area table">
                    <div className="table-head">
                        <div className="modal_header">
                            <span className={props?.airtimeHistory?.statusDescription === "SUCCESSFUL" ? "status bg-success" : "status bg-danger" }>
                                
                                {
                                    props?.airtimeHistory?.statusDescription === "SUCCESSFUL" ? "Paid" : "Failed"
                                }
                            </span>
                            <div className="row">
                                <div className="col">
                                    <div className="content-area">
                                        <b>Payment sent to</b>
                                        <i className="fas fa-building ml-3 mr-1"></i>
                                         PreponeBills Ltd
                                        <p>{props?.airtimeHistory?.transDate}</p>
                                        <p> Trans Ref:  
                                             {
                                                 ` ${ props?.airtimeHistory?.transRef}`
                                            }

                                        </p>
                                        <p>Payment Status: 
                                            {
                                                ` ${props?.airtimeHistory?.paymentStatus}`
                                            }
                                        </p>
                                        <p>Payment Type: XpressPay Gateway</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="content-area text-right">
                                        <div className="price">
                                            <b>Amount</b>
                                            <p>₦
                                            {
                                                 `${props?.airtimeHistory?.transactionAmount}`
                                            }
                                            </p>
                                        </div>                                                        
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="table-content bg-offwhite">
                            <b>
                                <div className="row">
                                    <div className="col">Order Details</div>
                                    <div className="col">Phone Number</div>
                                </div>
                            </b>
                        </div>
                        <div className="table-content">

                            <div className="row">
                                <div className="col">
                                    {
                                        ` ${props?.airtimeHistory?.billerName} `
                                    }

                                    Recharge
                                </div>
                                <div className="col text-left mr-4">
                                    {
                                        props?.airtimeHistory?.billerCode === '9MOBILEAIRTIME' ? `${ details? details[2] : ""}`
                                        : 
                                        props?.airtimeHistory?.billerCode === 'airtel' ? `${ details? details[1] : ""}`
                                        : 
                                        props?.airtimeHistory?.billerCode === 'glo' ? `${ details? details[1] : ""}`
                                        : 
                                        props?.airtimeHistory?.billerCode === 'mtn' ? `${ details? details[1] : ""}`
                                        :
                                        props?.airtimeHistory?.billerCode === 'NTEL' ? `${ details? details[1] : ""}`
                                        :
                                        ""
                                    }
                                </div>
                               
                            </div>
                        </div>
                       

                        <div className="row">
                            <div className="col-md-5 ml-auto">
                                <div className="sub-content">
                                    <br/>
                                    <div className="row">
                                        <div className="col">Purchase Total</div>
                                        <div className="col text-right">₦
                                            {
                                               props?.airtimeHistory?.billerCode === '9MOBILEAIRTIME' ? `${props?.airtimeHistory?.transactionAmount}`
                                               : 
                                               props?.airtimeHistory?.billerCode === 'airtel' ? `${props?.airtimeHistory?.transactionAmount}`
                                               : 
                                               props?.airtimeHistory?.billerCode === 'glo' ? `${props?.airtimeHistory?.transactionAmount}`
                                               : 
                                               props?.airtimeHistory?.billerCode === 'mtn' ? `${props?.airtimeHistory?.transactionAmount}`
                                               :
                                               props?.airtimeHistory?.billerCode === 'NTEL' ? `${props?.airtimeHistory?.transactionAmount}`
                                               :
                                               ""
                                            }
                                        </div>
                                    </div>
                                   
                                    
                                    <div className="row">
                                        <div className="col">Service Charge</div>
                                        <div className="col text-right">₦0.00</div>
                                    </div>
                                    
                                   
                                    
                                    <hr/>
                                    <div className="row">
                                        <div className="col"><b>Net Amount</b></div>
                                        <div className="col text-right">₦
                                            {
                                                 props?.airtimeHistory?.billerCode === '9MOBILEAIRTIME' ? `${ details? details[3] : ""}`
                                                 : 
                                                 props?.airtimeHistory?.billerCode === 'airtel' ? `${ details? details[2] : ""}`
                                                 : 
                                                 props?.airtimeHistory?.billerCode === 'glo' ? `${ details? details[2] : ""}`
                                                 : 
                                                 props?.airtimeHistory?.billerCode === 'mtn' ? `${ details? details[2] : ""}`
                                                 :
                                                 props?.airtimeHistory?.billerCode === 'NTEL' ? `${ details? details[2] : ""}`
                                                 :
                                                 ""
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-footer mt-3 bg-offwhite p-3">
                            <div className="row">
                                <div className="col">
                                    <p><b>Payment Ref: </b>
                                        {
                                            props?.airtimeHistory?.billerCode === '9MOBILEAIRTIME' ? ` ${props?.airtimeHistory?.paymentRef} `
                                            : 
                                            props?.airtimeHistory?.billerCode === 'airtel' ? ` ${props?.airtimeHistory?.paymentRef} `
                                            : 
                                            props?.airtimeHistory?.billerCode === 'glo' ? ` ${props?.airtimeHistory?.paymentRef} `
                                            : 
                                            props?.airtimeHistory?.billerCode === 'mtn' ? ` ${props?.airtimeHistory?.paymentRef} `
                                            :
                                            props?.airtimeHistory?.billerCode === 'NTEL' ? ` ${props?.airtimeHistory?.paymentRef} `
                                            :
                                            ""
                                        }
                                    </p>
                                    <p className="mb-0"><b>Thank You!</b></p>
                                    <p>
                                        Thank you for using Prepone bills payment platform,
                                        we promise to serve you better.
                                    </p>
                                </div>
                                <div className="col">
                                    <p className="mb-0"><b>Contact info</b></p>
                                    <p>
                                        PreponeBills Ltd
                                        The recipient of this payment is verified and is located in Port Harcourt, Nigeria.
                                        http://preponebills.com/,
                                        help@preponebills.com
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="table-footer mt-3 bg-offwhite p-3">
                            <div className="row">
                                <div className="col">
                                    <div className="invoice-option  btn-refund text-center my-4">
                                        <Link to="#" className="invoice-btn btn"><i className="far fa-file-pdf"></i> Save as PDF</Link>
                                        <Link to="#" className="invoice-btn btn"><i className="fas fa-print"></i> Print Receipt</Link>
                                        <Link to="#" className="invoice-btn btn"><i className="far fa-envelope"></i> Email Receipt</Link>
                                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-reply"></i> Action 
                                            <div className="dropdown-menu">
                                                    <Link className="dropdown-item" to="#">Refund</Link>
                                                    <Link className="dropdown-item" to="#">Archive</Link>
                                                </div>
                                        </button>
                                                                                                    
                                    </div>
                                                                                
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AirtimeDetailsComp