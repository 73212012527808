import React, {  useEffect } from 'react'
import { Link } from "react-router-dom"
import { addCableTVPhoto } from '../../redux/slice/cableTVSlice/cableTVPhotoSlice'

import {  useAppDispatch, useAppSelector } from "../../utils/TypeScript"

import DSTV1 from "../assets/images/cabletv/DSTV1.png"
import GOTV1 from "../assets/images/cabletv/GOTV 1.png"
import Startimes from "../assets/images/cabletv/Startimes .png"
import DSTVFormComp from './DSTVForm'
import GOTVFormComp from './GOTVForm'
import StartimesFormComp from './StartimesForm'


const CableTVOrderComp = () => {


    const { currentCableTVPhoto }  = useAppSelector((state) => state.cableTVPhoto)

    const dispatch = useAppDispatch()


    useEffect(()=>{
        const cableTVPhoto =  JSON.parse(localStorage.getItem('cableTVPhoto') || "{}");
        dispatch(addCableTVPhoto(cableTVPhoto))

    }, [])
    

  return (
    <div className="col-lg-9">
                    <div className="row mx-1">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3 ">
                                    <div className="mr-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>{
                                            currentCableTVPhoto?.billerCode === "DSTV1" ? "DSTV" : currentCableTVPhoto?.billerCode === "GOTV1" ? "GOTV" : currentCableTVPhoto?.billerCode === "startimes" ? "Startimes" : ""
                                        }</h4>
                                    </div>
                                    <div className="ml-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>Amount</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills mx-3">
                           
                            <li className="nav-item">
                                <Link className="nav-link active" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                           
                            <li className="nav-item">
                                <Link className="nav-link" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            
                            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                  
                                    <div className="operators mx-3">
                                       
                                       <div className="row operator-row" id='operator-row'>
                                          
                                       <div className="col-md-2 col-sm-4">
                                               <div className="single-operator">
                                                   <div className="op-logo" data-operator="op1">
                                                       <img src={currentCableTVPhoto?.billerCode === "DSTV1" ? DSTV1 : currentCableTVPhoto?.billerCode === "GOTV1" ? GOTV1 : currentCableTVPhoto?.billerCode === "startimes" ? Startimes : ""} alt="Cable TV Logo" />
                                                   </div>
                                               </div>
                                           </div>
                                          
                                           
                                       </div>
                                   </div>


                                {currentCableTVPhoto?.billerCode === "DSTV1" ? <DSTVFormComp /> : currentCableTVPhoto?.billerCode === "GOTV1" ? <GOTVFormComp /> : currentCableTVPhoto?.billerCode === "startimes" ? <StartimesFormComp /> : ""}
                            </div>

                        </div>
                    </div>

                </div>
  )
}

export default CableTVOrderComp