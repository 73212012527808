import React from 'react'
import { Link } from "react-router-dom"

const PaymentAssurance = () => {
  return (
    <section className="section bg-light shadow-md pt-4 pb-3" >
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <div className="iconBox">
                        <i className="fas fa-lock"></i>
                        <h5 style={{color: "#3f0d5e"}}>Secure Payments</h5>
                        <p>We use best industry standard methods to secure your payments & information.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="iconBox">
                        <i className="fas fa-hand-holding-usd"></i>
                        <h5 style={{color: "#3f0d5e"}}>Trust pay</h5>
                        <p>100% Payment Protection. Easy Return Policy.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="iconBox">
                            <i className="fas fa-retweet"></i>
                        <h5 style={{color: "#3f0d5e"}}>Refer &amp; Earn</h5>
                        <p>Invite a friend to sign up and get a bonus from PreponeBills.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="iconBox">
                        <i className="fab fa-superpowers"></i>
                        <h5 style={{color: "#3f0d5e"}}>24/7 Support</h5>
                        <p>We're here to help. Have a query and need help ? <Link to="/contact-us">Click here</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default PaymentAssurance