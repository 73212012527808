import { createSlice } from '@reduxjs/toolkit'
import { ICurrentBillerPhoto } from "../../../utils/TypeScript"



export interface BettingState {
    currentBettingPhoto?: ICurrentBillerPhoto,
    loading: boolean
}

const initialState: BettingState = {
  currentBettingPhoto: undefined,
    loading: false,
}


const bettingPhotoSlice = createSlice({
    name: 'bettingphoto',
    initialState,
    reducers: {
      addBettingPhoto: (state, action) => {
        state.currentBettingPhoto = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('bettingphoto') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('bettingphoto') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addBettingPhoto} = bettingPhotoSlice.actions

export default bettingPhotoSlice.reducer
