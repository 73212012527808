import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentEKEDCOrderPaymentOrder } from '../../../utils/electricityTypes/electricityTypes'


export interface IEPostpaidState {
    currentIEPostpaidOrder?: ICurrentEKEDCOrderPaymentOrder,
    loading: boolean
}

const initialState: IEPostpaidState = {
    currentIEPostpaidOrder: undefined,
    loading: false,
}


const iepostpaidSlice = createSlice({
    name: 'iepostpaid',
    initialState,
    reducers: {
      addIEPostpaidOrder: (state, action) => {
        state.currentIEPostpaidOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('iepostpaid') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('iepostpaid') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addIEPostpaidOrder } = iepostpaidSlice.actions

export default iepostpaidSlice.reducer
