import React from 'react'
import { Link } from "react-router-dom"

const ProfileMenuTwo = () => {
  return (
    <div 
    // id="menu2" 
        className="tab-pane fade active">
        {/* className="tab-pane fade"> */}
        <div className="row">
            <p className="col-sm-3"><b>Language</b></p>
            <p className="col-sm-9">English (United States)</p>
        </div>
        <hr/>
        <div className="row">
            <p className="col-sm-3"><b>Time Zone</b></p>
            <p className="col-sm-9">(GMT+01:00) West Africa</p>
        </div>
        <hr/>
        <div className="row">
            <p className="col-sm-3"><b>Password</b></p>
            <p className="col-sm-9"><input type="password" className="form-control-plaintext" id="password" value="EnterPassword"/> </p>
        </div>
        <hr/>
        <div className="row">
            <p className="col-sm-3"><b>2-step verification</b></p>
            <p className="col-sm-9">Add an extra layer of security to your account by using a one-time security code in addition to your password each time you log in. <Link to="#" data-toggle="modal" data-target="#step" style={{color:"#9417E2"}}><i className="fas fa-wrench"></i> SETUP</Link></p>
        </div>
        <hr/>


        <div className="row">
            <p className="col-sm-3"><b>Stay logged in for faster purchases</b></p>
            <p className="col-sm-9">Add an extra layer of security to your account by using a one-time security code in addition to your password each time you log in. <Link to="#" data-toggle="modal" data-target="#staylogin" style={{color:"#9417E2"}}>Update</Link></p>
        </div>
        <hr/>
        <div className="row">
            <p className="col-sm-3"><b>Security question</b></p>
            <p className="col-sm-9"><input type="password" className="form-control-plaintext" id="security" value="EnterPassword"/> </p>
        </div>
        <hr/>
        <div className="row">
            <p className="col-sm-3"><b>Account type</b></p>
            <p className="col-sm-9">Business <Link to="#" className="close-account" data-toggle="modal" data-target="#closeaccount" style={{color:"#9417E2"}}><i className="fas fa-times" ></i> Close Account</Link></p>
        </div>

        {/* <!-- Modal --> */}
        <div className="modal fade step-secourity" id="closeaccount" 
        // tabindex="-1" 
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <p>Are you sure??</p></h5>
                                <p>You want to close you account</p>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>                                                    
                        </div>
                        <div className="modal-body">
                                <form>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
                                    <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Password</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Security question</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Security question"/>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn" style={{backgroundColor:"#9417E2"}}>I want to close</button>
                        </div>
                    </div>
                </div>
            </div>


        {/* <!-- Modal --> */}
        <div className="modal fade step-secourity" id="staylogin" 
        // tabindex="-1" 
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered " role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            <p>Stay logged in for faster purchases</p></h5>
                            <p>With One Touch™, you’ll speed through checkout without having to log in every time.</p>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>                                                    
                    </div>
                    <div className="modal-body">
                        <p><b>You're logged in on these devices:</b></p>
                        <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Dvice Name</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Desktop Chrome Windows 10 NT 10.0</td>
                                    <td><Link to="#" style={{color:"#9417E2"}}>Log out</Link></td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Desktop Chrome Windows 10 NT 10.0</td>
                                    <td><Link to="#" style={{color:"#9417E2"}}>Log out</Link></td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>Desktop Chrome Windows 10 NT 10.0</td>
                                    <td><Link to="#" style={{color:"#9417E2"}}>Log out</Link></td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>Desktop Chrome Windows 10 NT 10.0</td>
                                    <td><Link to="#" style={{color:"#9417E2"}}>Log out</Link></td>
                                </tr>
                            
                                </tbody>
                            </table>
                        <Link to="#" style={{color:"#9417E2"}}>Turn off One Touch™ on all devices</Link>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn" style={{backgroundColor:"#9417E2"}}>Save</button>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Modal --> */}
        <div className="modal fade step-secourity" id="step" 
        // tabindex="-1" 
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <p>Protect 2-step verification</p></h5>

                                <p>Each time you log in, you’ll use a one-time code in addition to your password. Choose how you’ll get your code.</p>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>                                                    
                        </div>
                        <div className="modal-body">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitch1"/>
                                <label className="custom-control-label" htmlFor="customSwitch1">Text me a code</label>
                            </div>
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitch2"/>
                                <label className="custom-control-label" htmlFor="customSwitch2">Use an authenticator app</label>
                            </div>
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" disabled id="customSwitch3"/>
                                <label className="custom-control-label" htmlFor="customSwitch3">Use a security key</label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn" style={{backgroundColor:"#9417E2"}}>Set It up</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default ProfileMenuTwo