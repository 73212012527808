import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addBettingOrder } from '../../redux/slice/bettingSlice/bettingSlice'
import { lookupBetting } from '../../utils/lookup/lookupTrans'
import { ICurrentBettingOrder, useAppDispatch, useAppSelector } from '../../utils/TypeScript'
import { validateBettingForm } from '../../utils/validateBettingForm/validBettingForm'
import Spinner from '../Alert/Spinner/Spinner'
import Toast from '../Alert/Toast'


const BettingFormComp = () => {


    const dispatch = useAppDispatch()
    const navigate = useNavigate()


    const [loading, setLoading] = useState(false)
    const [userAccountNumber, setUserAccountNumber] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [amount, setAmount] = useState("")
    // const [uniqueCode, setUniqueCode] = useState("")


    const { profile } = useAppSelector(state => state.profile)
    const { currentUser } = useAppSelector(state => state.auth)
    const { currentBettingPhoto }  = useAppSelector((state) => state.bettingPhoto)



    const handleSubmit = async (e: FormEvent) =>{
        e.preventDefault()
        setLoading(true)


        const bettingOrderdetails = {userAccountNumber, amount, phoneNumber }
        
        const result = validateBettingForm(bettingOrderdetails)

        if(result.errLength){

           toast.error(() => <Toast title="Error"
            body={result.errMsg} />)

            setLoading(false)
            return 
        }

        const uniqueCode = 
            currentBettingPhoto?.billerCode === "BET9JA" ? "BET9JA_30941" 
            : 
            currentBettingPhoto?.billerCode === "MYLOTTOHUB" ? "MYLOTTOHUB_84073" 
            : 
            currentBettingPhoto?.billerCode === "MELBET" ? "MELBET_73339" 
            : 
            currentBettingPhoto?.billerCode === "PARIPESA" ? "PARIPESA_96456" 
            :
            currentBettingPhoto?.billerCode === "BANGBET" ? "BANGBET_42980" 
            :
            currentBettingPhoto?.billerCode === "BETKING" ? "BETKING_94244" 
            :
            currentBettingPhoto?.billerCode === "NAIRAMILLION" ? "NAIRAMILLION_64314" 
            :
            currentBettingPhoto?.billerCode === "CLOUDBET" ? "CLOUDBET_24923" 
            :
            currentBettingPhoto?.billerCode === "ONE_XBET" ? "ONE_XBET_57419" 
            :
            currentBettingPhoto?.billerCode === "NAIJABET" ? "NAIJABET_88311" 
            :
            currentBettingPhoto?.billerCode === "BETLION" ? "BETLION_63689" 
            :
            ""


        let transactionId = "PreponeBills-" + Math.floor(Math.random() * 1000000);

        const bettingLookup = {
            requestId: transactionId,
            uniqueCode: uniqueCode,
            details: {
                accountNumber: userAccountNumber
            }
        }

        // console.log( bettingLookup);

        const lookupResult = await lookupBetting(bettingLookup)
        const { data, responseMessage } = lookupResult
        // console.log(lookupResult);

        if(responseMessage.toLowerCase() === "successful"){

            const bettingOrder: ICurrentBettingOrder = {
                requestId: transactionId,
                uniqueCode: uniqueCode,
                details: {
                    accountNumber: userAccountNumber,
                    amount: Number(amount),
                    phoneNumber,
                    fullName: `${data?.firstName } ${data?.lastName }`,
                    userName: `${data?.username }`
                },
                userEmail: email
            }
    
            dispatch( addBettingOrder(bettingOrder))
            localStorage.setItem("bettingOrder", JSON.stringify(bettingOrder));
            navigate("/betting-summary") 
            return setLoading(false)
        } else {

            setLoading(false)
            return toast.error("Could not find the user with this Customer Account Number!") 
      
        }


    }


    // useEffect( ()=>{
    //     if(!profile) return;
    //     setEmail(profile.userEmail)
    //     setSmartCardNumber(profile.smartCardNumber)
    // }, [profile])



  return (
    
    <>
    <form onSubmit={handleSubmit} className="mx-3 mt-4">

        <span className='text-danger' style={{fontSize: "0.9rem"}}>
            {
            currentUser ? "" : "Login to remember details next time."
            }
        
        </span>
        <div className="form-row">
            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1" className='fs-10'>Customer Account Number (ID)<span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter Card Number" name="userAccountNumber" value={userAccountNumber} onChange={e => setUserAccountNumber(e.target.value)}/>
            </div>

            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Amount (₦)<span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter amount" name="amount" value={amount} onChange={e => setAmount(e.target.value)}/>
            </div>
        </div>


        <div className="form-row">

            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Phone Number <span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter phone number" name="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
            </div>



            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Email Address <span className='text-danger'></span></label>
                <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>

            
        </div>


        <ul className="pager my-5" style={{width: "100%"}}>
            <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
        </ul>



    </form>
    { loading && <Spinner /> }

    </>
  )
}

export default BettingFormComp