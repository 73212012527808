import React from 'react'
import Office from "../../components/assets/images/office.png"

const AboutPageFirstSection = () => {
  return (
    <section className="about bg-offwhite">
    <div className="container">
        <div className="row">
            <div className="col-lg-7 col-md-9 m-auto text-center">
                <div className="sec-heading">
                    <span className="tagline" style={{color: "#9417E2"}}>About PreponeBills</span>
                    <h3 className="sec-title" style={{color: "#3f0d5e"}}>As a trusted agency in online and ultility bill payments </h3>
                </div>
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-md-6">
                <div className="entry-content">
                    <h3 className='' style={{color: "#3f0d5e"}}>About Us</h3>
                    <p>PreponeBills is powered by Prepone Technologies Limited, a telecommunication and information technology services firm that aims at providing seamless and value-added services to its clientele.</p>


                    {/* <h3 className=''>Corporate Philosophy</h3> */}
                    
                    <h6 className='' style={{color: "#3f0d5e"}}>Vision Statement</h6>
                    <p>To build a world class information technology services company. </p>

                    <h6 className='' style={{color: "#3f0d5e"}}>Mision Statement</h6>
                    <p>To go beyond the ordinary in our service delivery, providing cutting edge information technology solutions.</p>

                    <h6 className='' style={{color: "#3f0d5e"}}>Core Values</h6>
                    <p>
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>Integrity <br />
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Innovation <br />
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Excellence <br />
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Professionalism <br />
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i> Passion for Customers <br />
                    </p>
                </div>
            </div>
            <div className="col-md-6">
                <div className="video-box">
                    <img src={Office} alt="office" />
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default AboutPageFirstSection