import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addCableTVPhoto } from "../../redux/slice/cableTVSlice/cableTVPhotoSlice";
import { addCableTVOrder } from "../../redux/slice/cableTVSlice/cableTVSlice";
import { postCableTVTransaction } from "../../utils/processTransactions/processCableTV/processCableTVTransactions";
// import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from "../../utils/TypeScript";
import Spinner from "../Alert/Spinner/Spinner";
import { toast } from "react-toastify";

const BodiesDoneComp = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { currentCableTVPhoto } = useAppSelector((state) => state.cableTVPhoto);
  const { currentCableTVOrder } = useAppSelector((state) => state.cableTV);
  const { currentFIRSOrder } = useAppSelector((state) => state.firs);

  console.log({ currentCableTVPhoto });

  const redirectToHome = () => {
    setTimeout(() => {
      localStorage.clear();
    }, 50000);
  };

  const postTransaction = async () => {
    setLoading(true);
    if (currentCableTVOrder !== undefined) {
      const transactionResponse = await postCableTVTransaction(
        currentCableTVOrder
      );
      const { responseMessage } = transactionResponse;
      // console.log(transactionResponse);

      if (responseMessage.toLowerCase() === "successful") {
        setSuccess(true);
        setLoading(false);
        localStorage.clear();
        redirectToHome();
      } else {
        localStorage.clear();
        redirectToHome();
        return setLoading(false);
      }
    }
  };

  //   const getFromLocalStorage = () => {
  //     const cableTVPhoto = JSON.parse(
  //       localStorage.getItem("cableTVPhoto") || "{}"
  //     );
  //     const cableTVOrder = JSON.parse(
  //       localStorage.getItem("cableTVOrder") || "{}"
  //     );

  //     dispatch(addCableTVPhoto(cableTVPhoto));
  //     dispatch(addCableTVOrder(cableTVOrder));

  //     setPost(true);
  //   };

  useEffect(() => {
    // if(localStorage.getItem('cableTVPhoto') === null ) return navigate("/")
    // getFromLocalStorage();
  }, []);

  useEffect(() => {
    // if(localStorage.getItem('payment') === null ) return navigate("/")
    // const payment =  localStorage.getItem('payment') || " ";
    // if(payment !== "i") return setLoading(false)
    // postTransaction()

    toast.error("Thank you! Your transaction has been processed!");
  }, [currentFIRSOrder]);

  return (
    <div className="col-lg-9">
      <div className="row">
        <div className="col-lg-12">
          <div className="bg-light shadow-sm text-center mb-3">
            <div className="d-flex admin-heading pr-3">
              <div className="mr-auto">
                <h4
                  className="text-9 font-weight-400"
                  style={{ color: "#3f0d5e" }}
                >
                  <i className="fas fa-wallet mr-2"></i>
                  {
                  currentCableTVPhoto?.billerCode === "NSE"
                  ? "NG Society of Engineers"
                  : currentCableTVPhoto?.billerCode === "LCC"
                  ? "Lekki Concession Company"
                  : currentCableTVPhoto?.billerCode === "RIVS"
                  ? "Rivers State Govt"
                  : ""}
                </h4>
              </div>
              <div className="ml-auto">
                <h4
                  className="text-9 font-weight-400"
                  style={{ color: "#3f0d5e" }}
                >
                  ₦{Number(currentFIRSOrder?.amount).toLocaleString() ?? 0}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 profile-content ">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link className="nav-link" to="">
              Order
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="">
              Summary
            </Link>
          </li>
       
          <li className="nav-item">
            <Link className="nav-link active" to="">
              Done
            </Link>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="five"
            role="tabpanel"
            aria-labelledby="five-tab"
          >
            {loading ? (
              ""
            ) : (
              <div className="done my-5">
                <h3 className="title">
                  {success ? "Transaction Successful" : "Transaction Failed"}
                </h3>

                <span
                  className={`text-5 d-block  mt-4 mb-3 ${
                    success ? "text-success" : "text-danger"
                  }`}
                >
                  {success ? (
                    <i className="fas fa-check-circle"></i>
                  ) : (
                    <i className="fas fa-times-circle"></i>
                  )}
                </span>

                <p>
                  {" "}
                  <span>Your </span>
                  {currentFIRSOrder?.serviceName}
                  <span> payment of ₦</span>
                  {Number(currentFIRSOrder?.amount).toLocaleString() ?? 0}
                  <br />
                  for <b>{currentFIRSOrder?.customer_id}</b>
                  <span> {success ? "was successful" : "failed"}.</span>
                  {success
                    ? " Thank you for using PreponeBills"
                    : " Please try again."}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default BodiesDoneComp;
