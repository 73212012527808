import React from 'react'
import { useAppSelector } from '../utils/TypeScript'
import { motion } from "framer-motion"

import AsideBar from '../components/global/AsideBar/AsideBar'
import ProfileBar from '../components/global/ProfileBar/ProfileBar'
import CableTVDoneComp from '../components/CableTvComps/CableTVDoneComp'

const CableTVDonePage = () => {
  
  const { currentUser } = useAppSelector(state => state.auth)


  return (
  <motion.div
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        // exit={{ scaleY: 0 }}
        transition={{ duration: 0.5 }}
      >        
    <ProfileBar />

        <div id="content" className="py-4">
            <div className="container">
                <div className="row">

                  <CableTVDoneComp />

                  
                {
                    currentUser

                    &&
                    
                    <AsideBar />
                  }


                </div >
            </div>
        </div>
  </motion.div>

  )
}

export default CableTVDonePage