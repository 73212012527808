import React, { useState, FormEvent } from 'react'
import { toast } from 'react-toastify'
import { authForgotPassword } from '../../redux/slice/authSlice'
import { useAppDispatch, useAppSelector} from "../../utils/TypeScript"
import { validateForgotPasswordDetail } from '../../utils/Validation'
import Toast from '../Alert/Toast'


const ForgotPasswordForm = () => {

    const [email, setEmail] = useState('')

    const { loading } = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()


    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()

        const result = validateForgotPasswordDetail(email)

        if(result.errLength){

            return toast.error(() => <Toast title="Error"
            body={result.errMsg} />)
        }
   
        dispatch(authForgotPassword(email))
       
    }



  return (
    <section className="banner v4 bg-offwhite">
          <div className="container">
              <div className="row">
                  <div className="col-md-8 m-auto">
                      <div className="ban-content text-center">
                          <h1 style={{color:"#3f0d5e"}}>Recover your Password</h1>
                          <p>Let us help you recover your password. Type in your email address and a link to reset your password will be sent to your email.</p>
                      </div>
                      <form className="bill-form v2" onSubmit={handleSubmit}>
                          <div className="form-field">
                              <div className="join-field">
                                  <input type="email" placeholder="email address"  name="email" value={email}
                                    onChange={e => setEmail(e.target.value)}/>
                                  <button type='submit' className="btn btn-block 
                                   btn-filled  " 
                                //    disabled={(email) ? false : true}
                                  ><span className="bh"></span> <span>{loading ? 'Loading...' : 'Submit'}</span></button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </section>
  )
}

export default ForgotPasswordForm