
import { createSlice } from '@reduxjs/toolkit'
import { IHistoryResponse } from '../../../utils/transHistory/historyTypes'


export interface CableTVHistroyState {
    currentCableTVHistory?: IHistoryResponse[],
    loading: boolean
}

const initialState: CableTVHistroyState = {
    currentCableTVHistory: undefined,
    loading: false,
}


const CableTVHistorySlice = createSlice({
    name: 'cabletvhistory',
    initialState,
    reducers: {
      addCableTVHistory: (state, action) => {
        state.currentCableTVHistory = [...action.payload]
        // state.currentHistory = [...state.currentHistory, ...action.payload]
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('cabletvhistory') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('cabletvhistory') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addCableTVHistory } = CableTVHistorySlice.actions

export default CableTVHistorySlice.reducer
