import React from 'react'
import PrivacyHeader from '../components/PrivacyComps/PrivacyHeader'
import PrivacyMainComp from '../components/PrivacyComps/PrivacyMainComp'

const PrivacyPolicyPage = () => {
  return (
    <div>
        <PrivacyHeader />

        <PrivacyMainComp />
    </div>
  )
}

export default PrivacyPolicyPage