import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentGloOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface GloState {
    currentGloOrder?: ICurrentGloOrder,
    loading: boolean
}

const initialState: GloState = {
    currentGloOrder: undefined,
    loading: false,
}


const gloSlice = createSlice({
    name: 'glo',
    initialState,
    reducers: {
      addGloOrder: (state, action) => {
        state.currentGloOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('glo') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('glo') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addGloOrder } = gloSlice.actions

export default gloSlice.reducer
