import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentEKEDCPrepaidOrder } from '../../../utils/electricityTypes/electricityTypes'


export interface KadunaPrepaidState {
    currentKadunaPrepaidOrder?: ICurrentEKEDCPrepaidOrder,
    loading: boolean
}

const initialState: KadunaPrepaidState = {
    currentKadunaPrepaidOrder: undefined,
    loading: false,
}


const kadunaprepaidSlice = createSlice({
    name: 'kadunaprepaid',
    initialState,
    reducers: {
      addKadunaPrepaidOrder: (state, action) => {
        state.currentKadunaPrepaidOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('kadunaprepaid') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('kadunaprepaid') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addKadunaPrepaidOrder } = kadunaprepaidSlice.actions

export default kadunaprepaidSlice.reducer
