import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentJambOrder } from '../../../utils/TypeScript'


export interface JambState {
    currentJambOrder?: ICurrentJambOrder,
    loading: boolean
}

const initialState: JambState = {
    currentJambOrder: undefined,
    loading: false,
}


const jambSlice = createSlice({
    name: 'jamb',
    initialState,
    reducers: {
      addJambOrder: (state, action) => {
        state.currentJambOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('jamb') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('jamb') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addJambOrder } = jambSlice.actions

export default jambSlice.reducer
