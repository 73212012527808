import React from 'react'
import { Link } from "react-router-dom"


const ContactInfoSection = () => {
  return (
    
    <section className="callto-action">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-9 m-auto text-center">
                        <div className="sec-heading">
                            <span className="tagline text-white">Contact Info</span>
                            <h3 className="sec-title">Have questions? Get in touch. </h3>

                            <Link to="/contact-us">

                                <span className="tagline text-white mt-5">Talk to us directly <i className="fa fa-angle-double-right" aria-hidden="true"></i></span>
                            </Link>


                        </div>
                    </div>
                </div>
                {/* <div className="row">
    
                    <div className="col-md-3 contactInfo">
                        <h5>Mediterranean base</h5>
                        <p><span className="circle small"><FontAwesomeIcon icon={faPhone} /></span> + 385 91 555 555</p>
                        <p><span className="circle small"><FontAwesomeIcon icon={faEnvelope} /></span> <a href="mailto:mediterranean@office.com">mediterranean@office.com</a></p>
                    </div>
    
                    <div className="col-md-3 contactInfo">
                        <h5>South Pacific base</h5>
                        <p><span className="circle small"><FontAwesomeIcon icon={faPhone} /></span> + 021 1 555 555</p>
                        <p><span className="circle small"><FontAwesomeIcon icon={faEnvelope} /></span> <a href="mailto:southpacific@office.com">southpacific@office.com</a></p>
                    </div>
    
                    <div className="col-md-3 contactInfo">
                        <h5>Caribbean base</h5>
                        <p><span className="circle small"><FontAwesomeIcon icon={faPhone} /></span> + 33 44 555 555</p>
                        <p><span className="circle small"><FontAwesomeIcon icon={faEnvelope} /></span> <a href="mailto:caribbean@office.com">caribbean@office.com</a></p>
                    </div>
    
                    <div className="col-md-3 contactInfo">
                        <h5>USA base</h5>
                        <p><span className="circle small"><FontAwesomeIcon icon={faPhone} /></span> + 1 555 555 555</p>
                        <p><span className="circle small"><FontAwesomeIcon icon={faEnvelope} /></span> <a href="mailto:unitedstates@office.com">unitedstates@office.com</a></p>
                    </div>
                </div> */}
            </div>
        </section>
  )
}

export default ContactInfoSection