import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentPHEDCOrder } from '../../../utils/electricityTypes/electricityTypes'


export interface PHEDCState {
    currentPHEDCOrder?: ICurrentPHEDCOrder,
    loading: boolean
}

const initialState: PHEDCState = {
    currentPHEDCOrder: undefined,
    loading: false,
}


const phedcSlice = createSlice({
    name: 'phedc',
    initialState,
    reducers: {
      addPHEDCOrder: (state, action) => {
        state.currentPHEDCOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('phedc') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('phedc') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addPHEDCOrder } = phedcSlice.actions

export default phedcSlice.reducer
