import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom" 
import { addCableTVHistory } from '../../redux/slice/transHistorySlice/cableTvHistory'
import { fetchAllCabletvHistory,  } from '../../utils/transHistory/fetchTransHistory'
import { IHistoryResponse } from '../../utils/transHistory/historyTypes'
import { useAppDispatch, useAppSelector } from '../../utils/TypeScript'
import Spinner from '../Alert/Spinner/Spinner'
import CableTVHistoryCardComp from './CableTVHistoryCard'

const CableTVHistoryPageComp = () => {

    const [loading, setLoading] = useState(false)

    const { currentUser } = useAppSelector(state => state.auth)
    const { currentCableTVHistory } = useAppSelector(state => state.cableTVHistory)
    const dispatch = useAppDispatch()

    const userSignUpDate = moment(currentUser?.metadata?.creationTime).format('DD/MM/YYYY').toString()


    
    const getCabletvTransactionsHistory = async () =>{

        if(!currentCableTVHistory){
            setLoading(true)
    
            const cableTvhistory = await fetchAllCabletvHistory(currentUser?.email, userSignUpDate) 
            
            dispatch(addCableTVHistory(cableTvhistory))
        
            setLoading(false)

        } else {
            return
        }
        
    }

    useEffect(() => {
        getCabletvTransactionsHistory()
    }, [currentUser])


  return (
    
            <div className="col-lg-9">
                <h2 className="admin-heading bg-offwhite">All CableTV Transactions</h2>
                <div className="profile-content">
                   
                    <div className="transaction-title bg-offwhite">
                            <div className="items">
                                <div className="row">
                                    <div className="col"><span className="">Date</span></div>
                                    <div className="col">Description</div>
                                    <div className="col text-center">SmartCard</div>
                                    <div className="col text-center">Status</div>
                                    <div className="col">Amount</div>
                                </div>
                            </div>
                        </div>

                        <div className="transaction-area">

                            {
                                currentCableTVHistory && currentCableTVHistory?.length < 1 ? 
                                    <div className="items">


                                        <div className="roww">
                                            


                                            <div className="text-center">
                                                <h5 className="text-center">No history to show.</h5>
                                            
                                            </div>
                                        </div>
                                

                                    </div>
                                :


                                    
                                        currentCableTVHistory?.map(( obj: IHistoryResponse, i )=>{
                                            return <CableTVHistoryCardComp hist={obj} key={i}/>
                                        })
                                    
                            }


                        </div>

                </div>
                { loading && <Spinner /> }

            </div>
  )
}

export default CableTVHistoryPageComp