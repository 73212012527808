import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentSpectranetDataOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface SpectranetDataState {
    currentSpectranetDataOrder?: ICurrentSpectranetDataOrder,
    loading: boolean
}

const initialState: SpectranetDataState = {
    currentSpectranetDataOrder: undefined,
    loading: false,
}


const spectranetDataSlice = createSlice({
    name: 'spectranetdata',
    initialState,
    reducers: {
      addSpectranetDataOrder: (state, action) => {
        state.currentSpectranetDataOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('spectranetdata') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('spectranetdata') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addSpectranetDataOrder } = spectranetDataSlice.actions

export default spectranetDataSlice.reducer
