import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentNineMobileDataOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface NineMobileDataState {
    currentNineMobileDataOrder?: ICurrentNineMobileDataOrder,
    loading: boolean
}

const initialState: NineMobileDataState = {
    currentNineMobileDataOrder: undefined,
    loading: false,
}


const nineMobileDataSlice = createSlice({
    name: 'ninemobiledata',
    initialState,
    reducers: {
      addNineMobileDataOrder: (state, action) => {
        state.currentNineMobileDataOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('ninemobiledata') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('ninemobiledata') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addNineMobileDataOrder } = nineMobileDataSlice.actions

export default nineMobileDataSlice.reducer
