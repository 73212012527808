import React from 'react'

const Banner = () => {
  return (
    
    <section className="banner py-0">
        <div className=" owl-carousel hero-slider" >


            {/* <div className="item" style={{backgroundImage: "url('images/slider/s1.jpg')"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <div className="banner-content">
                                <h2>Online Mobile Recharge</h2>
                                <p>Can'their wherein own upon female without saying made in second the male Own you. Him fill good seasons hath</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <div className="item" style={{backgroundImage: "url('images/slider/s2.jpg')"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <div className="banner-content">
                                <h2>Utility bill payments</h2>
                                <p>Can'their wherein own upon female without saying made in second the male Own you. Him fill good seasons hath</p>
                            </div>
                        </div>
                        style={{
                    animation: "slideInFromTop 1s ease-in"
                }}
                    </div>
                </div>
            </div> */}


            <div className="item" style={{backgroundImage: "url('images/slider/s3.jpg')"}}>
                <div className="container " >
                    <div className="row" style={{display: "flex", animation: "slideInFromLeft 2s ease-in"}}>
                        <div className="col-lg-7 col-md-7 text-center" style={{ margin: "auto"}}>
                            <div className="banner-content">
                                <h2 className='font-weight-bold'>Online Bills Payments</h2>
                                <p>PreponeBills is a one-stop-online shop for bills payment services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Banner