import { createSlice } from '@reduxjs/toolkit'
import { ICurrentOrderGeneral } from '../../../utils/TypeScript'



export interface DataPhotoState {
    currentDataPhoto?: ICurrentOrderGeneral,
   
}

const initialState: DataPhotoState= {
    currentDataPhoto: undefined,
  
}


const dataPhotoSlice = createSlice({
    name: 'dataphoto',
    initialState,
    reducers: {
      addDataPhoto: (state, action) => {
        state.currentDataPhoto = action.payload
      }
    },
})


export const { addDataPhoto } = dataPhotoSlice.actions

export default dataPhotoSlice.reducer
