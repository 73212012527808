import { toast } from "react-toastify"
import { postToAPI } from "./FetchData"
import { ICurrentFIRSOrder, IProduct } from "./TypeScript"


import axios from "axios";



export const getProductsByCategoriesIdAndBillerId = async ( categoriesId: number, billerId: number ) => {
    try {
        const payload = { "size":"100", "page": "0" }
       
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/all-products-bycategoriesandbillerid`, 
        {
            url: `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
            publicKey: `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
            categoryId: `${categoriesId}`,
            billerId: `${billerId}`,
            payload 
        },
        {   headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        const { data } = res


        return (data)        
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}


// export const getProductsByCategoriesIdAndBillerIdd = async ( categoriesId: number, billerId: number ) => {
//     try {
//         const payload = { "size":"100", "page": "0" }
       
//         const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/all-products-bycategoriesandbillerid`, 
//         {
//             'url': `${process.env.REACT_APP_BASE_API_TEST_URL}`,
//             'publicKey': `${process.env.REACT_APP_PREPONE_BILLS_TEST_PUBLIC_KEY}`,
//             "categoryId": `${categoriesId}`,
//             "billerId": `${billerId}`,
//             payload
//         },
//         {   headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
//         )

//         const { data } = res

//         // console.log(data.data.productDTOList);

//         // const result = data.filter((product: IProduct ) => {
//         //     const productfieldsArray = (product.productfields)
//         //     const [ productfield ] = productfieldsArray


//         //     if(productfield){

//         //         return productfield.productName === biller
//         //     } else {
//         //         return null
//         //     }
            
//         // })

//         // const billerDetails = result[0].productfields

//         // const [ currentBiller] = billerDetails

//          return (data)
       
        
     
//     } catch (err: any) {
//         return toast.error(err.message)
//     }

// }


export const getBiller = async ( biller: string) => {
    try {
        const res = await postToAPI('billersandproductandfields')

        const { data } = res


        const result = data.filter((product: IProduct ) => {
            const productfieldsArray = (product.productfields)
            const [ productfield ] = productfieldsArray


            if(productfield){

                return productfield.productName === biller
            } else {
                return null
            }
            
        })

        const billerDetails = result[0].productfields

        const [ currentBiller] = billerDetails

        return currentBiller
       
        
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}


// export const getBillerData = async ( biller: string) => {
//     try {
//         const res = await postToAPI('billersandproductandfields')

//         const { data } = res


//         const result = data.filter((product: IProduct ) => {
//             const productfieldsArray = (product.productfields)
//             const [ productfield ] = productfieldsArray


//             if(productfield){

//                 return productfield.productName === biller
//             } else {
//                 return null
//             }
            
//         })


//         const billerDetails = result[0].productfields

//         const [ currentBiller] = billerDetails

//         return currentBiller
       
        
     
//     } catch (err: any) {
//         return toast.error(err.message)
//     }

// }


export const fetchElectricityBiller = async ( billerCode: string ) => {
    try {
        const res = await postToAPI('billersandproductandfields')

        const { data } = res
        console.log(data)

       


        // const result = data.filter((product: IProduct ) => {
        //     const productfieldsArray = (product.productfields)
        //     const [ productfield ] = productfieldsArray


        //     if(productfield){

        //         return productfield.productName === biller
        //     } else {
        //         return null
        //     }
            
        // })

        const result = data.filter((product: IProduct )=>{
            return product.billerCode === billerCode

        })

        const product = result[0].productfields

        const [productDetails] = product

        console.log(productDetails)
        return productDetails
       
        
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}


export const fetchCableTVBiller = async ( billerCode: string | undefined ) => {
    try {
        const res = await postToAPI('billersandproductandfields')

        const { data } = res

        // console.log({msg: data})


        const result = data.filter((product: IProduct )=>{
            return product.billerCode === billerCode

        })

        const product = result[0].productfields

        const [productDetails] = product

        return productDetails
       
        
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}


export const fetchAirtimeBiller = async ( billerCode: string | undefined ) => {
    try {
        const res = await postToAPI('billersandproductandfields')

        const { data } = res
        console.log(data)


        const result = data.filter((product: IProduct )=>{
            return product.billerCode === billerCode

        })

        const product = result[0].productfields

        const [productDetails] = product

        return productDetails
       
        
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}

export const fetchDataBiller = async ( billerCode: string) => {
    try {
        const res = await postToAPI('billersandproductandfields')

        const { data } = res

        const result = data.filter((product: IProduct )=>{
            return product.billerCode === billerCode

        })

        const product = result[0].productfields

        const [productDetails] = product

        return productDetails
       
        
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}




// Flutterwave

export const initiateFlutterwavePayment = async ( payload: Object ) => {
    try {
       
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/initiate-payment`, 
            {
                token: `${process.env.REACT_APP_FLUTTERWAVE_LIVE_SECRET_KEY}`,
                ...payload,
            },
        {   headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        const { data } = res

        return (data)        
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}


export const verifyFlutterwavePayment = async ( payload: Object ) => {
    try {
       
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/verify-transaction`, 
            {
                token: `${process.env.REACT_APP_FLUTTERWAVE_LIVE_SECRET_KEY}`,
                ...payload,
            },
        {   headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        const { data } = res

        return (data)        
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}


export const getBillerInfo = async ( code: string, payload: Object ) => {
    try {
       
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/billers/${code}`, 
            {
                token: `${process.env.REACT_APP_FLUTTERWAVE_LIVE_SECRET_KEY}`,
                ...payload,
            },
        {   headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        const { data } = res


        return (data)        
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}

export const getBillerItem = async ( code: string ) => {
    try {
       
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/bill-items/${code}`, 
            {
                token: `${process.env.REACT_APP_FLUTTERWAVE_LIVE_SECRET_KEY}`,
            },
        {   headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        const { data } = res


        return (data)        
     
    } catch (err: any) {
        return toast.error(err.message)
    }

}

export const postFLWServicesTransaction = async ( payload: ICurrentFIRSOrder | undefined  ) => {
    try { 
        
        console.log("called")
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/purchase-service`, 
            {
                token: `${process.env.REACT_APP_FLUTTERWAVE_LIVE_SECRET_KEY}`,
                ...payload,
            },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        const { data } = res


        return (data) 
     
    } catch (err: any) {
        if (err.response) {

            // console.error('Error response:', err.response);
            return err.response.data || err.response.statusText;
          } else if (err.request) {

            // console.error('No response:', err.request);
            return 'No response received from the server.';
          } else {

            // console.error('Error message:', err.message);
            return err.message;
          }
    }

}


