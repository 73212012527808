import React from 'react'
// import {  useAppSelector } from '../../utils/TypeScript'

import VisaCard from "../assets/images/visa.png"

const UpdateCard = () => {

    // const { currentUser } = useAppSelector(state => state.auth)



  return (
    <div className="edit-content">
        <form id="update-card" method="post">
            <div className="form-group">
                <label htmlFor="edircardNumber">Your Card Number</label>
                <div className="input-group">
                    <div className="input-group-prepend"><span className="input-group-text"><img className="ml-auto" src={VisaCard} alt="visa" title="" /></span></div>
                    <input type="text" className="form-control" data-card-form="edircardNumber" id="edircardNumber" disabled value="XXXXXXXXXXXX4623" placeholder="Card Number" />
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="edit-card-holder-name">Card Holder Name</label>
                <input type="text" className="form-control" data-card-form="edit-card-holder-name" id="edit-card-holder-name" required value="User's Name" placeholder="Card Holder Name" />
            </div>

            <div className="form-row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="edit-expire-name">Expiry Date</label>
                        <input id="edit-expire-name" type="text" className="form-control" data-card-form="edit-expire-name" required value="05/25" placeholder="MM/YY" />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="edit-cvv-number">CVV Number <span className="text-info ml-1" data-toggle="tooltip" data-original-title="For this youre credit the three-digit CVV number is printed on the signature panel on the back of the card immediately after the card's account number."><i className="fas fa-question-circle"></i></span></label>
                        <input id="edit-cvv-number" type="password" className="form-control" data-card-form="edit-cvv-number" required value="321" placeholder="CVV (3 digits)" />
                    </div>
                </div>                                      
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Security question</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Security question" />
                    </div>
                </div>
                    
            </div>
            
            <button className="btn btn-default d-block">
                <span>Update Card</span>
            </button>
        </form>
    </div>
  )
}

export default UpdateCard