import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { IHistoryDates, IHistoryResponse } from '../../utils/transHistory/historyTypes'


const CableTVHistoryCardComp = (props: {hist: IHistoryResponse}) => {

    const transDetailsArray = props?.hist?.transDetails?.split('|')
    
    
    const transactionDate = moment(props?.hist.transDate).format().split('T')[0];

    const totalAmount = Number(props?.hist?.transactionAmount) + 100



  return (
    <div className="items">

        <Link to={`/cabletv/${props.hist.transRef}`} >  
            <div className="row">
                <div className="col pay-date">
                    <span className="date">{transactionDate}</span>
                    {/* <span className="pay-month">Jan</span> */}
                </div>


                <div className="col">
                    
                    <span className="name">{props.hist.product}</span>
                </div>
                 


                <div className="col">
                    <span className="payment-amaount">
                        {
                            transDetailsArray ? `${transDetailsArray[0]}` : ""
                        }
                    </span>
                </div>


                <div className="col text-center">
                    <span className={props?.hist.statusDescription === "SUCCESSFUL" ? "payments-status text-success" : "payments-status text-danger"} >
                        <i className={props.hist.statusDescription === "SUCCESSFUL" ? "fas fa-check-circle" : "fas fa-times-circle"} data-toggle="tooltip" data-original-title="In Progress"></i>
                    </span>
                </div>


                


                <div className="col">
                    <span className="payment-amaount">₦ 
                        {
                            totalAmount
                        }
                    </span>
                    {/* <span className="currency">(₦)</span> */}
                </div>
            </div>
        </Link>

    </div>
  )
}

export default CableTVHistoryCardComp