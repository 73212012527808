import { Input } from "../../components/bulkAirtime/forms/MTNN";
import { IAirtelOrder, IAirtimeOrder, IBulkAirtimeOrder, IGloOrder, IMTNOrder, INineMobileOrder, INTELOrder } from "../AirtimeDateTypeScript/airtimeDataTypes"
import { validateEmail } from "../Validation";

export const validateMultipleAirtimeDetails = (inputs: Input[]) => {
  // const { phoneNumbers, amount } = inputs

  const errors: any[] = [];

  inputs.forEach((input, i) =>{
    if(!input.phoneNumber){
      errors.push(`Please enter phone number ${i+1}`)
    } else if(input.phoneNumber.length < 11){
      errors.push(`Please enter a valid phone number ${i+1}`)
    } else if(input.phoneNumber.length > 11){
      errors.push(`Please enter a valid phone number ${i+1}`)
    }

    if(!input.amount){
      errors.push(`Please enter amount ${i+1}`)
    }else if( Number(input.amount) < 100 ){
      errors.push("Minimum amount should be ₦100.")
    }else if( Number(input.amount) > 10000 ){
      errors.push("Maximum amount per transaction should be ₦10, 000.")
    }

  })
  

  return {
    errMsg: errors,
    errLength: errors.length
  }


}

export const validateBulkAirtimeDetails = (nineMobileOrderDetails: IBulkAirtimeOrder) => {
  const { phoneNumbers, amount } = nineMobileOrderDetails

  const errors = [];

  if(!amount){
    errors.push("Please enter amount.")
  }else if( Number(amount) < 100 ){
    errors.push("Minimum amount should be ₦100.")
  }else if( Number(amount) > 10000 ){
    errors.push("Maximum amount per transaction should be ₦10, 000.")
  }

  // if(!email){
  //   errors.push("Please enter your email.")
  // }else if( !validateEmail(email)){
  //   errors.push("Email format is incorrect.")
  // }

  if(!phoneNumbers){
      errors.push("Please enter phone numbers.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }


}


export const validateAirtimeDetails = (nineMobileOrderDetails: IAirtimeOrder) => {
  const { phoneNumber, amount } = nineMobileOrderDetails

  const errors = [];

  if(!amount){
    errors.push("Please enter amount.")
  }else if( Number(amount) < 100 ){
    errors.push("Minimum amount should be ₦100.")
  }else if( Number(amount) > 10000 ){
    errors.push("Maximum amount per transaction should be ₦10, 000.")
  }

  // if(!email){
  //   errors.push("Please enter your email.")
  // }else if( !validateEmail(email)){
  //   errors.push("Email format is incorrect.")
  // }

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }


}


export const validateNineMobileForm = (nineMobileOrderDetails: INineMobileOrder) => {
  const { phoneNumber, amount } = nineMobileOrderDetails

  const errors = [];

  if(!amount){
    errors.push("Please enter amount.")
  }else if( Number(amount) < 100 ){
    errors.push("Minimum amount should be ₦100.")
  }else if( Number(amount) > 10000 ){
    errors.push("Maximum amount per transaction should be ₦10, 000.")
  }

  // if(!email){
  //   errors.push("Please enter your email.")
  // }else if( !validateEmail(email)){
  //   errors.push("Email format is incorrect.")
  // }

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }


}


export const validateAirtelForm = (airtelOrderDetails: IAirtelOrder) => {
  const { phoneNumber, amount } = airtelOrderDetails

  const errors = [];

  if(!amount){
    errors.push("Please enter amount.")
  }else if( Number(amount) < 100 ){
    errors.push("Minimum amount should be ₦100.")
  }else if( Number(amount) > 10000 ){
    errors.push("Maximum amount per transaction should be ₦10, 000.")
  }

  // if(!email){
  //   errors.push("Please enter your email.")
  // }else if( !validateEmail(email)){
  //   errors.push("Email format is incorrect.")
  // }

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }

}


export const validateGloForm = (gloOrderDetails: IGloOrder) => {
  const { phoneNumber, amount } = gloOrderDetails

  const errors = [];

  if(!amount){
    errors.push("Please enter amount.")
  }else if( Number(amount) < 100 ){
    errors.push("Minimum amount should be ₦100.")
  }else if( Number(amount) > 10000 ){
    errors.push("Maximum amount per transaction should be ₦10, 000.")
  }

  // if(!email){
  //   errors.push("Please enter your email.")
  // }else if( !validateEmail(email)){
  //   errors.push("Email format is incorrect.")
  // }

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }

}


export const validateMTNForm = (mtnOrderDetails: IMTNOrder) => {
  const { phoneNumber, amount } = mtnOrderDetails

  const errors = [];

  if(!amount){
    errors.push("Please enter amount.")
  }else if( Number(amount) < 100 ){
    errors.push("Minimum amount should be ₦100.")
  }else if( Number(amount) > 10000 ){
    errors.push("Maximum amount per transaction should be ₦10, 000.")
  }

 

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  // if(!email){
  //   errors.push("Please enter your email.")
  // }else if( !validateEmail(email)){
  //   errors.push("Email format is incorrect.")
  // }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }

}


export const validateNTELForm = (ntelOrderDetails: INTELOrder) => {
  const { phoneNumber, amount, email } = ntelOrderDetails

  const errors = [];

  if(!amount){
    errors.push("Please enter amount.")
  }else if( Number(amount) < 100 ){
    errors.push("Minimum amount should be ₦100.")
  }else if( Number(amount) > 10000 ){
    errors.push("Maximum amount per transaction should be ₦10, 000.")
  }

 

  if(!phoneNumber){
      errors.push("Please enter phone number.")
  } else if(phoneNumber.length < 11){
    errors.push("Please enter a valid phone number.")
  } else if(phoneNumber.length > 11){
    errors.push("Please enter a valid phone number.")
  }

  if(!email){
    errors.push("Please enter your email.")
  }else if( !validateEmail(email)){
    errors.push("Email format is incorrect.")
  }
  

  return {
    errMsg: errors,
    errLength: errors.length
  }

}