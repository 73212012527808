import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentEKEDCOrderPaymentOrder } from '../../../utils/electricityTypes/electricityTypes'


export interface IEPrepaidState {
    currentIEPrepaidOrder?: ICurrentEKEDCOrderPaymentOrder,
    loading: boolean
}

const initialState: IEPrepaidState = {
    currentIEPrepaidOrder: undefined,
    loading: false,
}


const ieprepaidSlice = createSlice({
    name: 'ieprepaid',
    initialState,
    reducers: {
      addIEPrepaidOrder: (state, action) => {
        state.currentIEPrepaidOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('ieprepaid') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('ieprepaid') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addIEPrepaidOrder } = ieprepaidSlice.actions

export default ieprepaidSlice.reducer
