import axios from "axios";
import { toast } from "react-toastify";
import { ICurrentAbujaPostpaidOrder, ICurrentEKEDCOrderPaymentOrder, ICurrentEKEDCPostpaidOrder, ICurrentEKEDCPrepaidOrder, ICurrentKanoPostpaidCOrder, ICurrentPHEDCOrder } from "../../electricityTypes/electricityTypes";
import { IElectricityResponseData } from "../../TypeScript";



export const postElectricityTransaction = async ( payload: Object  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transactions`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
          'publicKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PRIVATE_KEY}`,
          endpoint: "electricity",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }

}




export const postAbujaPostpaidTransaction = async ( payload: ICurrentAbujaPostpaidOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }

}


export const postAbujaPrepaidpaidTransaction = async ( payload: ICurrentAbujaPostpaidOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postEKEDCOrderPaymentTransaction = async ( payload: ICurrentEKEDCOrderPaymentOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postEKEDCPostpaidTransaction = async ( payload: ICurrentEKEDCOrderPaymentOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postEKEDCPrepaidTransaction = async ( payload: ICurrentEKEDCPrepaidOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postIBEDCTransaction = async ( payload: ICurrentEKEDCPostpaidOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postIEDCPostpaidTransaction = async ( payload: ICurrentEKEDCOrderPaymentOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postIEDCPrepaidTransaction = async ( payload: ICurrentEKEDCOrderPaymentOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postJosPostpaidTransaction = async ( payload: ICurrentEKEDCPrepaidOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postJosPrepaidTransaction = async ( payload: ICurrentEKEDCPrepaidOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postKadunaPostpaidTransaction = async ( payload: ICurrentEKEDCPrepaidOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postKadunaPrepaidTransaction = async ( payload: ICurrentEKEDCPrepaidOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postKanoPostpaidTransaction = async ( payload: ICurrentKanoPostpaidCOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postKanoPrepaidTransaction = async ( payload: ICurrentKanoPostpaidCOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postPHEDCTransaction = async ( payload: ICurrentPHEDCOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const sendElectricityTransEmail = async ( userEmail: string , product: string , payload: IElectricityResponseData ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/sendmail`, 
        {
        //   'url': `${process.env.REACT_APP_BASE_API_URL}`,
        //   'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          user: `${process.env.REACT_APP_PREPONE_BILLS_EMAIL}`,
          pass: `${process.env.REACT_APP_PREPONE_BILLS_EMAIL_APP_PASSWORD}`,

          responseMessage: payload?.responseMessage,
          userEmail,
          token: payload?.data.token,

          accountNumber: payload?.data.accountNumber,
          amount: payload?.data.amount,
          unit: payload?.data.unit,

          requestId: payload?.requestId,
          referenceId: payload?.referenceId,
          product
         
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        return res.data;

     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}



export const sendElecTransEmail = async ( userEmail: string , product: string ) => {
    try {
        console.log("1");
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/sendmail`, 
        {
        //   'url': `${process.env.REACT_APP_BASE_API_URL}`,
        //   'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          user: `${process.env.REACT_APP_PREPONE_BILLS_EMAIL}`,
          pass: `${process.env.REACT_APP_PREPONE_BILLS_EMAIL_APP_PASSWORD}`,

        //   responseMessage: payload?.responseMessage,
          userEmail,
        //   token: payload?.data.token,

        //   accountNumber: payload?.data.accountNumber,
        //   amount: payload?.data.amount,
        //   unit: payload?.data.unit,

        //   requestId: payload?.requestId,
        //   referenceId: payload?.referenceId,
          product
         
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        console.log(res.data);

        
        return res.data;

     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}