import React, { useEffect } from 'react'
import { useAppSelector } from '../utils/TypeScript'

import CallToAction from '../components/callToAction/CallToAction'
import DashboardComp from '../components/Dashboard/DashboardComp'
import AsideBar from '../components/global/AsideBar/AsideBar'
import ProfileBar from '../components/global/ProfileBar/ProfileBar'
import Toast from '../components/Alert/Toast'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const DashboardPage = () => {

  const { currentUser } = useAppSelector(state => state.auth)
  const navigate = useNavigate()


  useEffect(() => {
    if(!currentUser){
      toast.error(() => <Toast title="Error"
        body="Please Login!" />)
       return navigate("/login");
    }
  }, [ navigate, currentUser])


  return (
    <div>
        <ProfileBar />

        <div id="content" className="py-4">
            <div className="container">
                <div className="row">


                  {
                    currentUser

                    &&
                    
                    <DashboardComp />
                  }


                  {
                    currentUser

                    &&

                    <AsideBar />
                  }
                  

                </div>
            </div>
        </div>

        <CallToAction />
    </div>
  )
}

export default DashboardPage