import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addJambOrder } from '../../redux/slice/educationSlice/jambSlice'
import { addWAECPINOrder } from '../../redux/slice/educationSlice/waecPINSlice'
import { profileUpdate } from '../../redux/slice/profileSlice/profileSlice'
import {  getProductsByCategoriesIdAndBillerId } from '../../utils/billers'
import { lookupJAMB, lookupWAEC } from '../../utils/lookup/lookupTrans'
import { useAppSelector, useAppDispatch, ICableTVPackages, IJambPackages, ICurrentJambOrder, ICurrentWAECPINOrder,} from "../../utils/TypeScript"
import { validateJambOrder, validateWAECPINDetails, validateWAECREGDetails } from '../../utils/validateEducation/validateEducation'
import Spinner from '../Alert/Spinner/Spinner'
import Toast from '../Alert/Toast'


const WAECFormComp = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()


    const [loading, setLoading] = useState(false)
    const [noOfCandidates, setNoOfCandidates] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [packageName, setPackageName] = useState("")
    const [amount, setAmount] = useState("")

    const [packages, setPackages] = useState([])
    const [uniqueCode, setUniqueCode] = useState("")
    const [waecpin, setWaecpin] = useState(false)


    const { profile } = useAppSelector(state => state.profile)
    const { currentUser } = useAppSelector(state => state.auth)
  


    const handleSubmit = async (e: FormEvent) => {

        uniqueCode === "WAEC_94533" ? submitWAECPINForm(e) : submitWAECRegistrationForm(e)
        
    }

    const submitWAECRegistrationForm = async (e: FormEvent) =>{
        e.preventDefault()
        setLoading(true)


        const waecRegDetails = { uniqueCode, noOfCandidates, email, phoneNumber }
        
        const result = validateWAECREGDetails(waecRegDetails)

        if(result.errLength){

           toast.error(() => <Toast title="Error"
            body={result.errMsg} />)

            setLoading(false)
            return 
        }

        let transactionId = "PreponeBills-" + Math.floor(Math.random() * 1000000);


        const waecREGOrder = {
            requestId: transactionId,
            uniqueCode,
            details: {
                noOfCandidate: Number(noOfCandidates),                
                amount
            }
        }
        const lookupResult = await lookupWAEC(waecREGOrder)
        const { data, responseMessage } = lookupResult
        console.log(waecREGOrder);


        if(responseMessage.toLowerCase() === "successful"){ }

            const waecPINOrder: ICurrentWAECPINOrder = {
                requestId: transactionId,
                uniqueCode,
                details: {
                    email,
                    phoneNumber,
                    packageName,
                    amount: data.totalAmount,
                    noOfCandidate: Number(noOfCandidates),
                },
            }
    
            dispatch( addWAECPINOrder(waecPINOrder))
            localStorage.setItem("waecPINOrder", JSON.stringify(waecPINOrder));
            navigate("/education-summary") 
            return setLoading(false)
        

        // setLoading(false)
        
    }




    const submitWAECPINForm = async (e: FormEvent) =>{
        e.preventDefault()
        setLoading(true)

        const waecPINDetails = { uniqueCode, email, phoneNumber }
        
        const result = validateWAECPINDetails(waecPINDetails)

        if(result.errLength){

           toast.error(() => <Toast title="Error"
            body={result.errMsg} />)

            setLoading(false)
            return 
        }

        let transactionId = "PreponeBills-" + Math.floor(Math.random() * 1000000);


        const waecPINOrder: ICurrentWAECPINOrder = {
            requestId: transactionId,
            uniqueCode,
            details: {
                email,
                phoneNumber,
                packageName,
                amount,
                noOfCandidate: 0
            }
        }
        // console.log(waecPINOrder);
        dispatch( addWAECPINOrder(waecPINOrder))
        localStorage.setItem("waecPINOrder", JSON.stringify(waecPINOrder));
        navigate("/education-summary") 
        setLoading(false)

    }



    const fetchBiller = async () => {

        if(packages.length < 1){

            setLoading(true)
            const JambServices = await getProductsByCategoriesIdAndBillerId( 6, 25)

            // console.log(JambServices.data.productDTOList)
            setPackages(JambServices.data.productDTOList) 
            setLoading(false)
        } else {
            return
        }

    }

    const setPackageAmount = ()=>{
       if(!uniqueCode){
           return
       } else {
            const selectedJambPackage = packages.filter(( paackage : IJambPackages)=>{
                return paackage.uniqueCode === uniqueCode
            })

            setAmount(selectedJambPackage[0]['amount']);
            setPackageName(selectedJambPackage[0]['name']);
            // console.log(selectPackage)


       }
        
    }


    useEffect( ()=>{
         fetchBiller()
    }, [])

    useEffect( ()=>{
         setPackageAmount()
    }, [uniqueCode])

    useEffect( ()=>{
         uniqueCode === "WAEC_94533" ? setWaecpin(true) : setWaecpin(false)
    }, [uniqueCode])


    // useEffect( ()=>{
    //     if(!profile) return;
    //     setEmail(profile.userEmail)
    //     setSmartCardNumber(profile.smartCardNumber)
    // }, [profile])



  return (
      <>
    <form onSubmit={handleSubmit} className="mx-3 mt-4">

        <span className='text-danger' style={{fontSize: "0.9rem"}}>
            {
            currentUser ? "" : "Login to remember details next time."
            }
        
        </span>
        <div className="form-row">

            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlInput04">Select WAEC Service<span className='text-danger'>*</span></label>

                <select className="custom-select form-control" id="operator" name="selectPackage" value={uniqueCode} onChange={e => setUniqueCode(e.target.value)}>
                    <option value="">Select WAEC Service</option>
                    { packages.length ?
                       ( packages.map((cablePackage: IJambPackages, index) =>
                            <option  key={index} value={cablePackage.uniqueCode}>{cablePackage.name}</option>  
                        )) : ""
                    }
                </select>
            </div>
            {
                waecpin ? 

                <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Email Address <span className='text-danger'>*</span></label>
                <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
                </div>
                

                :


                <div className="col-md-6 col-lg-6 form-group">
                    <label htmlFor="exampleFormControlSelect1" className='fs-10'>Number of Candidates<span className='text-danger'>*</span></label>
                    <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter Number of Candidates" name="noOfCandidates" value={noOfCandidates} onChange={e => setNoOfCandidates(e.target.value)}/>
                </div>

            }    



        </div>


        <div className="form-row">

            <div className="col-md-6 col-lg-6">
                
                <label htmlFor="exampleFormControlSelect "> <span className='text-danger'></span></label>
                
                <div className="form-group input-group pt-2">
                    <div className="input-group-prepend "> <span className="input-group-text px-3 text-white" style={{backgroundColor: "#BC61F5"}}>(Amount) ₦</span> 
                    </div>
                    <input className="form-control text-center" id="amount" placeholder="Amount"  type="number" name='amount' value={amount} readOnly/>
                    
                </div>
            </div>

            <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="exampleFormControlSelect1">Phone Number<span className='text-danger'>*</span></label>
                <input type="number" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter Phone Number" name="email" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}/>
            </div>
        </div>



        <div className="form-group">

            {
                waecpin ?
                ""
                :


                <div className="col-md-6 col-lg-6 form-group">
                    <label htmlFor="exampleFormControlSelect1">Email Address <span className='text-danger'>*</span></label>
                    <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
                </div>
            }


            {/* <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Customer Full Name <span className='text-danger'></span></label>
                <input type="text" className="form-control shadow form-control-lg smartCardNumber" id="smartCardNumber"  placeholder="Enter full name" name="customerDetails" value={customerDetails} onChange={e => setCustomerDetails(e.target.value)} />
            </div> */}

           
        </div>



        <ul className="pager my-5" style={{width: "100%"}}>
            <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
        </ul>



    </form>
    { loading && <Spinner /> }

    </>
  )
}

export default WAECFormComp