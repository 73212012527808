import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentAbujaPostpaidOrder } from '../../../utils/electricityTypes/electricityTypes'


export interface AbujaState {
    currentAbujaElectOrder?: ICurrentAbujaPostpaidOrder,
    loading: boolean
}

const initialState: AbujaState = {
    currentAbujaElectOrder: undefined,
    loading: false,
}


const abujaElectSlice = createSlice({
    name: 'abujaElect',
    initialState,
    reducers: {
      addAbujaElectOrder: (state, action) => {
        state.currentAbujaElectOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('abujaelect') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('abujaelect') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addAbujaElectOrder } = abujaElectSlice.actions

export default abujaElectSlice.reducer
