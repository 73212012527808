import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { IHistoryResponse } from '../../utils/transHistory/historyTypes'
import { useAppSelector } from '../../utils/TypeScript'

const TransactionsDetailsComp = (props: {dataHistory: IHistoryResponse | undefined}) => { 

    const { currentDataHistory } = useAppSelector(state => state.dataHistory)

    // const [singelDataHistory, setSingleDataHistory] = useState({})

    console.log(props?.dataHistory)

    const transDetailsArray = props?.dataHistory?.transDetails.split('|')
    const details = props? transDetailsArray : ["", "", "", "", "", "", "","", "", "", "", "", "", ""]





  return (
    <div className="col-lg-9">
        <div className="profile-content">
            <h3 className="admin-heading bg-offwhite">
                <p>Data Transaction Details</p>
                {/* <span>Details Activity</span> */}
            </h3>
            <div className="order-summery transaction-summery">
                <div className="order-details-area table">
                    <div className="table-head">
                        <div className="modal_header">
                            <span className={props?.dataHistory?.statusDescription === "SUCCESSFUL" ? "status bg-success" : "status bg-danger" }>
                                
                                {
                                    props?.dataHistory?.statusDescription === "SUCCESSFUL" ? "Paid" : "Failed"
                                }
                            </span>
                            <div className="row">
                                <div className="col">
                                    <div className="content-area">
                                        <b>Payment sent to</b>
                                        <i className="fas fa-building ml-3 mr-1"></i>
                                         PreponeBills Ltd
                                        <p>{props?.dataHistory?.transDate}</p>
                                        <p> Transaction ID: 3SN08103GH522126652E</p>
                                        <p>Payment Status: COMPLETED</p>
                                        <p>Payment Type: Checkout</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="content-area text-right">
                                        <div className="price">
                                            <b>Amount</b>
                                            <p>₦
                                            {
                                                props?.dataHistory?.billerCode === '9mobiledata1' ? `${ details? details[4] : ""}`
                                                : 
                                                props?.dataHistory?.billerCode === 'airtel-data' ? `${ details? details[3] : ""}`
                                                : 
                                                props?.dataHistory?.billerCode === 'glo-data' ? `${ details? details[3] : ""}`
                                                : 
                                                props?.dataHistory?.billerCode === 'data' ? `${ details? details[3] : ""}`
                                                :
                                                props?.dataHistory?.billerCode === 'NTELBundle' ? `${ details? details[3] : ""}`
                                                :
                                                props?.dataHistory?.billerCode === 'SMILE' ? `${ details? details[3] : ""}`
                                                :
                                                props?.dataHistory?.billerCode === 'SPECTRANET' ? `${ details? details[3] : ""}`
                                                :
                                                ""
                                            }
                                            </p>
                                        </div>                                                        
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="table-content bg-offwhite">
                            <b>
                                <div className="row">
                                    <div className="col">Order Details</div>
                                    {/* <div className="col">Quantity</div> */}
                                    <div className="col">Price</div>
                                    <div className="col text-right">Subtotal</div>
                                </div>
                            </b>
                        </div>
                        <div className="table-content">

                            <div className="row">
                                <div className="col">
                                    MTN Recharge</div>
                                {/* <div className="col">01</div> */}
                                <div className="col">₦1450</div>
                                <div className="col text-right">₦2000</div>
                            </div>
                        </div>
                        <div className="table-content">
                            <div className="row">
                                <div className="col">
                                    Glo Recharge</div>
                                {/* <div className="col">01</div> */}
                                <div className="col">₦1450</div>
                                <div className="col text-right">₦2000</div>
                            </div>
                        </div>
                        <div className="table-content">
                            <div className="row">
                                <div className="col">
                                    MTN Recharge</div>
                                {/* <div className="col">01</div> */}
                                <div className="col">₦1450</div>
                                <div className="col text-right">₦2000</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-5 ml-auto">
                                <div className="sub-content">
                                    <br/>
                                    <div className="row">
                                        <div className="col">Purchase Total</div>
                                        <div className="col text-right"> - ₦600</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Sales Tax</div>
                                        <div className="col text-right"> ₦0.00</div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col">Service Charge</div>
                                        <div className="col text-right">₦0.00</div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col">Gross Amount</div>
                                        <div className="col text-right">- ₦600</div>
                                    </div>
                                    
                                    <hr/>
                                    <div className="row">
                                        <div className="col"><b>Net Amount</b></div>
                                        <div className="col text-right">₦6,200</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-footer mt-3 bg-offwhite p-3">
                            <div className="row">
                                <div className="col">
                                    <p><b>Invoice ID: </b>8c8e15c76d4a059xccfe0dad4e5ce5sd</p>
                                    <p className="mb-0"><b>Funding details</b></p>
                                    <p>
                                        Funding Type: Promotional Return
                                        Funding Source: -₦600 - PayPal Account
                                    </p>
                                </div>
                                <div className="col">
                                    <p className="mb-0"><b>Contact info</b></p>
                                    <p>
                                        PreponeBills Ltd
                                        The recipient of this payment has verified their account and is located in Nigeria.
                                        http://preponebills.com/
                                        help@preponebills.com
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="table-footer mt-3 bg-offwhite p-3">
                            <div className="row">
                                <div className="col">
                                    <div className="invoice-option  btn-refund text-center my-4">
                                        <Link to="#" className="invoice-btn btn"><i className="far fa-file-pdf"></i> Save as PDF</Link>
                                        <Link to="#" className="invoice-btn btn"><i className="fas fa-print"></i> Print Receipt</Link>
                                        <Link to="#" className="invoice-btn btn"><i className="far fa-envelope"></i> Email Receipt</Link>
                                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-reply"></i> Action 
                                            <div className="dropdown-menu">
                                                    <Link className="dropdown-item" to="#">Refund</Link>
                                                    <Link className="dropdown-item" to="#">Archive</Link>
                                                </div>
                                        </button>
                                                                                                    
                                    </div>
                                                                                
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TransactionsDetailsComp