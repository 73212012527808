import React, { useState } from 'react'
import { Link } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import PersonalInfoComp from './PersonalInfoComp';
import PersonalAddressComp from './PersonalAddressComp';
import AccountSettingsComp from './AccountSettingsComp';
import ChangePasswordComp from './ChangePasswordComp';
import ProfileMenuOne from './ProfileMenuOne';
import ProfileMenuTwo from './ProfileMenuTwo';


const ProfileComp = () => {

    const [updateProfileModal, setUpdateProfileModal] = useState(false);
    const [profileMenu, setProfileMenu] = useState(true);

    const showUpdateProfileModal = () => {
        setUpdateProfileModal(true);
    };


    const hideUpdateProfileModal = () => {
        setUpdateProfileModal(false);
    };

    const toggleProfileMenuFalse = () => {
        setProfileMenu(false);
    };

    const toggleProfileMenuTrue = () => {
        setProfileMenu(true);
    };
  



  return (
    <div className="col-lg-9 profile-area">
        <h3 className="admin-heading bg-offwhite">
            <Link to="#edit-personal-details" className="btn-link pbtn" data-id="edit-personal-details" onClick={showUpdateProfileModal}>
                <i className="fas fa-edit mr-1"></i>Update
            </Link>
            <p>Personal Profile</p>
            <span>Your Personal information</span>
        </h3>



        {/* Update Profile Modal */}
        <Modal show={updateProfileModal} onHide={hideUpdateProfileModal} size="lg" className=''>
    
            <Modal.Body >
            
                {/* <!-- Edit personal info bg-offwhite  --> */}
                    <div 
                        // id="edit-personal-details" 
                        className="bg-offwhite  update-profile-modal m-3 ">
                        <div className="bg-white shadow p-4 content-edit-area">
                            <div className="edit-header">
                                <h5 className="title" style={{color:"#3f0d5e"}}>Personal Information</h5>
                                <button type="button" className="close" onClick={hideUpdateProfileModal}><span aria-hidden="true">&times;</span></button>
                            </div>

                            <PersonalInfoComp />
                            
                        </div>

                        <PersonalAddressComp />
                        
                        <AccountSettingsComp />

                        <ChangePasswordComp />

                        
                    </div>
                {/* <!-- Edit personal info End --> */}
            </Modal.Body>
        
        </Modal>



    


        {/* <!-- Edit personal info End --> */}
        <div className="infoItems shadow">
            <ul className="nav nav-tabs">
                <li onClick={toggleProfileMenuTrue}><Link data-toggle="tab" to="#" className="active">Personal information</Link></li>

                <li onClick={toggleProfileMenuFalse}><Link data-toggle="tab" to="#">Login and security</Link></li>
            </ul>

            <div className="tab-content">

                {
                    profileMenu

                    ?
                        <ProfileMenuOne />

                    :
                        
                        <ProfileMenuTwo />
                }
            

            </div>


        </div>



    </div>
  )
}

export default ProfileComp