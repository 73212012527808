import { createSlice, } from '@reduxjs/toolkit'
import { ICurrentBulkAirtimeOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface BulkAirtimeState {
    currentBulkAirtimeOrder?: ICurrentBulkAirtimeOrder,
    loading: boolean
}

const initialState: BulkAirtimeState = {
    currentBulkAirtimeOrder: undefined,
    loading: false,
}


const bulkAirtimeSlice = createSlice({
    name: 'airtime',
    initialState,
    reducers: {
      addBulkAirtimeOrder: (state, action) => {
        state.currentBulkAirtimeOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('airtime') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('airtime') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addBulkAirtimeOrder } = bulkAirtimeSlice.actions

export default bulkAirtimeSlice.reducer
