import React from 'react'

const ComingSoonComp = () => {
  return (
    <section className="coming-sec">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 m-auto">
            <div className="sec-heading">
              <h2 className="sec-title">Blog is Coming Soon</h2>
              <p className="sec-subtitle">Our exciting new blog is coming soon, Stay connected...</p>
            </div>
            <div className="text-center" id="clock"></div>
            <form className="bill-form v2 text-center" action="#">
              <h3>Stay connected</h3>
              <div className="form-field">
                <div className="join-field">
                  <input type="text" placeholder="Type a question..." />
                  <button className="btn btn-block btn-filled form-btn"><span className="bh"></span> <span>Search</span></button>
                </div>
              </div>
            </form>
          </div>        
        </div>
      </div>
    </section>
  )
}

export default ComingSoonComp