import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom" 
import { addAirtimeHistory } from '../../redux/slice/transHistorySlice/airtimeHistory'
import { fetchAllAirtimeHistory } from '../../utils/transHistory/fetchTransHistory'
import { IHistoryResponse } from '../../utils/transHistory/historyTypes'
import { useAppDispatch, useAppSelector } from '../../utils/TypeScript'
import Spinner from '../Alert/Spinner/Spinner'
import AirtimeHistoryCardComp from './AirtimeHistoryCard'

const AirtimeHistoryPageComp = () => {

    const [loading, setLoading] = useState(false)

    const { currentUser } = useAppSelector(state => state.auth)
    const { currentAirtimeHistory } = useAppSelector(state => state.airtimeHistory)
    const dispatch = useAppDispatch()

    const userSignUpDate = moment(currentUser?.metadata?.creationTime).format('DD/MM/YYYY').toString()


    
    const getAirtimeTransactionsHistory = async () =>{

        if(!currentAirtimeHistory){
            setLoading(true)
    
            const airtimeHistory = await fetchAllAirtimeHistory(currentUser?.email, userSignUpDate) 
            
            dispatch(addAirtimeHistory(airtimeHistory))
        
            setLoading(false)

        } else {
            return
        }
        
    }


    useEffect(() => {
        getAirtimeTransactionsHistory()
    }, [currentUser])
    
    


  return (
    
            <div className="col-lg-9">
                <h2 className="admin-heading bg-offwhite">All Airtime Transactions</h2>
                <div className="profile-content">
                   
                    <div className="transaction-title bg-offwhite">
                            <div className="items">
                                <div className="row">
                                    <div className="col"><span className="">Date</span></div>
                                    <div className="col">Description</div>
                                    <div className="col text-center">Number</div>
                                    <div className="col text-center">Status</div>
                                    <div className="col">Amount</div>
                                </div>
                            </div>
                        </div>

                        <div className="transaction-area">

                            {
                                currentAirtimeHistory && currentAirtimeHistory?.length < 1 ? 
                                    <div className="items">


                                        <div className="roww">
                                            


                                            <div className="text-center">
                                                <h5 className="text-center">No history to show.</h5>
                                            
                                            </div>
                                        </div> 
                                

                                    </div>
 

                                :
                                    currentAirtimeHistory?.map(( obj: IHistoryResponse, i )=>{
                                        return <AirtimeHistoryCardComp hist={obj} key={i}/>
                                    })
                            }

                        </div>


                </div>
                { loading && <Spinner /> }

            </div>
  )
}

export default AirtimeHistoryPageComp