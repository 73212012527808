import React, { useState, FormEvent} from 'react'
import { Link  } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../utils/TypeScript"
import { authRegister } from "../../redux/slice/authSlice"
import { validateRegisterDetails } from "../../utils/Validation"

import Vector from "../assets/images/vector.png"
import { toast } from 'react-toastify'
import Toast from '../Alert/Toast'
import Spinner from '../Alert/Spinner/Spinner'




const SignupMainComp = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [cf_password, setCfPassword] = useState('')
    const [agreeToTerms, setAgreeToTerms] = useState(false)

    const { loading }  = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()


    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        const user = { name, email, password, cf_password, agreeToTerms }
        const result = validateRegisterDetails(user)

        if(result.errLength){

            return toast.error(() => <Toast title="Error"
            body={result.errMsg} />)
        }

        dispatch(authRegister(user))  
        
    }



    

    



  return (
    <section className="banner login-registration">
        <div className="vector-img">
            <img src={Vector} alt="vector" />
        </div>
        <div className="container" 
        // style={{ animation: "slideInFromLeft 2s ease-in" }}
        >
            <div className="row">
                <div className="col-md-6">
                    <div className="content-box">
                        <h2 className='font-weight-bold'>Create Account</h2>
                        <p>To enjoy the functionalities on PreponeBills you should create an account<br /> your details are secured by PreponeBills.</p>
                    </div>

                    <form action="#" className="sl-form text-dark" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className='text-dark'>Full Name</label>
                            <input type="text" placeholder="First & Last name"
                            name="name" value={name} onChange={e => setName(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label>Enter Email</label>
                            <input type="email" placeholder="example@gmail.com"
                            name="email" value={email} onChange={e => setEmail(e.target.value)}
                             />
                        </div>

                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" placeholder="Password" 
                            name="password" value={password} onChange={e => setPassword(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label> Confirm Password</label>
                            <input type="password" placeholder="Confirm Password"
                            name="cf_password" value={cf_password} onChange={e => setCfPassword(e.target.value)}
                             />
                        </div>

                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" 
                            checked={agreeToTerms}
                            onChange={() => setAgreeToTerms(!agreeToTerms)}
                            />
                            <label className="form-check-label">Agree with <Link to="/terms-conditions" className='' style={{color:"#9417E2"}}>Terms and Conditions</Link></label>
                        </div>

                        <button className="btn btn-filled btn-round">Signup</button>

                        <p className="notice">Already have an account? <Link to="/login" className=''>Login Account</Link></p>
                    </form>
                </div>
            </div>
        </div>
        { loading && <Spinner /> }
    </section>
  )
}

export default SignupMainComp