import React, { useEffect, useState } from 'react'
// import XPay from "npm_xpresspay";

import { Link, useNavigate } from "react-router-dom"
import { useAppSelector } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner';
import axios from "axios";
import { generateTransactionId, LiveBaseURL } from "../../utils/config";

const DataSummaryComp = () => {

    const tranId = localStorage.getItem("tranId") || "";


    const [state, setState] = useState({
        amount: "",
        loading: false,
        transactions: [],
    });

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()


    const { currentDataPhoto } = useAppSelector(state => state.dataPhoto)
    const { currentDataOrder } = useAppSelector(state => state.data)





    const PaymentGateway = async () => {
        let transId = generateTransactionId();

        const totalAmount = currentDataOrder? currentDataOrder?.details?.amount : ""

        const userEmail =  currentDataOrder?.userEmail === ""?  "info@preponebills.com" :   currentDataOrder?.userEmail

        const publicKey =  process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC;



        setState({ ...state, loading: true });
        setLoading(true)
        try {
            
            const response = await axios.post(
              `${LiveBaseURL}/api/Payments/Initialize`,
              {
                amount: `${totalAmount}`,
                transactionId: transId,
                email: `${userEmail}`,
                publicKey: `${process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC}`,
                currency: "NGN",
                mode: "Live",
                callbackUrl: window.location.href,
                metadata: [
                  {
                    name: "sample",
                    value: "test",
                  },
                ],
              },
              {
                headers: {
                  Authorization: `Bearer ${publicKey}`,
                  "Content-Type": "application/json",
                },
              }
            );
      
            if (response?.data?.data?.paymentUrl) {
              setState({ ...state, amount: `${totalAmount}`, loading: false });
              sessionStorage.setItem("tranId", transId);
              sessionStorage.setItem("reference", response?.data?.reference);
              localStorage.setItem("tranId", transId);
      
              window.location.href = response?.data?.data?.paymentUrl;
              setLoading(false);
            } else {
              setState({ ...state, amount: `${totalAmount}`, loading: false });
              window.location.href = response?.data?.data?.paymentUrl;
              setLoading(false);
            }
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
    }

    // navigate("/data-done")

    const VerifyPayment = async () => {    
        setLoading(true);
        const tranId = localStorage.getItem("tranId") || "";
        const publicKey =
          process.env.REACT_APP_PREPONE_BILLS_PAYMENT_GATEWAY_PUBLIC;
    
        if (!publicKey) {
          setLoading(false);
          return;
        }
        
        if (!tranId) {
          setLoading(false);
          return;
        }
    
        try {
    
          const response = await axios.post(
            `${LiveBaseURL}/api/Payments/VerifyPayment`,
            {
              publicKey,
              transactionId: tranId,
              mode: "Live",
            },
            {
              headers: {
                Authorization: `Bearer ${publicKey}`,
                "Content-Type": "application/json",
              },
            }
          );
        
          const { data } = response;
    
          if (data?.data?.isSuccessful) {
            const amount = data?.amount;
    
            setState({ ...state, amount, transactions: data?.history });
            console.log("Transaction successful");
            localStorage.removeItem("tranId");
            localStorage.setItem("payment", "i");
            navigate("/data-done")
        } else {
            console.log("Transaction not successful:", data?.responseMessage);
            setState({ ...state, amount: "" });
            localStorage.setItem("payment", "o");
          }
        } catch (error) {
          console.log("Error verifying payment:", error);
          setState({ ...state, amount: "" });
        } finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        VerifyPayment();
      }, [tranId]);




  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>
                                        {
                                            currentDataPhoto?.billerCode === '9mobiledata1' ? "9Mobile Data"
                                            : 
                                            currentDataPhoto?.billerCode === 'airtel-data' ? "Airtel Data"
                                            : 
                                            currentDataPhoto?.billerCode === 'glo-data' ? "Glo Data"
                                            : 
                                            currentDataPhoto?.billerCode === 'data' ? "MTN Data"
                                            :
                                            currentDataPhoto?.billerCode === 'NTELBundle' ? "ntel Data"
                                            :
                                            currentDataPhoto?.billerCode === 'SMILE' ? "SMILE DATA BUNDLE"
                                            :
                                            currentDataPhoto?.billerCode === 'SPECTRANET' ? "SPECTRANET"
                                            :
                                            ""
                                        }
                                        </h4>
                                    </div>
                                    <div className="ml-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>₦
                                            {
                                               currentDataOrder?.details?.amount
                                            }
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">

                            <li className="nav-item">
                                <Link className="nav-link" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to="">Summary</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/data-payment">Payment</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="">Done</Link>
                            </li>
                        </ul>


                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade  show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <div className="order-summery">
                                    <h3 className="title">Order Summary</h3>
                                    <div className="order-details-area table">

                                        <div className="bg-offwhite table-content">
                                            <div className="row">

                                                <div className="col">Data Plan</div>

                                                <div className="col text-right">
                                                    {
                                                       currentDataOrder?.details?.productName
                                                    }
                                                </div>
                                                
                                            </div>
                                        </div>
                                        



                                        {/* {
                                             currentDataPhoto?.billerCode === '9mobiledata1' ? 
                                                
                                             <div className="table-content">
                                                <div className="row">
                                                    <div className="col">Data Plan</div>
                                                    <div className="col text-right">
                                                        {
                                                            currentDataPhoto?.billerCode === '9mobiledata1' ? currentNineMobileDataOrder?.transDetails[0].fieldValue
                                                            : 
                                                            currentDataPhoto?.billerCode === 'airtel-data' ? ""
                                                            : 
                                                            currentDataPhoto?.billerCode === 'glo-data' ? ""
                                                            : 
                                                            currentDataPhoto?.billerCode === 'data' ? ""
                                                            :
                                                            currentDataPhoto?.billerCode === 'NTELBundle' ? ""
                                                            :
                                                            currentDataPhoto?.billerCode === 'SMILE' ? ""
                                                            :
                                                            currentDataPhoto?.billerCode === 'SPECTRANET' ? ""
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                             : 
                                             currentDataPhoto?.billerCode === 'airtel-data' ? ""
                                             : 
                                             currentDataPhoto?.billerCode === 'glo-data' ? ""
                                             : 
                                             currentDataPhoto?.billerCode === 'data' ? ""
                                             :
                                             currentDataPhoto?.billerCode === 'NTELBundle' ? ""
                                             :
                                             currentDataPhoto?.billerCode === 'SMILE' ? ""
                                             :
                                             currentDataPhoto?.billerCode === 'SPECTRANET' ? ""
                                             :
                                             ""
                                        } */}

                                        


                                        <div className="table-content">
                                            <div className="row">
                                                <div className="col">
                                                    
                                                    Email
                                                </div>


                                                <div className="col text-right">
                                                    {
                                                        currentDataOrder?.userEmail
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bg-offwhite table-content">
                                            <div className="row">
                                                <div className="col">
                                                    
                                                    Phone number
                                                </div>


                                                <div className="col text-right">
                                                    {
                                                       currentDataOrder?.details?.phoneNumber
                                                    }
                                                </div>
                                                
                                            </div>
                                        </div>






                                        {/* {
                                             currentDataPhoto?.billerCode === '9mobiledata1' ?   ""
                                             : 
                                             currentDataPhoto?.billerCode === 'airtel-data' ? ""
                                             : 
                                             currentDataPhoto?.billerCode === 'glo-data' ? ""
                                             : 
                                             currentDataPhoto?.billerCode === 'data' ? ""
                                             :
                                             currentDataPhoto?.billerCode === 'NTELBundle' ? ""
                                             :
                                             currentDataPhoto?.billerCode === 'SMILE' ? 
                                                <div className="table-content">
                                                    <div className="row">
                                                        <div className="col">Customer Name</div>
                                                        <div className="col text-right">
                                                            {
                                                                currentDataPhoto?.billerCode === 'SMILE' ? currentSmileDataOrder?.transDetails[1].fieldValue
                                                                :
                                                                currentDataPhoto?.billerCode === 'SPECTRANET' ? ""
                                                                :
                                                                ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                             :
                                             currentDataPhoto?.billerCode === 'SPECTRANET' ? ""
                                             :
                                             ""
                                        } */}


                                        {/* {
                                             currentDataPhoto?.billerCode === '9mobiledata1' ?   ""
                                             : 
                                             currentDataPhoto?.billerCode === 'airtel-data' ? ""
                                             : 
                                             currentDataPhoto?.billerCode === 'glo-data' ? ""
                                             : 
                                             currentDataPhoto?.billerCode === 'data' ? ""
                                             :
                                             currentDataPhoto?.billerCode === 'NTELBundle' ? ""
                                             :
                                             currentDataPhoto?.billerCode === 'SMILE' ? 
                                                <div className="table-content">
                                                    <div className="row">
                                                        <div className="col">Invoice Id</div>
                                                        <div className="col text-right">
                                                            {
                                                                currentDataPhoto?.billerCode === 'SMILE' ? currentSmileDataOrder?.transDetails[3].fieldValue
                                                                :
                                                                currentDataPhoto?.billerCode === 'SPECTRANET' ? ""
                                                                :
                                                                ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                             :
                                             currentDataPhoto?.billerCode === 'SPECTRANET' ? ""
                                             :
                                             ""
                                        } */}






                                        <div className="row">
                                            <div className="col-md-5 ml-auto">
                                                <div className="table-content">
                                                    <div className="row">
                                                        <div className="col">Amount</div>
                                                        <div className="col text-right">₦
                                                            {
                                                                currentDataOrder?.details?.amount
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col">Service Charge</div>
                                                        <div className="col text-right">₦0.00</div>
                                                    </div>
                                                    <hr />
                                                    
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col font-weight-bold">Total</div>
                                                        <div className="col text-right font-weight-bold">₦{Number(
                                                                currentDataOrder?.details?.amount
                                                            ) + 0}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <ul className="pager my-5">
                                    <li><Link to="/data-order" className="btn btn-default "><i className="fas fa-chevron-left"></i> Back</Link></li>
                                    <li
                                        
                                        onClick={() => PaymentGateway()}
                                    >
                                        <Link to="#" className="btn btn-default ">
                                             Next 
                                             <i className="fas fa-chevron-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    { loading && <Spinner /> }
                </div>
  )
}

export default DataSummaryComp