import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { FormSubmit } from '../../utils/TypeScript'

const WeAreHere = () => {  
    const [data, setData] = useState("")

    const handleSubmit = (e: FormSubmit )=>{
        e.preventDefault()
        if(!data)
        return toast.error("You need to type something.")

        setData("")
        return toast.success("Thank you for your feedback.")
    }
  return (
    <section className="banner v4 bg-offwhite">
          <div className="container">
              <div className="row">
                  <div className="col-md-8 m-auto">
                      <div className="ban-content text-center">
                          <h1 style={{color: "#3f0d5e"}}>We Are Here To Help You</h1>
                          <p>A list of frequently asked questions have been provided on every thing you need to know so you can use PreponeBills like a PRO.</p>
                      </div>
                      <form className="bill-form v2" onSubmit={handleSubmit}>
                          <div className="form-field">
                              <div className="join-field">
                                  <input type="text" placeholder="Type a question..." 
                                  value={data} name="data" onChange={(e)=> setData(e.target.value)}/>
                                  <button type='submit' className="btn btn-block btn-filled form-btn"><span className="bh"></span> <span>Search</span></button>
                              </div>
                          </div>
                          {/* <span className="form-txt">*We are collect your searching keywords to improve our FAQ</span> */}
                      </form>
                  </div>
              </div>
          </div>
      </section>
  )
}

export default WeAreHere