import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import {  ICurrentOrderGeneral, useAppDispatch } from "../../utils/TypeScript"


import EOne from "../assets/images/electricity/e1.png"
import ETwo from "../assets/images/electricity/e2.png"
// import EThree from "../assets/images/electricity/e3.png"
import EFour from "../assets/images/electricity/e4.png"
import EFive from "../assets/images/electricity/e5.png"
import ESix from "../assets/images/electricity/e6.png"
import ESeven from "../assets/images/electricity/e7.png"
import EEight from "../assets/images/electricity/e8.png"
import ENine from "../assets/images/electricity/e9.png"
import ETen from "../assets/images/electricity/e10.png"
import EEleven from "../assets/images/electricity/e11.png"
import ETwelve from "../assets/images/electricity/e12.png"
import EThirteen from "../assets/images/electricity/e13.png"
import EFourtheen from "../assets/images/electricity/e14.png"
import EFifteen from "../assets/images/electricity/e15.png"
import EEDC from "../assets/images/electricity/eedc.png"
import Spinner from '../Alert/Spinner/Spinner'
import { addElectricityPhoto } from '../../redux/slice/electricitySlice/electricityPhotoSlice'


const ElectricitySelectComp = () => {

    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()



    const getElectricityBiller = async ( billerCode: string) => {
        setLoading(true)

             const electricityPhoto: ICurrentOrderGeneral = {
                billerCode: billerCode,
                productId: ""
            }

        dispatch(addElectricityPhoto(electricityPhoto))
        localStorage.setItem("electricityPhoto", JSON.stringify(electricityPhoto));
        navigate("/electricity-order") 
        setLoading(false)


    }

    useEffect(() => {
        localStorage.clear();
    }, [])


  return (
    <section className="we-work bg-offwhite">
        <div className="container">
            <div className="row">
                <div className=" col-md-12 m-auto text-center">
                    <div className="sec-heading mb-4">
                        <span className="tagline">Service providers</span>
                    </div>
                </div>
            </div>
            <div className="portfolioITems">
                <div className="ui-group filters mb-disable">
                    <div className="button-group js-radio-button-group" data-filter-group="color">
                        <button className="button is-checked" data-filter="">All</button>
                        <button className="button" data-filter="">Abuja</button>
                        <button className="button" data-filter=".data">Eko</button>
                        <button className="button" data-filter=".data">Enugu</button>
                        <button className="button" data-filter=".dth">Ibadan</button>
                        <button className="button" data-filter=".broadband">Ikeja</button>
                        <button className="button" data-filter=".broadband">Jos</button>
                        <button className="button" data-filter=".broadband">Kaduna</button>
                        <button className="button" data-filter=".broadband">Kano</button>
                        <button className="button" data-filter=".broadband">Port Harcourt</button>
                      
                    </div>
                </div>

                {/* <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"><img class="jss88 jss87" alt="" src="" aria-label="EKEDC ORDERPAYMENT" title="EKEDC ORDERPAYMENT"></a> */}


                <div className="portGrid row">
                    <div className="protItem col-3 col-md-1" onClick={() => getElectricityBiller('ABJ_POSTPAID')}>
                        <img src={EOne} alt="" className="we-work-im"  aria-label="Abuja Postpaid" title="Abuja Postpaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("ABJ_PREPAID")}>
                        <img src={ETwo} alt="" className="we-work-img" aria-label="Abuja Prepaid" title="Abuja Prepaid"/>
                    </div>

                    {/* <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("EKEDC_ORDER_PAYMENT")}>
                        <img src={EThree} alt="" className="we-work-img" aria-label="EKEDC Order Payment" title="EKEDC Order Payment"/>
                    </div> */}

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("EKEDC_POSTPAID")}>
                        <img src={EFour} alt="" className="we-work-img" aria-label="EKEDC Postpaid" title="EKEDC Postpaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("EKEDC_PREPAID")}>
                        <img src={EFive} alt="" className="we-work-img" aria-label="EKEDC Prepaid" title="EKEDC Prepaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("EEDC")}>
                        <img src={EEDC} alt="" className="we-work-img" aria-label="Enugu Electricity" title="Enugu Electricity"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("IBEDC_F")}>
                        <img src={ESix} alt="" className="we-work-img" aria-label="IBEDC" title="IBEDC"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("iedc postpaid")}>
                        <img src={ESeven} alt="" className="we-work-img" aria-label="Ikeja Electric Postpaid" title="Ikeja Electric Postpaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("iedc")}>
                        <img src={EEight} alt="" className="we-work-img" aria-label="Ikeja Electric Prepaid" title="Ikeja Electric Prepaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("JOS_POSTPAID")}>
                        <img src={ENine} alt="" className="we-work-img" aria-label="Jos Electric Postpaid" title="Jos Electric Postpaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("JOS_PREPAID")}>
                        <img src={ETen} alt="" className="we-work-img" aria-label="Jos Electric Prepaid" title="Jos Electric Prepaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("KADUNA_POSTPAID")}>
                        <img src={EEleven} alt="" className="we-work-img" aria-label="Kaduna Electric Postpaid" title="Kaduna Electric Postpaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("KADUNA_PREPAID")}>
                        <img src={ETwelve} alt="" className="we-work-img" aria-label="Kaduna Electric Prepaid" title="Kaduna Electric Prepaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("KEDCOPostpaid")}>
                        <img src={EThirteen} alt="" className="we-work-img" aria-label="KEDCO PostPaid" title="KEDCO PostPaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("KEDCOPrepaid")}>
                        <img src={EFourtheen} alt="" className="we-work-img" aria-label="KEDCO PrepaidPaid" title="KEDCO PrepaidPaid"/>
                    </div>

                    <div className="protItem col-3 col-md-1 data" onClick={() => getElectricityBiller("PHEDDIR2")}>
                        <img src={EFifteen} alt="" className="we-work-img" aria-label="Port Harcourt Electric Dist. Comp." title="Port Harcourt Electric Dist. Comp."/>
                    </div>
                   
                </div>
            </div>
        </div>

        { loading && <Spinner /> }
    </section>
  )
}

export default ElectricitySelectComp