import { createSlice } from '@reduxjs/toolkit'
import { ICurrentOrderGeneral } from '../../../utils/TypeScript'



export interface TransHistoryBillerCodeState {
    currentTransHistoryBillerCode?: ICurrentOrderGeneral,
   
}

const initialState: TransHistoryBillerCodeState= {
    currentTransHistoryBillerCode: undefined,
  
}


const transHistoryBillerCodeSlice = createSlice({
    name: 'transBillerCode',
    initialState,
    reducers: {
      addTransHistoryBillerCode: (state, action) => {
        state.currentTransHistoryBillerCode = {...action.payload}
      }
    },
})


export const { addTransHistoryBillerCode } = transHistoryBillerCodeSlice.actions

export default transHistoryBillerCodeSlice.reducer
