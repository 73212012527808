import React from 'react'
import {  useAppSelector } from '../../../utils/TypeScript'

import { Link } from "react-router-dom"
import UserImage from "../../assets/png/userr.png"
// import moment from 'moment'

const ProfileBar = () => {
    const { currentUser } = useAppSelector(state => state.auth)

    const currentDate = new Date();

    // const time = currentDate.toLocaleString()
    const hr = currentDate.getHours()
    const mins = currentDate.getMinutes()
    const secs = currentDate.getSeconds()
    const amOrPm = hr > 11 ? "PM" : "AM"

    const time = `${hr}:${mins}:${secs}${amOrPm}`

    // var dt = new Date();
    // var hours = dt.getHours(); // gives the value in 24 hours format
    // var minutes = dt.getMinutes() ; 
    // var finalTime = "Time  - " + hours + ":" + minutes; 
    // finalTime // final time Time - 22:10


    // const currentDate = new Date();

    //     const time = currentDate.toLocaleTimeString("en-US", {
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit"
    // });




  return (
    <div className="profilebar shadow mt-2 border-2"  style={{
        animation: "slideInFromTop 1s ease-in"
    }}
    >
    <div className="container">
        <div className="row">
            <div className="col">
                <div className="balance-area">

                    {
                        currentUser
                        ?

                            <Link to="/profile">
                                <div className="header-pro-thumb">
                                    <img className="rounded-circle" src={UserImage} alt="profile" style={{color: "#3f0d5e"}} />
                                </div> 
                                <span className="pl-4 pt-1"><b>{currentUser?.displayName}</b></span>
                            </Link>

                        :

                            <Link to="/profile">
                                <div className="header-pro-thumb">
                                    <img className="rounded-circle" src={UserImage} alt="profile" />
                                </div> 
                                <span className="pl-4 pt-1"><b>Dear User</b></span>
                            </Link>

                    }


                

                </div>
            </div>
            <div className="col">
                <div className="local-time">
                    <p><b>Current Time: </b>{ time }</p>


                    {/* {let today = new Date();
 
                     curTime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();} */}
                </div>
            </div>
            <div className="col">
                <div className="local-time">
                    <p><b>Logged In: </b>{currentUser?.metadata.lastSignInTime}</p>
                </div>
            </div>
            <div className="col notify-col text-right">
                <div className="notify-btn"><Link to="/transactions"><i className="far fa-bell" style={{color: "#9417E2"}}></i></Link></div>
            </div>
        </div>
    </div>
</div>
  )
}

export default ProfileBar