import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../utils/TypeScript";

import NSE from "../assets/new/nigerian_society_of_engineers_logo_s2.png";
import LCC from "../assets/new/lcc.jpg";
import RIVS from "../assets/new/logoriv.jpg";
import NGEngineersForm from "./NGEngineersForm";


const BodiesOrderComp = () => {
  const { currentCableTVPhoto } = useAppSelector((state) => state.cableTVPhoto);

  const dispatch = useAppDispatch();

  return (
    <div className="col-lg-9">
      <div className="row mx-1">
        <div className="col-lg-12">
          <div className="bg-light shadow-sm text-center mb-3">
            <div className="d-flex admin-heading pr-3 ">
              <div className="mr-auto">
                <h4
                  className="text-9 font-weight-400"
                  style={{ color: "#3f0d5e" }}
                >
                  <i className="fas fa-wallet mr-2"></i>
                  {currentCableTVPhoto?.billerCode === "NSE"
                    ? "NG Society of Engineers"
                    : currentCableTVPhoto?.billerCode === "LCC"
                    ? "Lekki Concession Company"

                    : currentCableTVPhoto?.billerCode === "RIVS"
                    ? "Rivers State Govt"
                    : ""}
                </h4>
              </div>
              <div className="ml-auto">
                <h4
                  className="text-9 font-weight-400"
                  style={{ color: "#3f0d5e" }}
                >
                  Amount
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 profile-content ">
        <ul className="nav nav-pills mx-3">
          <li className="nav-item">
            <Link className="nav-link active" to="">
              Order
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="">
              Summary
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="">
              Done
            </Link>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="operators mx-3">
              <div className="row operator-row" id="operator-row">
                <div className="col-md-2 col-sm-4">
                  <div className="single-operator">
                    <div className="op-logo" data-operator="op1">
                      <img
                        src={
                          currentCableTVPhoto?.billerCode === "NSE"
                            ? NSE
                            : currentCableTVPhoto?.billerCode === "LCC"
                            ? LCC
                            
                            : currentCableTVPhoto?.billerCode === "RIVS"
                            ? RIVS
                            : ""
                        }
                        alt="Cable TV Logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {currentCableTVPhoto?.billerCode === "FIRS" ? (
              <FIRSPaymentFormComp />
            ) : currentCableTVPhoto?.billerCode === "LCC" ? (
              <LCCFormComp />
            )
            : currentCableTVPhoto?.billerCode === "RIVS" ? (
                <RVSForm />
              ) : 
            (
              ""
            )} */}

            <NGEngineersForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodiesOrderComp;
