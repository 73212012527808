import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faDollar, faReceipt, faQuestion} from '@fortawesome/free-solid-svg-icons';



const Questions = () => {
  return (
    <div>
        <section className="faq-tab">
          <div className="container">
              <div className="row">
                  <div className="col-lg-8 col-md-10 m-auto text-center">
                      <div className="sec-heading">
                          <h2 className="sec-title" style={{color: "#3f0d5e"}}>Frequently Asked Questions</h2>
                          <p className="sec-subtitle">The purpose of a page is to provide information on  questions or concerns you might have.</p>
                      </div>
                  </div>
              </div>

              <div className="row">
                  <div className="col-lg-10 col-md-12 m-auto">
                      <ul className="row nav nav-tabs">
                          <li className="col-md-4">
                              <a className="active" data-toggle="tab" href="#general-qs">
                                  <i className="fas"><FontAwesomeIcon icon={faQuestion} /></i>
                                  
                                  General Questions
                              </a>
                          </li>
                          <li className="col-md-4">
                              <a data-toggle="tab" href="#trasn-money">
                                    
                                    <i className="fas"><FontAwesomeIcon icon={faReceipt} /></i>  
									Transactions
                              </a>
                          </li>
                          {/* <li className="col-md-3">
                              <a data-toggle="tab" href="#mbl-banking">
                                     <i className="fas"><FontAwesomeIcon icon={faLightbulb} /></i>
                                  	Pay Electricity Bill
                              </a>
                          </li> */}
                          <li className="col-md-4">
                              <a data-toggle="tab" href="#fee-charges">
                                 <i className="fas"><FontAwesomeIcon icon={faDollar} /></i>  
								Fees and Charges
                              </a>
                          </li>
                      </ul>






                      <div className="tab-content">
                          <div id="general-qs" className="tab-pane fade in active show">
                              <div className="accordion" id="accordion">
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#faq-1" aria-expanded="true">
                                          What type of transactions can I carry out on www.Preponebills.com?
                                      </h5>
                                      <div id="faq-1" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>
                                              PreponeBills is a one-stop-online shop for bills payment services, and it provides a platform for the following services: <br />
                                            1.	Purchase of airtime and data for 9mobile, Airtel, Glo, MTN and nTel <br />
                                            2.	Pay electricity bills for AEDC, EKEDC, IBEDC, IKEDC, Jos Electricity, Kaduna Electricity, Kano Electricity, PHED. <br />
                                            3.	Pay Cable subscriptions for DSTV, GOTV, and StarTimes. <br />
                                            4.	Pay for collections e.g. Law Union Insurance and Leadway Assurance.

                                              </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#faq-2" aria-expanded="false">
                                        What are the payment options accepted on the website?
                                      </h5>

                                      <div id="faq-2" className="collapse show" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>
                                              The Payment Options available on PreponeBills are: <br />
                                                1. Debit Cards; Visa Cards, Master Cards and Verve Cards. <br />
                                                2. Bank Transfer <br />
                                                3. USSD (Coming Soon) <br />
                                                4. QR Code (Coming Soon) <br />
                                                5. Wallet (Coming Soon) <br />

                                              </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#faq-3" aria-expanded="false">
                                      How can I start using the services on PreponeBills
                                      </h5>

                                      <div id="faq-3" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>
                                              Unregistered users can only use the following functionalities: <br />
                                                1. Buy Airtime <br />
                                                2. Buy Data <br />
                                                3. Pay Bills <br />
                                                To access the full functionalities of the platform, you should register (see how to register below). After registering, you can also: <br />
                                                View and download your transaction history
                                                Carry out Bulk Airtime and Data purchases
                                                Pay for Collections and Levies.

                                              </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#faq-4" aria-expanded="false">
                                      How do I register?
                                      </h5>

                                      <div id="faq-4" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>
                                              Kindly follow the steps below to register: <br />
                                                1.	Click on “Register” at the top right of the homepage and complete the signup form displayed. <br />
                                                2.	Successful registration initiates a mail to supplied email address for verification. <br />
                                                3.	Click on the activation link sent to the email address provided to activate your profile. <br />
                                                4.	Return to www.PreponeBills.com and login with your userID (email address) and password.

                                              </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#faq-5" aria-expanded="false">
                                      Is PreponeBills safe and secure?
                                      </h5>

                                      <div id="faq-5" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>PreponeBills is very safe and secure. The website runs on a Secure Socket Layer (SSL) and the platform is compliant with international security standards.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#faq-5" aria-expanded="false">
                                      What happens if I cannot remember my password?
                                      </h5>

                                      <div id="faq-5" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>Click on “Forgot Password” at the bottom of the log-in page and a link will be sent to your e-mail address. Click on this link to profile a new password. If you don’t see the mail in your “Inbox”, check the “Spam” or “Junk” mailbox.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#faq-5" aria-expanded="false">
                                      Can I update my profile?
                                      </h5>

                                      <div id="faq-5" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>Yes, as a registered user, you can update your profile by clicking on your name at the top left of the dashboard.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#faq-5" aria-expanded="false">
                                      Can I change my password?
                                      </h5>

                                      <div id="faq-5" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>Yes, as a registered user, you can change your password by clicking on your name at the top left of the dashboard. and clicking on change password.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#faq-5" aria-expanded="false">
                                      Who do I contact for support or more information about PreponeBills?
                                      </h5>

                                      <div id="faq-5" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>Contact Customer Care Centre: <br />
                                                1.	E-mail address: info@preponebills.com <br />
                                                2.	Telephone numbers 
                                                +2348148407140 <br />
                                                3.	Click on “Contact Us” menu located at the top right of the Home page and send us a message.
                                                </p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>





                          {/* Second Tab */}
                          <div id="trasn-money" className="tab-pane fade">
                              <div className="accordion" id="accordion1">
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#tm-1" aria-expanded="true">
                                      I made a successful payment but I am yet to receive service/receipt/Token as expected.
                                      </h5>
                                      <div id="tm-1" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>Contact help@preponebills.com with your payment reference.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#tm-2" aria-expanded="false">
                                      I made a payment that was not successful, but I have debited, what do I do?
                                      </h5>

                                      <div id="tm-2" className="collapse show" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>The payment should be reversed into your account or card within 24 hours. But if it is not reversed, please contact your bank.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#tm-3" aria-expanded="false">
                                      Can I get notifications when my transactions on web are successful?
                                      </h5>

                                      <div id="tm-3" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>Yes. Notification E-mails are sent to the email address provided at the point of initiating the transaction regardless of if it was Successful or Failed.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#tm-3" aria-expanded="false">
                                      How do I view transaction history?
                                      </h5>

                                      <div id="tm-3" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>You can view your Transaction History following the steps below: <br />
                                            1.	Log-in to the platform using your userID (email address) and password. <br />
                                            2.	Click on your username which will be the last item on the menu to the left of the Homepage. <br />
                                            3.	Click on “View Transaction History” from the drop-down menu to have an overview of transactions done. <br />
                                            4.	You can now filter and view transactions by date and reference number
                                            </p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>






                          {/* Third Tab */}
                          <div id="mbl-banking" className="tab-pane fade">
                              <div className="accordion" id="accordion2">
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#mblb-1" aria-expanded="true">
                                          Input Wallet Informations
                                      </h5>
                                      <div id="mblb-1" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscied do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspen disse ultrices gravida.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#mblb-2" aria-expanded="false">
                                          Access Your Mobile Wallet
                                      </h5>

                                      <div id="mblb-2" className="collapse show" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>Molestie eu blandit eu sodales, nulla vitae nulla tempor et porta, ante nec commodo. Nulla non fringilla urna dolor vivamus, vulputate eu neque vivamus lacus in, imperdiet id sed malesuada per.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#mblb-3" aria-expanded="false">
                                          Confirm information payover
                                      </h5>

                                      <div id="mblb-3" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>Blandit eu sodales, nulla vitae nulla tempor et porta, ante nec commodo. Nulla non fringilla urna dolor vivamus, vulputate eu neque vivamus lacus in, imperdiet id sed malesuada per.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>





                          {/* Fourt Tab */}
                          <div id="fee-charges" className="tab-pane fade">
                              <div className="accordion" id="accordion3">
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#fc-1" aria-expanded="true">
                                      Are there any fees for this service, monthly or annual charges?
                                      </h5>
                                      <div id="fc-1" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>
                                              1.	Buy airtime – All at face value. No additional charges. <br />
                                                2.	Pay bills – A N100 convenience fee is charged for each successful transaction

                                              </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#fc-2" aria-expanded="false">
                                      I made a successful payment but I am yet to receive service/receipt/Token as expected.
                                      </h5>

                                      <div id="fc-2" className="collapse show" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>
                                              Contact info@preponebills.com with your payment reference.
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                                  {/* <div className="accordion-item">
                                      <h5 className="collapsed" data-toggle="collapse" data-target="#fc-3" aria-expanded="false">
                                          Confirm information and payover
                                      </h5>

                                      <div id="fc-3" className="collapse" data-parent="#accordion">
                                          <div className="acr-body">
                                              <p>Blandit eu sodales, nulla vitae nulla tempor et porta, ante nec commodo. Nulla non fringilla urna dolor vivamus, vulputate eu neque vivamus lacus in, imperdiet id sed malesuada per.</p>
                                          </div>
                                      </div>
                                  </div> */}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </div>
  )
}

export default Questions