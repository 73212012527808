import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate  } from "react-router-dom"
import { toast } from 'react-toastify'
import Toast from '../Alert/Toast'
import {useAppDispatch, useAppSelector } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'

import { ICurrentSmileDataOrder, IMTNDataProducts } from '../../utils/AirtimeDateTypeScript/airtimeDataTypes'
import {  fetchDataBiller } from '../../utils/billers'
import { validateSmileDataForm } from '../../utils/ValidData/validData'
import { addSmileDataOrder } from '../../redux/slice/dataSlice/smileSlice'
import { lookupSmileData } from '../../utils/lookup/lookupTrans'





const SmileDataComp = () => {

  const [loading, setLoading] = useState(false)
  const [chooseSmileProduct, setChooseSmileProduct] = useState("")

  const [selectProduct, setSelectProduct] = useState("")
  const [smileAccountNumber, setsmileAccountNumber] = useState("")
  const [dataPackage, setDataPackage] = useState("")
  const [dataPackages, setDataPackages] = useState([])
  const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [invoiceId, setInvoiceId] = useState("")


  
  const { currentDataPhoto }  = useAppSelector((state) => state.dataPhoto)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()



  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading(true)
    const smileDataOrderDetails = { selectProduct, smileAccountNumber, dataPackage, amount, email, invoiceId, customerName }

    const result = validateSmileDataForm(smileDataOrderDetails)

    if(result.errLength){

       toast.error(() => <Toast title="Error"
        body={result.errMsg} />)

        setLoading(false)
        return 
    }


    const smileDataLookup = {
      billerCode:"SMILE",
      customerAccountNumber:smileAccountNumber,
      customerAccountType:""
    }

    const lookupResult = await lookupSmileData(smileDataLookup)
    const customername = lookupResult.customerName


    if(customername === null || customername === ""){
      setLoading(false)
       return toast.error("Could not find the user with this smile account number!") 
     } else {

        let transactionId = "PreponeBills" + Math.floor(Math.random() * 1000000);

        const smileDataOrder: ICurrentSmileDataOrder = {
          billerCode: 'SMILE',
          productId: "1134",
          externalRef: transactionId,
          transDetails: [
            {
            fieldName: "Amount",
            fieldValue: amount,
            fieldControlType : "TEXTBOX"
            },
            {
            fieldName: "Customer Name",
            fieldValue: customername,
            fieldControlType : "TEXTBOX"
            },
            {
            fieldName: "Email",
            fieldValue: email,
            fieldControlType : "TEXTBOX"
            },
            {
            fieldName: "Invoice Id",
            fieldValue: transactionId,
            fieldControlType : "LOOKUP"
            },
            {
            fieldName: "Select Data Package",
            fieldValue: dataPackage,
            fieldControlType : "LOOKUP"
            },
            {
            fieldName: "Select Product",
            fieldValue: "SMILE_BUNDLE",
            fieldControlType : "LOOKUP"
            },
            {
            fieldName: "Smile Account Number",
            fieldValue: smileAccountNumber,
            fieldControlType : "TEXTBOX"
            }
          ]
        }

          
        dispatch(addSmileDataOrder(smileDataOrder))
        localStorage.setItem("smileDataOrder", JSON.stringify(smileDataOrder));
        localStorage.setItem("smileData", JSON.stringify(chooseSmileProduct));
        navigate("/data-summary") 
        setLoading(false)
      }
    
}






const fetchBiller = async () => {

    setLoading(true)

  const currentBillerCode = currentDataPhoto ? currentDataPhoto?.billerCode : 'data'
  const selectetCableTVBiller = await fetchDataBiller( currentBillerCode)
  console.log(selectetCableTVBiller);

  const packages = selectetCableTVBiller.productFields[4].ListItems
  setDataPackages(packages)

  setLoading(false)

  return selectetCableTVBiller;

}


    const setPackageAmount = ()=>{
        if(!chooseSmileProduct){
            return
        } else {
            const selectedProduct = dataPackages.filter(( paackage : IMTNDataProducts)=>{
                return paackage.ItemName === chooseSmileProduct
        })

            
            setAmount(selectedProduct[0]['Amount']);
            setDataPackage(selectedProduct[0]['ItemType']);
        }
    }
    
     


 useEffect( ()=>{
      fetchBiller()
 }, [])

 useEffect( ()=>{
      setPackageAmount()
 }, [chooseSmileProduct])










  return (
    <form onSubmit={handleSubmit} className="mx-3">
      <div className="form-row">
     
        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlInput04">Select Product<span className='text-danger'>*</span></label>

          <select className="custom-select form-control" id="operator" name="productType" value={selectProduct} onChange={e => setSelectProduct(e.target.value)} >
            <option value="">SMILE_BUNDLE</option>
          </select>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Smile Account Number <span className='text-danger'>*</span></label>
            <input type="number" className="form-control" data-bv-field="number" id="mobileNumber"  placeholder="Enter phone number" name="customerNumber" value={smileAccountNumber} onChange={e => setsmileAccountNumber(e.target.value)} />
        </div>

      </div>


      <div className="form-row">

        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlInput04">Select Data Package<span className='text-danger'>*</span></label>

          <select className="custom-select form-control" id="operator" name="productType" value={chooseSmileProduct} onChange={e => setChooseSmileProduct(e.target.value)} >
            <option value="">Select Data Package</option>

                {
                    dataPackages.map((packg : IMTNDataProducts, index) =>
                        <option className='' key={index} value={packg.ItemName}>{packg.ItemName}</option>  
                    )
                }
          </select>
        </div>


        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Email <span className='text-danger'>*</span></label>
          <input type="email" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter email" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>


        

      </div>


      <div className="form-row">
        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Amount <span className='text-danger'>*</span></label>
          <input type="number" className="form-control" data-bv-field="number" id="amount"  placeholder="Amount" name="amount" value={amount} readOnly/>
        </div>

        {/* <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="exampleFormControlSelect1">Invoice Id <span className='text-danger'>*</span></label>
          <input type="number" className="form-control" data-bv-field="email" id="mobileNumber"  placeholder="Enter invoice Id" name="email" value={invoiceId} onChange={e => setInvoiceId(e.target.value)}/>
        </div> */}

        {/* <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">Customer Name <span className='text-danger'>*</span></label>
            <input type="text" className="form-control shadow form-control-lg smartCardNumber" id="smartCardNumber"  placeholder="Enter full name" name="customerDetails" value={customerName} onChange={e => setCustomerName(e.target.value)} />
        </div> */}
      </div>


      {/* <div className="form-group">

        

      </div> */}



      <ul className="pager my-5" style={{width: "100%"}}>
          <button type='submit' className="btn btn-default text-align-end " style={{width: "100%"}}>Continue <i className="fas fa-chevron-right"></i></button>
      </ul>
      { loading && <Spinner /> }
    </form>
  )
}

export default SmileDataComp