import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { profileUpdate } from "../../redux/slice/profileSlice/profileSlice";
import { validateFLWPayload } from "../../utils/lookup/lookupTrans";
import {
  useAppSelector,
  useAppDispatch,
  ICurrentFIRSOrder,
  BillerItemData,
} from "../../utils/TypeScript";
import { validateValidFIRSOrder } from "../../utils/Validation";
import Spinner from "../Alert/Spinner/Spinner";
import Toast from "../Alert/Toast";
import { generateReference } from "../../utils/utils";
import { addFIRSOrder } from "../../redux/slice/govtPaySlice/firsSlice";
import { getBillerItem } from "../../utils/billers";

const FIRSPaymentFormComp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tin, setTin] = useState("");
  const [email, setEmail] = useState("");

  const [amount, setAmount] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [packages, setPackages] = useState<BillerItemData[]>([]);
  const [uniqueCode, setUniqueCode] = useState("");
  const [billerCode, setBillerCode] = useState("");
  const [billerName, setBillerName] = useState("");
  const [itemCode, setItemCode] = useState("");

  const { profile } = useAppSelector((state) => state.profile);
  const { currentUser } = useAppSelector((state) => state.auth);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const validPayload = { tin, email, amount, phoneNumber, itemCode };

    const result = validateValidFIRSOrder(validPayload);

    if (result.errLength) {
      toast.error(() => <Toast title="Error" body={result.errMsg} />);

      setLoading(false);
      return;
    }

    // const reference = await generateReference("FIRS")

    const payloadLookup = {
      billerCode: billerCode,
      itemCode: itemCode,
      customer: tin,
      //   billerCode: "BIL119",
      //   itemCode: "CB141",
      //   customer: "0025401100"
    };

    const lookupResult = await validateFLWPayload(payloadLookup);
    console.log({ lookupResult });
    // const { data, status } = lookupResult?.response

    if (lookupResult?.response?.status.toLowerCase() === "success") {
      // Save Transaction details for later
      // if(currentUser){

      //     const dataa: IProfile = {
      //     meterNo: profile?.meterNo? profile?.meterNo : "",
      //     phoneNo: phoneNumber,
      //     userEmail: email,
      //     smartCardNumber: smartCardNumber
      //     }
      //     await dispatch(profileUpdate({user: currentUser, dataa}))
      // }

      const payload: ICurrentFIRSOrder = {
        country: "NG",
        customer_id: tin,
        amount: amount,
        //   reference: reference,
        currency: "NGN",
        serviceName: "FIRS",
        billerCode: billerCode,
        itemCode: itemCode,
        type: "FIRS",
        biller_name: billerName,
        callback_url: "https://www.preponebills.com/govt-levy-done",
        meta: {
          tax_type: "VAT",
          tax_period: "Q1-2024",
        },
        userEmail: email,
        phoneNumber,
      };

      dispatch(addFIRSOrder(payload));
      localStorage.setItem("firsOrder", JSON.stringify(payload));

      navigate("/govt-levy-summary")
      setLoading(false);
    } else {
      setLoading(false);
      return toast.error("Could not find the user with this TIN number!");
    }
  };

  const fetchPackages = async () => {
    const res = await getBillerItem("BIL130");

    setLoading(true);

    setPackages(res?.data);

    setLoading(false);
  };

  const setSelectedPackage = () => {
    if (!uniqueCode) {
      return;
    } else {
      const selectedPackage = packages.filter((paackage: BillerItemData) => {
        return paackage.item_code === uniqueCode;
      });

      setBillerName(selectedPackage[0]["biller_code"]);
      setItemCode(selectedPackage[0]["item_code"]);
      setBillerCode(selectedPackage[0]["biller_code"]);
      //  console.log(selectedPackage)
    }
  };

  useEffect(() => {
    if (!profile) return;
    setEmail(profile.userEmail);
    // setSmartCardNumber(profile.smartCardNumber)
    setPhoneNumber(profile.phoneNo);
  }, []);

  useEffect(() => {
    fetchPackages();
  }, []);

  useEffect(() => {
    setSelectedPackage();
  }, [uniqueCode]);

  return (
    <>
      <form onSubmit={handleSubmit} className="mx-3 mt-4">
        {/* <span className='text-danger' style={{fontSize: "0.9rem"}}>
            {
            currentUser ? "" : "Login to remember details next time."
            }
        
        </span> */}
        <div className="form-row">
          <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1" className="fs-10">
              Tax Identification Number (TIN){" "}
              <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              data-bv-field="number"
              id="mobileNumber"
              placeholder="Enter TIN"
              name="tin"
              value={tin}
              onChange={(e) => setTin(e.target.value)}
            />
          </div>

          <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlInput04">
              Select Package <span className="text-danger">*</span>
            </label>

            <select
              className="custom-select form-control"
              id="operator"
              name="selectPackage"
              value={uniqueCode}
              onChange={(e) => setUniqueCode(e.target.value)}
            >
              <option value="">Select Package</option>
              {packages?.length
                ? packages.map((each: BillerItemData, index) => (
                    <option key={index} value={each.item_code}>
                      {each.name}
                    </option>
                  ))
                : ""}
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-6 col-lg-6">
            <label htmlFor="exampleFormControlSelect ">
              {" "}
              <span className="text-danger"></span>
            </label>
            <div className="form-group input-group pt-2">
              <div className="input-group-prepend ">
                {" "}
                <span
                  className="input-group-text px-3 text-white"
                  style={{ backgroundColor: "#BC61F5" }}
                >
                  (Amount) ₦
                </span>
              </div>
              <input
                className="form-control text-center"
                id="amount"
                placeholder="Amount"
                type="number"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">
              Phone Number <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              data-bv-field="customerNumber"
              id="amount"
              placeholder="Enter phone number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-6 col-lg-6 form-group">
            <label htmlFor="exampleFormControlSelect1">
              Email Address <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              data-bv-field="email"
              id="mobileNumber"
              placeholder="Enter email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <ul className="pager my-5" style={{ width: "100%" }}>
          <button
            type="submit"
            className="btn btn-default text-align-end "
            style={{ width: "100%" }}
          >
            Continue <i className="fas fa-chevron-right"></i>
          </button>
        </ul>
      </form>
      {loading && <Spinner />}
    </>
  );
};

export default FIRSPaymentFormComp;
