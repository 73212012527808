import React from 'react'
import CallToAction from '../components/callToAction/CallToAction'
import CardComps from '../components/cardsComps/CardComps'

import AsideBar from '../components/global/AsideBar/AsideBar'
import ProfileBar from '../components/global/ProfileBar/ProfileBar'


const ProfileCardsPage = () => {
  return (
    <div>
        <ProfileBar />

        <div id="content" className="py-4">
            <div className="container">
                <div className="row">

                    <CardComps />
                    <AsideBar />


                </div>
            </div>
        </div>

        <CallToAction />
    </div>
  )
}

export default ProfileCardsPage