import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentMultipleAirtimeOrder } from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface MultipleAirtimeState {
    currentMultipleAirtimeOrder?: ICurrentMultipleAirtimeOrder,
    loading: boolean
}

const initialState: MultipleAirtimeState = {
    currentMultipleAirtimeOrder: undefined,
    loading: false,
}


const multipleAirtimeSlice = createSlice({
    name: 'airtime',
    initialState,
    reducers: {
      addMultipleAirtimeOrder: (state, action) => {
        state.currentMultipleAirtimeOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('airtime') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('airtime') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addMultipleAirtimeOrder } = multipleAirtimeSlice.actions

export default multipleAirtimeSlice.reducer
