import React from 'react'
import { Link } from "react-router-dom"
import { IHistoryResponse } from '../../utils/transHistory/historyTypes'

const CableTVDetailsComp = (props: {cableTVHistory: IHistoryResponse | undefined}) => { 


    const transDetailsArray = props?.cableTVHistory?.transDetails?.split('|')
    const details = transDetailsArray 

    const totalTransactionAmount = Number(props?.cableTVHistory?.transactionAmount) + 100



  return (
    <div className="col-lg-9">
        <div className="profile-content">
            <h3 className="admin-heading bg-offwhite">
                <p>CableTV Transaction Details</p>
            </h3>
            <div className="order-summery transaction-summery">
                <div className="order-details-area table">
                    <div className="table-head">
                        <div className="modal_header">
                            <span className={props?.cableTVHistory?.statusDescription === "SUCCESSFUL" ? "status bg-success" : "status bg-danger" }>
                                
                                {
                                    props?.cableTVHistory?.statusDescription === "SUCCESSFUL" ? "Paid" : "Failed"
                                }
                            </span>
                            <div className="row">
                                <div className="col">
                                    <div className="content-area">
                                        <b>Payment sent to</b>
                                        <i className="fas fa-building ml-3 mr-1"></i>
                                         PreponeBills Ltd
                                        <p>{props?.cableTVHistory?.transDate}</p>
                                        <p> Trans Ref:  
                                             {
                                                ` ${ props?.cableTVHistory?.transRef}` 
                                            }

                                        </p>
                                        <p>Payment Status: 
                                            {
                                                ` ${ props?.cableTVHistory?.paymentStatus}` 
                                            }
                                        </p>
                                        <p>Payment Type: XpressPay Gateway</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="content-area text-right">
                                        <div className="price">
                                            <b>Amount</b>
                                            <p>₦
                                            {
                                                totalTransactionAmount
                                            }
                                            </p>
                                        </div>                                                        
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="table-content bg-offwhite">
                            <b>
                                <div className="row">
                                    <div className="col">Order Details</div>
                                    <div className="col">Phone Number</div>
                                    <div className="col">Package</div>
                                </div>
                            </b>
                        </div>
                        <div className="table-content">

                            <div className="row">
                                <div className="col">
                                    {
                                        ` ${props?.cableTVHistory?.product} `
                                    }
                                </div>
                                <div className="col ">
                                    {
                                        props?.cableTVHistory?.billerCode === 'DSTV1' ? `${ details? details[0] : ""}`
                                        : 
                                        props?.cableTVHistory?.billerCode === 'GOTV1' ? `${ details? details[0] : ""}`
                                        : 
                                        props?.cableTVHistory?.billerCode === 'startimes' ? `${ details? details[0] : ""}`
                                        : 
                                        ""
                                    }
                                </div>
                                <div className="col text-right">
                                    {
                                        props?.cableTVHistory?.billerCode === 'DSTV1' ? `${ details? details[3] : ""}`
                                        : 
                                        props?.cableTVHistory?.billerCode === 'GOTV1' ? `${ details? details[2] : ""}`
                                        : 
                                        props?.cableTVHistory?.billerCode === 'startimes' ? `${ details? details[3] : ""}`
                                        : 
                                        ""
                                    }
                                </div>
                               
                            </div>
                        </div>
                       

                        <div className="row">
                            <div className="col-md-5 ml-auto">
                                <div className="sub-content">
                                    <br/>
                                    <div className="row">
                                        <div className="col">Purchase Total</div>
                                        <div className="col text-right">₦
                                            {
                                               `${props?.cableTVHistory?.transactionAmount}`
                                            }
                                        </div>
                                    </div>
                                   
                                    
                                    <div className="row">
                                        <div className="col">Service Charge</div>
                                        <div className="col text-right">₦100.00</div>
                                    </div>
                                    
                                   
                                    
                                    <hr/>
                                    <div className="row">
                                        <div className="col"><b>Net Amount</b></div>
                                        <div className="col text-right">₦
                                            {
                                                 
                                                 totalTransactionAmount
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-footer mt-3 bg-offwhite p-3">
                            <div className="row">
                                <div className="col">
                                    <p><b>Payment Ref: </b>
                                        {
                                            ` ${props?.cableTVHistory?.paymentRef} `
                                        }
                                    </p>
                                    <p className="mb-0"><b>Thank You!</b></p>
                                    <p>
                                        Thank you for using Prepone bills payment platform,
                                        we promise to serve you better.
                                    </p>
                                </div>
                                <div className="col">
                                    <p className="mb-0"><b>Contact info</b></p>
                                    <p>
                                        PreponeBills Ltd
                                        The recipient of this payment is verified and is located in Port Harcourt, Nigeria.
                                        http://preponebills.com/,
                                        help@preponebills.com
                                    </p>
                                </div>
                            </div>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CableTVDetailsComp