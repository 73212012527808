import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {IUserRegister, IUserLogin, IAuth } from "../../utils/TypeScript"
import { registerApi, loginApi, forgotPassApi, signOutApi} from "../../actions/authActions"

export const authRegister = createAsyncThunk(
    'auth/register',
    async (userRegisterDetails: IUserRegister) => {
      return await registerApi(userRegisterDetails)
    }
)
  
export const authLogin = createAsyncThunk(
    'auth/login',
    async (userLoginDetails: IUserLogin) => {
      return await loginApi(userLoginDetails)
    }
)

export const authForgotPassword = createAsyncThunk(
    'auth/forgot_password',
    async (email: string) => {
        return await forgotPassApi(email)
    }
)
  
export const authLogout = createAsyncThunk(
    'auth/logout',
    async () => {
        return await signOutApi()
    }
)


export interface AuthState {
    currentUser?: IAuth,
    loading: boolean
}

const initialState: AuthState = {
    currentUser: undefined,
    loading: false
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      addUser: (state, action) => {
        state.currentUser = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('auth') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('auth') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false }
        )
    }
})


export const { addUser} = authSlice.actions

export default authSlice.reducer