import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom" 
import { addAirtimeHistory } from '../../redux/slice/transHistorySlice/airtimeHistory'
import { addCableTVHistory } from '../../redux/slice/transHistorySlice/cableTvHistory'
import { addDataHistory } from '../../redux/slice/transHistorySlice/dataHistory'
import { addElectricityHistory } from '../../redux/slice/transHistorySlice/electricityHistory'
import { addTransHistory } from '../../redux/slice/transHistorySlice/transactionHistorySlice'
import { fetchAllAirtimeHistory, fetchAllCabletvHistory, fetchAllDataHistory, fetchAllElectricityHistory   } from '../../utils/transHistory/fetchTransHistory'
import { IHistoryResponse } from '../../utils/transHistory/historyTypes'
import { useAppDispatch, useAppSelector } from '../../utils/TypeScript'
import Spinner from '../Alert/Spinner/Spinner'
import AirtimeHistoryCardComp from './AirtimeHistoryCard'
import TransactionHistoryCardComp from './TransCard'

const TransactionsPageComp = () => {

    const [loading, setLoading] = useState(false)

    const { currentUser } = useAppSelector(state => state.auth)
    const { currentHistory } = useAppSelector(state => state.transHistory)
    const { currentAirtimeHistory } = useAppSelector(state => state.airtimeHistory)
    const { currentDataHistory } = useAppSelector(state => state.dataHistory)
    const { currentCableTVHistory } = useAppSelector(state => state.cableTVHistory)
    const { currentElectricityHistory } = useAppSelector(state => state.electricityHistory)
    const dispatch = useAppDispatch()

    
    // const getAirtimeTransactionsHistory = async () =>{
    //     setLoading(true)
    
    //     const airtimeHistory = await fetchAllAirtimeHistory() 
        
    //     dispatch(addAirtimeHistory(airtimeHistory))
    
    //     setLoading(false)
    // }
       
    // const getDataTransactionsHistory = async () =>{
    //     setLoading(true)
    
    //     const dataHistory = await fetchAllDataHistory() 
        
    //     dispatch(addDataHistory(dataHistory))
    
    //     // setLoading(false)
    // }  
    
    // const getCabletvTransactionsHistory = async () =>{
    //     setLoading(true)
    
    //     const cableTvhistory = await fetchAllCabletvHistory() 
        
    //     dispatch(addCableTVHistory(cableTvhistory))
    
    //     // setLoading(false)
    // }


    // const getElectricityTransactionsHistory = async () =>{
    //     setLoading(true)
    
    //     const electricityhistory = await fetchAllElectricityHistory() 
        
    //     dispatch(addElectricityHistory(electricityhistory))
    
    //     // setLoading(false)
    // }  
    
    
    // useEffect(()=>{
    //     getCabletvTransactionsHistory()
    // }, [])
    
    // useEffect(()=>{
    //     getAirtimeTransactionsHistory()
    // }, [])
    
    // useEffect(()=>{
    //     getDataTransactionsHistory()
    // }, [])

    // useEffect(()=>{
    //     getElectricityTransactionsHistory()
    // }, [])
   



  return (
    
            <div className="col-lg-9">
                <h2 className="admin-heading bg-offwhite">All Transactions</h2>

                {/* <div className="row">
                    
                    <div className="col-12">
                        <form id="custom-filter-area" method="post">
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="form-group filter-area">
                                        <i className="fas fa-calendar-alt"></i>
                                        <input id="custom-date" type="date" className="form-control py-4" placeholder="Date Range" />
                                        <div className="export-area" data-toggle="collapse">
                                            <div className="dropdown filter-btn">
                                                <Link className=" btn-link ml-2" to="#"><i className="far fa-file-excel"></i></Link>
                                                <Link className=" btn-link ml-2" to="#"> <i className="far fa-file-pdf"></i></Link>
                                                <Link className=" btn-link ml-2" to="#" ><i className="fas fa-print"></i></Link>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                            </div>
                        </form>
                    </div>
                </div> */}

                <div className="profile-content">
                   
                    <div className="transaction-title bg-offwhite">
                            <div className="items">
                                <div className="row">
                                    <div className="col"><span className="">Date</span></div>
                                    <div className="col">Description</div>
                                    <div className="col text-center">contact</div>
                                    <div className="col text-center">Status</div>
                                    <div className="col">Amount</div>
                                </div>
                            </div>
                        </div>

                        <div className="transaction-area">

                            {/* {
                                currentAirtimeHistory?.map(( obj: IHistoryResponse, i )=>{
                                    return <TransactionHistoryCardComp hist={obj} key={i}/>
                                })
                            } */}
                            {
                                currentAirtimeHistory?.map(( obj: IHistoryResponse, i )=>{
                                    return <AirtimeHistoryCardComp hist={obj} key={i}/>
                                })
                            }

                            {/* <TransactionHistoryCardComp /> */}
                           
                        </div>

                        {/* Pagination for transaction History */}

                    {/* <ul className="pagination justify-content-left mt-4 pt-4 pl-0">
                        <li className="page-item disabled"><Link className="page-link" to="#" ><i className="fas fa-angle-left"></i></Link></li>
                        <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                        <li className="page-item active"><Link className="page-link" to="#">2 <span className="sr-only">(current)</span></Link></li>
                        <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                        <li className="page-item d-flex align-content-center text-muted mr-2">...</li>
                        <li className="page-item"><Link className="page-link" to="#">19</Link></li>
                        <li className="page-item"><Link className="page-link" to="#"><i className="fas fa-angle-right"></i></Link>
                        </li>
                    </ul> */}








                    {/* <ul className="pagination justify-content-left mt-4 pt-4 pl-0">
                        <li className="page-item disabled"><a className="page-link" to="#" tabindex="-1"><i className="fas fa-angle-left"></i></a></li>
                        <li className="page-item"><a className="page-link" to="#">1</a></li>
                        <li className="page-item active"><a className="page-link" to="#">2 <span className="sr-only">(current)</span></a></li>
                        <li className="page-item"><a className="page-link" to="#">3</a></li>
                        <li className="page-item d-flex align-content-center text-muted mr-2">...</li>
                        <li className="page-item"><a className="page-link" to="#">19</a></li>
                        <li className="page-item"><a className="page-link" to="#"><i className="fas fa-angle-right"></i></a>
                        </li>
                    </ul> */}

                </div>
                { loading && <Spinner /> }

            </div>
  )
}

export default TransactionsPageComp