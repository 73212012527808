import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentEKEDCPrepaidOrder } from '../../../utils/electricityTypes/electricityTypes'


export interface EKEDCPrepaidState {
    currentEKEDCPrepaidOrder?: ICurrentEKEDCPrepaidOrder,
    loading: boolean
}

const initialState: EKEDCPrepaidState = {
    currentEKEDCPrepaidOrder: undefined,
    loading: false,
}


const eKEDCPrepaidSlice = createSlice({
    name: 'ekedcPrepaidElect',
    initialState,
    reducers: {
      addEKEDCPrepaidOrder: (state, action) => {
        state.currentEKEDCPrepaidOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('ekedcprepaid') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('ekedcprepaid') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addEKEDCPrepaidOrder } = eKEDCPrepaidSlice.actions

export default eKEDCPrepaidSlice.reducer
