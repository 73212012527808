import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slice/authSlice'
import dataSlice from './slice/dataSlice/dataSlice'

import billersSlice from "./slice/billersSlice"

import cableTVPhotoSlice from "./slice/cableTVSlice/cableTVPhotoSlice"
import cableTVSlice from "./slice/cableTVSlice/cableTVSlice"
import dSTVSlice from "./slice/cableTVSlice/dstvSlice"
import gOTVSlice from "./slice/cableTVSlice/gotvSlice"

import airtimePhotoSlice from "./slice/airtimeSlice/airtimePhotoSlice"
import nineMobileSlice from "./slice/airtimeSlice/nineMobileSlice"
import startimesSlice from "./slice/cableTVSlice/startimesSlice"
import airtelSlice from "./slice/airtimeSlice/airtelSlice"
import gloSlice from "./slice/airtimeSlice/gloSlice"
import mtnSlice from "./slice/airtimeSlice/mtnSlice"
import ntelSlice from "./slice/airtimeSlice/ntelSlice"

import dataPhotoSlice from "./slice/dataSlice/dataPhotoSlice"
import mtnDataSlice from "./slice/dataSlice/mtnDataSlice"
import nineMobileDataSlice from "./slice/dataSlice/nineMobileSlice"
import airtelDataSlice from "./slice/dataSlice/airtelSlice"
import gloDataSlice from "./slice/dataSlice/gloSlice"
import ntelDataSlice from "./slice/dataSlice/ntelSlice"
import spectranetDataSlice from "./slice/dataSlice/spectranetSlice"
import smileDataSlice from "./slice/dataSlice/smileSlice"

import electricityPhotoSlice from "./slice/electricitySlice/electricityPhotoSlice"
import abujaElectSlice from "./slice/electricitySlice/abujaSlice"
import ekedcOrderElectSlice from "./slice/electricitySlice/EKEDCOrderPayment"
import ekedcPostpaidSlice from "./slice/electricitySlice/ekedcPostpaidSlice"
import ekedcPrepaidSlice from "./slice/electricitySlice/ekedcPrepaidSlice"
import phedcSlice from "./slice/electricitySlice/phedcSlice"
import ibedcSlice from "./slice/electricitySlice/ibedcSlice"
import iePostpaidSlice from "./slice/electricitySlice/iePostpaidSlice"
import iePrepaidSlice from "./slice/electricitySlice/iePrepaidSlice"
import josPostpaidSlice from './slice/electricitySlice/josPostpaidSlice'
import josPrepaidSlice from './slice/electricitySlice/josPrepaidSlice'
import kadunaPostpaidSlice from './slice/electricitySlice/kadunaPostpaidSlice'
import kadunaPrepaidSlice from './slice/electricitySlice/kadunaPrepaidSlice'
import kanoPostpaidSlice from './slice/electricitySlice/kanoPostpaidSlice'
import kanoPrepaidSlice from './slice/electricitySlice/kanoPrepaidSlice'
import transactionSateSlice from './slice/transactionState/transactionState'

import transactionHistorySlice from './slice/transHistorySlice/transactionHistorySlice'
import airtimeHistorySlice from './slice/transHistorySlice/airtimeHistory'
import dataHistorySlice from './slice/transHistorySlice/dataHistory'
import cableTVHistorySlice from './slice/transHistorySlice/cableTvHistory'
import electricityHistorySlice from './slice/transHistorySlice/electricityHistory'

import historyBillerCodeSlice from './slice/transHistorySlice/transHistoryBillerCode'
import profileSlice from './slice/profileSlice/profileSlice'

import airtimeSlice from "./slice/airtimeSlice/airtimeSlice"
import bulkAirtimeSlice from "./slice/airtimeSlice/bulkAirtimeSlice"
import multipleAirtimeSlice from "./slice/airtimeSlice/multipleAirtimeSlice"
import bettingPhotoSlice from "./slice/bettingSlice/bettingPhoto"
import bettingSlice from "./slice/bettingSlice/bettingSlice"

import educationPhotoSlice from "./slice/educationSlice/educationPhoto"
import jambSlice from "./slice/educationSlice/jambSlice"
import waecPINSlice from "./slice/educationSlice/waecPINSlice"

import electSlice from "./slice/electricitySlice/electSlice"

import firsSlice from "./slice/govtPaySlice/firsSlice"
import bodiesSlice from "./slice/bodiesSlice"




import { loadState, saveState } from '../utils/helpers'


export interface RootState {
  transactionState: ReturnType<typeof transactionSateSlice>;
  auth: ReturnType<typeof authSlice>;
  airtime: ReturnType<typeof airtimeSlice>;
  bulkAirtime: ReturnType<typeof bulkAirtimeSlice>;
  multipleAirtime: ReturnType<typeof multipleAirtimeSlice>;
  data: ReturnType<typeof dataSlice>;
  billers: ReturnType<typeof billersSlice>;
  cableTVPhoto: ReturnType<typeof cableTVPhotoSlice>;
  cableTV: ReturnType<typeof cableTVSlice>;
  dSTV: ReturnType<typeof dSTVSlice>;
  gOTV: ReturnType<typeof gOTVSlice>;
  startimes: ReturnType<typeof startimesSlice>;
  airtimePhoto: ReturnType<typeof airtimePhotoSlice>;
  nineMobile: ReturnType<typeof nineMobileSlice>;
  airtel: ReturnType<typeof airtelSlice>;
  glo: ReturnType<typeof gloSlice>;
  mtn: ReturnType<typeof mtnSlice>;
  ntel: ReturnType<typeof ntelSlice>;
  dataPhoto: ReturnType<typeof dataPhotoSlice>;
  mtnData: ReturnType<typeof mtnDataSlice>;
  nineMobileData: ReturnType<typeof nineMobileDataSlice>;
  airtelData: ReturnType<typeof airtelDataSlice>;
  gloData: ReturnType<typeof gloDataSlice>;
  ntelData: ReturnType<typeof ntelDataSlice>;
  spectranetData: ReturnType<typeof spectranetDataSlice>;
  smileData: ReturnType<typeof smileDataSlice>;
  electricityPhoto: ReturnType<typeof electricityPhotoSlice>;
  abujaElect: ReturnType<typeof abujaElectSlice>;
  eKEDCORDERPayment: ReturnType<typeof ekedcOrderElectSlice>;
  ekedcPostpaid: ReturnType<typeof ekedcPostpaidSlice>;
  ekedcPrepaid: ReturnType<typeof ekedcPrepaidSlice>;
  ibedc: ReturnType<typeof ibedcSlice>;
  phedc: ReturnType<typeof phedcSlice>;
  iePostpaid: ReturnType<typeof iePostpaidSlice>;
  iePrepaid: ReturnType<typeof iePrepaidSlice>;
  josPostpaid: ReturnType<typeof josPostpaidSlice>;
  josPrepaid: ReturnType<typeof josPrepaidSlice>;
  kadunaPostpaid: ReturnType<typeof kadunaPostpaidSlice>;
  kadunaPrepaid: ReturnType<typeof kadunaPrepaidSlice>;
  kanoPostpaid: ReturnType<typeof kanoPostpaidSlice>;
  kanoPrepaid: ReturnType<typeof kanoPrepaidSlice>;
  transHistory: ReturnType<typeof transactionHistorySlice>;
  airtimeHistory: ReturnType<typeof airtimeHistorySlice>;
  dataHistory: ReturnType<typeof dataHistorySlice>;
  cableTVHistory: ReturnType<typeof cableTVHistorySlice>;
  electricityHistory: ReturnType<typeof electricityHistorySlice>;
  transHistoryBillerCode: ReturnType<typeof historyBillerCodeSlice>;
  profile: ReturnType<typeof profileSlice>;
  bettingPhoto: ReturnType<typeof bettingPhotoSlice>;
  betting: ReturnType<typeof bettingSlice>;
  educationPhoto: ReturnType<typeof educationPhotoSlice>;
  jamb: ReturnType<typeof jambSlice>;
  waecPIN: ReturnType<typeof waecPINSlice>;
  electricity: ReturnType<typeof electSlice>;
  firs: ReturnType<typeof firsSlice>;
  bodies: ReturnType<typeof bodiesSlice>;
}


const persistedState: Partial<RootState> | undefined = loadState();


export const store = configureStore({
  reducer: {
    transactionState: transactionSateSlice,
    auth: authSlice,
    airtime: airtimeSlice, bulkAirtime: bulkAirtimeSlice,
    multipleAirtime: multipleAirtimeSlice,
    data: dataSlice,
    billers: billersSlice,
    cableTVPhoto: cableTVPhotoSlice,
    cableTV: cableTVSlice,
    dSTV: dSTVSlice,
    gOTV: gOTVSlice,
    startimes: startimesSlice,
    airtimePhoto: airtimePhotoSlice, nineMobile:nineMobileSlice, airtel:airtelSlice, glo:gloSlice, mtn: mtnSlice, ntel:ntelSlice,
    dataPhoto: dataPhotoSlice, mtnData: mtnDataSlice, nineMobileData: nineMobileDataSlice, airtelData: airtelDataSlice, gloData: gloDataSlice, ntelData: ntelDataSlice, spectranetData: spectranetDataSlice, smileData: smileDataSlice,
    electricityPhoto: electricityPhotoSlice, abujaElect: abujaElectSlice, eKEDCORDERPayment: ekedcOrderElectSlice, ekedcPostpaid: ekedcPostpaidSlice, ekedcPrepaid: ekedcPrepaidSlice, ibedc: ibedcSlice, phedc: phedcSlice, iePostpaid: iePostpaidSlice, iePrepaid: iePrepaidSlice, josPostpaid: josPostpaidSlice, josPrepaid: josPrepaidSlice, kadunaPostpaid: kadunaPostpaidSlice, kadunaPrepaid: kadunaPrepaidSlice, kanoPostpaid: kanoPostpaidSlice, kanoPrepaid: kanoPrepaidSlice, transHistory: transactionHistorySlice, airtimeHistory: airtimeHistorySlice, dataHistory: dataHistorySlice, cableTVHistory: cableTVHistorySlice, electricityHistory: electricityHistorySlice, transHistoryBillerCode: historyBillerCodeSlice, profile: profileSlice, bettingPhoto: bettingPhotoSlice, betting: bettingSlice, educationPhoto: educationPhotoSlice, jamb: jambSlice, waecPIN: waecPINSlice, electricity: electSlice,  firs: firsSlice, bodies: bodiesSlice



  },

  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})



// Subscribe to store changes and save the updated state to local storage
store.subscribe(() => {
  saveState({
    transactionState: store.getState().transactionState,
    auth: store.getState().auth,
    airtime: store.getState().airtime,
    bulkAirtime: store.getState().bulkAirtime,
    multipleAirtime: store.getState().multipleAirtime,
    data: store.getState().data,
    billers: store.getState().billers,
    cableTVPhoto: store.getState().cableTVPhoto,
    cableTV: store.getState().cableTV,
    dSTV: store.getState().dSTV,
    gOTV: store.getState().gOTV,
    startimes: store.getState().startimes,
    airtimePhoto: store.getState().airtimePhoto,
    nineMobile: store.getState().nineMobile,
    airtel: store.getState().airtel,
    glo: store.getState().glo,
    mtn: store.getState().mtn,
    ntel: store.getState().ntel,
    dataPhoto: store.getState().dataPhoto,
    mtnData: store.getState().mtnData,
    nineMobileData: store.getState().nineMobileData,
    airtelData: store.getState().airtelData,
    gloData: store.getState().gloData,
    ntelData: store.getState().ntelData,
    spectranetData: store.getState().spectranetData,
    smileData: store.getState().smileData,
    electricityPhoto: store.getState().electricityPhoto,
    abujaElect: store.getState().abujaElect,
    eKEDCORDERPayment: store.getState().eKEDCORDERPayment,
    ekedcPostpaid: store.getState().ekedcPostpaid,
    ekedcPrepaid: store.getState().ekedcPrepaid,
    ibedc: store.getState().ibedc,
    phedc: store.getState().phedc,
    iePostpaid: store.getState().iePostpaid,
    iePrepaid: store.getState().iePrepaid,
    josPostpaid: store.getState().josPostpaid,
    josPrepaid: store.getState().josPrepaid,
    kadunaPostpaid: store.getState().kadunaPostpaid,
    kadunaPrepaid: store.getState().kadunaPrepaid,
    kanoPostpaid: store.getState().kanoPostpaid,
    kanoPrepaid: store.getState().kanoPrepaid,
    transHistory: store.getState().transHistory,
    airtimeHistory: store.getState().airtimeHistory,
    dataHistory: store.getState().dataHistory,
    cableTVHistory: store.getState().cableTVHistory,
    electricityHistory: store.getState().electricityHistory,
    transHistoryBillerCode: store.getState().transHistoryBillerCode,
    profile: store.getState().profile,
    bettingPhoto: store.getState().bettingPhoto,
    betting: store.getState().betting,
    educationPhoto: store.getState().educationPhoto,
    jamb: store.getState().jamb,
    waecPIN: store.getState().waecPIN,
    electricity: store.getState().electricity,
    firs: store.getState().firs,
    bodies: store.getState().bodies,
  });
});


// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch