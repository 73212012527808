import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentDataOrder} from "../../../utils/AirtimeDateTypeScript/airtimeDataTypes"


export interface DataState {
    currentDataOrder?: ICurrentDataOrder,
    loading: boolean
}

const initialState: DataState = {
    currentDataOrder: undefined,
    loading: false,
}


const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      addDataOrder: (state, action) => {
        state.currentDataOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('data') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('data') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const {  addDataOrder } = dataSlice.actions

export default dataSlice.reducer
