import React from 'react'

const PrivacyMainComp = () => {
  return (
    <section className="terms-condition">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="entry-content" style={{ textJustify:"auto" }}>
                        <h3 style={{color:"#3f0d5e"}}>Privacy Policy for PreponeBills</h3>
                        <p>Our mission is to create rewarding moments. Using our services requires personal information collection and sharing as well as browser data tracking to provide you with these services. In line with our Privacy Policy, we may collect information about you from you directly, or when you visit our site, or perform business transactions with us or from other sources (as permitted by law).</p>

                        <p>We collect information directly from you when you choose to participate in our offers and programs or otherwise provide information directly to us. The following are examples of information we may collect directly from you:</p>

                        <ul>
                            <li><i className="fa fa-angle-double-right" aria-hidden="true"></i>	Customer Name</li>

                            <li><i className="fa fa-angle-double-right" aria-hidden="true"></i>	Card Details</li>

                            <li><i className="fa fa-angle-double-right" aria-hidden="true"></i>	Account Details</li>

                            <li><i className="fa fa-angle-double-right" aria-hidden="true"></i>	Phone Number</li>

                            <li><i className="fa fa-angle-double-right" aria-hidden="true"></i>	E-mail</li>
                        </ul>

                        <p>We may also obtain information about you from data aggregators and public databases.</p>


                        <h6 style={{color:"#3f0d5e"}}>How we use these Information</h6>
                        <p>We use the information we collect primarily for Know your customer (KYC) purposes. We also use the information to manage agreements, process payments, to fulfil our obligations under applicable regulations, and to manage our site and services. </p>

                        <h6 style={{color:"#3f0d5e"}}>Your Choices And Access To Your Information</h6>
                        <p>We give you choices about how we communicate with you.</p>

                        <ul>
                            <li><i className="fa fa-angle-double-right" aria-hidden="true"></i>	E-mail</li>

                            <li><i className="fa fa-angle-double-right" aria-hidden="true"></i>	SMS</li>

                            <li><i className="fa fa-angle-double-right" aria-hidden="true"></i>	Phone call</li>

                            <li><i className="fa fa-angle-double-right" aria-hidden="true"></i>	Hard copy letter</li>

                        </ul>


                           <p>We will continue to use the above channels for business communication for the duration of our business relationship. We may need to retain the contact information and email communications for record keeping, analysis or other reasons, to the extent permitted by law.</p>


                        <h6 style={{color:"#3f0d5e"}}>Access</h6>
                        <p>We take steps to keep your personal information accurate. You can view, correct, or update the personal contact information you provide to PreponeBills. <br />
                        Contact our Data Privacy Officer to access and update the personal information you provided to PreponeBills. We will respond to valid requests in an appropriate timeframe.
                        </p>


                        <h6 style={{color:"#3f0d5e"}}>Information Sharing</h6>
                        <p>We share your personal information only as described below and within PreponeBills.</p>

                        <h6 style={{color:"#3f0d5e"}}>Third-Party Service Providers</h6>
                        <p>We rely on third-party service providers to perform various services on our behalf. To perform these services, we may need to share your personal information with them. For example, we may rely on a service provider to:</p>

                        <ul>
                            <li className='pt-2'><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>Deliver our email and mobile messages.</li>

                            <li className='pt-2'><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>Manage payments or take other actions on our behalf.</li>

                            <li className='pt-2'><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>Conduct research and analyze data, sometimes combined with other sources, to improve our products, services, and sites. <br />


                            We provide these companies with the minimum personal information they need to provide these services on our behalf. We require these companies to protect this personal information and not to use the information for any other purpose.

                            </li>

                            <li className='pt-2'><i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>Sale of our brands or business - In connection with the sale of one or more of our brands or a part of our business to a different company, we reserve the right to transfer your personal information to a new owner that agrees to offer equivalent safeguards for the use and disclosure of your personal information.</li>

                            <li className='pt-2'>
                                <i className="fa fa-angle-double-right mr-2" aria-hidden="true"></i>	Legal action and obligations - We may disclose your personal information: <br />


                                <span className='ml-4'>
                                    <i className="fa fa-angle-right mr-2" aria-hidden="true"></i>
                                    Where permitted by law, to protect and defend the rights and property of PreponeBills (including enforcement of valid agreements).
                                </span> <br />


                                <span className='ml-4'>
                                    <i className="fa fa-angle-right mr-2" aria-hidden="true"></i>
                                    When required by law or public authorities.
                                </span>
                            </li>

                        </ul>


                        <h6 style={{color:"#3f0d5e"}}>Cookies and other technologies</h6>
                        <p>When you interact with the PreponeBills Site, we try to make that experience simple and meaningful. When you visit the PreponeBills Site, a web server sends a cookie or other similar technology to your computer or mobile device (as the case may be).</p>

                        <p>Cookies are small pieces of information which are issued to your computer or mobile device when you visit a website or access or use a mobile application and which store and sometimes track information. Some cookies we use may last only for the duration of your web or application session and expire when you close your browser or exit the PreponeBills Site. Other cookies are used to remember you when you return to the PreponeBills Site and will last for longer. The cookies and/or other similar technologies we use collect a variety of relevant information, such as the type of internet browser or mobile device you use, any website from which you have come to the S PreponeBills Site, your IP address and/or the operating system of your computer or mobile device.</p>



                        <h6 style={{color:"#3f0d5e"}}>Information Security</h6>
                        <p>We place great importance on the security of all personal information associated with our users. We have appropriate technical, administrative, and physical procedures in place to protect personal information from loss, misuse, or alteration. For example, our security and privacy policies are periodically reviewed and enhanced as necessary and only authorized personnel have access to personal information.</p>

                        <p>We keep personal information only for a reasonably needed amount of time. When we provide your personal information to our third-party service providers, we require those companies to protect the information and to not use the information for any other purpose.
                        </p>

                        <p>When we collect or transmit sensitive information such as a credit card number or internet banking password, we use industry standard methods to protect that information.</p>


                        <h6 style={{color:"#3f0d5e"}}>Information Transfer</h6>
                        <p>Your personal information may be transferred to, stored, and processed in a country other than the one in which it was provided. When we do so, we take into consideration the applicable data protection laws for the transfer of personal information.</p>

                        <p>We take steps to protect personal information no matter what country it is stored in or transferred to.</p>

                        <h6 style={{color:"#3f0d5e"}}>Changes To Our Privacy Notice</h6>
                        <p>We will post changes to this privacy notice and update the effective date when this privacy notice is updated. We may modify this policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications.</p>



                        <h6 style={{color:"#3f0d5e"}}>Data Minimalization</h6>
                        <p>PreponeBills limits Personal Information collection and usage to data that is relevant, adequate, and necessary for carrying out the purpose for which the data is processed.</p>

                        <p>PreponeBills will evaluate if, and to what extent the processing of Personal Information is necessary and where the purpose allows, anonymized data will be used.</p>



                        <h6 style={{color:"#3f0d5e"}}>Age Restriction/Child Safety</h6>
                        <p>Protecting the safety of children when they use the Internet is important to us. The PreponeBills Site and Features are intended for use only by persons who are at least 18 years of age. We do not knowingly transact or provide any services to children under 18.</p>



                        <h6 style={{color:"#3f0d5e"}}>Contact Us</h6>
                        <p>If you have questions or concerns regarding PreponeBills’ privacy practices, please register them with us by sending an email to our Data Privacy Officer (info@PreponeBills.com).</p>








                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default PrivacyMainComp