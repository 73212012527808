import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom" 
import { addElectricityHistory } from '../../redux/slice/transHistorySlice/electricityHistory'
import { fetchAllElectricityHistory,  } from '../../utils/transHistory/fetchTransHistory'
import { IHistoryResponse } from '../../utils/transHistory/historyTypes'
import { useAppDispatch, useAppSelector } from '../../utils/TypeScript'
import Spinner from '../Alert/Spinner/Spinner'
import ElectricityHistoryCardComp from './ElectricityHistoryCard'

const ElectricityHistoryPageComp = () => {

    const [loading, setLoading] = useState(false)

    const { currentUser } = useAppSelector(state => state.auth)
    const { currentElectricityHistory } = useAppSelector(state => state.electricityHistory)
    const dispatch = useAppDispatch()

    const userSignUpDate = moment(currentUser?.metadata?.creationTime).format('DD/MM/YYYY').toString()


    
    const getElectricityTransactionsHistory = async () =>{

        if(!currentElectricityHistory){
            setLoading(true)
    
            const electricityhistory = await fetchAllElectricityHistory(currentUser?.email, userSignUpDate) 
            
            dispatch(addElectricityHistory(electricityhistory))
        
            setLoading(false)

        } else {
            return
        }
        
    }    


    useEffect(() => {
        getElectricityTransactionsHistory()
    }, [currentUser])


  return (
    
            <div className="col-lg-9">
                <h2 className="admin-heading bg-offwhite">All Electricity Transactions</h2>
                <div className="profile-content">
                   
                    <div className="transaction-title bg-offwhite">
                            <div className="items">
                                <div className="row">
                                    <div className="col"><span className="">Date</span></div>
                                    <div className="col">Description</div>
                                    <div className="col text-center">Meter No.</div>
                                    <div className="col text-center">Status</div>
                                    <div className="col">Amount</div>
                                </div>
                            </div>
                        </div>

                        <div className="transaction-area">

                            {
                                currentElectricityHistory && currentElectricityHistory?.length < 1 ? 

                                    <div className="items">


                                        <div className="roww">
                                            


                                            <div className="text-center">
                                                <h5 className="text-center">No history to show.</h5>
                                            
                                            </div>
                                        </div> 


                                    </div>

                                :

                                    currentElectricityHistory?.map(( obj: IHistoryResponse, i )=>{
                                        return <ElectricityHistoryCardComp hist={obj} key={i}/>
                                    })
                            }

                        </div>

                </div>
                { loading && <Spinner /> }

            </div>
  )
}

export default ElectricityHistoryPageComp