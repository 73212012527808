import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";

const url = `${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/transactions`

const accessCredentials = {
    'url': `${process.env.REACT_APP_BASE_API_URL}`,
    'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
    'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
    'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
}

const requestHeader = {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}


const TodayDate = moment().format('DD/MM/YYYY').toString()
const launchDate = "14/07/2022"

// console.log({...accessCredentials}, requestHeader)





export const fetchAllAirtimeHistory = async ( userEmail: string | null | undefined, userSignUpDate: string) => {
    try {

        // Airtime
        const searchEmail = userEmail

        // Airtime Payload

        let airtelAirtimePayload = {
            billerCode: "airtel",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }

        let gloAirtimePayload = {
            billerCode: "glo",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let mtnAirtimePayload = {
            billerCode: "mtn",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let nineMobileAirtimePayload = {
            billerCode: "9MOBILEAIRTIME",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let ntelAirtimePayload = {
            billerCode: "NTEL",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }

        
        const airtelResponse = await axios.post(url, {...accessCredentials, payload: airtelAirtimePayload }, requestHeader)
        
        const filteredAIRTELResponse = airtelResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[3] === searchEmail
        })
        
        const gloResponse = await axios.post(url, {...accessCredentials, payload: gloAirtimePayload }, requestHeader)


        const filteredGloResponse = gloResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[3] === searchEmail
        })

        const mtnResponse = await axios.post(url, {...accessCredentials, payload: mtnAirtimePayload },  requestHeader )


        const filteredMTNResponse = mtnResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[3] === searchEmail
        })

        const ninemobileResponse = await axios.post(url, {...accessCredentials, payload: nineMobileAirtimePayload },  requestHeader )


        const filteredNINEMOBILEResponse = ninemobileResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[1] === searchEmail
        })

        const ntelResponse = await axios.post(url, {...accessCredentials, payload: ntelAirtimePayload },  requestHeader )


        const filteredNTELResponse = ntelResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[2] === searchEmail
        })


        console.log(mtnResponse, gloResponse)
        console.log(mtnResponse.data.responseData, gloResponse.data.responseData)

        
        const generalResponse = [
            ...filteredAIRTELResponse, ...filteredGloResponse, 
            ...filteredMTNResponse, 
            ...filteredNINEMOBILEResponse, ...filteredNTELResponse
        ]


        const userHistory = generalResponse.filter((dta: any) =>{
            return dta.transAccountId === `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`
        })


        return userHistory; 
     
    } catch (err: any) { 
        return toast.error(err.message) 
    }

}



export const fetchAllDataHistory = async ( userEmail: string | null | undefined, userSignUpDate: string) => {
    try {

        const searchEmail = userEmail

        // let dataResponses: any = []

        let airtelDataPayload = {
            billerCode: "airtel-data",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let mtnDataPayload = {
            billerCode: "data",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let gloDataPayload = {
            billerCode: "glo-data",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let nineMobileDataPayload = {
            billerCode: "9mobiledata1",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let ntelDataPayload = {
            billerCode: "NTELBundle",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let smileDataPayload = {
            billerCode: "SMILE",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        // let spectranetDataPayload = {
        //     billerCode: "SPECTRANET",  
        //     startDate: `${userSignUpDate}`,
        //     endDate: `${TodayDate}`
        // }

        // Data
        const airtelDataResponse = await axios.post(url, {...accessCredentials, payload: airtelDataPayload },  requestHeader )        
    
        const gloDataResponse = await axios.post(url, {...accessCredentials, payload: gloDataPayload },  requestHeader )

        const mtnDataResponse = await axios.post(url, {...accessCredentials, payload: mtnDataPayload },  requestHeader )

        const ninemobileDataResponse = await axios.post(url, { ...accessCredentials, payload: nineMobileDataPayload },  requestHeader )

        const ntelDataResponse = await axios.post(url, {...accessCredentials, payload: ntelDataPayload },  requestHeader )

        // if(airtelDataResponse.data.responseData === null){
        //     console.log(airtelDataResponse.data.responseData)
        // } else {
        //     dataResponses = [...dataResponses, ...airtelDataResponse.data.responseData]
        // }
        // if(gloDataResponse.data.responseData === null){
        //     console.log(gloDataResponse.data.responseData)
        // } else {
        //     dataResponses = [...dataResponses, ...gloDataResponse.data.responseData]
        // }
        // if(mtnDataResponse.data.responseData === null){
        //     console.log(mtnDataResponse.data.responseData)
        // } else {
        //     dataResponses = [...dataResponses, ...mtnDataResponse.data.responseData]
        // }
        // if(ninemobileDataResponse.data.responseData === null){
        //     console.log(ninemobileDataResponse.data.responseData)
        // } else {
        //     dataResponses = [...dataResponses, ...ninemobileDataResponse.data.responseData]
        // }
        // if(ntelDataResponse.data.responseData === null){
        //     console.log(ntelDataResponse.data.responseData)
        // } else {
        //     dataResponses = [...dataResponses, ...ntelDataResponse.data.responseData]
        // }

       

        // console.log(dataResponses)

        const smileDataResponse = await axios.post(url, {...accessCredentials, payload: smileDataPayload },  requestHeader )

        // let smileResponse: any = []
        // if(smileDataResponse.data.responseData === null){
        //     console.log(smileDataResponse.data.responseData)
        // } else {
        //  smileResponse = [...smileDataResponse.data.responseData]
        //     return smileResponse
        // }

        let dataResponses = [...airtelDataResponse.data.responseData, ...gloDataResponse.data.responseData, ...mtnDataResponse.data.responseData, ...ninemobileDataResponse.data.responseData, ...ntelDataResponse.data.responseData]


        const filteredDATAResponses = dataResponses.filter((dta: any) =>{
            return dta.transDetails.split('|')[2] === searchEmail
        })


        const filteredSMILEDATAResponse = smileDataResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[4] === searchEmail
        })

        // const spectranetDataResponse = await axios.post(url, {...accessCredentials, payload: spectranetDataPayload },  requestHeader )

       
        const generalResponse = [...filteredDATAResponses, ...filteredSMILEDATAResponse ]

        const userHistory = generalResponse.filter((dta: any) =>{
            return dta.transAccountId === `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`
        })

        return userHistory; 
     
    } catch (err: any) { 
        return toast.error(err.message) 
    }

}


export const fetchAllCabletvHistory = async ( userEmail: string | null | undefined, userSignUpDate: string) => {
    try {
        const searchEmail = userEmail

        // CableTv Payload

        let dstvPayload = {
            billerCode: "DSTV1",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let gotvPayload = {
            billerCode: "GOTV1",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let startimesPayload = {
            billerCode: "startimes",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }


        // Data
        const dstvResponse = await axios.post(url, {...accessCredentials, payload: dstvPayload },  requestHeader )

        const filteredDSTVResponse = dstvResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[1] === searchEmail
        })
    
        const gotvResponse = await axios.post(url, {...accessCredentials, payload: gotvPayload },  requestHeader )

        const filteredGOTVResponse = gotvResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[1] === searchEmail
        })

        const startimesResponse = await axios.post(url, {...accessCredentials, payload: startimesPayload },  requestHeader )

        const filteredSTARTIMESResponse = startimesResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[4] === searchEmail
        })


        const generalResponse = [...filteredDSTVResponse, ...filteredGOTVResponse, ...filteredSTARTIMESResponse ]

        const userHistory = generalResponse.filter((dta: any) =>{
            return dta.transAccountId === `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`
        }) 

        // const userHistory = filteredResult.filter((dta: any) =>{
        //     return dta.transDetails.split('|')[1] === "Davidchosen33@gmail.com"
        // }) 

        // const transDetailsArray = props.hist.transDetails.split('|')

        return userHistory;
     
    } catch (err: any) { 
        return toast.error(err.message)  
    }

}



export const fetchAllElectricityHistory = async (userEmail: string | null | undefined, userSignUpDate: string ) => {
    try {

        const searchEmail = userEmail

        // Electricity Payload


        let abjPostpaidPayload = {
            billerCode: "ABJ_POSTPAID",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }


        let abjPrepaidPayload = {
            billerCode: "ABJ_PREPAID",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let ekedcPostpaidPayload = {
            billerCode: "EKEDC_POSTPAID",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let ekedcPrepaidPayload = {
            billerCode: "EKEDC_PREPAID",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        // let ekeOrderPaymentPayload = {
        //     billerCode: "EKEDC_ORDER_PAYMENT",  
        //     startDate: `${launchDate}`,
        //     endDate: `${TodayDate}`
        // }
        let ibedcPayload = {
            billerCode: "IBEDC_F",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let iePostpaidPayload = {
            billerCode: 'iedc postpaid',  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let iePrepaidPayload = {
            billerCode: "iedc",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let josPostpaidPayload = {
            billerCode: "JOS_POSTPAID",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let josPrepaidPayload = {
            billerCode: "JOS_PREPAID",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let kadunaPostpaidPayload = {
            billerCode: "KADUNA_POSTPAID",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        let kadunaPrepaidPayload = {
            billerCode: "KADUNA_PREPAID",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }
        // let kanoPostpaidpaidPayload = {
        //     billerCode: "KEDCOPostpaid",  
        //     startDate: `${launchDate}`,
        //     endDate: `${TodayDate}`
        // }
        // let kanoPrepaidPayload = {
        //     billerCode: "KEDCOPrepaid",  
        //     startDate: `${launchDate}`,
        //     endDate: `${TodayDate}`
        // }
        let phedcPayload = {
            billerCode: "PHEDDIR2",  
            startDate: `${launchDate}`,
            endDate: `${TodayDate}`
        }



        // Data
        const abjPostpaidResponse = await axios.post(url, { ...accessCredentials, payload: abjPostpaidPayload },  requestHeader )

        const filteredABJPOSTResponse = abjPostpaidResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[5] === searchEmail
        })
    
        const abjPrepaidResponse = await axios.post(url, { ...accessCredentials, payload: abjPrepaidPayload },  requestHeader )

        const filteredABJPREResponse = abjPrepaidResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[5] === searchEmail
        })

        const ekedcPostpaidResponse = await axios.post(url, { ...accessCredentials, payload: ekedcPostpaidPayload },  requestHeader )

        const filteredEKEDCPOSTResponse = ekedcPostpaidResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[4] === searchEmail
        })

        const ekedcPrepaidResponse = await axios.post(url, { ...accessCredentials, payload: ekedcPrepaidPayload },  requestHeader )

        const filteredEKEDCPREResponse = ekedcPrepaidResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[5] === searchEmail
        })

        // const ekeOrderpaymentResponse = await axios.post(url, { ...accessCredentials, payload: ekeOrderPaymentPayload },  requestHeader )

        const ibedcResponse = await axios.post(url, { ...accessCredentials, payload: ibedcPayload },  requestHeader )

        const filteredIBEDCResponse = ibedcResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[1] === searchEmail
        })

        const iePostpaidResponse = await axios.post(url, { ...accessCredentials, payload: iePostpaidPayload },  requestHeader )

        const filteredIEPOSTResponse = iePostpaidResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[4] === searchEmail
        })

        const iePrepaidResponse = await axios.post(url, { ...accessCredentials, payload: iePrepaidPayload },  requestHeader )

        const filteredIEPREResponse = iePrepaidResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[4] === searchEmail
        })

        const josPostpaidResponse = await axios.post(url, { ...accessCredentials, payload: josPostpaidPayload },  requestHeader )

        const filteredJOSPOSTResponse = josPostpaidResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[6] === searchEmail
        })

        const josPrepaidResponse = await axios.post(url, { ...accessCredentials, payload: josPrepaidPayload },  requestHeader )

        const filteredJOSPREResponse = josPrepaidResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[6] === searchEmail
        })

        const kadunaPostpaidResponse = await axios.post(url, { ...accessCredentials, payload: kadunaPostpaidPayload },  requestHeader )

        const filteredKDPOSTResponse = kadunaPostpaidResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[6] === searchEmail
        })

        const kadunaPrepaidResponse = await axios.post(url, { ...accessCredentials, payload: kadunaPrepaidPayload },  requestHeader )

        const filteredKDPREResponse = kadunaPrepaidResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[6] === searchEmail
        })

        // const kanoPostpaidResponse = await axios.post(url, { ...accessCredentials, payload: kanoPostpaidpaidPayload },  requestHeader )

        // const kanoPrepaidResponse = await axios.post(url, { ...accessCredentials, payload: kanoPrepaidPayload },  requestHeader )

        const phedcResponse = await axios.post(url, { ...accessCredentials, payload: phedcPayload },  requestHeader )

        const filteredPHEDCResponse = phedcResponse.data.responseData.filter((dta: any) =>{
            return dta.transDetails.split('|')[6] === searchEmail
        })

        console.log(phedcResponse.data.responseData, 
            // kadunaPrepaidResponse, kadunaPostpaidResponse, josPrepaidResponse, josPostpaidResponse, 
            iePrepaidResponse.data.responseData, iePostpaidResponse.data.responseData, ekedcPrepaidResponse.data.responseData, ekedcPostpaidResponse.data.responseData, ibedcResponse.data.responseData 
             );


        const generalResponse = [...filteredABJPOSTResponse, ...filteredABJPREResponse, ...filteredEKEDCPOSTResponse, ...filteredEKEDCPREResponse, ...filteredIBEDCResponse, ...filteredIEPOSTResponse, ...filteredIEPREResponse, ...filteredJOSPOSTResponse, ...filteredJOSPREResponse, ...filteredKDPOSTResponse, ...filteredKDPREResponse, ...filteredPHEDCResponse, ]

        const userHistory = generalResponse.filter((dta: any) =>{
            return dta.transAccountId === `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`
        })

        return userHistory; 


     
    } catch (err: any) { 
        return toast.error(err.message) 
    }

}