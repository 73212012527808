import { createSlice } from '@reduxjs/toolkit'
import { ICurrentCableTVOrder } from "../../../utils/TypeScript"



export interface CableTVState {
    currentCableTVPhoto?: ICurrentCableTVOrder,
    loading: boolean
}

const initialState: CableTVState = {
  currentCableTVPhoto: undefined,
    loading: false,
}


const cableTVPhotoSlice = createSlice({
    name: 'cabletvphoto',
    initialState,
    reducers: {
      addCableTVPhoto: (state, action) => {
        // state.currentOrder = action.payload
        state.currentCableTVPhoto = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('cabletvphoto') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('cabletvphoto') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addCableTVPhoto} = cableTVPhotoSlice.actions

export default cableTVPhotoSlice.reducer
