import { createSlice, } from '@reduxjs/toolkit'
import {  ICurrentNSEOrder } from '../../../utils/TypeScript'


export interface BodiesOrderState {
    currentBodiesOrder?: ICurrentNSEOrder,
}

const initialState: BodiesOrderState = {
    currentBodiesOrder: undefined,
}


const bodiesSlice = createSlice({
    name: 'bodies', 
    initialState,
    reducers: {
      addBodiesOrder: (state, action) => {
        state.currentBodiesOrder = action.payload;
      }
    },
})


export const {  addBodiesOrder } = bodiesSlice.actions

export default bodiesSlice.reducer
