
import { createSlice } from '@reduxjs/toolkit'
import { IHistoryResponse } from '../../../utils/transHistory/historyTypes'


export interface TransHistoryState {
    currentHistory?: IHistoryResponse[],
    loading: boolean
}

const initialState: TransHistoryState = {
    currentHistory: undefined,
    loading: false,
}


const transHistorySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
      addTransHistory: (state, action) => {
        state.currentHistory = [...action.payload]
        // state.currentHistory = [...state.currentHistory, ...action.payload]
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('history') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('history') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addTransHistory } = transHistorySlice.actions

export default transHistorySlice.reducer
