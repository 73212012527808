import { createSlice } from '@reduxjs/toolkit'
import {ICurrentStartimesOrder } from '../../../utils/TypeScript'





export interface StartimesState {
    currentStartimesOrder?:ICurrentStartimesOrder,
    loading: boolean
}

const initialState: StartimesState = {
    currentStartimesOrder: undefined,
    loading: false,
}


const startimesSlice = createSlice({
    name: 'startimes',
    initialState,
    reducers: {
      addStartimesOrder: (state, action) => {
        state.currentStartimesOrder = action.payload
      }
    },

    extraReducers: (builder) => {
      builder
        .addMatcher(
          ({type}) => type.startsWith('startimes') && type.endsWith('/pending'),
          (state) => { state.loading = true }
        )
        .addMatcher(
          ({type}) => type.startsWith('startimes') && type.endsWith('/fulfilled'),
          (state) => { state.loading = false}
        )
    }
})


export const { addStartimesOrder } = startimesSlice.actions

export default startimesSlice.reducer
