import React, {  useEffect } from 'react'
import { Link } from "react-router-dom"

import {  useAppDispatch, useAppSelector } from "../../utils/TypeScript"

import Betting1 from "../assets/images/betting/1.png"
import Betting2 from "../assets/images/betting/2.webp"
import Betting3 from "../assets/images/betting/3.webp"
import Betting4 from "../assets/images/betting/4.webp"
import Betting5 from "../assets/images/betting/5.webp"
import Betting6 from "../assets/images/betting/6.webp"
import Betting7 from "../assets/images/betting/7.webp"
import Betting8 from "../assets/images/betting/8.webp"
import Betting9 from "../assets/images/betting/9.webp"
import Betting10 from "../assets/images/betting/10.webp"
import Betting11 from "../assets/images/betting/11.webp"
import { addBettingPhoto } from '../../redux/slice/bettingSlice/bettingPhoto'
import BettingFormComp from './BettingForm'

// import DSTVFormComp from './DSTVForm'
// import GOTVFormComp from './GOTVForm'
// import StartimesFormComp from './StartimesForm'


const BettingOrderComp = () => {


    const { currentBettingPhoto }  = useAppSelector((state) => state.bettingPhoto)

    const dispatch = useAppDispatch()


    useEffect(()=>{
        const bettingPhoto =  JSON.parse(localStorage.getItem('bettingPhoto') || "{}");
        dispatch(addBettingPhoto(bettingPhoto))

    }, [])
    

  return (
    <div className="col-lg-9">
                    <div className="row mx-1">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3 ">
                                    <div className="mr-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>
                                                    {
                                                    currentBettingPhoto?.billerCode === "BET9JA" ? "BET9JA" 
                                                       : 
                                                       currentBettingPhoto?.billerCode === "MYLOTTOHUB" ? "MYLOTTOHUB" 
                                                       : 
                                                       currentBettingPhoto?.billerCode === "MELBET" ? "MELBET" 
                                                       : 
                                                       currentBettingPhoto?.billerCode === "PARIPESA" ? "PARIPESA" 
                                                       :
                                                       currentBettingPhoto?.billerCode === "BANGBET" ? "BANGBET" 
                                                       :
                                                       currentBettingPhoto?.billerCode === "BETKING" ? "BETKING" 
                                                       :
                                                       currentBettingPhoto?.billerCode === "NAIRAMILLION" ? "NAIRAMILLION" 
                                                       :
                                                       currentBettingPhoto?.billerCode === "CLOUDBET" ? "CLOUDBET" 
                                                       :
                                                       currentBettingPhoto?.billerCode === "ONE_XBET" ? "ONE_XBET" 
                                                       :
                                                       currentBettingPhoto?.billerCode === "NAIJABET" ? "NAIJABET" 
                                                       :
                                                       currentBettingPhoto?.billerCode === "BETLION" ? "BETLION" 
                                                       :
                                                       ""
                                        }
                                        </h4>
                                    </div>
                                    <div className="ml-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>Amount</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills mx-3">
                           
                            <li className="nav-item">
                                <Link className="nav-link active" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                           
                            <li className="nav-item">
                                <Link className="nav-link" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            
                            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                  
                                    <div className="operators mx-3">
                                       
                                       <div className="row operator-row" id='operator-row'>
                                          
                                       <div className="col-md-2 col-sm-4">
                                               <div className="single-operator">
                                                   <div className="op-logo" data-operator="op1">
                                                       <img src={currentBettingPhoto?.billerCode === "BET9JA" ? Betting1 
                                                       : 
                                                       currentBettingPhoto?.billerCode === "MYLOTTOHUB" ? Betting2 
                                                       : 
                                                       currentBettingPhoto?.billerCode === "MELBET" ? Betting3 
                                                       : 
                                                       currentBettingPhoto?.billerCode === "PARIPESA" ? Betting4 
                                                       :
                                                       currentBettingPhoto?.billerCode === "BANGBET" ? Betting5 
                                                       :
                                                       currentBettingPhoto?.billerCode === "BETKING" ? Betting6 
                                                       :
                                                       currentBettingPhoto?.billerCode === "NAIRAMILLION" ? Betting7 
                                                       :
                                                       currentBettingPhoto?.billerCode === "CLOUDBET" ? Betting8 
                                                       :
                                                       currentBettingPhoto?.billerCode === "ONE_XBET" ? Betting9 
                                                       :
                                                       currentBettingPhoto?.billerCode === "NAIJABET" ? Betting10 
                                                       :
                                                       currentBettingPhoto?.billerCode === "BETLION" ? Betting11 
                                                       :
                                                       ""
                                                    } alt="Cable TV Logo" />
                                                   </div>
                                               </div>
                                           </div>
                                          
                                           
                                       </div>
                                   </div>
                                


                                <BettingFormComp />

                                {/* {currentCableTVPhoto?.billerCode === "DSTV1" ? <DSTVFormComp /> : currentCableTVPhoto?.billerCode === "GOTV1" ? <GOTVFormComp /> : currentCableTVPhoto?.billerCode === "startimes" ? <StartimesFormComp /> : ""} */}
                            </div>

                        </div>
                    </div>

                </div>
  )
}

export default BettingOrderComp