import React from 'react'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faCalendar, faUser, faHome, faPalette, faDollar} from '@fortawesome/free-solid-svg-icons';


const WhatWeDo = () => {
  return (
    <section className="work bg-bluewhite">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-9 m-auto text-center">
                    <div className="sec-heading">
                        <span className="tagline"
                        style={{color: "#9417E2"}}>What We Do </span>
                        <h3 className="sec-title" style={{color: "#6C0BA9"}}
                        >Simple, fast and Hassle Free payments. </h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="process">
                        <ul>
                            <Link to="/select-recharge">
                                <li className="card">
                                    <span className="icon-bg">
                                        <i className="">

                                            <FontAwesomeIcon icon={faSignal} />
                                        </i>
                                    </span>
                                    <h5>Buy Airtime</h5>
                                    <p style={{color: "grey"}}
                                    >Airtime recharge/top-up (9mobile, Airtel, MTN and Glo).</p>
                                </li>
                            </Link>
                            <Link to="/data-select">

                                <li className="card">
                                    <span className="icon-bg">
                                        <i className="">
                                        <FontAwesomeIcon icon={faCalendar} />
                                        </i>
                                    </span>
                                    <h5>Buy Data</h5>
                                    <p style={{color: "grey"}}>Data recharge/top-up (9mobile, Airtel, MTN and Glo).</p>
                                </li>
                            </Link>

                            <Link to="select-electricity">

                                <li className="card">
                                    <span className="icon-bg">
                                        <i className="">
                                            <FontAwesomeIcon icon={faUser} />
                                        </i>
                                    </span>
                                    <h5>Electricity Bill </h5>
                                    <p style={{color: "grey"}}>Electricity bill payment (Prepaid and Postpaid).</p>
                                </li>
                            </Link>
                        </ul>
                    </div>

                </div>
                <div className="col-lg-6">
                    <div className="process">
                        <ul>

                            <Link to="/select-cabletv">

                                <li className="card">
                                    <span className="icon-bg">
                                        <i className="faPalette">
                                            <FontAwesomeIcon icon={faPalette} />
                                        </i>
                                    </span>
                                    <h5>Cable TV Bills </h5>
                                    <p style={{color: "grey"}}>Cable TV (DsTV, GoTV, Startimes).</p>
                                </li>
                            </Link>

                            <Link to="">

                                <li className="card">
                                    <span className="icon-bg">
                                        <i className="">
                                            <FontAwesomeIcon icon={faHome} />
                                        </i>
                                    </span>
                                    <h5>Government & Corporate Organizations</h5>
                                    <p style={{color: "grey"}}>Collection services for government corporate organizations.</p>
                                </li>
                            </Link>

                            <Link to="">

                                <li className="card">
                                    <span className="icon-bg">
                                        <i className="">
                                            <FontAwesomeIcon icon={faDollar} />
                                        </i>
                                    </span>
                                    <h5>Religious Institutions</h5>
                                    <p style={{color: "grey"}}>Donations for religious institutions.</p>
                                </li>
                            </Link>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </section>
  )
}

export default WhatWeDo