import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { addDataPhoto } from '../../redux/slice/dataSlice/dataPhotoSlice'
import {  ICurrentOrderGeneral, useAppDispatch } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'


import NineMobile from "../assets/images/data/1.png"
import Airtel from "../assets/images/data/2.png"
import Glo from "../assets/images/data/3.png"
import MTN from "../assets/images/data/4.png"
import NTEL from "../assets/images/data/5.png"
import Smile from "../assets/images/data/6.png"
import Spectranet from "../assets/images/data/7.png"


const DataSelectComp = () => {

    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()



    const getDataBiller = async (billerCode: string ) => {
        setLoading(true)


             const dataPhoto: ICurrentOrderGeneral = {
            
                billerCode: billerCode,
                productId: "",
                
            }

        dispatch(addDataPhoto( dataPhoto))
        localStorage.setItem("dataPhoto", JSON.stringify(dataPhoto));
        navigate("/data-order") 
        setLoading(false)


    }


    useEffect(() => {
        localStorage.clear();
    }, [])


  return (
    <section className="we-work bg-offwhite">
        <div className="container">
            <div className="row">
                <div className=" col-md-12 m-auto text-center">
                    <div className="sec-heading mb-4">
                        <span className="tagline">Service providers</span>
                        {/* <h3 className="sec-title">Select your service provider</h3> */}
                    </div>
                </div>
            </div>
            <div className="portfolioITems">
                <div className="ui-group filters mb-disable">
                    <div className="button-group js-radio-button-group" data-filter-group="color">
                        <button className="button is-checked" data-filter="">All</button>
                        <button className="button" data-filter="">9mobile</button>
                        <button className="button" data-filter=".data">Airtel</button>
                        <button className="button" data-filter=".dth">Glo</button>
                        <button className="button" data-filter=".dth">MTN</button>
                        {/* <button className="button" data-filter=".dth">ntel</button>
                        <button className="button" data-filter=".dth">smile</button>
                        <button className="button" data-filter=".dth">Spectranet</button> */}
                      
                    </div>
                </div>


                <div className="portGrid row">
                    <div className="protItem col-3 col-md-1" onClick={() => getDataBiller('9mobiledata1')}>
                        <img src={NineMobile} alt="" className="we-work-im"  aria-label='9MOBILEDATA' title='9MOBILEDATA'/>
                    </div>


                    <div className="protItem col-3 col-md-1" onClick={() => getDataBiller('airtel-data')}>
                        <img src={Airtel} alt="" className="we-work-im"  aria-label='Airtel Data ' title='Airtel Data '/>
                    </div>


                    <div className="protItem col-3 col-md-1" onClick={() => getDataBiller('glo-data')}>
                        <img src={Glo} alt="" className="we-work-im"  aria-label='Glo Data Bundle' title='Glo Data Bundle'/>
                    </div>


                    <div className="protItem col-3 col-md-1" onClick={() => getDataBiller("data")}>
                        <img src={MTN} alt="" className="we-work-im"  aria-label='MTN DATA' title='MTN DATA'/>
                    </div>


                    {/* <div className="protItem col-3 col-md-1" 
                    // onClick={() => getDataBiller("NTELBundle")}
                    >
                        <img src={NTEL} alt="" className="we-work-im"  aria-label='ntel Data' title='ntel Data'/>
                    </div>


                    <div className="protItem col-3 col-md-1" 
                    // onClick={() => getDataBiller("SMILE")}
                    >
                        <img src={Smile} alt="" className="we-work-im"  aria-label='SMILE DATA BUNDLE' title='SMILE DATA BUNDLE'/>
                    </div>


                    <div className="protItem col-3 col-md-1" 
                    // onClick={() => getDataBiller("SPECTRANET")}
                    >
                        <img src={Spectranet} alt="" className="we-work-im"  aria-label='SPECTRANET' title='SPECTRANET'/>
                    </div> */}

                   

                   
                   

                   
                   
                </div>
            </div>
        </div>

        { loading && <Spinner /> }
    </section>
  )
}

export default DataSelectComp