import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { addCableTVPhoto } from '../../redux/slice/cableTVSlice/cableTVPhotoSlice'
import { addEducationPhoto } from '../../redux/slice/educationSlice/educationPhoto'
import { ICurrentBillerPhoto, useAppDispatch } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'


import Education1 from "../assets/images/education/1.webp"
import Education2 from "../assets/images/education/2.gif"


const EducationSelectComp = () => {

    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()



    const getEducationBiller = async (billerCode: string ) => {
        setLoading(true)


             const educationPhoto: ICurrentBillerPhoto = {
                
                externalref: "",
                billerCode: billerCode,
                productId: "",
                
            }

        dispatch(addEducationPhoto(educationPhoto))
        localStorage.setItem("educationPhoto", JSON.stringify(educationPhoto));
        navigate("/education-order") 
        setLoading(false)


    }

    useEffect(() => {
        localStorage.clear();
    }, [])


  return (
    <section className="we-work bg-offwhite">
        <div className="container">
            <div className="row">
                <div className=" col-md-12 m-auto text-center">
                    <div className="sec-heading mb-4">
                        <span className="tagline">Service providers</span>
                        {/* <h3 className="sec-title">Select your service provider</h3> */}
                    </div>
                </div>
            </div>
            <div className="portfolioITems">
                <div className="ui-group filters mb-disable">
                    <div className="button-group js-radio-button-group" data-filter-group="color">
                        <button className="button is-checked" data-filter="">All</button>
                        <button className="button" data-filter="">WAEC</button>
                        <button className="button" data-filter=".data">JAMB</button>
                      
                    </div>
                </div>


                <div className="portGrid row">
                    <div className="protItem col-3 col-md-2" onClick={() => getEducationBiller("WAEC")}>
                        <img src={Education1} alt="" className="we-work-im"  aria-label="WAEC" title="WAEC"/>
                    </div>

                    <div className="protItem col-3 col-md-2 data" onClick={() => getEducationBiller("JAMB")}>
                        <img src={Education2} alt="" className="we-work-img" aria-label="JAMB" title="JAMB"/>
                    </div>              
                                
                   
                </div>
            </div>
        </div>

        { loading && <Spinner /> }
    </section>
  )
}

export default EducationSelectComp