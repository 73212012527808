import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom'
import { 
  useAppDispatch, useAppSelector, 
} from './utils/TypeScript'
// import XPay from "npm_xpresspay";

import { 
  onIdTokenChanged, 
  // sendEmailVerification, 
  // signOut 
} from 'firebase/auth'
import { auth } from './Firebase'
import { AnimatePresence } from "framer-motion";



import FooterSection from "./components/global/footer/FooterSection";
import Navbar from './components/global/navbar/Navbar';
import TopNavbar from "./components/global/navbar/TopNavbar/TopNavbar";
import PageRender from './PageRender';
import { useLocation } from 'react-router-dom';
import { 
  // toast, 
  ToastContainer 
} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { addUser } from "./redux/slice/authSlice";
import { fetchProfile } from "./redux/slice/profileSlice/profileSlice";
// import { fetchAllAirtimeHistory, fetchAllCabletvHistory, fetchAllDataHistory, fetchAllElectricityHistory } from "./utils/transHistory/fetchTransHistory";
// import { addTransHistory } from "./redux/slice/transHistorySlice/transactionHistorySlice";
// import { addElectricityHistory } from "./redux/slice/transHistorySlice/electricityHistory";
// import { addAirtimeHistory } from "./redux/slice/transHistorySlice/airtimeHistory";
// import { addDataHistory } from "./redux/slice/transHistorySlice/dataHistory";
// import { addCableTVHistory } from "./redux/slice/transHistorySlice/cableTvHistory";



const App = () => {

  const { currentUser } = useAppSelector(state => state.auth)


  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation();


  useEffect(() => {

    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if(user){

        dispatch(addUser(user))
      }else{
        dispatch(addUser(undefined))
      }
    })

    return unsubscribe;

  }, [dispatch, navigate])



  useEffect(() => {
    if(!currentUser?.uid) return;
    dispatch(fetchProfile(currentUser.uid))
  },[currentUser, dispatch])




  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  
 

  return (
  <div>
    <div>
      
        <TopNavbar />
        <Navbar />
        <AnimatePresence exitBeforeEnter initial={false}>

            

          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<PageRender />} />
            <Route path="/:page" element={<PageRender />} />
            <Route path="/:page/:slug" element={<PageRender />} />
          </Routes>
        </AnimatePresence>

          <ToastContainer  autoClose={5000}/>
          <FooterSection />
    </div>
  </div>
  )
}

export default App