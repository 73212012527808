import React from 'react'
import {Link} from "react-router-dom"

const PersonalInfoComp = () => {
  return (
    <div className="edit-content">
        <form id="personaldetails" >
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="firstName"><b>First Name</b></label>
                        <input type="text" value="" className="form-control" data-pr-form="firstName" id="firstName" placeholder="First Name" required />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="fullName"><b>Last Name</b></label>
                        <input type="text" value="" className="form-control" data-pr-form="fullName" id="fullName" placeholder="Full Name" required />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="birthDate"><b>Date of Birth</b></label>
                        <div className="position-relative">
                            <input id="birthDate" value="" type="date" className="form-control" required placeholder="Date of Birth" />
                            <span className="icon-inside">
                                {/* <i className="fas fa-calendar-alt"></i> */}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="emailID">Email ID <span className="text-muted font-weight-500">(Primary)</span></label>
                                <input type="text" value="" className="form-control" data-pr-form="emailid" id="emailID" required placeholder="Email ID" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="emailID">Email ID <span className="text-muted font-weight-500">(Personal)</span></label>
                                <input type="text" value="" className="form-control" data-pr-form="emailid" id="emailID" required placeholder="Email ID" />
                            </div>
                        </div>
                    </div>
                    <Link className="btn-link float-right mb-3" to="#" style={{color:"#9417E2"}}><span className="text-3 mr-1" ><i className="fas fa-plus-circle"></i></span>Add another email</Link>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="mobile-number">Mobile <span className="text-muted font-weight-500">(Primary)</span></label>
                                <input type="text" value="" className="form-control" data-pr-form="mobile-number" id="mobile-number" required placeholder="Mobile Number" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobile-number">Mobile <span className="text-muted font-weight-500">(Home)</span></label>
                                <input type="text" value="" className="form-control" data-pr-form="mobile-number" id="mobile-number" required placeholder="Mobile Number" />
                            </div>
                        </div>
                    </div>
                    <Link className="btn-link float-right mb-3" to="#" style={{color:"#9417E2"}}><span className="text-3 mr-1"><i className="fas fa-plus-circle"></i></span>Add another Phone</Link>
                </div>

            </div>


            <button className="btn btn-default" style={{width: "100%"}} type="submit"><i className="far fa-save"></i> Save Changes</button>
        </form>

    </div>
  )
}

export default PersonalInfoComp